'use strict';

import React from 'react';
import GridEntries from 'globalAdministration/grids/components/gridEntries/gridEntries.container';
import { useParams } from 'react-router-dom';

export function GridsDetailsContainer() {
	const { gridId } = useParams();

	return <GridEntries id={Number(gridId)} />;
}
