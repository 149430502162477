import { IFetchDataProps } from 'types/reactDataWrapper';
import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './gradeLevels.phrases';

function handleError(err: Error): Error {
    const error = formatErrorMessage(err);
    setFeedback(error, 0);

    throw error;
}

export function fetchGradeLevels(state: IFetchDataProps, gradeScaleId: number): Promise<IApiRes> {
    const idFilter: string = `:grade_scale.id=='${gradeScaleId}'`;
    const filter: string = state.filter ? `${idFilter};${state.filter}` : idFilter

    const params = {
        limit: state.limit,
        sort: state.sort,
        offset: state.offset,
        filter,
    };

    return get(
        '/training/grade_levels',
        params,
        null,
        state.headers
    )
        .then((res: IApiRes) => res)
        .catch(handleError);
}

export function addGradeLevels(data: Record<string, unknown>): Promise<IApiRes> {
    return post(`/training/grade_levels`, data)
        .then((res: IApiRes) => {
            setFeedback(phrases.GRADE_LEVEL_ADDED, 1);
            return res;
        })
        .catch(handleError);
}

export function editGradeLevels(id: number, data: Record<string, unknown>): Promise<IApiRes> {
    return post(`/training/grade_levels/${id}`, data)
        .then((res: IApiRes) => {
            setFeedback(phrases.GRADE_LEVEL_EDITED, 1);
            return res;
        })
        .catch(handleError);
}

export function deleteGradeLevels(id: number): Promise<IApiRes> {
    return remove(`/training/grade_levels/${id}`)
        .then((res: IApiRes) => {
            setFeedback(phrases.GRADE_LEVEL_DELETED, 1);
            return res;
        })
        .catch(handleError);
}
