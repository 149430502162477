'use strict';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

// components
import { Icon } from 'dumb';

// mui
import { Box, Typography } from '@mui/material';

export type TipProps = {
	styles?: React.CSSProperties;
	onTipClick?: () => void;
	onTipCloseClick?: () => void;
	content: string;
	icon?: string;
	iconClose?: string;
	iconColor?: string;
	hoverable?: boolean;
};

const defaultProps = {
	onTipClick: noop,
	onTipCloseClick: noop,
	icon: 'lightbulb_outline',
	iconClose: 'highlight_off',
	iconColor: 'black',
	hoverable: false,
	styles: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
};

function Tip({
	onTipClick,
	onTipCloseClick,
	content,
	icon,
	iconClose,
	iconColor,
	hoverable,
	styles,
}: TipProps): JSX.Element {
	const _toggleHoverState = () => {
		if (hoverable) {
			setTipHovered(!tipHovered);
		}
	};

	const onTipClickWrapper = () => {
		if (hoverable && onTipClick) {
			onTipClick();
		}
	};

	const onTipCloseClickWrapper = () => {
		if (hoverable && onTipCloseClick) {
			onTipCloseClick();
		}
	};

	const [tipHovered, setTipHovered] = useState(false);

	return (
		<Box
			sx={{
				...styles,
				...(hoverable && {
					cursor: 'pointer',
				}),
			}}
		>
			<Box
				sx={{ marginRight: (theme) => theme.spacing(0.5) }}
				onClick={onTipCloseClickWrapper}
				onMouseEnter={_toggleHoverState}
				onMouseLeave={_toggleHoverState}
			>
				{tipHovered ? (
					<Icon name={iconClose} color={iconColor} />
				) : (
					<Icon name={icon} color={iconColor} />
				)}
			</Box>

			<Typography
				sx={{
					margin: 0,
					fontSize: (theme) => theme.typography.body2.fontSize,
					textAlign: 'center',
					marginBottom: '2px',
				}}
				onClick={onTipClickWrapper}
			>
				{content}
			</Typography>
		</Box>
	);
}

Tip.defaultProps = defaultProps;

Tip.propTypes = {
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onTipClick: PropTypes.func,
	onTipCloseClick: PropTypes.func,
	icon: PropTypes.string,
	iconColor: PropTypes.string,
	iconClose: PropTypes.string,
	hoverable: PropTypes.bool,
	styles: PropTypes.object,
};

export default Tip;
