'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// different batch views
import IngredientAllergensBatchStepForm from './components/ingredientAllergensBatchStepForm';
import IngredientAllergensBatchStepList from './components/ingredientAllergensBatchStepList';

// phrases/ constants
import phrases from './../../ingredientAllergens.phrases';

// loash
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';

class IngredientAllergensModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this._onSubmitForm = this._onSubmitForm.bind(this);
		this._batchSubmit = this._batchSubmit.bind(this);
		this._getIsBatchFormValid = this._getIsBatchFormValid.bind(this);

		this.modalRef = React.createRef();
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList, useGlobalResource } = this.props;

		let listArray;
		if (useGlobalResource) {
			listArray = batchFormData.ingredients.map((entry) => ({
				identifier: _uniqueId('row-'),
				ingredient: entry,
				degree: batchFormData.degree,
				allergen: batchFormData.allergen,
			}));
		} else {
			listArray = batchFormData.markets.reduce((acc, currentValue) => {
				const values = batchFormData.ingredients.map((entry) => ({
					identifier: _uniqueId('row-'),
					ingredient: entry,
					market: currentValue,
					degree: batchFormData.degree,
					allergen: batchFormData.allergen,
				}));

				const combined = [...acc, ...values];

				return combined;
			}, []);
		}

		addBatchList(listArray);

		this._goToStep(2);
	}

	_batchSubmit() {
		const { batchListData, handleClose, batchAddData, useGlobalResource } =
			this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchListData.map((entry) => {
			return {
				ingredient: entry?.ingredient?.value?.id ?? null,
				...(!useGlobalResource && {
					market: entry?.market?.value?.id ?? null,
				}),
				ingredient_allergen_degree_of: entry?.degree?.value ?? null,
				allergen: entry?.allergen?.value,
			};
		});

		batchAddData({ batch: payload }, useGlobalResource)
			.then(() => {
				this.setState(() => ({
					loadingModal: false,
				}));

				handleClose();
			})
			.catch(() =>
				this.setState(() => ({
					loadingModal: false,
				}))
			);
	}

	_getIsBatchFormValid() {
		const { batchFormData, useGlobalResource } = this.props;

		if (useGlobalResource)
			return (
				!_isEmpty(batchFormData.ingredients) &&
				batchFormData.degree &&
				batchFormData.allergen
			);

		return (
			!_isEmpty(batchFormData.ingredients) &&
			!_isEmpty(batchFormData.markets) &&
			batchFormData.degree &&
			batchFormData.allergen
		);
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
			useGlobalResource,
		} = this.props;

		const steps = [
			{
				component: (
					<IngredientAllergensBatchStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						useGlobalResource={useGlobalResource}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: this._getIsBatchFormValid(),
				onNext: this._onSubmitForm,
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_NEXT_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<IngredientAllergensBatchStepList
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						loyaltyProductUrl={this.loyaltyProductUrl}
						useGlobalResource={useGlobalResource}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: this._batchSubmit,
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				ref={this.modalRef}
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
			/>
		) : null;
	}
}

IngredientAllergensModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	addBatchList: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchAddData: PropTypes.func,
	useGlobalResource: PropTypes.bool,
};

export default IngredientAllergensModalBatch;
