import React from 'react';
import { IColumnHeader } from 'types/reactDataWrapper';
import { FilterProvider } from 'reactDataWrapper/utilities';
import { InputCollectionSelect } from 'dumb';
import collectionSelectEnums from 'services/enums/collectionSelect';
import { SelectOption } from 'types/select';

export default function getColumns(
	reduxKey: string
): IColumnHeader<IEmploymentType>[] {
	return [
		{
			id: 'type',
			Header: 'Type',
			accessor: 'type',
			filterPath: ':type',
			sortable: true,
			filterable: true,
		},
		{
			id: 'description',
			Header: 'Description',
			accessor: 'description',
			filterPath: ':description',
			sortable: true,
			filterable: true,
		},
		{
			id: 'market',
			Header: 'Market',
			accessor: 'market.name',
			filterPath: ':market.name',
			sortable: true,
			filterable: true,
			Filter: ({ column }) => (
				<FilterProvider
					reduxKey={reduxKey}
					columnId={column.id}
					provide={(
						filterValue: SelectOption,
						persistToFilterStorage: ({
							handle,
							value,
						}: {
							handle: string;
							value: SelectOption;
						}) => void
					) => (
						<InputCollectionSelect
							id={column.id}
							name="market"
							value={filterValue}
							handleChange={(key, value) => {
								persistToFilterStorage({ handle: key, value });
							}}
							styleType={collectionSelectEnums.TYPE_IN_TABLE}
							cache
							apiPath="/administration/markets"
							params={{
								limit: 30,
							}}
							optionFormat={(entry) => ({
								value: entry.id,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>
					)}
				/>
			),
		},
		{
			id: 'min',
			Header: 'Min',
			accessor: 'minimum_hours',
			filterPath: ':minimum_hours',
			sortable: true,
			filterable: true,
			Cell: (d) =>
				d.original.minimum_hours ? `${d.original.minimum_hours} hrs` : '',
		},
		{
			id: 'max',
			Header: 'Max',
			accessor: 'maximum_hours',
			filterPath: ':maximum_hours',
			sortable: true,
			filterable: true,
			Cell: (d) =>
				d.original.maximum_hours ? `${d.original.maximum_hours} hrs` : '',
		},
		{
			id: 'modulationPeriod',
			Header: 'Modulation Period',
			accessor: 'modulation_period',
			filterPath: ':modulation_period',
			sortable: true,
			filterable: true,
			Cell: (d) =>
				d.original.modulation_period
					? `${d.original.modulation_period} weeks`
					: '',
		},
		{
			id: 'standardHours',
			Header: 'Standard Hours',
			accessor: 'standard_hours',
			filterPath: ':standard_hours',
			sortable: true,
			filterable: true,
			Cell: (d) =>
				d.original.standard_hours ? `${d.original.standard_hours} hrs` : '',
		},
		{
			id: 'payType',
			Header: 'Pay Type',
			accessor: 'pay_type',
			filterPath: ':pay_type',
			sortable: true,
			filterable: true,
		},
		{
			id: 'role',
			Header: 'Role',
			accessor: 'role',
			filterPath: ':role',
			sortable: true,
			filterable: true,
		},
		{
			Header: 'Legacy',
			id: 'legacy',
			accessor: 'legacy',
			filterPath: ':legacy',
			Cell: (d) => <input type="checkbox" disabled checked={!!d.value} />,
		},
	];
}
