import React, { useContext, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

// redux
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import changeCase from 'change-case';

// Ui
import {
	CardActionArea,
	CardContent,
	Card,
	Typography,
	Grid,
	Box,
} from '@mui/material';

// Utils
import { JoeRouterProvider } from 'router/config/routerProvider';
import { getChildrenRoutes } from 'router/config/router.utils.jsx';

const removeDuplicates = (arr, check) => {
	return arr.filter((item, index) => {
		const i = arr.findIndex((x) => x[check] === item[check]);
		return index === i;
	});
};

const TileDirectory = ({ pathName, parent }) => {
	const powerBIReports = useSelector((state) => state.powerBIReport?.reports);
	const dispatch = useDispatch();
	const { filteredRoutes } = useContext(JoeRouterProvider);
	const { section, pages } = getChildrenRoutes(
		pathName,
		filteredRoutes,
		parent
	);

	const handleClick = (page) => {
		if (page.external) {
			return window.open(page.path, '_blank');
		}
		return dispatch(push(`${window.location.pathname}/${page.path}`));
	};

	const pagesToRender = useMemo(() => {
		if (pathName === 'reports') {
			const filteredPowerBIReports = removeDuplicates(powerBIReports, 'path');

			return [...pages, ...filteredPowerBIReports];
		}
		return pages;
	},[pathName, pages, powerBIReports]);

	return (
		<Box component="section" mb={2}>
			<Box pt={2} pb={1}>
				<Typography variant="h1" component="h1" gutterBottom>
					{section.title}
				</Typography>
			</Box>

			<Grid container spacing={1}>
				{pagesToRender.map((page, index) => {
					return (
						<Fragment key={index}>
							{page.section && (
								<Grid item xs={12} sm={12} md={12} mt={1}>
									<Typography variant="h5" component="h2">
										{page.section}
									</Typography>
								</Grid>
							)}
							<Grid item xs={12} sm={6} md={3}>
								<Card onClick={() => handleClick(page)}>
									<CardActionArea>
										<CardContent>
											<Typography
												sx={{ fontWeight: 600 }}
												align="center"
												variant="h5"
												component="h2"
												noWrap
												gutterBottom
											>
												{changeCase.sentence(page.title)}
											</Typography>
											<Typography
												gutterBottom
												align="center"
												variant="body2"
												noWrap
												component="p"
											>
												{page.description}
											</Typography>
										</CardContent>
									</CardActionArea>
								</Card>
							</Grid>
						</Fragment>
					);
				})}
			</Grid>
		</Box>
	);
};

TileDirectory.propTypes = {
	pathName: PropTypes.string.isRequired,
	parent: PropTypes.string,
};

export default TileDirectory;
