import { ActionType } from 'types/redux';
import { IModuleReduxValue, IModuleReduxState } from '../types';
import { SET_MODULE, UPDATE_MODULE, RESET_STATE, UPDATE_LEGACY_FILTER_STATE } from './modules.actions';

const defaultState: IModuleReduxState = {
    data: {
        defaultModule: null,
        module: {
            name: null,
            type: null,
            sort_order: null,
            grade_scale: null,
            moneyball_position: null,
            badge_category: null,
            module_category: null,
            legacy: false,
        },
    },
    legacyFilterOn: true,
    legacyFilter: null,
};

function reducer(
    state = defaultState,
    action: ActionType
): IModuleReduxState {
    const { type, payload } = action;

    switch (type) {
        case SET_MODULE: {
            const castedPayload = payload as IModuleReduxValue;

            return {
                ...state,
                data: {
                    module: castedPayload,
                    defaultModule: castedPayload,
                },
            };
        }

        case UPDATE_MODULE: {
            const castedPayload = payload as Partial<IModuleReduxValue>;

            return {
                ...state,
                data: {
                    ...state.data,
                    module: {
                        ...state.data.module,
                        ...castedPayload,
                    },
                },
            };
        }

        case UPDATE_LEGACY_FILTER_STATE: {
            const castedPayload = payload as boolean;

            return {
                ...state,
                legacyFilterOn: castedPayload,
            };
        }

        case RESET_STATE: {
            return defaultState;
        }

        default:
            return state;
    }
}

export default reducer;
