// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './posConfigurationVersionCurrencies.phrases';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw error;
}

/**
 * @function fetchPosConfigurationVersionCurrencies
 * @description Fetch all pos configuration version currencies
 */
export function fetchPosConfigurationVersionCurrencies(
	state: IFetchDataProps,
	extraFilter: string
) {
	const filter = state.filter ? `${state.filter};${extraFilter}` : extraFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/pos/pos_configuration_currencies', params, null, state.headers)
		.then((res: IApiRes<IPosConfigurationCurrency>) => {
			return res;
		})
		.catch(handleError);
}

export function addPosConfigurationVersionCurrency(
	data: Record<string, unknown>
) {
	return post('/pos/pos_configuration_currencies', data)
		.then((res: IApiRes<IPosConfigurationCurrency>) => {
			setFeedback(phrases.POS_CONFIGURATION_VERSION_CURRENCY_ADDED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function editPosConfigurationVersionCurrency
 * @param {Object} payload - update object
 */
export function editPosConfigurationVersionCurrency(
	data: Record<string, unknown>
) {
	return post(`/pos/pos_configuration_currencies/${data.id}`, data)
		.then((res: IApiRes<IPosConfigurationCurrency>) => {
			setFeedback(phrases.POS_CONFIGURATION_VERSION_CURRENCY_EDITED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function deletePosConfigurationVersionCurrency
 * @param {Number} id - id of pos config version to delete
 */
export function deletePosConfigurationVersionCurrency(id: number) {
	return remove(`/pos/pos_configuration_currencies/${id}`)
		.then((res: IApiRes<IPosConfigurationCurrency>) => {
			setFeedback(phrases.POS_CONFIGURATION_VERSION_CURRENCY_DELETED, 1);
			return res;
		})
		.catch(handleError);
}
