import {
	Body,
	Head,
	HeaderCell,
	Row,
	Subtitle,
	Table,
	TableCell,
	Title,
	Report,
} from 'dumb/report';
import constants from 'dumb/report/constants';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import getWaitingTimeValue from './../utilities/getWaitingTimeValue';

import ShiftplannerReportPrWorkplace from './shiftplannerReportPrWorkplace.component';
import { Loader } from 'dumb';

const SLOW = 'Slow';
const BUSY = 'Busy';
const TOO_BUSY = 'Too Busy';

class ShiftplannerMarkets extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			expandedRows: new Set(),
		};
	}

	/**
	 * @function _filterReportEntriesBasedOnClickedRow
	 * @private
	 * @description Utility function for the filter method.
	 * extracts all entries with same workplaceId, Type, and start of period.
	 * @param {Object} entry : The entry from the map function.
	 * @param {Object} clickedRow : The clicked row
	 * @return bool
	 */
	_filterReportEntriesBasedOnClickedRow(entry, clickedRow = {}) {
		return (
			entry.workplace.id === clickedRow.workplace.id &&
			entry.type === clickedRow.type &&
			entry.period.from === clickedRow.period.from
		);
	}

	/**
	 * @function _addConditionalClassnames
	 * @private
	 * @param {object} value
	 * @param {string} path
	 * @param {isFuture} path to condition that should be met in order for
	 * @param {numberOfProducts} path to products
	 */
	_addConditionalClassnames(
		value,
		path,
		isFuture,
		numberOfProducts,
		isAllStar
	) {
		const future = _get(value, isFuture, false);
		const products = _get(value, numberOfProducts, 0);
		const allStar = _get(value, isAllStar, false);

		return !future && value.rowType === 'entry' && products > 0
			? [
					{ 'shiftplanner-report__heat--slow': _get(value, path) === SLOW },
					{ 'shiftplanner-report__heat--busy': _get(value, path) === BUSY },
					{
						'shiftplanner-report__heat--too-busy':
							_get(value, path) === TOO_BUSY,
					},
					{ 'shiftplanner-report__heat--golden': allStar === true },
			  ]
			: [];
	}

	/**
	 * @method _onExpandHandler
	 * @memberof ShiftplannerMarkets
	 * @param {integer} rowNumber - the index of the row being clicked
	 * @param {Object} entry - The clicked entry
	 */
	_onExpandHandler(rowNumber, entry) {
		// only entry type rows should be expanded
		if (entry.rowType !== 'entry') return;

		this.setState(
			(prevState) => {
				const expandedRows = new Set(prevState.expandedRows);
				expandedRows.has(rowNumber)
					? expandedRows.delete(rowNumber)
					: expandedRows.add(rowNumber);

				return {
					loading: true,
					expandedRows,
				};
			},
			() => {
				this.props.fetchShiftplannerReportPrWorkplace(entry).then(() => {
					this.setState(() => ({
						loading: false,
					}));
				});
			}
		);
	}

	_renderRows() {
		return !_isEmpty(this.props.reportData)
			? this.props.reportData
					.sort((a, b) => {
						return a.rowType === 'entry' && b.rowType === 'entry' &&
							a.workplace.sort_order < b.workplace.sort_order
							? -1
							: 1;
					})
					.map((entry, index) => {
						return (
							<Row
								key={entry.id}
								index={index}
								type={entry.rowType}
								totalColSpan={this.props.totalColSpan}
								onClick={() => this._onExpandHandler(index, entry)}
								expanded={this.state.expandedRows.has(index)}
								renderExpandedHeader={null}
								renderExpanded={(rowNumber) => {
									const reportData = this.props.shiftplannerReportPrWorkplace.filter(
										(entry) =>
											this._filterReportEntriesBasedOnClickedRow(
												entry,
												this.props.reportData[rowNumber]
											)
									);
									if (this.state.loading && _isEmpty(reportData))
										return <Loader />;

									return (
										<ShiftplannerReportPrWorkplace
											totalColSpan={this.props.totalColSpan}
											showProductsPrShift={this.props.showProductsPrShift}
											shiftplannerReportPrWorkplace={
												this.props.shiftplannerReportPrWorkplace
											}
											reportData={reportData}
											showHalfHourly={this.props.showHalfHourly}
										/>
									);
								}}>
								<TableCell
									colSpan="4"
									content={_get(entry, 'name', null)}
									alignLeft
								/>

								<TableCell
									colSpan="2"
									alignRight
									content={_get(entry, 'workplace.grid.name', null)}
								/>

								{/* Monday */}
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									separate
									alignRight
									content={_get(entry, 'monday.employees', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'monday.heat',
										'monday.future',
										'monday.fuel_cost',
										'monday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.INTEGER}
									colSpan="1"
									alignRight
									content={_get(entry, 'monday.optimal_employees', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'monday.heat',
										'monday.future',
										'monday.fuel_cost',
										'monday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									alignRight
									content={getWaitingTimeValue(entry, 'monday')}
									classNames={this._addConditionalClassnames(
										entry,
										'monday.heat',
										'monday.future',
										'monday.fuel_cost',
										'monday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.INTEGER}
									colSpan="1"
									alignRight
									content={_get(entry, 'monday.fuel_cost', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'monday.heat',
										'monday.future',
										'monday.fuel_cost',
										'monday.all_star'
									)}
								/>

								{/* Tuesday */}
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									alignRight
									separate
									content={_get(entry, 'tuesday.employees', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'tuesday.heat',
										'tuesday.future',
										'tuesday.fuel_cost',
										'tuesday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.INTEGER}
									colSpan="1"
									alignRight
									content={_get(entry, 'tuesday.optimal_employees', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'tuesday.heat',
										'tuesday.future',
										'tuesday.fuel_cost',
										'tuesday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									alignRight
									content={getWaitingTimeValue(entry, 'tuesday')}
									classNames={this._addConditionalClassnames(
										entry,
										'tuesday.heat',
										'tuesday.future',
										'tuesday.fuel_cost',
										'tuesday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.INTEGER}
									colSpan="1"
									alignRight
									content={_get(entry, 'tuesday.fuel_cost', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'tuesday.heat',
										'tuesday.future',
										'tuesday.fuel_cost',
										'tuesday.all_star'
									)}
								/>

								{/* Wednesday */}
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									alignRight
									separate
									content={_get(entry, 'wednesday.employees', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'wednesday.heat',
										'wednesday.future',
										'wednesday.fuel_cost',
										'wednesday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.INTEGER}
									colSpan="1"
									alignRight
									content={_get(entry, 'wednesday.optimal_employees', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'wednesday.heat',
										'wednesday.future',
										'wednesday.fuel_cost',
										'wednesday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									alignRight
									content={getWaitingTimeValue(entry, 'wednesday')}
									classNames={this._addConditionalClassnames(
										entry,
										'wednesday.heat',
										'wednesday.future',
										'wednesday.fuel_cost',
										'wednesday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.INTEGER}
									colSpan="1"
									alignRight
									content={_get(entry, 'wednesday.fuel_cost', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'wednesday.heat',
										'wednesday.future',
										'wednesday.fuel_cost',
										'wednesday.all_star'
									)}
								/>

								{/* Thursday */}
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									alignRight
									separate
									content={_get(entry, 'thursday.employees', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'thursday.heat',
										'thursday.future',
										'thursday.fuel_cost',
										'thursday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.INTEGER}
									colSpan="1"
									alignRight
									content={_get(entry, 'thursday.optimal_employees', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'thursday.heat',
										'thursday.future',
										'thursday.fuel_cost',
										'thursday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									alignRight
									content={getWaitingTimeValue(entry, 'thursday')}
									classNames={this._addConditionalClassnames(
										entry,
										'thursday.heat',
										'thursday.future',
										'thursday.fuel_cost',
										'thursday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.INTEGER}
									colSpan="1"
									alignRight
									content={_get(entry, 'thursday.fuel_cost', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'thursday.heat',
										'thursday.future',
										'thursday.fuel_cost',
										'thursday.all_star'
									)}
								/>

								{/* Friday */}
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									alignRight
									separate
									content={_get(entry, 'friday.employees', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'friday.heat',
										'friday.future',
										'friday.fuel_cost',
										'friday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.INTEGER}
									colSpan="1"
									alignRight
									content={_get(entry, 'friday.optimal_employees', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'friday.heat',
										'friday.future',
										'friday.fuel_cost',
										'friday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									alignRight
									content={getWaitingTimeValue(entry, 'friday')}
									classNames={this._addConditionalClassnames(
										entry,
										'friday.heat',
										'friday.future',
										'friday.fuel_cost',
										'friday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.INTEGER}
									colSpan="1"
									alignRight
									content={_get(entry, 'friday.fuel_cost', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'friday.heat',
										'friday.future',
										'friday.fuel_cost',
										'friday.all_star'
									)}
								/>

								{/* saturday */}
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									alignRight
									separate
									content={_get(entry, 'saturday.employees', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'saturday.heat',
										'saturday.future',
										'saturday.fuel_cost',
										'saturday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.INTEGER}
									colSpan="1"
									alignRight
									content={_get(entry, 'saturday.optimal_employees', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'saturday.heat',
										'saturday.future',
										'saturday.fuel_cost',
										'saturday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									alignRight
									content={getWaitingTimeValue(entry, 'saturday')}
									classNames={this._addConditionalClassnames(
										entry,
										'saturday.heat',
										'saturday.future',
										'saturday.fuel_cost',
										'saturday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.INTEGER}
									colSpan="1"
									alignRight
									content={_get(entry, 'saturday.fuel_cost', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'saturday.heat',
										'saturday.future',
										'saturday.fuel_cost',
										'saturday.all_star'
									)}
								/>

								{/* sunday */}
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									alignRight
									separate
									content={_get(entry, 'sunday.employees', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'sunday.heat',
										'sunday.future',
										'sunday.fuel_cost',
										'sunday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.INTEGER}
									colSpan="1"
									alignRight
									content={_get(entry, 'sunday.optimal_employees', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'sunday.heat',
										'sunday.future',
										'sunday.fuel_cost',
										'sunday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									alignRight
									content={getWaitingTimeValue(entry, 'sunday')}
									classNames={this._addConditionalClassnames(
										entry,
										'sunday.heat',
										'sunday.future',
										'sunday.fuel_cost',
										'sunday.all_star'
									)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.INTEGER}
									colSpan="1"
									alignRight
									content={_get(entry, 'sunday.fuel_cost', null)}
									classNames={this._addConditionalClassnames(
										entry,
										'sunday.heat',
										'sunday.future',
										'sunday.fuel_cost',
										'sunday.all_star'
									)}
								/>

								{/* Summary */}
								<TableCell
									cellType={constants.CELL_TYPES.PERCENTAGE}
									colSpan="1"
									alignRight
									separate
									content={_get(entry, 'summary.slow_hours', null)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.PERCENTAGE}
									colSpan="1"
									alignRight
									content={_get(entry, 'summary.optimal_hours', null)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.PERCENTAGE}
									colSpan="1"
									alignRight
									content={_get(entry, 'summary.busy_hours', null)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.PERCENTAGE}
									colSpan="1"
									alignRight
									content={_get(entry, 'summary.too_busy_hours', null)}
								/>
								<TableCell
									cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
									colSpan="1"
									alignRight
									content={_get(
										entry,
										'summary.average_products_sold_hourly',
										null
									)}
								/>
							</Row>
						);
					})
			: null;
	}

	render() {
		const { title, subtitle } = this.props;

		return (
			<Report dataCy="shiftplanner-report">
				<Title title={title} />
				<Subtitle subtitle={subtitle} rightAligned />
				<Table fixed>
					<Head>
						<Row>
							<HeaderCell colSpan="6" content="Workplace" alignLeft />
							<HeaderCell colSpan="4" separate content="Monday" />
							<HeaderCell colSpan="4" separate content="Tuesday" />
							<HeaderCell colSpan="4" separate content="Wednesday" />
							<HeaderCell colSpan="4" separate content="Thursday" />
							<HeaderCell colSpan="4" separate content="Friday" />
							<HeaderCell colSpan="4" separate content="Saturday" />
							<HeaderCell colSpan="4" separate content="Sunday" />
							<HeaderCell colSpan="5" separate content="Summary" />
						</Row>

						<Row subheader>
							<HeaderCell colSpan="4" content="Workplace" alignLeft />
							<HeaderCell colSpan="2" content="HR Grid" alignRight />

							<HeaderCell
								colSpan="1"
								content="Man H."
								tooltip="Man Hours"
								separate
							/>
							<HeaderCell
								colSpan="1"
								content="Opt. H."
								tooltip="Optimal Hours"
							/>
							<HeaderCell
								colSpan="1"
								content="AWT"
								tooltip="Average Waiting Time"
							/>
							<HeaderCell
								colSpan="1"
								content="W. Prod."
								tooltip="Weighted Products"
							/>

							<HeaderCell
								colSpan="1"
								content="Man H."
								tooltip="Man Hours"
								separate
							/>
							<HeaderCell
								colSpan="1"
								content="Opt. H."
								tooltip="Optimal Hours"
							/>
							<HeaderCell
								colSpan="1"
								content="AWT"
								tooltip="Average Waiting Time"
							/>
							<HeaderCell
								colSpan="1"
								content="W. Prod."
								tooltip="Weighed Products"
							/>

							<HeaderCell
								colSpan="1"
								content="Man H."
								tooltip="Man Hours"
								separate
							/>
							<HeaderCell
								colSpan="1"
								content="Opt. H."
								tooltip="Optimal Hours"
							/>
							<HeaderCell
								colSpan="1"
								content="AWT"
								tooltip="Average Waiting Time"
							/>
							<HeaderCell
								colSpan="1"
								content="W. Prod."
								tooltip="Weighed Products"
							/>

							<HeaderCell
								colSpan="1"
								content="Man H."
								tooltip="Man Hours"
								separate
							/>
							<HeaderCell
								colSpan="1"
								content="Opt. H."
								tooltip="Optimal Hours"
							/>
							<HeaderCell
								colSpan="1"
								content="AWT"
								tooltip="Average Waiting Time"
							/>
							<HeaderCell
								colSpan="1"
								content="W. Prod."
								tooltip="Weighed Products"
							/>

							<HeaderCell
								colSpan="1"
								content="Man H."
								tooltip="Man Hours"
								separate
							/>
							<HeaderCell
								colSpan="1"
								content="Opt. H."
								tooltip="Optimal Hours"
							/>
							<HeaderCell
								colSpan="1"
								content="AWT"
								tooltip="Average Waiting Time"
							/>
							<HeaderCell
								colSpan="1"
								content="W. Prod."
								tooltip="Weighed Products"
							/>

							<HeaderCell
								colSpan="1"
								content="Man H."
								tooltip="Man Hours"
								separate
							/>
							<HeaderCell
								colSpan="1"
								content="Opt. H."
								tooltip="Optimal Hours"
							/>
							<HeaderCell
								colSpan="1"
								content="AWT"
								tooltip="Average Waiting Time"
							/>
							<HeaderCell
								colSpan="1"
								content="W. Prod."
								tooltip="Weighed Products"
							/>

							<HeaderCell
								colSpan="1"
								content="Man H."
								tooltip="Man Hours"
								separate
							/>
							<HeaderCell
								colSpan="1"
								content="Opt. H."
								tooltip="Optimal Hours"
							/>
							<HeaderCell
								colSpan="1"
								content="AWT"
								tooltip="Average Waiting Time"
							/>
							<HeaderCell
								colSpan="1"
								content="W. Prod."
								tooltip="Weighed Products"
							/>

							<HeaderCell colSpan="1" content="Slow" separate />
							<HeaderCell colSpan="1" content="Op." />
							<HeaderCell colSpan="1" content="Busy" />
							<HeaderCell colSpan="1" content="T/Busy" />
							<HeaderCell colSpan="1" content="P/H" />
						</Row>
					</Head>

					<Body>{this._renderRows()}</Body>
				</Table>
			</Report>
		);
	}
}

ShiftplannerMarkets.propTypes = {
	reportData: PropTypes.array,
	showProductsPrShift: PropTypes.string,
	subtitle: PropTypes.string,
	title: PropTypes.string,
	totalColSpan: PropTypes.number,
	showHalfHourly: PropTypes.bool,
	shiftplannerReportPrWorkplace: PropTypes.array,
	fetchShiftplannerReportPrWorkplace: PropTypes.func,
};

ShiftplannerMarkets.defaultProps = {
	reportData: {},
};

export default ShiftplannerMarkets;
