import React from 'react';
import { useSelector } from 'react-redux';
import { useRoutes, Outlet } from 'react-router-dom';
import { getNavigationItems } from 'services/navigation';

import { useGate } from 'statsig-react';
import { introRoute } from '../intro';
import { hrRoutes } from '../hr';
import { organizationRoutes } from '../organization';
import { financeRoutes } from '../finance';
import { shiftPlannerRoutes } from '../shiftPlanner';
import { myShiftPlannerRoutes } from '../myShiftPlanner';
import { shiftMarketplaceRoutes } from '../shiftMarketplace';
import { salesRoutes } from '../sales';
import { salaryRoutes } from '../salary';
import { getInventoryControlRoutes } from '../inventory';
import { userManagementRoutes } from '../userManagement';
import { reportsRoutes } from '../reports';
import { globalAdministrationRoutes } from '../globalAdministration';
import { salesConfigurationRoutes } from '../salesConfiguration';

// Comps
import TileDirectory from 'smart/tileDirectory/tileDirectory';

const navigationLinks = [
	{
		// section: 'External Links',
		title: 'Store tasks',
		element: <></>,
		allowedAccessControlLevels: ['Shiftplan View'],
		icon: require('@mui/icons-material/PlaylistAddCheckOutlined').default,
		external: true,
		path: 'https://storetask.joejuice.com/',
	},
	{
		title: 'E-campus',
		path: 'https://e-campus.joejuice.com',
		element: <></>,
		allowedAccessControlLevels: ['Shiftplan View'],
		icon: require('@mui/icons-material/SchoolOutlined').default,
		external: true,
	},
	{
		title: 'Whistleblower',
		path: 'https://joejuice.whistleblowernetwork.net/frontpage',
		element: <></>,
		allowedAccessControlLevels: ['Shiftplan View'],
		icon: require('@mui/icons-material/Sports').default,
		external: true,
	},
	{
		title: 'IT Support',
		path: 'https://helpdesk.joejuice.com',
		element: <></>,
		allowedAccessControlLevels: ['Shiftplan View'],
		icon: require('@mui/icons-material/SupportAgent').default,
		external: true,
	},
	{
		path: 'external-links',
		title: 'Other resources',
		element: <Outlet />,
		allowedAccessControlLevels: ['Shiftplan View'],
		icon: require('@mui/icons-material/ListAltOutlined').default,
		// external: true,
		// path: 'https://storetask.joejuice.com/',
		children: [
			{
				index: true,
				element: (
					<div>
						<TileDirectory pathName="external-links" />
						{/* <PowerBiDirectory /> */}
					</div>
				),
			},
			{
				title: 'Retail',
				path: 'http://retail.joejuice.com',
				element: <></>,
				allowedAccessControlLevels: ['Shiftplan View'],
				external: true,
			},
			{
				title: 'Joe Pay Console',
				path: 'https://joepay-console.joejuice.com',
				element: <></>,
				allowedAccessControlLevels: ['Shiftplan View'],
				external: true,
			},
			{
				title: 'Privacy Notice',
				path: 'https://content.joejuice.com/policy/employee-privacy-notice',
				element: <></>,
				allowedAccessControlLevels: ['Shiftplan View'],
				external: true,
			},
			{
				title: 'Concept Manual',
				path: 'https://concept-manual.webflow.io',
				allowedAccessControlLevels: ['Shiftplan View'],
				external: true,
			},
		],
	},
];

export default function useFilteredRoutes() {
	const hasInventory = useGate('inventory').value;
	const hasInventoryControl = useGate('inventory_control').value;
	const routes = [
		...(process.env.CYPRESS_TEST === 'true' ? [] : introRoute),
		...organizationRoutes,
		...hrRoutes,
		...financeRoutes,
		...salesRoutes,
		...salaryRoutes,
		...(hasInventory
			? [...getInventoryControlRoutes(hasInventoryControl)]
			: []),
		...shiftPlannerRoutes,
		...myShiftPlannerRoutes,
		...shiftMarketplaceRoutes,
		...userManagementRoutes,
		...reportsRoutes,
		...globalAdministrationRoutes,
		...salesConfigurationRoutes(),
		...navigationLinks,
	];

	const user = useSelector((state) => state?.userData?.user);
	const filteredRoutes = getNavigationItems({ routes, user });

	return {
		routes: useRoutes([
			...filteredRoutes,
			{ path: '*', element: <p>Route not found</p> },
		]),
		filteredRoutes,
	};
}
