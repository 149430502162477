import React from 'react';
import { Tip, TipProps } from '../tip';

type ErrorTipProps = Pick<TipProps, 'content'>;

export default function ErrorTip({ content }: ErrorTipProps) {
	return (
		<Tip
			content={content}
			icon="error_outline"
			iconColor="red"
			styles={{
				color: 'red',
				display: 'flex',
				flexDirection: 'row',
			}}
		/>
	);
}
