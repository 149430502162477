import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Services
import { validAccess } from 'accessControl';

// Components
import StaffDetails from './components/staffDetails';
import { Modal } from 'dumb';

// Store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import * as actions from './store/staffDetails.actions';
import {
	updatePhoneNumber,
	updatePinCode,
	updateEmail,
	toggleEditMode,
	toggleTrainingHistory,
	setQualificationData,
	updateQualifications,
	setGradeReviewRowData,
	updateGradeReviewRowData,
	resetGradeReviewRowData,
	setUserSeniority,
	resetUserSeniority,
	setFetching,
	_staffDetailsFetched,
	_staffPinFetched,
	setUserDetails,
	resetState,
	_toggleUserDetailsSaving,
	_userDetailsUpdated,
	clearStaffDetailsData,
} from './store/staffDetails.actions';

// utils
import {
	getDetails,
	getPin,
	updatePin,
	updateDetails,
	fetchSeniority,
} from './staffDetails.service';
import { set as setFeedback } from 'feedback.vanilla.service';

// Tools
import _isEmpty from 'lodash/isEmpty';
import phrases from './staffDetails.phrases';

class StaffDetailsContainer extends Component {
	constructor(props) {
		super(props);

		this.handleClose = this.handleClose.bind(this);
		this.toggleEditMode = this.toggleEditMode.bind(this);
		this.toggleTrainingHistory = this.toggleTrainingHistory.bind(this);
		this.saveEditedUserDetails = this.saveEditedUserDetails.bind(this);
		this.handleFetchPin = this.handleFetchPin.bind(this);
		this.handleGetUserDetails = this.handleGetUserDetails.bind(this);
		this.handleUpdateStaffDetails = this.handleUpdateStaffDetails.bind(this);

		this.managerRole = validAccess(undefined, [
			'Shiftplan Manager',
			'Shiftplan Admin',
		]);
	}

	componentDidMount() {
		this.loadData();
	}

	componentWillUnmount() {
		const { resetUserSeniority, editMode, resetState } = this.props;

		resetUserSeniority();
		resetState();
		if (editMode) this.toggleEditMode();
	}

	loadData() {
		const {
			marketId,
			person,
			seniority,
			setUserSeniority,
			setFetching,
			resetState,
		} = this.props;

		const personId = person.id;

		if (personId && marketId) {
			setFetching(true);

			this.handleGetUserDetails({ personId, marketId })
				.then(this.handleFetchPin)
				.catch(() => {
					resetState();
					setFetching(false);
				});
		}

		// if no seniority passed
		if (!seniority && validAccess(undefined, ['Shiftplan Manager']))
			fetchSeniority({ personId }).then((res) => {
				if (_isEmpty(res.data)) return;
				setUserSeniority(res.data[0]);
			});
	}

	handleGetUserDetails({ personId, marketId }) {
		const { setUserDetails } = this.props;

		return getDetails(personId, marketId).then((res) => {
			const payload = {
				id: res?.person?.id ?? null,
				name: res?.person?.full_name ?? null,
				email: res?.person?.person_email_address?.email_address ?? null,
				phoneNumber: res?.person?.person_phone_number?.phone_number ?? null,
				badge: res?.person?.current_moneyball_sub_position?.badge ?? null,
			};

			setUserDetails(payload);

			return res;
		});
	}

	handleFetchPin(res) {
		const {
			updatePinCode,
			staffDetailsFetched,
			staffPinFetched,
			setFetching,
			loggedInPersonId,
			person,
		} = this.props;

		const personId = person.id;

		// Retrieve pin if logged in user same as user being viewed
		if (loggedInPersonId === personId) {
			let pinPayload = {};

			return getPin(personId)
				.then((pinData) => {
					updatePinCode(pinData.pin);
					pinPayload = pinData;

					staffDetailsFetched(res);
					staffPinFetched(pinPayload);
					setFetching(false);
				})
				.catch(() => {
					setFetching(false);
					updatePinCode(null);
				});
		} else {
			staffPinFetched({});
			staffDetailsFetched(res);
			setFetching(false);
		}
	}

	handleClose() {
		const { toggleEditMode, toggleModalView } = this.props;

		// todo: cleanup -> this probably doesn't work
		clearStaffDetailsData();

		toggleEditMode(false);
		toggleModalView();
	}

	saveEditedUserDetails() {
		const {
			phoneNumber,
			email,
			pin,
			staffDetails,
			pinData,
			person,
			loggedInPersonId,
		} = this.props;

		const viewingLoggedInUser = person.id === loggedInPersonId;

		const data = {
			email_address: email,
			phone_number: phoneNumber,
		};

		if (viewingLoggedInUser && pin !== pinData.pin) data.pin = pin;

		this.toggleEditMode();
		this.handleUpdateStaffDetails(
			staffDetails.id,
			staffDetails.person.id,
			data
		);
	}

	handleUpdateStaffDetails(id, personId, data) {
		const {
			toggleUserDetailsSaving,
			staffPinFetched,
			staffDetailsFetched,
			userDetailsUpdated,
		} = this.props;

		if (_isEmpty(data)) return;

		toggleUserDetailsSaving(true);

		// if we're updating the pin - only for our user
		if (data.pin) {
			updatePin(personId, data.pin)
				.then((pinData) => {
					staffPinFetched(pinData);

					if (!data.phone_number && !data.email_address)
						toggleUserDetailsSaving(false);
				})
				.catch(() => toggleUserDetailsSaving(false));
		}

		// don't try to update phone number or email if we don't have manager role
		if (!this.managerRole) {
			toggleUserDetailsSaving(false);
			return;
		}

		// updating phone number or an email
		if (data.phone_number || data.email_address) {
			return updateDetails(id, data)
				.then((payload) => {
					staffDetailsFetched(payload);
					toggleUserDetailsSaving(false);

					setFeedback('Staff details updated', 1);

					setTimeout(() => {
						userDetailsUpdated('');
					}, 1500);
				})
				.catch(() => {
					toggleUserDetailsSaving(false);
					setTimeout(() => {
						userDetailsUpdated('');
					}, 1500);
				});
		}
	}

	toggleEditMode() {
		const { editMode, toggleEditMode } = this.props;
		const toggle = !editMode;

		toggleEditMode(toggle);
	}

	toggleTrainingHistory() {
		const { isTrainingVisible, toggleTrainingHistory } = this.props;

		toggleTrainingHistory(!isTrainingVisible);
	}

	render() {
		const {
			badge,
			children,
			editMode,
			email,
			fetching,
			headerComponent,
			id,
			isTrainingVisible,
			name,
			optionsArray,
			phoneNumber,
			pin,
			savingUserDetails,
			staffDetails,
			userUpdateStatus,
			visible,
			zIndex,
			marketId,
			toggleModalView,
			updatePhoneNumber,
			updatePinCode,
			updateEmail,
			qualificationData,
			setQualificationData,
			updateQualifications,
			resetTrainingExamsData,
			setGradeReviewRowData,
			updateGradeReviewRowData,
			gradeReviewRowData,
			resetGradeReviewRowData,
			seniority,
			person,
			userSeniority,
			loggedInPersonId,
		} = this.props;

		const userData = {
			id,
			name,
			email,
			phoneNumber,
			pin,
		};
		const viewingLoggedInUser = loggedInPersonId === person.id;

		return (
			<Modal
				onClose={toggleModalView}
				confirmButtonLabel={phrases.CLOSE_MODAL_BUTTON_TEXT}
				onConfirmClick={toggleModalView}
				isOpen={visible}
				loading={fetching}
				zIndex={450}
				noContentPadding
				dataCy="staff-details-modal"
			>
				<StaffDetails
					userData={userData}
					title="Staff details"
					modifierClassName="staff-details-modal"
					marketId={marketId}
					handleClose={this.handleClose}
					saveEditedUserDetails={this.saveEditedUserDetails}
					toggleEditMode={this.toggleEditMode}
					toggleTrainingHistory={this.toggleTrainingHistory}
					badge={badge}
					// eslint-disable-next-line react/no-children-prop
					children={children}
					details={staffDetails}
					editMode={editMode}
					fetching={fetching}
					updateEmail={updateEmail}
					updatePhoneNumber={updatePhoneNumber}
					updatePinCode={updatePinCode}
					headerComponent={headerComponent}
					isTrainingVisible={isTrainingVisible}
					optionsArray={optionsArray}
					phrases={phrases}
					savingUserDetails={savingUserDetails}
					userUpdateStatus={userUpdateStatus}
					visible={visible}
					zIndex={zIndex}
					qualificationData={qualificationData}
					setQualificationData={setQualificationData}
					updateQualifications={updateQualifications}
					resetTrainingExamsData={resetTrainingExamsData}
					setGradeReviewRowData={setGradeReviewRowData}
					updateGradeReviewRowData={updateGradeReviewRowData}
					gradeReviewRowData={gradeReviewRowData}
					resetGradeReviewRowData={resetGradeReviewRowData}
					seniority={seniority || userSeniority}
					viewingLoggedInUser={viewingLoggedInUser}
				/>
			</Modal>
		);
	}
}

StaffDetailsContainer.defaultProps = {
	person: {},
	marketId: null,
	visible: false,
	toggleTrainingHistory() {},
	toggleModalView() {},
	headerComponent() {},

	// redux state props
	staffDetails: {},
	fetching: false,
	updatePhoneNumber() {},
	updatePinCode() {},
	updateEmail() {},
};

StaffDetailsContainer.propTypes = {
	person: PropTypes.object,
	marketId: PropTypes.number,
	visible: PropTypes.bool,
	zIndex: PropTypes.number,
	toggleTrainingHistory: PropTypes.func,
	toggleModalView: PropTypes.func,
	optionsArray: PropTypes.arrayOf(Object),

	// redux state props
	staffDetails: PropTypes.object,
	fetching: PropTypes.bool,
	updatePhoneNumber: PropTypes.func,
	updatePinCode: PropTypes.func,
	updateEmail: PropTypes.func,
	toggleEditMode: PropTypes.func,
	phoneNumber: PropTypes.string,
	email: PropTypes.string,
	pin: PropTypes.number,
	pinData: PropTypes.object,
	editMode: PropTypes.bool,
	isTrainingVisible: PropTypes.bool,
	badge: PropTypes.object,
	children: PropTypes.object,
	headerComponent: PropTypes.func,
	id: PropTypes.number,
	name: PropTypes.string,
	savingUserDetails: PropTypes.bool,
	userUpdateStatus: PropTypes.string,
	qualificationData: PropTypes.object,
	setQualificationData: PropTypes.func,
	updateQualifications: PropTypes.func,
	resetTrainingExamsData: PropTypes.func,
	setGradeReviewRowData: PropTypes.func,
	updateGradeReviewRowData: PropTypes.func,
	resetGradeReviewRowData: PropTypes.func,
	gradeReviewRowData: PropTypes.object,
	seniority: PropTypes.array,
	// same as seniority but in case where we open
	// staff details outside shiftplanner we need ot re-fetch it
	userSeniority: PropTypes.array,
	setUserSeniority: PropTypes.func,
	resetUserSeniority: PropTypes.func,
	setFetching: PropTypes.func,
	staffDetailsFetched: PropTypes.func,
	staffPinFetched: PropTypes.func,
	setUserDetails: PropTypes.func,
	resetState: PropTypes.func,
	toggleUserDetailsSaving: PropTypes.func,
	userDetailsUpdated: PropTypes.func,
	loggedInPersonId: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updatePhoneNumber: updatePhoneNumber,
			updatePinCode: updatePinCode,
			updateEmail: updateEmail,
			toggleEditMode: toggleEditMode,
			toggleTrainingHistory: toggleTrainingHistory,
			setQualificationData: setQualificationData,
			updateQualifications: updateQualifications,
			resetTrainingExamsData: updateQualifications,
			setGradeReviewRowData: setGradeReviewRowData,
			updateGradeReviewRowData: updateGradeReviewRowData,
			resetGradeReviewRowData: resetGradeReviewRowData,
			setUserSeniority: setUserSeniority,
			resetUserSeniority: resetUserSeniority,
			setFetching: setFetching,
			staffDetailsFetched: _staffDetailsFetched,
			staffPinFetched: _staffPinFetched,
			setUserDetails: setUserDetails,
			resetState: resetState,
			toggleUserDetailsSaving: _toggleUserDetailsSaving,
			userDetailsUpdated: _userDetailsUpdated,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		loggedInPersonId: store.userData.user?.user?.person?.id ?? null,
		qualificationData: store.staffDetails.qualificationData,
		staffDetails: store.staffDetails.details,
		pinData: store.staffDetails.pinData,
		fetching: store.staffDetails.fetching,
		email: store.staffDetails.email,
		phoneNumber: store.staffDetails.phoneNumber,
		pin: store.staffDetails.pin,
		id: store.staffDetails.id,
		name: store.staffDetails.name,
		badge: store.staffDetails.badge,
		editMode: store.staffDetails.editMode,
		isTrainingVisible: store.staffDetails.isTrainingVisible,
		savingUserDetails: store.staffDetails.savingUserDetails,
		userUpdateStatus: store.staffDetails.userUpdateStatus,
		gradeReviewRowData: store.staffDetails.gradeReviewRowData,
		userSeniority: store.staffDetails.userSeniority,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StaffDetailsContainer);
