import { IColumnHeader } from 'types/reactDataWrapper';

export default function getColumns(): IColumnHeader<IAdministrationModuleCategory>[] {
    return [
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            filterPath: ':name',
        },
        {
            Header: 'Sort Order',
            id: 'sort_order',
            accessor: 'sort_order',
            filterPath: ':sort_order',
        },
    ];
}
