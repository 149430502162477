import React, { useCallback, useState, ChangeEvent } from 'react';
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/hr/employmentTypes.columns';
import getMarketColumns from 'reactDataWrapperColumns/organization/markets.columns';
import { Button, Input, InputCollectionSelect, Toggle } from 'dumb';
import { SelectOption } from 'types/select';
import {
	fetchEmploymentTypes,
	createEmploymentType,
	editEmploymentType,
	deleteEmploymentType,
} from './employmentTypes.service';
import { ZodError, z } from 'zod';
import { FormErrors } from 'types/utils';
import { useAppSelector } from 'appState/storeHooks';

const reduxKey = 'hr/employmentTypes';

const typeOptions = ['FA', 'FLEX', 'PA', 'PT', 'FT'] as const;
const payTypeOptions = ['Hourly', 'Fixed'] as const;
const roleOptions = ['Operation', 'HQ'] as const;

const schema = z.object({
	type: z.enum(typeOptions),
	description: z.string().nullish(),
	market: z.number().positive(),
	minimum_hours: z.number().positive().nullish(),
	maximum_hours: z.number().positive().nullish(),
	modulation_period: z.number().positive().nullish(),
	standard_hours: z.number().positive().nullish(),
	pay_type: z.enum(payTypeOptions),
	role: z.enum(roleOptions),
	legacy: z.boolean().nullish(),
});

export default function EmploymentTypes() {
	const [formErrors, setFormErrors] = useState<FormErrors<IEmploymentType>>({});
	const [formData, setFormData] = useState<Partial<IEmploymentType>>({});
	const [showLegacy, setShowLegacy] = useState(false);

	const { customFilters } = useAppSelector((state) => ({
		customFilters: state.filterSortColumnsData.tables[reduxKey]?.custom ?? {},
	}));

	const getExtraFilters = (): string => {
		let filter = '';

		const marketFilter = customFilters?.market
			? `:market.id=='${(customFilters.market as SelectOption).value}'`
			: '';

		if (marketFilter)
			filter = filter ? `${filter};${marketFilter}` : marketFilter;

		const legacyFilter = showLegacy ? '' : ':legacy==false';

		if (legacyFilter)
			filter = filter ? `${filter};${legacyFilter}` : legacyFilter;

		return filter;
	};

	const handleToggleLegacyFilter = useCallback(() => {
		setShowLegacy((prev) => !prev);
	}, [setShowLegacy]);

	const handleEdit = useCallback(async () => {
		const schemaWithId = schema.extend({ id: z.number() });
		const employmentTypeToEdit = { ...formData, market: formData.market?.id };

		const validated = schemaWithId.parse(employmentTypeToEdit);
		return editEmploymentType(validated);
	}, [formData]);

	const handleCreate = useCallback(async () => {
		const employmentTypeToCreate = { ...formData, market: formData.market?.id };

		const validated = schema.parse(employmentTypeToCreate);
		return createEmploymentType(validated);
	}, [formData]);

	const handleModalClose = useCallback(() => {
		setFormData({});
		setFormErrors({});
	}, [setFormData, setFormErrors]);

	return (
		<ReactDataWrapper
			title="Employment Types"
			columns={getColumns(reduxKey)}
			fetchData={fetchEmploymentTypes}
			filterable
			reduxKey={reduxKey}
			accessAreasAllowedToEdit={['Employment Admin']}
			customAreaComponents={
				<Button
					id="legacyFilterButton"
					size="tiny"
					onClick={handleToggleLegacyFilter}
					type={showLegacy ? '' : 'inverted'}
				>
					Legacy
				</Button>
			}
			extraFilters={getExtraFilters()}
			onError={(e: unknown) => {
				if (e instanceof ZodError) {
					setFormErrors(e.flatten().fieldErrors);
				}
			}}
			editableCells={[
				{
					header: 'Type',
					value: (
						<Input
							type="select"
							required
							errors={formErrors.type}
							value={formData.type}
							disabled={!!formData.id}
							options={typeOptions.map((o) => ({
								label: o,
								value: o,
							}))}
							onChange={(option: SelectOption) =>
								setFormData({ ...formData, type: option?.value })
							}
						/>
					),
				},
				{
					header: 'Description',
					value: (
						<Input
							errors={formErrors.description}
							value={formData.description}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								setFormData({ ...formData, description: e.target?.value })
							}
						/>
					),
				},
				{
					header: 'Market',
					value: (
						<InputCollectionSelect
							id="market"
							label="Market"
							disabled={!!formData.id}
							errors={formErrors.market}
							placeholder="Select Market"
							value={
								formData.market
									? {
											label: formData.market.name,
											value: formData.market.id,
									  }
									: undefined
							}
							handleChange={(_, value) =>
								setFormData({ ...formData, market: value?.value })
							}
							required
							cache
							apiPath="/organization/markets"
							params={{
								sort: ':name+',
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
							tableTitle="Markets"
							tableReduxKey="hr/employmentTypes/markets"
							tableColumns={getMarketColumns('hr/employmentTypes/markets')}
						/>
					),
				},
				{
					header: 'Min',
					value: (
						<Input
							type="number"
							value={formData.minimum_hours}
							errors={formErrors.minimum_hours}
							min={0}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								setFormData({
									...formData,
									minimum_hours: parseInt(e.target.value),
								})
							}
						/>
					),
				},
				{
					header: 'Max',
					value: (
						<Input
							type="number"
							value={formData.maximum_hours}
							errors={formErrors.maximum_hours}
							min={0}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								setFormData({
									...formData,
									maximum_hours: parseInt(e.target.value),
								})
							}
						/>
					),
				},
				{
					header: 'Modulation Period',
					value: (
						<Input
							type="number"
							value={formData.modulation_period}
							errors={formErrors.modulation_period}
							min={0}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								setFormData({
									...formData,
									modulation_period: parseInt(e.target.value),
								})
							}
						/>
					),
				},
				{
					header: 'Standard Hours',
					value: (
						<Input
							type="number"
							value={formData.standard_hours}
							errors={formErrors.standard_hours}
							min={0}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								setFormData({
									...formData,
									standard_hours: parseInt(e.target.value),
								})
							}
						/>
					),
				},
				{
					header: 'Pay Type',
					value: (
						<Input
							type="select"
							required
							errors={formErrors.pay_type}
							value={formData.pay_type}
							options={payTypeOptions.map((o) => ({
								label: o,
								value: o,
							}))}
							onChange={(option: SelectOption) =>
								setFormData({ ...formData, pay_type: option?.value })
							}
						/>
					),
				},
				{
					header: 'Role',
					value: (
						<Input
							type="select"
							required
							errors={formErrors.role}
							value={formData.role}
							options={roleOptions.map((o) => ({
								label: o,
								value: o,
							}))}
							onChange={(option: SelectOption) =>
								setFormData({ ...formData, role: option?.value })
							}
						/>
					),
				},
				{
					header: 'Legacy',
					value: (
						<Toggle
							toggled={formData.legacy}
							errors={formErrors.legacy}
							onClick={(value: boolean) =>
								setFormData({
									...formData,
									legacy: value,
								})
							}
						/>
					),
				},
			]}
			editEntry={handleEdit}
			deleteEntry={deleteEmploymentType}
			createEntry={handleCreate}
			setInitialEditValues={setFormData}
			onModalClose={handleModalClose}
		/>
	);
}
