import { IColumnHeader } from 'types/reactDataWrapper';

export default function getColumns(): IColumnHeader<IAdministrationGradeScale>[] {
    return [
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            filterPath: ':name',
        },
    ];
}
