import { ActionType } from 'types/redux';
import { IModuleReduxValue } from '../types';

export const SET_MODULE =
    'MODULES/SET_MODULE';
export const UPDATE_MODULE =
    'MODULES/UPDATE_MODULE';

export const UPDATE_LEGACY_FILTER_STATE =
    'MODULES/UPDATE_LEGACY_FILTER_STATE';
export const RESET_STATE = 'MODULES/RESET_STATE';

export function setModules(payload: IModuleReduxValue): ActionType {
    return {
        type: SET_MODULE,
        payload,
    };
}

type updateModule = Partial<IModuleReduxValue>;
export function updateModule(payload: updateModule): ActionType {
    return {
        type: UPDATE_MODULE,
        payload,
    };
}

export function updateLegacyFilterState(payload: boolean): ActionType {
    return {
        type: UPDATE_LEGACY_FILTER_STATE,
        payload,
    };
}

export function resetState(): ActionType {
    return {
        type: RESET_STATE,
    };
}
