'use strict';

import { HotbarComponentFactory as h } from 'hotdamnbar/factory';
import {
	HotbarStaticDataProvider,
	HotbarEndpointDataProvider
} from 'hotdamnbar/dataProviders';

const MarketsDataProvider = new HotbarEndpointDataProvider(
	'/organization/markets'
);
MarketsDataProvider.setSortOrder(':sort_order+');

const StoresDataProvider = new HotbarEndpointDataProvider(
	'/shiftplanning/workplaces',
	{ market: 'market.id' },
	entry => {
		if (entry.state === 'Operating' && entry.type === 'Store') {
			return { value: entry.id, label: entry.name, market: entry.market };
		}
	}
);
StoresDataProvider.setSortOrder(':sort_order+');
StoresDataProvider.setTypingFilterParameter('workplace.name');

const EntriesTypeDataProvider = new HotbarStaticDataProvider([
	{
		value: 'HOURLY',
		label: 'Hourly'
	},
	{
		value: 'HALF_HOURLY',
		label: 'Half Hourly'
	}
]);

const GridOptionsDataProvider = new HotbarStaticDataProvider([
	{
		value: 'DEFAULT',
		label: 'Default'
	},
	{
		value: 'AUDIT',
		label: 'Audit'
	}
]);

const shiftplannerHotbar = h.hotbar('shiftplannerHotbar');

shiftplannerHotbar.add(h.select('Markets', false, MarketsDataProvider, true));
shiftplannerHotbar.add(h.select('Stores', false, StoresDataProvider, true), {
	market: 'Markets'
});
shiftplannerHotbar.add(h.dateRange('Period', true, true));
shiftplannerHotbar.add(
	h.select('Hourly division', true, EntriesTypeDataProvider)
);

shiftplannerHotbar.add(h.select('Grid options', true, GridOptionsDataProvider));

export default shiftplannerHotbar;
