import { ActionType } from 'types/redux';
import { IGradeScaleReduxValue, IGradeScaleReduxState } from '../types';

import {
	SET_GRADE_SCALE,
	UPDATE_GRADE_SCALE,
	RESET_STATE,
} from './gradeScales.actions';

const defaultState: IGradeScaleReduxState = {
	data: {
		defaultGradeScale: null,
		gradeScale: {
			name: null,
		},
	},
};

function reducer(
	state = defaultState,
	action: ActionType
): IGradeScaleReduxState {
	const { type, payload } = action;

	switch (type) {
	case SET_GRADE_SCALE: {
		const castedPayload = payload as IGradeScaleReduxValue;

		return {
				...state,
				data: {
					gradeScale: castedPayload,
					defaultGradeScale: castedPayload,
				},
			};
	}

	case UPDATE_GRADE_SCALE: {
		const castedPayload = payload as Partial<IGradeScaleReduxValue>;

		return {
				...state,
				data: {
					...state.data,
					gradeScale: {
						...state.data.gradeScale,
						...castedPayload,
					},
				},
			};
	}

	case RESET_STATE: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
