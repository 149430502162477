import React from 'react';

import { IColumnHeader } from 'types/reactDataWrapper';
import { Chip } from '@mui/material';
import moment from 'moment';
import constants from 'services/constants';

export default function getColumns(): IColumnHeader<IPartnershipCode>[] {
	return [
		{
			Header: 'Market',
			id: 'market',
			accessor: (d) => d.partnership.market.name ?? '',
			filterPath: ':partnership.market.name',
		},
		{
			Header: 'Code',
			id: 'code',
			accessor: (d) => d.code ?? '',
			filterPath: ':code',
		},
		{
			Header: 'Partnership ID',
			id: 'partnership',
			accessor: (d) => d.partnership.partnership_id ?? '',
			show: false,
			filterPath: ':partnership.partnership_id',
		},
		{
			Header: 'Status',
			id: 'status',
			accessor: (d) => d.status ?? '',
			filterPath: ':status',
			show: false,
			Cell: (d) => {
				const status = d.original.status;
				const color = status === 'active' ? 'success' : 'error';
				return <Chip label={status} size="small" color={color} />;
			},
		},
		{
			Header: 'Expires At',
			id: 'expires_at',
			accessor: 'expires_at',
			filterPath: ':expires_at',
			Cell: (d) =>
				d.original.expires_at &&
				moment.utc(d.original.expires_at).format(constants.shortDate),
		},
	];
}
