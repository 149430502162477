/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';

// components
import {
	Input,
	InputCollectionSelect,
	Toggle,
	SingleDatePickerInput,
} from 'dumb';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

// lodash
import _get from 'lodash/get';

// phrases
import phrases from './workplaceEditableCells.phrases';
import moment from 'moment';
import constants from 'services/constants';

// enums
import enums from 'services/enums.service';
import timezones from 'data/timeZones';

const WorkplaceEditableCells = ({ workplace, editStoreEntry }) => {
	return [
		{
			header: 'Name',
			value: (
				<Input
					id="name"
					placeholder="Enter name..."
					value={workplace.name}
					onChange={(event) => editStoreEntry('name', event)}
					required
				/>
			),
		},
		{
			header: 'External name',
			value: (
				<Input
					id="external-name"
					placeholder="Enter external name..."
					required
					value={workplace.externalName}
					onChange={(event) => editStoreEntry('externalName', event)}
				/>
			),
		},
		{
			header: 'Market',
			value: workplace.editMode ? (
				<span>{workplace.market}</span>
			) : (
				<InputCollectionSelect
					id="market"
					placeholder="Select market..."
					value={workplace.market}
					handleChange={(key, value) => editStoreEntry('market', value)}
					cache
					apiPath="/administration/markets"
					params={{
						limit: 30,
					}}
					required
					clearable={false}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
					tableColumns={[
						{
							Header: 'Name',
							accessor: 'name',
							id: 'name',
							filterPath: ':name',
						},
						{
							Header: 'Country',
							id: 'country',
							accessor: (d) => _get(d, 'country.name', ''),
							filterPath: ':country.name',
						},
						{
							Header: 'Market region',
							id: 'market-region',
							accessor: (d) => _get(d, 'market_region.name', ''),
							filterPath: ':market_region.name',
						},
						{
							Header: 'Tax label',
							id: 'tax-label',
							accessor: 'tax_label',
							filterPath: ':tax_label',
						},
						{
							Header: 'Default break tag',
							id: 'default-break-tag',
							accessor: (d) => _get(d, 'default_break_tag.name', ''),
							filterPath: ':default_break_tag.name',
						},
						{
							Header: 'Clockin alignment required',
							id: 'clocking-alignment-required',
							accessor: 'clocking_alignment_required',
							filterPath: ':clocking_alignment_required',
							Cell: (d) => (
								<Input
									id={`${d.original.id}-clocking-alignment-required`}
									type="checkbox"
									checked={d.value}
									disabled
								/>
							),
							filterable: false,
						},
						{
							Header: 'Opened',
							id: 'opened',
							accessor: 'opened',
							filterPath: ':opened',
							// eslint-disable-next-line react/prop-types
							Filter: ({ column }) => (
								<DateFilterSelector
									reduxKey="/administration/markets-workplace-edit-modal"
									// eslint-disable-next-line react/prop-types
									columnId={column.id}
									dateIdentifier="from"
								/>
							),
						},
						{
							Header: 'Closed',
							accessor: 'closed',
							id: 'closed',
							filterPath: ':closed',
							// eslint-disable-next-line react/prop-types
							Filter: ({ column }) => (
								<DateFilterSelector
									reduxKey="/administration/markets-workplace-edit-modal"
									// eslint-disable-next-line react/prop-types
									columnId={column.id}
									dateIdentifier="to"
								/>
							),
						},
					]}
					tableReduxKey="/administration/markets-workplace-edit-modal"
					tableTitle={phrases.MARKET_TITLE}
				/>
			),
		},
		...(!workplace.id
			? [
					{
						header: 'Company',
						value: (
							<InputCollectionSelect
								id="company"
								tableReduxKey="/administration/workplace/company"
								tableTitle="Companies"
								placeholder="Select company..."
								value={workplace.company}
								handleChange={(key, value) => editStoreEntry('company', value)}
								required
								clearable={false}
								apiPath="/administration/companies"
								params={{
									limit: 30,
								}}
								optionFormat={(entry) => ({
									value: entry.id,
									label: entry.name,
								})}
								inputFilterFormat={(input) => `:name=like='%${input}%'`}
							/>
						),
					},
			  ]
			: []),
		{
			header: 'Type',
			value: (
				<InputCollectionSelect
					id="type"
					placeholder="Select type..."
					value={workplace.type}
					handleChange={(key, value) => editStoreEntry('type', value)}
					options={enums.workplaces}
					required
					clearable={false}
				/>
			),
		},
		{
			header: 'State',
			value: (
				<InputCollectionSelect
					id="state"
					placeholder="Select state..."
					value={workplace.state}
					handleChange={(key, value) => editStoreEntry('state', value)}
					options={enums.workplaceStates}
					required
					clearable={false}
				/>
			),
		},
		{
			header: 'Timezone',
			value: (
				<InputCollectionSelect
					id="timezone"
					placeholder="Select timezone..."
					value={workplace.timezone}
					handleChange={(key, value) => editStoreEntry('timezone', value)}
					options={timezones}
					required
					clearable={false}
				/>
			),
		},
		{
			header: 'Territory',
			value: workplace.editMode ? (
				<span>{workplace.territory}</span>
			) : (
				<InputCollectionSelect
					id="territory"
					placeholder="Select territory..."
					value={workplace.territory}
					handleChange={(key, value) => editStoreEntry('territory', value)}
					cache
					apiPath="/administration/territories"
					params={{
						limit: 30,
					}}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
					clearable={false}
					required
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
					tableColumns={[
						{
							Header: 'Name',
							accessor: 'name',
							id: 'name',
							filterPath: ':name',
						},
						{
							Header: 'Market',
							id: 'market',
							accessor: (d) => _get(d, 'market.name', ''),
							filterPath: ':market.name',
						},
						{
							Header: 'Sort order',
							accessor: 'sort_order',
							id: 'sort_order',
							filterPath: ':sort_order',
						},
						{
							Header: 'Opened',
							accessor: 'opened',
							id: 'opened',
							filterPath: ':opened',
						},
						{
							Header: 'Closed',
							accessor: 'closed',
							id: 'closed',
							filterPath: ':closed',
						},
					]}
					tableReduxKey="/administration/territories-workplace-edit-modal"
					tableTitle={phrases.TERRITORY_TITLE}
				/>
			),
		},
		{
			header: 'Opened',
			value: (
				<SingleDatePickerInput
					id="opened"
					onChange={(event) => editStoreEntry('opened', event)}
					selectedDate={
						workplace.opened || moment().format(constants.shortDate)
					}
					noClockButton
					required
				/>
			),
		},
		{
			header: 'Closed',
			value: (
				<SingleDatePickerInput
					id="closed"
					onChange={(event) => editStoreEntry('closed', event)}
					selectedDate={workplace.closed || '9999-12-31'}
					noClockButton
					clearable={workplace.closed !== '9999-12-31'}
				/>
			),
		},
		{
			header: 'Grid',
			value: (
				<InputCollectionSelect
					id="grid"
					placeholder="Select grid..."
					value={workplace.grid}
					handleChange={(key, value) => editStoreEntry('grid', value)}
					cache
					apiPath="/hr/grids"
					params={{
						limit: 30,
					}}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
					clearable={false}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
				/>
			),
		},
		{
			header: 'Area type',
			value: (
				<InputCollectionSelect
					id="area-type"
					placeholder="Select area type..."
					value={workplace.storeAreaType}
					handleChange={(key, value) => editStoreEntry('storeAreaType', value)}
					required
					cache
					apiPath="/administration/store_area_types"
					params={{
						limit: 30,
					}}
					optionFormat={(entry) => ({
						value: entry.id,
						label: entry.name,
					})}
					clearable={false}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
				/>
			),
		},
		{
			header: 'Continent tag',
			value: (
				<InputCollectionSelect
					id="store-continent-tag"
					placeholder="Select continent tag..."
					value={workplace.storeContinentTag}
					handleChange={(key, value) =>
						editStoreEntry('storeContinentTag', value)
					}
					cache
					apiPath="/administration/store_continent_tags"
					params={{
						limit: 30,
					}}
					optionFormat={(entry) => ({
						value: entry.id,
						label: entry.name,
					})}
					clearable={false}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
				/>
			),
		},
		{
			header: 'Forecast tag',
			value: (
				<InputCollectionSelect
					id="forecast-tag"
					placeholder="Select forecast tag..."
					value={workplace.storeForecastTag}
					handleChange={(key, value) =>
						editStoreEntry('storeForecastTag', value)
					}
					cache
					apiPath="/administration/store_forecast_tags"
					params={{
						limit: 30,
					}}
					optionFormat={(entry) => ({
						value: entry.id,
						label: entry.name,
					})}
					clearable={false}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
				/>
			),
		},
		{
			header: 'Location type',
			value: (
				<InputCollectionSelect
					id="location-type"
					placeholder="Select location type..."
					value={workplace.storeLocationType}
					handleChange={(key, value) =>
						editStoreEntry('storeLocationType', value)
					}
					cache
					required
					apiPath="/administration/store_location_types"
					params={{
						limit: 30,
					}}
					optionFormat={(entry) => ({
						value: entry.id,
						label: entry.name,
					})}
					clearable={false}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
				/>
			),
		},
		...(workplace.editMode
			? [
					{
						header: 'Store region',
						value: <span>{workplace.storeRegion}</span>,
					},
			  ]
			: []),
		{
			header: 'Food control report url',
			value: (
				<Input
					id="food-control-report-url"
					placeholder="Enter food control report url..."
					value={workplace.foodControlReportUrl}
					onChange={(event) => editStoreEntry('foodControlReportUrl', event)}
				/>
			),
		},
		{
			header: 'M2',
			value: (
				<Input
					id="m2"
					placeholder="Enter m2..."
					value={workplace.storeSize}
					type="number"
					onChange={(event) => editStoreEntry('storeSize', event)}
				/>
			),
		},
		{
			header: 'First year forecast',
			value: (
				<Input
					id="first-year-forecast"
					placeholder="Enter first year forecast..."
					value={workplace.storeFirstYearForecast}
					onChange={(event) => editStoreEntry('storeFirstYearForecast', event)}
				/>
			),
		},
		{
			header: 'Audit number',
			value: (
				<Input
					id="audit-number"
					placeholder="Enter audit number..."
					value={workplace.auditNumber}
					onChange={(event) => editStoreEntry('auditNumber', event)}
				/>
			),
		},
		{
			header: 'Department code',
			value: (
				<Input
					id="department-code"
					placeholder="Enter department code..."
					value={workplace.departmentCode}
					type="number"
					onChange={(event) => editStoreEntry('departmentCode', event)}
				/>
			),
		},
		{
			header: 'Sort order',
			value: (
				<Input
					id="sort-order"
					placeholder="Enter sort order..."
					value={workplace.sortOrder}
					type="number"
					onChange={(event) => editStoreEntry('sortOrder', event)}
				/>
			),
		},
		{
			header: 'Franchise',
			value: (
				<Toggle
					id="franchise"
					toggled={workplace.franchiseStore}
					onClick={(event) => editStoreEntry('franchiseStore', event)}
				/>
			),
		},
		{
			header: 'Opening offset',
			value: (
				<Input
					id="opening-offset"
					placeholder="Enter opening offset... (max 120)"
					value={workplace.openingOffset}
					type="number"
					onChange={(event) => editStoreEntry('openingOffset', event)}
				/>
			),
		},
		{
			header: 'Closing offset',
			value: (
				<Input
					id="closing-offset"
					placeholder="Enter closing offset... (max 120)"
					value={workplace.closingOffset}
					type="number"
					onChange={(event) => editStoreEntry('closingOffset', event)}
				/>
			),
		},
		{
			header: 'Legacy',
			value: (
				<Toggle
					id="legacy"
					toggled={workplace.legacy}
					onClick={(event) => editStoreEntry('legacy', event)}
				/>
			),
		},
		{
			header: 'Show in Joe App',
			value: (
				<Toggle
					id="show-in-joe-app"
					toggled={workplace.showInJoeApp}
					onClick={(event) => editStoreEntry('showInJoeApp', event)}
				/>
			),
		},
	];
};

WorkplaceEditableCells.propTypes = {
	workplace: PropTypes.string,
	editStoreEntry: PropTypes.func,
};

export default WorkplaceEditableCells;
