import { IColumnHeader } from 'types/reactDataWrapper';
import _get from "lodash/get";
import React from "react";

export default function getColumns(): IColumnHeader<IAdministrationModule>[] {
    return [
        {
            Header: 'Sort Order',
            id: 'sort_order',
            accessor: 'sort_order',
            filterPath: ':sort_order',
        },
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            filterPath: ':name',
        },
        {
            Header: 'Type',
            id: 'type',
            accessor: 'type',
            filterPath: ':type',
        },
        {
            Header: 'Grade Scale',
            id: 'grade_scale',
            accessor: (d: IAdministrationGradeScale) => _get(d, 'grade_scale.name', ''),
            filterPath: ':grade_scale.name',
        },
        {
            Header: 'Badge Category',
            id: 'badge_category',
            accessor: (d: any) => _get(d, 'badge.badge_category.name', ''),
            filterPath: ':badge.badge_category.name',
        },
        {
            Header: 'Moneyball Position',
            id: 'moneyball_position',
            accessor: (d: any) => _get(d, 'moneyball_position.name', ''),
            filterPath: ':moneyball_position.name',
        },
        {
            Header: 'Module Category',
            id: 'module_category',
            accessor: (d: any) => _get(d, 'module_category.name', ''),
            filterPath: ':module_category.name',
        },
        {
            Header: 'Legacy',
            id: 'legacy',
            accessor: 'legacy',
            filterPath: ':legacy',
            Cell: (d) => (
                <input type="checkbox" checked={d.value as boolean} />
            ),
        },
    ];
}
