import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

// components
import { Backdrop } from 'dumb';
import OverlayContent from './components/overlayContent';

// styles
import './overlay.css';

const Overlay = ({
	visible,
	style,
	height: heightProp,
	zIndex,
	children,
	onClose,
	list,
	disableKeys,
	noBackdrop,
	render,
}) => {
	// get modal-root element to create portal.
	const rootElement = document.getElementById('overlay-root');

	const [height, setHeight] = useState(
		heightProp || (window.visualViewport?.height ?? 0) * 0.75
	);
	const propHeight = useMemo(() => heightProp, [heightProp]);

	const _onkeyDown = (event) => {
		// if escape is pressed and any select element is not open
		if (event.keyCode === 27 && onClose && !disableKeys) {
			document.removeEventListener('keydown', _onkeyDown);
			onClose();
		}
	};

	const onEnter = () => {
		// Esc
		document.addEventListener('keydown', _onkeyDown, false);
	};

	const onExit = () => {
		document.removeEventListener('keydown', _onkeyDown);
	};

	const getContent = () => (
		<OverlayContent
			visible={visible}
			list={list}
			zIndex={zIndex}
			style={style}
			onExit={onExit}
			onEnter={onEnter}
			// eslint-disable-next-line react/no-children-prop
			children={children}
			onClose={onClose}
			render={render}
			height={height}
			setHeight={setHeight}
			propHeight={propHeight}
		/>
	);

	return ReactDOM.createPortal(
		<>
			{noBackdrop ? (
				getContent()
			) : (
				<Backdrop zIndex={zIndex} isOpen={visible} closeBackdrop={onClose}>
					{getContent()}
				</Backdrop>
			)}
		</>,
		rootElement
	);
};

Overlay.defaultProps = {
	visible: false,
	noBackdrop: false,
};

Overlay.propTypes = {
	visible: PropTypes.bool.isRequired,
	style: PropTypes.object,
	height: PropTypes.number.isRequired,
	zIndex: PropTypes.number,
	children: PropTypes.any,
	onClose: PropTypes.func,
	list: PropTypes.bool,
	disableKeys: PropTypes.bool,
	noBackdrop: PropTypes.bool,
	render: PropTypes.func,
};

export default Overlay;
