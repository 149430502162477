'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { batch } from 'react-redux';

// Store
import { bindActionCreators } from 'redux';
import { store, connectWithStore } from 'appState';
import {
	removeProductVariant,
	editProductTile,
	resetState,
	editGroup,
	setLoader,
} from './store/addTileModal.actions';
import { setGroups } from './../posConfigurationLayout.actions';

// Components
import { ModalStepper, ButtonTile } from 'dumb';
import AddGroupForm from './../addGroupModal/components/addGroupForm';
import AddProductForm from './components/addProductForm';
import AddSelectImage from './../addGroupModal/components/addSelectImage';

// Tools
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

// Other
import './addTileModal.css';
import phrases from './addTileModal.phrases';
import constants from './../utilities/constants';

class AddTileModal extends Component {
	constructor(props) {
		super(props);

		this.modalRef = React.createRef();

		// Binds
		this._goToStep = this._goToStep.bind(this);
		this._handleClose = this._handleClose.bind(this);
		this.onGroupFormUpdate = this.onGroupFormUpdate.bind(this);
		this.onProductTileUpdate = this.onProductTileUpdate.bind(this);
		this._onUpdateProductTile = this._onUpdateProductTile.bind(this);

		this.state = { destiny: null };
	}

	onGroupFormUpdate(data) {
		this.props.editGroup(data);
	}

	_onGroupSubmit() {
		const {
			groupData,
			layoutId,
			position,
			layoutGroupId,
			activeTile,
			addTile,
			isTypePos,
		} = this.props;

		if (activeTile.id) {
			this._onUpdateProductTile(activeTile.type);
		} else {
			groupData.tile_layout = layoutId;
			groupData.show_in_menu = false;
			groupData.position = position;

			store.dispatch(setLoader(true));

			const groupDataModified = {
				...groupData,
				background_color: isTypePos
					? groupData.title_color
					: groupData.background_color,
				title_color: isTypePos
					? groupData.background_color
					: groupData.title_color,
			};

			const payload = {
				tile_layout_group: layoutGroupId,
				tile_layout_sub_group: {
					background_color: groupDataModified.background_color,
					name: groupDataModified.name,
					description: groupDataModified.description,
					title_color: groupDataModified.title_color,
					position,
					tile_position_focus: groupDataModified.tile_position_focus,
					show_in_menu: groupDataModified.show_in_menu,
					asset_collection: groupDataModified.asset_collection,
				},
				background_color: groupDataModified.background_color,
				name: groupDataModified.name,
				description: groupDataModified.description,
				title_color: groupDataModified.title_color,
				position,
				type: 'Group',
			};

			addTile(payload)
				.then((result) => {
					const res = result[0];

					const groups = {
						[res.tile_layout_sub_group.id]: {
							id: res.tile_layout_sub_group.id,
							tile_layout: layoutId,
							asset_collection: {
								id: payload.tile_layout_sub_group.asset_collection,
							},
							name: res.name,
							description: res.description,
							position: res?.position ?? '',
							show_in_menu: payload.tile_layout_sub_group.show_in_menu,
							title_position_focus:
								payload.tile_layout_sub_group.tile_position_focus,
							title_color: payload.tile_layout_sub_group.title_color,
							background_color: payload.tile_layout_sub_group.background_color,
							created: res.created,
							updated: res.updated,
							deleted: res.deleted,
						},
					};

					batch(() => {
						store.dispatch(setGroups(groups));
						store.dispatch(setLoader(false));
					});

					this._handleClose();
				})
				.catch(() => {
					store.dispatch(setLoader(false));
				});
		}
	}

	/**
	 * @function onProductTileUpdate
	 * @param {object} data
	 * @description Updated the reducer data with new input info from Tile form.
	 */
	onProductTileUpdate(data) {
		this.props.editProductTile(data);
	}

	_onProductSubmit() {
		const {
			productTile,
			position,
			layoutGroupId,
			activeTile,
			addTile,
			isTypePos,
			handlePostTileAddCallback,
		} = this.props;

		if (activeTile.id) {
			// IF edit
			this._onUpdateProductTile(activeTile.type);
		} else {
			// position prop seems to be undefined, but given the complexity of this component, I'm not sure where it's coming from and if it's safe to remove it
			const tilePosition = position ?? productTile.position;

			const tile = {
				...productTile,
				position: tilePosition,
				product: _get(productTile, 'product.id'),
				tile_layout_group: layoutGroupId,
				background_color: isTypePos
					? productTile.title_color
					: productTile.background_color,
				title_color: isTypePos
					? productTile.background_color
					: productTile.title_color,
				...(productTile.flair && {
					flair: productTile.flair.id,
				}),
			};

			// If Add new
			store.dispatch(setLoader(true));
			addTile(tile)
				.then(handlePostTileAddCallback)
				.then(this._handleClose)
				.catch((err) => console.error(err.messsage))
				.finally(() => store.dispatch(setLoader(false)));
		}
	}

	_onUpdateProductTile(type) {
		const { groupData, productTile, updateTile } = this.props;
		const data = type === constants.TILE_TYPE.GROUP ? groupData : productTile;

		store.dispatch(setLoader(true));

		updateTile(data).finally(() => {
			store.dispatch(setLoader(false));
			this._handleClose();
		});
	}

	_handleClose() {
		this.props.resetState();
		this.props.handleClose();
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_chooseDestiny(step) {
		this.setState(() => ({ destiny: step === 2 ? 'Folder' : 'Product' }));
		this._goToStep(step);
	}

	_renderChoiceStep() {
		return (
			<>
				<div className="pos-tile-modal__row pos-tile-modal__row--centered">
					<p>{phrases.PLEASE_SELECT_YOUR_DESTINY}</p>
				</div>
				<div className="pos-tile-modal__row pos-tile-modal__row--centered">
					<ButtonTile
						icon="print"
						type="inverted"
						shadow
						onClick={() => this._chooseDestiny(2)}
					>
						{phrases.FOLDER}
					</ButtonTile>
					<ButtonTile
						icon="file_download"
						type="inverted"
						shadow
						onClick={() => this._chooseDestiny(3)}
					>
						{phrases.PRODUCT}
					</ButtonTile>
				</div>
			</>
		);
	}

	_renderGroupStep() {
		const { groupData, isTypePos } = this.props;

		return (
			<AddGroupForm
				onGroupFormUpdate={this.onGroupFormUpdate}
				groupData={groupData}
				isTypePos={isTypePos}
			/>
		);
	}

	_renderProductStep() {
		const {
			productTile,
			posConfigurationsVariantsList,
			imageAssets,
			isTypePos,
			subTypeRow,
		} = this.props;

		return (
			<AddProductForm
				onProductTileUpdate={this.onProductTileUpdate}
				posConfigurationsVariantsList={posConfigurationsVariantsList}
				productTile={productTile}
				imageAssets={imageAssets}
				isTypePos={isTypePos}
				subTypeRow={subTypeRow}
			/>
		);
	}

	_renderSelectImageStep() {
		const { productTile, imageAssets, groupData } = this.props;

		const tileAssets = imageAssets.filter(
			(asset) => asset.type === 'Product Tile'
		);

		return (
			<AddSelectImage
				productTile={this.state.destiny === 'Product' ? productTile : groupData}
				imageAssets={tileAssets}
				onImageUpdate={
					this.state.destiny === 'Product'
						? this.onProductTileUpdate
						: this.onGroupFormUpdate
				}
			/>
		);
	}

	_getRightStep() {
		const { activeTile } = this.props;

		const type = activeTile.type;

		switch (type) {
			case constants.TILE_TYPE.GROUP:
				return 1;
			case constants.TILE_TYPE.PRODUCT:
			case constants.TILE_TYPE.VARIANT:
				return 2;
			default:
				return 0;
		}
	}

	render() {
		const { isOpen, loading, productTile, groupData, loadingTile, activeTile } =
			this.props;

		const startingStep = this._getRightStep();

		const isExisting = activeTile.id;

		const steps = [
			{
				component: this._renderChoiceStep(),
				title: 'Create a tile',
				customFooter: <></>,
			},
			{
				component: this._renderGroupStep(),
				title: isExisting ? 'Edit group' : 'Add group',
				isValid: groupData.name,
				onNext: () => this._goToStep(4),
				onBack: isExisting ? this._handleClose : () => this._goToStep(1),
				loading: loading || loadingTile,
				confirmButtonLabel: isExisting ? phrases.UPDATE : phrases.CREATE,
				cancelButtonLabel: isExisting ? 'Close' : phrases.BACK,
			},
			{
				component: this._renderProductStep(),
				title: isExisting ? 'Edit product setup' : 'Add products setup',
				isValid: !_isEmpty(productTile.product) && productTile.name,
				onNext: () => this._onProductSubmit(),
				onBack: isExisting ? this._handleClose : () => this._goToStep(1),
				loading: loading || loadingTile,
				confirmButtonLabel: phrases.CONTINUE,
				cancelButtonLabel: isExisting ? 'Close' : phrases.BACK,
			},
			{
				component: this._renderSelectImageStep(),
				title: 'Select an image',
				isValid: true,
				onNext: () =>
					this.state.destiny === 'Product '
						? this._onProductSubmit()
						: this._onGroupSubmit(),
				onBack: () => this._goToStep(1),
				loading: loading,
				confirmButtonLabel: activeTile.id ? phrases.UPDATE : phrases.CREATE,
				cancelButtonLabel: phrases.BACK,
			},
		];

		return (
			<div>
				{isOpen && (
					<ModalStepper
						ref={this.modalRef}
						isOpen={isOpen}
						steps={steps}
						startStep={startingStep}
						// showStep={busyHalfHourKeys.length > 0}
						showStep={false}
						onClose={this._handleClose}
					/>
				)}
			</div>
		);
	}
}

AddTileModal.propTypes = {
	// Passed in props
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	type: PropTypes.string,
	layoutId: PropTypes.number,
	layoutGroupId: PropTypes.number,
	position: PropTypes.number,
	posConfigurationsVariantsList: PropTypes.array,
	loadingTile: PropTypes.bool,
	activeTile: PropTypes.object,
	handlePostTileAddCallback: PropTypes.func,

	// Store props
	productTile: PropTypes.object,
	groupData: PropTypes.object,
	resetState: PropTypes.func,
	editGroup: PropTypes.func,
	editProductTile: PropTypes.func,
	addTile: PropTypes.func,
	loading: PropTypes.bool,
	updateTile: PropTypes.func,
	imageAssets: PropTypes.array,
	isTypePos: PropTypes.string,
	subTypeRow: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			removeProductVariant,
			editProductTile,
			resetState,
			editGroup,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		productTile: store.salesConfigurationPOSaddTile.data.productTile,
		groupData: store.salesConfigurationPOSaddTile.data.group,
		loading: store.salesConfigurationPOSaddTile.ui.loading,
		loadingProduct: store.salesConfigurationPOSaddTile.ui.loadingProduct,
	};
};

export default connectWithStore(
	AddTileModal,
	mapStateToProps,
	mapDispatchToProps
);
