import React, { useState } from 'react';
import { useGate } from 'statsig-react';

// redux
import { useAppSelector } from 'appState/storeHooks';

// components
import { Tooltip, Button, Overlay } from 'dumb';
import VacationTable from 'modules/structure/hr/juicers/components/juicerDetails/components/vacationIllnessWrapper/vacationsTable/vacationsTable.container';
import PartnershipCodesTable from 'modules/structure/globalAdministration/joePayPartnerships/partnershipCodesTable.container';

// phrases
import phrases from './../appbar.phrases';

const AppBarStaffModalHeader = () => {
	const personId = useAppSelector(
		(store) => store.userData?.user?.user?.person?.id ?? ''
	);
	const [showVacationsTableOverlay, setShowVacationsTableOverlay] =
		useState(false);
	const isTestUser = useGate('juicers_on_the_app').value;

	const toggleVacationsTableOverlay = () =>
		setShowVacationsTableOverlay(!showVacationsTableOverlay);

	const [showPartnershipOverlay, setShowPartnershipOverlay] = useState(false);

	const togglePartnershipTableOverlay = () =>
		setShowPartnershipOverlay(!showPartnershipOverlay);

	return (
		<>
			<Tooltip
				zIndex={500}
				text="Click to view and log a new vacation"
				renderData={(ref, onMouseEnter, onMouseLeave) => (
					<Button
						dataCy="appbar-staff-details-header-vacation-button"
						shadow
						size="tiny"
						type="secondary"
						onClick={toggleVacationsTableOverlay}
						refProp={ref}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					>
						{phrases.VACATION_BUTTON}
					</Button>
				)}
			/>

			{isTestUser && (
				<Tooltip
					zIndex={500}
					text="Click to view partnership codes"
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							dataCy="appbar-staff-details-header-partnership-button"
							shadow
							size="tiny"
							type="secondary"
							onClick={togglePartnershipTableOverlay}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						>
							{phrases.PARTNERSHIP_BUTTON}
						</Button>
					)}
				/>
			)}

			{/* render vacation table in overlay when we click on the vacations button */}
			{showVacationsTableOverlay && (
				<Overlay
					zIndex={500}
					height={500}
					visible={showVacationsTableOverlay}
					onClose={toggleVacationsTableOverlay}
				>
					<VacationTable
						personId={personId}
						modalZIndex={550}
						customEndpoint={`/hr/persons/${personId}/vacations`}
					/>
				</Overlay>
			)}

			{showPartnershipOverlay && (
				<Overlay
					zIndex={500}
					height={500}
					visible={showPartnershipOverlay}
					onClose={togglePartnershipTableOverlay}
				>
					<PartnershipCodesTable />
				</Overlay>
			)}
		</>
	);
};

export default AppBarStaffModalHeader;
