// src/utils/AuthService.js
import { validAccess } from 'accessControl';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { isProduction } from 'environment';

/**
 *
 * @function track
 * @description Add tracking to logrocket, but depending on the user.
 * @param {*} event - event to be tracked at logrocket
 */
export default function trackWithLogRocket(event) {
	if (validAccess(undefined, ['Shiftplan Manager', 'Shiftplan Admin']))
		LogRocket.track(event);
}

export function initLogrocket() {
	// if not in production, don't initialize
	if (!isProduction()) return;

	// wait for new user data to be set before initializing logrocket
	setTimeout(() => {
		// if shiftplanner manager or above, initialize
		const managerRole = validAccess(undefined, [
			'Shiftplan Manager',
			'Shiftplan Admin',
		]);

		if (!managerRole) return;

		// wait 5 seconds before initializing logrocket
		setTimeout(() => {
			// from their docs: "LogRocket will only record sessions for which LogRocket.init() is called." -> https://docs.logrocket.com/docs/disabling-logrocket-for-certain-users
			LogRocket.init('ydjtdv/joe-and-the-juice', {
				// eslint-disable-next-line no-undef
				release: AppVersion,
				console: {
					isEnabled: {
						// what logs to save to logrocket sessions
						debug: false,
						warn: false,
					},
					// Use this option to control whether aggregated data about messages logged with console.error() or LogRocket.error() is surfaced in LogRocket error reporting.
					shouldAggregateConsoleErrors: true,
				},
			});
			setupLogRocketReact(LogRocket);

			console.info('Initialized LogRocket');
		}, 9000);
	}, 10000);
}
