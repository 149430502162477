'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { ReactDataWrapper } from 'reactDataWrapper';
import _get from 'lodash/get';
import { push } from 'redux-first-history';

import {
	Backdrop,
	Button,
	Input,
	InputCleave,
	InputCollectionSelect,
	Icon,
	Legend,
} from 'dumb';
import CollectionSelect from 'collectionSelect';
import ModalBatch from './components/modalBatch/modalBatch.container';
import EditModalChoice from './components/editModalChoice/editModalChoice.container';
import FilterValueProvider from 'reactDataWrapper/utilities/filterProvider.component';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

import {
	setSalaryComponent,
	updateSalaryComponent,
	resetSalaryComponent,
	updateBatchForm,
	resetBatch,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
} from './store/salaryComponents.actions';

import {
	fetchSalaryComponents,
	editSalaryComponent,
	addSalaryComponent,
	deleteSalaryComponent,
	fetchGlobalSalaryComponents,
	fetchSalaryGroups,
	editBatchSalaryComponent,
} from './store/salaryComponents.service';

import { enums } from './salaryComponents.enums';
import collectionSelectEnums from 'services/enums/collectionSelect';

import phrases from './salaryComponents.phrases';
import { Tooltip } from 'dumb/index';
import { Box, Tooltip as MuiTooltip } from '@mui/material';

const reduxKey = 'salary/salary_components';

class SalaryComponents extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this._toggleModal = this._toggleModal.bind(this);

		this._onFocusChange = this._onFocusChange.bind(this);
		this._getFilter = this._getFilter.bind(this);
		this._toggleEditModal = this._toggleEditModal.bind(this);
		this._handleToggleActiveFilter = this._handleToggleActiveFilter.bind(this);
		this.highlightInvalidSalaryComponents =
			this.highlightInvalidSalaryComponents.bind(this);

		this.today = moment.utc().format('YYYY-MM-DD');

		this.state = {
			pages: null,
			showModal: false,
			showModalEdit: false,
			activeFilterOn: true,
			backdrop: false,
			filterState: {},
			activeFilter: `:active.to=ge='${this.today}'`,
		};

		this.columns = [
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'salary_group.market.name', ''),
				filterPath: ':salary_group.market.name',
				Filter: () => {
					return (
						<FilterValueProvider
							reduxKey={
								props.salaryGroupId
									? `${props.salaryGroupId}/${reduxKey}`
									: reduxKey
							}
							columnId="market"
							provide={(filterValue, persistToFilterStorage) => {
								return (
									<CollectionSelect
										value={filterValue}
										name="market"
										id="filter-market"
										className="list-filter-select"
										handleChange={(key, value) => {
											persistToFilterStorage({ handle: key, value });
											this._onFocusChange(false);
										}}
										onFocus={() => this._onFocusChange(true)}
										openOnFocus
										onBlur={() => this._onFocusChange(false)}
										closeOnSelect={false}
										multi
										clearable={false}
										placeholder="Select market"
										apiPath="/organization/markets"
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
										params={{
											limit: 300,
										}}
										styleType={collectionSelectEnums.TYPE_IN_TABLE}
									/>
								);
							}}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>
					);
				},
			},
			{
				Header: 'Salary group',
				id: 'salary_group',
				width: 180,
				accessor: (d) => _get(d, 'salary_group.name', ''),
				filterPath: ':salary_group.name',
				Filter: () => {
					return (
						<FilterValueProvider
							reduxKey={reduxKey}
							columnId="salary_group"
							provide={(filterValue, persistToFilterStorage) => {
								return (
									<CollectionSelect
										value={filterValue}
										className="list-filter-select"
										name="salary_group"
										id="filter-salarygr"
										handleChange={(key, value) => {
											persistToFilterStorage({ handle: key, value });
											this._onFocusChange(false);
										}}
										onFocus={() => this._onFocusChange(true)}
										openOnFocus
										onBlur={() => this._onFocusChange(false)}
										closeOnSelect={false}
										multi
										clearable={false}
										placeholder="Select salary group"
										apiPath="/salary/salary_groups"
										params={{
											limit: 50,
										}}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
										optionFormat={(entry) => ({
											value: entry.id,
											label: `${entry.name} (${entry.market.name})`,
										})}
										styleType={collectionSelectEnums.TYPE_IN_TABLE}
									/>
								);
							}}
						/>
					);
				},
			},
			{
				Header: 'Global Salary Component',
				id: 'global_salary_component',
				accessor: (d) => _get(d, 'global_salary_component.name', ''),
				filterPath: ':global_salary_component.name',
				Filter: () => {
					return (
						<FilterValueProvider
							reduxKey={reduxKey}
							columnId="global_salary_component"
							provide={(filterValue, persistToFilterStorage) => {
								return (
									<CollectionSelect
										value={filterValue}
										className="list-filter-select"
										name="global_salary_component"
										id="filter-gl-salary-groups"
										handleChange={(key, value) => {
											persistToFilterStorage({ handle: key, value });
											this._onFocusChange(false);
										}}
										onFocus={() => this._onFocusChange(true)}
										openOnFocus
										onBlur={() => this._onFocusChange(false)}
										closeOnSelect={false}
										multi
										clearable={false}
										placeholder="Select global salary group"
										apiPath="/salary/global_salary_components"
										params={{
											limit: 50,
										}}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
										optionFormat={(entry) => ({
											value: entry.id,
											label: `${entry.name}`,
										})}
										styleType={collectionSelectEnums.TYPE_IN_TABLE}
									/>
								);
							}}
						/>
					);
				},
			},
			{
				Header: 'Description',
				id: 'description',
				show: false,
				minWidth: 60,
				accessor: (d) => _get(d, 'salary_group.description', 'das'),
				filterPath: ':salary_group.description',
			},
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Amount type',
				id: 'amount_type',
				accessor: (d) => _get(d, 'amount_type', ''),
				filterPath: ':amount_type',
			},
			{
				Header: 'Amount',
				id: 'amount',
				width: 60,
				className: 'u-align-right',
				accessor: (d) => _get(d, 'amount', ''),
				filterPath: ':amount',
				Cell: (d) => {
					return numeral(parseFloat(d.original.amount)).format('0,0.00');
				},
			},
			{
				Header: 'Frequency',
				id: 'frequency',
				accessor: (d) => _get(d, 'frequency', ''),
				filterPath: ':frequency',
			},
			{
				Header: 'From',
				id: 'activeFrom',
				width: 100,
				accessor: (d) => _get(d, 'active.from', ''),
				filterPath: ':active.from',
				Filter: ({ column }) => (
					<DateFilterSelector reduxKey={reduxKey} columnId={column.id} />
				),
			},
			{
				Header: 'To',
				id: 'activeTo',
				width: 100,
				accessor: (d) => _get(d, 'active.to', ''),
				filterPath: ':active.to',
				Filter: ({ column }) => (
					<DateFilterSelector reduxKey={reduxKey} columnId={column.id} />
				),
			},
			{
				Header: 'External export ID',
				id: 'external_export_id',
				width: 110,
				accessor: (d) => _get(d, 'external_export_id', ''),
				filterPath: ':external_export_id',
			},
			{
				Header: 'Rules',
				width: 100,
				id: 'rules',
				Cell: (d) => {
					const rules = d.original.rules;
					const rulesDescription = Object.keys(rules);

					return rulesDescription.map((ruleDescription, key) => {
						const hoverText = ruleDescription.replaceAll('_', ' ');

						return (
							<MuiTooltip title={hoverText} key={key}>
								<Box
									m={0.1}
									sx={{
										display: 'inline-block',
									}}
								>
									<input
										type="checkbox"
										checked={rules[ruleDescription]}
										disabled
									/>
								</Box>
							</MuiTooltip>
						);
					});
				},
				filterable: false,
				sortable: false,
			},
		];
	}

	_onFocusChange(toggled) {
		this.setState(() => ({ backdrop: toggled }));
	}

	_extractFilterString(filterString = '', entries = null) {
		if (!entries || entries.length === 0) return '';
		const ids = entries.map((x) => {
			return `'${x.value}'`;
		});

		return `:${filterString}=IN=[${ids}]`;
	}

	_getFilter() {
		const { activeFilter } = this.state;
		const { customFilterColumns } = this.props;

		const salaryMarketFilterString = this._extractFilterString(
			'salary_group.market.id',
			customFilterColumns.market
		);
		const salaryGroupFilterString = this._extractFilterString(
			'salary_group.id',
			customFilterColumns.salary_group
		);

		const globalSalaryComponentFilterString = this._extractFilterString(
			'global_salary_component.id',
			customFilterColumns.global_salary_component
		);

		return [
			salaryMarketFilterString,
			salaryGroupFilterString,
			globalSalaryComponentFilterString,
			activeFilter,
		].reduce((acc, x) => {
			if (!x.length) return acc;
			return acc.length ? acc + `;${x}` : x;
		}, '');
	}

	_handleToggleActiveFilter() {
		this.setState((prevState) => ({
			activeFilterOn: !prevState.activeFilterOn,
			activeFilter: prevState.activeFilterOn
				? ''
				: `:active.to=ge='${this.today}'`,
		}));
	}

	deleteEntry(id) {
		return deleteSalaryComponent(id);
	}

	editEntry() {
		const { rowValues } = this.props;

		const payload = {
			id: rowValues.id,
			name: _get(rowValues, 'name', ''),
			amount: _get(rowValues, 'amount', ''),
			active: {
				from: _get(rowValues, 'active.from', ''),
				to: _get(rowValues, 'active.to', '9999-12-31'),
			},
			external_export_id: rowValues.external_export_id,
		};

		return editSalaryComponent(rowValues.id, payload);
	}

	editMultiple(selectedRows) {
		const { rowValues } = this.props;

		if (rowValues.asset_collection && rowValues.asset_collection.id)
			rowValues.asset_collection = rowValues.asset_collection.id;

		const list = selectedRows.map((row) => {
			return {
				id: row.id,
				...rowValues,
			};
		});

		const payload = {
			batch: list,
		};

		return editBatchSalaryComponent(payload);
	}

	addEntry() {
		const { rowValues } = this.props;

		const payload = {
			market: _get(rowValues, 'market.id', ''),
			salary_group: _get(rowValues, 'salary_group.id', ''),
			global_salary_component: _get(
				rowValues,
				'global_salary_component.id',
				''
			),
			name: _get(rowValues, 'name', ''),
			amount_type: _get(rowValues, 'amount_type.value', enums.amount_type[0]),
			amount: _get(rowValues, 'amount', ''),
			frequency: _get(rowValues, 'frequency.value', enums.frequency[0]),
			active: {
				from: _get(rowValues, 'active.from', ''),
				to: _get(rowValues, 'active.to', '9999-12-31'),
			},
			external_export_id: _get(rowValues, 'external_export_id', ''),
		};

		return addSalaryComponent(payload);
	}

	setInitialEditValues(data) {
		this.props.setSalaryComponent(data);
	}

	getEditableCells() {
		const { rowValues } = this.props;

		return [
			{
				header: 'Markets',
				value: (
					<InputCollectionSelect
						id="markets"
						placeholder="select markets"
						value={{
							value: _get(rowValues, 'market.id', ''),
							label: `${_get(rowValues, 'market.name', '')}`,
						}}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'market'
							)
						}
						cache
						apiPath="/organization/markets"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Salary groups',
				value: (
					<InputCollectionSelect
						id="sal-gr"
						placeholder="select salary group"
						value={{
							value: _get(rowValues, 'salary_group.id', ''),
							label: `${_get(rowValues, 'salary_group.name', '')}`,
						}}
						disabled={!_get(rowValues, 'market.id', false)}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'salary_group'
							)
						}
						cache
						apiPath="/salary/salary_groups"
						params={{
							limit: 300,
							filter: `:market.id=='${_get(rowValues, 'market.id', '')}'`,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Global salary components',
				value: (
					<InputCollectionSelect
						id="sglob-gr"
						placeholder="Select global slary component"
						value={{
							value: _get(rowValues, 'global_salary_component.id', ''),
							label: `${_get(rowValues, 'global_salary_component.name', '')}`,
						}}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'global_salary_component'
							)
						}
						cache
						apiPath="/salary/global_salary_components"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(rowValues, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				),
			},
			{
				header: 'Amount type',
				value: (
					<Input
						type="select"
						id="amount-type"
						options={enums.amount_type.map((item, i) => ({
							value: item,
							label: item,
						}))}
						onChange={(event) => this.editStoreEntry(event, 'amount_type')}
						value={_get(rowValues, 'amount_type', enums.amount_type[0])}
					/>
				),
			},
			{
				header: 'Amount',
				value: (
					<Input
						id="amount"
						type="number"
						min={0}
						placeholder="name"
						value={_get(rowValues, 'amount', '')}
						onChange={(event) => this.editStoreEntry(event, 'amount')}
					/>
				),
			},
			{
				header: 'Frequency',
				value: (
					<Input
						type="select"
						id="frequency"
						options={enums.frequency.map((item, i) => ({
							value: item,
							label: item,
						}))}
						onChange={(event) => this.editStoreEntry(event, 'frequency')}
						value={_get(rowValues, 'frequency', enums.frequency[0])}
					/>
				),
			},
			{
				header: 'From',
				value: (
					<InputCleave
						id="active-from"
						placeholder="yyyy-mm-dd"
						options={{
							date: true,
							delimiter: '-',
							datePattern: ['Y', 'm', 'd'],
						}}
						value={_get(rowValues, 'active.from', '')}
						onChange={(event) => this.editStoreDatesEntry(event, 'from')}
					/>
				),
			},
			{
				header: 'To',
				value: (
					<InputCleave
						id="active-to"
						placeholder="yyyy-mm-dd"
						options={{
							date: true,
							delimiter: '-',
							datePattern: ['Y', 'm', 'd'],
						}}
						value={_get(rowValues, 'active.to', '')}
						onChange={(event) => this.editStoreDatesEntry(event, 'to')} // DEFAULT VALUE 9999-12-31
					/>
				),
			},
			{
				header: 'External export id',
				value: (
					<Input
						id="external_export_id"
						placeholder="external export id"
						type="number"
						min="0"
						value={_get(rowValues, 'external_export_id', '')}
						onChange={(event) =>
							this.editStoreEntry(event, 'external_export_id')
						}
					/>
				),
			},
		];
	}

	getEditableCellsEdit() {
		const { rowValues } = this.props;

		return [
			{
				header: 'Market',
				value: <span />,
			},
			{
				header: 'Salary group',
				value: <span>{_get(rowValues, 'salary_group.name', '')}</span>,
			},
			{
				header: 'Global salary component',
				value: (
					<span>{_get(rowValues, 'global_salary_component.name', '')}</span>
				),
			},
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(rowValues, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				),
			},
			{
				header: 'Amount type',
				// amount type
				value: <span>{_get(rowValues, 'amount_type', '')}</span>,
			},
			{
				header: 'Amount',
				// amount type
				value: (
					<Input
						id="amount"
						type="number"
						min={0}
						placeholder="name"
						value={_get(rowValues, 'amount', '')}
						onChange={(event) => this.editStoreEntry(event, 'amount')}
					/>
				),
			},
			{
				// frequcncey
				header: 'Frequency',
				value: <span>{_get(rowValues, 'frequency', '')}</span>,
			},
			{
				header: 'From',
				value: (
					<InputCleave
						id="active-from"
						placeholder="yyyy-mm-dd"
						options={{
							date: true,
							delimiter: '-',
							datePattern: ['Y', 'm', 'd'],
						}}
						value={_get(rowValues, 'active.from', '')}
						onChange={(event) => this.editStoreDatesEntry(event, 'from')}
					/>
				),
			},
			{
				header: 'To',
				value: (
					<InputCleave
						id="active-to"
						placeholder="yyyy-mm-dd"
						options={{
							date: true,
							delimiter: '-',
							datePattern: ['Y', 'm', 'd'],
						}}
						value={_get(rowValues, 'active.to', '')}
						onChange={(event) => this.editStoreDatesEntry(event, 'to')} // DEFAULT VALUE 9999-12-31
					/>
				),
			},
			{
				header: 'External export id',
				value: (
					<Input
						id="external_export_id"
						placeholder="external export id"
						type="number"
						min="0"
						value={_get(rowValues, 'external_export_id', '')}
						onChange={(event) =>
							this.editStoreEntry(event, 'external_export_id')
						}
					/>
				),
			},
		];
	}

	editStoreDatesEntry(e, type) {
		const { rowValues } = this.props;
		const value = e.target ? e.target.value : e;

		const updateObject = {
			global_salary_component: rowValues.global_salary_component,
			salary_group: rowValues.salary_group,
			name: rowValues.name,
			amount_type: rowValues.amount_type,
			amount: rowValues.amount,
			frequency: rowValues.frequency,
			active: rowValues.active,
			external_export_id: rowValues.external_export_id,
		};

		const active =
			type === 'from'
				? {
						from: value,
						to: _get(rowValues, 'active.to', '9999-12-31'),
				  }
				: {
						from: _get(rowValues, 'active.from', ''),
						to: value,
				  };

		const data = {
			...updateObject,
			...{ active },
		};

		this.props.updateSalaryComponent(data);
	}

	editStoreEntry(e, type) {
		const { rowValues } = this.props;
		const value = e && e.target ? e.target.value : e;

		const updateObject = {
			market: rowValues.market,
			salary_group: rowValues.salary_group,
			global_salary_component: rowValues.global_salary_component,
			name: rowValues.name,
			amount_type: rowValues.amount_type,
			amount: rowValues.amount,
			frequency: rowValues.frequency,
			external_export_id: rowValues.external_export_id,
			active: rowValues.active,
		};

		this.props.updateSalaryComponent({
			...updateObject,
			...{ [type]: value },
		});
	}

	fetchData(state) {
		return fetchSalaryComponents(state, this.props.salaryGroupId);
	}

	_toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetBatch();
	}

	_toggleEditModal() {
		this.setState((prevState) => ({ showModalEdit: !prevState.showModalEdit }));
		// this.props.resetBatch();
	}

	getAdditionalFilters() {
		const { activeFilterOn } = this.state;

		const highlightColor = '--red-100';

		const rowStyle = `rgba(${window
			.getComputedStyle(document.body)
			.getPropertyValue(highlightColor)}, 1`;

		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
				}}
			>
				<Button
					type={activeFilterOn ? '' : 'inverted'}
					shadow
					title="Filter active"
					size="tiny"
					onClick={this._handleToggleActiveFilter}
				>
					{phrases.ACTIVE}
				</Button>

				<Box mt="10px">
					<Legend
						points={[
							{
								bulletColor: rowStyle,
								label: 'Hover on the rules column to view missing rules',
							},
						]}
					/>
				</Box>
			</Box>
		);
	}

	_renderLink(e) {
		return (
			<Button
				type="inverted"
				shadow
				title={phrases.RULES}
				onClick={() => {
					const pathName = this.props.salaryGroupId
						? `${window.location.pathname}/component/${e.original.id}`
						: `${window.location.pathname}/${e.original.id}`;
					store.dispatch(push(pathName));
				}}
				size="micro"
			>
				<Icon name="tune" />
			</Button>
		);
	}

	highlightInvalidSalaryComponents(rowData) {
		const rulesKeys = Object.keys(rowData.original.rules);

		// get length of array containing rules that are invalid
		const missingRulesLength = rulesKeys.filter(
			(key) => rowData.original.rules[key] === false
		).length;

		// if all rules are false, highlight the row. If not, exit
		if (missingRulesLength !== rulesKeys.length) return {};

		const highlightColor = '--red-100';

		const rowStyle = `rgba(${window
			.getComputedStyle(document.body)
			.getPropertyValue(highlightColor)}, 1`;

		return {
			background: rowStyle,
		};
	}

	render() {
		const { backdrop } = this.state;

		const filter = this._getFilter();

		return (
			<>
				<Backdrop isOpen={backdrop} closeBackdrop={() => {}} zIndex={1} />
				<ReactDataWrapper
					dataCy="salary-component-table"
					title={phrases.SALARY_COMPONENT}
					className="-striped -highlight"
					columns={this.columns}
					totalEntries={this.state.totalEntries} // Display the total number of pages
					fetchData={this.fetchData} // Request new data when things change
					filterable
					defaultPageSize={25}
					reduxKey={reduxKey}
					preventFetch={backdrop}
					manual // Forces table not to paginate or sort automatically, so we can handle it server-side
					editEntry={(e) => this.editEntry(e)}
					editMultiple={this.editMultiple}
					editableCellsEdit={this.getEditableCellsEdit()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetSalaryComponent}
					actionsWidth={90}
					deleteEntry={this.deleteEntry}
					inRowButtons
					customAreaComponents={this.getAdditionalFilters()}
					extraFilters={`${filter}`}
					actions={(d) => this._renderLink(d)}
					actionRender={
						<>
							<Button
								type="inverted"
								label="Batch"
								shadow
								onClick={() => this._toggleModal()}
							>
								<Icon name="add" />
							</Button>

							{this.props.selectedRows.length >= 1 ? (
								<Tooltip
									text={phrases.TOOLTIP}
									renderData={(ref, onMouseEnter, onMouseLeave) => (
										<Button
											type="inverted"
											label="Multi"
											shadow
											onClick={() => this._toggleEditModal()}
											refProp={ref}
											onMouseEnter={onMouseEnter}
											onMouseLeave={onMouseLeave}
										>
											<Icon name="edit" />
										</Button>
									)}
								/>
							) : null}
						</>
					}
					customRowStyling={this.highlightInvalidSalaryComponents}
				/>

				<ModalBatch
					salaryGroupId={this.props.salaryGroupId}
					modalVisible={this.state.showModal}
					handleClose={this._toggleModal}
					batchOptions={this.props.batchOptions}
					updateBatchForm={this.props.updateBatchForm}
					fetchGlobalSalaryComponents={fetchGlobalSalaryComponents}
					fetchSalaryGroups={fetchSalaryGroups}
					listGlobalSalaryGroups={this.props.listGlobalSalaryGroups}
					listGlobalSalaryComponents={this.props.listGlobalSalaryComponents}
					addBatchList={this.props.addBatchList}
					batchList={this.props.batchList}
					addSalaryComponent={addSalaryComponent}
					editBatchListItem={this.props.editBatchListItem}
					removeBatchListItem={this.props.removeBatchListItem}
				/>

				<EditModalChoice
					modalVisible={this.state.showModalEdit}
					handleClose={this._toggleEditModal}
					rows={this.props.selectedRows}
				/>
			</>
		);
	}
}

SalaryComponents.propTypes = {
	salaryGroupId: PropTypes.number,
	rowValues: PropTypes.object,
	selectedRows: PropTypes.array,

	batchOptions: PropTypes.object,
	listGlobalSalaryGroups: PropTypes.object,
	listGlobalSalaryComponents: PropTypes.object,
	customFilterColumns: PropTypes.object,
	batchList: PropTypes.array,

	updateSalaryComponent: PropTypes.func,
	setSalaryComponent: PropTypes.func,
	resetSalaryComponent: PropTypes.func,
	updateBatchForm: PropTypes.func,
	resetBatch: PropTypes.func,
	addBatchList: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setSalaryComponent,
			updateSalaryComponent,
			resetSalaryComponent,
			updateBatchForm,
			resetBatch,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		rowValues: store.salaryComponents.data.salaryComponent,
		batchOptions: store.salaryComponents.data.batchOptions,
		batchList: store.salaryComponents.data.batchList,
		listGlobalSalaryGroups: store.listData['salary/salary_groups/batch'],
		listGlobalSalaryComponents:
			store.listData['salary/global_salary_components/batch'],
		selectedRows: _get(
			store,
			`listData.['salary/salary_components'].ui.selectedRows`,
			[]
		),
		customFilterColumns: _get(
			store,
			`filterSortColumnsData.tables[${reduxKey}].custom`,
			{}
		),
	};
};

export default connectWithStore(
	SalaryComponents,
	mapStateToProps,
	mapDispatchToProps
);
