import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Dungeons N' Dragons
import { DragSource, DropTarget } from 'react-dnd';

// components
import { Icon } from 'dumb';

// util
import _get from 'lodash/get';
import cx from 'classnames';
import { getFlairToDisplay } from './utils';

// styles
import './itemRow.css';

const ItemRow = ({
	imageAsset,
	tile,
	removeTile,
	editTile,
	isDragging,
	connectDragSource,
	connectDropTarget,
	isOverCurrent,
}) => {
	const getFlair = (entry) => {
		const flairToDisplay = getFlairToDisplay(entry);

		if (!flairToDisplay) return null;

		const flairText = flairToDisplay.name;
		const backgroundColor = flairToDisplay.background_color || '000000';
		const titleColor = flairToDisplay.title_color || 'ffffff';

		return (
			<div
				className="item-row__flair"
				style={{ backgroundColor: `#${backgroundColor}` }}
			>
				<span style={{ color: `#${titleColor}` }}>{flairText}</span>
			</div>
		);
	};

	const [hovering, setHovering] = useState(false);

	return connectDragSource(
		connectDropTarget(
			<div
				className={cx('item-row', {
					'item-row--hovering': hovering,
					'item-row--hovered-on': isOverCurrent,
				})}
				style={{ opacity: isDragging ? 0 : 1 }}
				onMouseEnter={() => setHovering(true)}
				onMouseLeave={() => setHovering(false)}
			>
				<div
					className={cx('item-row__add-new', {
						'item-row__add-new--hovered-on': isOverCurrent,
					})}
				/>

				<div
					className="item-row__image"
					style={{
						backgroundImage: `url('${_get(
							imageAsset,
							'resolution_one.url',
							null
						)}')`,
						backgroundPosition: 'center',
						backgroundSize: 'cover',
					}}
				/>
				<div className="item-row__content">
					<span className="item-row__content__name">{tile.name}</span>
					<span className="item-row__content__description">
						{tile.description}
					</span>
				</div>

				{getFlair(tile)}

				{hovering && (
					<div className="item-row__crud">
						<Icon
							className="item-row__crud__icon"
							name="edit"
							onClick={() => editTile(tile)}
						/>
						<Icon
							className="item-row__crud__icon"
							name="delete"
							onClick={() => removeTile(tile.id)}
						/>
					</div>
				)}
			</div>
		)
	);
};

ItemRow.propTypes = {
	type: PropTypes.string,
	tile: PropTypes.object,
	imageAsset: PropTypes.object,
	removeTile: PropTypes.func,
	editTile: PropTypes.func,
	addTile: PropTypes.func,
	id: PropTypes.number,
	index: PropTypes.numebr,
	handleProductVariantOnDrop: PropTypes.func,
	moveCard: PropTypes.func,
	onDrop: PropTypes.func,
};

export default DropTarget(
	['productCard', 'Product-variant'],
	{
		hover(props, monitor) {
			// productCard or Product-variant
			const itemType = monitor.getItemType();

			if (itemType !== 'productCard') return;
			const dragIndex = monitor.getItem().index;
			const hoverIndex = props.index;
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			props.moveCard(dragIndex, hoverIndex);
			monitor.getItem().index = hoverIndex;
		},
		drop(props, monitor) {
			const itemType = monitor.getItemType();

			if (itemType === 'Product-variant') {
				// this item is valid to be sent to the
				const item = monitor.getItem();

				props.handleProductVariantOnDrop({
					itemProps: item,
					position: props.tile.position,
				});
			} else {
				props.onDrop();
			}
		},
	},
	(connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		// isOverCurrent tells the wrapped component that it's being hovered over by another, draggable component
		isOverCurrent: monitor.isOver({ shallow: true }),
	})
)(
	DragSource(
		'productCard',
		{
			beginDrag: (props) => ({
				id: props.id,
				index: props.index,
			}),
		},
		(connect, monitor) => ({
			connectDragSource: connect.dragSource(),
			isDragging: monitor.isDragging(),
		})
	)(ItemRow)
);
