// interface
import { ActionType } from 'types/redux';
import {
	IWorkplaceTagsReduxValue,
	IWorkplaceTagsReducer,
	IWorkplaceTagsReduxValueBatch,
} from '../types';

// utils
import moment from 'moment';
import _uniqueId from 'lodash/uniqueId';

import {
	SET_WORKPLACE_TAG,
	UPDATE_WORKPLACE_TAG,
	RESET_STATE,
	RERENDER_TABLE,
	SET_BATCH_FORM_DATA,
} from './workplaceTags.actions';

const defaultData = {
	tag: null,
	workplace: null,
};

const defaultState: IWorkplaceTagsReducer = {
	tableKey: moment().unix(),
	data: {
		defaultWorkplaceTag: null,
		workplaceTag: defaultData,
	},
	batchList: [
		{
			identifier: _uniqueId('row-'),
			...defaultData,
		},
	],
};

function reducer(
	state = defaultState,
	action: ActionType
): IWorkplaceTagsReducer {
	const { type, payload } = action;

	switch (type) {
	case SET_WORKPLACE_TAG: {
		const castedPayload = payload as IWorkplaceTagsReduxValue;

		return {
				...state,
				data: {
					workplaceTag: castedPayload,
					defaultWorkplaceTag: castedPayload,
				},
			};
	}

	case UPDATE_WORKPLACE_TAG: {
		const castedPayload = payload as Partial<IWorkplaceTagsReduxValue>;

		return {
				...state,
				data: {
					...state.data,
					workplaceTag: {
						...state.data.workplaceTag,
						...castedPayload,
					},
				},
			};
	}

	case RESET_STATE: {
		return defaultState;
	}

	case RERENDER_TABLE:
		return {
				...state,
				tableKey: moment().unix(),
			};

	case SET_BATCH_FORM_DATA: {
		const castedPayload = payload as IWorkplaceTagsReduxValueBatch[];

		return {
				...state,
				batchList: castedPayload,
			};
	}

	default:
		return state;
	}
}

export default reducer;
