'use strict';
import React from 'react';
import PropTypes from 'prop-types';

import PosItemList from './components/posItemList/posItemList.component';
import PosLayout from './components/posLayout/posLayout.component';

import CategoryList from './components/categoryList/categoryList';

import { withDragDropContext } from 'utils';

import _maxBy from 'lodash/maxBy';
import _isEmpty from 'lodash/isEmpty';

const AppLayoutComponent = (props) => {
	const {
		posFullConfigurationsVariantsList,
		activeTile,
		addLayoutGroup,
		addTile,
		deleteLayoutGroup,
		removeTile,
		fetching,
		groupItemInFocus,
		groups,
		imageAssets,
		rotateTiles,
		rotateLayoutGroup,
		setMenuItemInFocus,
		tilesInGroup,
		updateLayoutGroup,
		updateTile,
		layoutId,
		type,
		subType,
	} = props;

	const menuAssets = imageAssets.filter((asset) => asset.type === 'Menu Logo');
	const tileAssets = imageAssets.filter((asset) => asset.type !== 'Menu Logo');

	const lastInLine = _maxBy(tilesInGroup, (group) =>
		parseInt(group.position, 10)
	);

	return (
		<div
			className="sales-pos-configuration"
			data-cy="sales-configuration-app-layout-container"
		>
			<div className="sales-pos-configuration__content-wrapper">
				<CategoryList
					groups={groups}
					addLayoutGroup={addLayoutGroup}
					updateLayoutGroup={updateLayoutGroup}
					deleteLayoutGroup={deleteLayoutGroup}
					rotateLayoutGroup={rotateLayoutGroup}
					layoutId={layoutId}
					fetching={fetching}
					setMenuItemInFocus={setMenuItemInFocus}
					groupItemInFocus={groupItemInFocus}
					imageAssets={menuAssets}
				/>

				<PosLayout
					subType={subType}
					activeTile={activeTile}
					addTile={addTile}
					removeTile={removeTile}
					imageAssets={tileAssets}
					rotateTiles={rotateTiles}
					tilesInGroup={tilesInGroup}
					updateTile={updateTile}
					layoutId={layoutId}
					type={type}
					disabled={_isEmpty(groups)}
				/>

				<PosItemList
					addTile={addTile}
					groupItemInFocus={groupItemInFocus}
					posConfigurationsVariantsList={posFullConfigurationsVariantsList}
					lastInLine={lastInLine}
					enableRowClick
				/>
			</div>
		</div>
	);
};

AppLayoutComponent.defaultProps = {
	phrases: {},
	layout: null,
};

AppLayoutComponent.propTypes = {
	layoutId: PropTypes.number,
	setMenuItemInFocus: PropTypes.func,
	updateTile: PropTypes.func,
	addLayoutGroup: PropTypes.func,
	addTile: PropTypes.func,
	updateLayoutGroup: PropTypes.func,
	deleteLayoutGroup: PropTypes.func,
	removeTile: PropTypes.func,
	type: PropTypes.string,
	posFullConfigurationsVariantsList: PropTypes.array,
	rotateLayoutGroup: PropTypes.func,
	tilesInGroup: PropTypes.array,
	imageAssets: PropTypes.array,
	groups: PropTypes.object,
	activeTile: PropTypes.object,
	fetching: PropTypes.bool,
	groupItemInFocus: PropTypes.number,
	rotateTiles: PropTypes.func,
	subType: PropTypes.string,
};

export default withDragDropContext(AppLayoutComponent);
