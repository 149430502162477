import React from 'react';

import { StatsigProvider } from 'statsig-react';
import { useAuth0 } from '@auth0/auth0-react';
import AuthService from 'auth.service';

type Props = {
	children: React.ReactNode;
};

const StatsigProviderWrapper = ({ children }: Props) => {
	const { user } = useAuth0();
	const userId = AuthService.getUserIdFromUserObject(user);

	return (
		<StatsigProvider
			sdkKey={process.env.STATSIG_SDK ?? ''}
			user={{
				userID: userId,
				email: user?.email ?? undefined,
			}}
			waitForInitialization>
			{children}
		</StatsigProvider>
	);
};

export default StatsigProviderWrapper;
