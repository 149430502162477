/* eslint-disable react/prop-types */
import React from 'react';
import { IColumnHeader } from 'types/reactDataWrapper';
import { Box, Chip } from '@mui/material';
import moment from 'moment';
import constants from 'services/constants';

function formatDate(date: string) {
	return moment.utc(date).format(constants.shortDate);
}

export function getStatusColor(status: string) {
	switch (status) {
		case 'Approved':
			return 'success';
		case 'Pending':
			return 'info';
		case 'Declined':
			return 'error';
		default:
			return 'default';
	}
}

export default function getColumns(
	reduxKey: string
): IColumnHeader<IRequest>[] {
	const columns: IColumnHeader<IRequest>[] = [
		{
			Header: 'Employee',
			id: 'employee',
			accessor: (d) => d.vacation?.person.full_name,
			filterPath: ':vacation.person.full_name',
		},
		{
			Header: 'Description',
			id: 'description',
			accessor: (d) => d.description ?? 'N/A',
			filterPath: ':description',
		},
		{
			Header: 'From',
			id: 'from',
			accessor: (d) => d.vacation.period.from,
			filterPath: ':vacation.period.from',
			Cell: (d) => formatDate(d.original.vacation.period.from),
		},
		{
			Header: 'To',
			id: 'to',
			accessor: (d) => d.vacation.period.to,
			filterPath: ':vacation.period.to',
			Cell: (d) => formatDate(d.original.vacation.period.to),
		},
		{
			Header: 'Resolved By',
			id: 'resolved_by',
			accessor: (d) => d.resolved_by?.name ?? 'N/A',
			filterPath: ':resolved_by.name',
			Cell: (d) => {
				const value = d.original.resolved_by?.name;
				return (
					<Box sx={{ color: !value ? 'text.disabled' : 'inherit' }}>
						{value ?? 'N/A'}
					</Box>
				);
			},
		},
		{
			Header: 'Declined Reason',
			id: 'declined_reason',
			accessor: (d) => d.declined_reason ?? 'N/A',
			filterPath: ':declined_reason',
			Cell: (d) => {
				const value = d.original.declined_reason;
				return (
					<Box sx={{ color: !value ? 'text.disabled' : 'inherit' }}>
						{value ?? 'N/A'}
					</Box>
				);
			},
		},
		{
			Header: 'Status',
			id: 'status',
			accessor: 'status',
			filterPath: ':status',
			Cell: (d) => {
				const status = d.value as string;
				return (
					<Chip label={status} size="small" color={getStatusColor(status)} />
				);
			},
		},
	];

	return columns;
}
