import { IColumnHeader } from 'types/reactDataWrapper';

export default function getColumns(): IColumnHeader<IAdministrationGridEntry>[] {
	return [
		{
			Header: 'Employees',
			id: 'employees',
			accessor: 'employees',
			filterPath: ':employees',
		},
		{
			Header: 'Minimum',
			id: 'minimum',
			accessor: 'minimum',
			filterPath: ':minimum',
		},
		{
			Header: 'Maximum',
			id: 'maximum',
			accessor: 'maximum',
			filterPath: ':maximum',
		},
		{
			Header: 'Optimal',
			id: 'optimal',
			accessor: 'optimal',
			filterPath: ':optimal',
		},
		{
			Header: 'Minimum (Audit range)',
			id: 'audit-range-minimum',
			accessor: 'audit_range_minimum',
			filterPath: ':audit_range_minimum',
		},
		{
			Header: 'Maximum (Audit range)',
			id: 'audit-range-maximum',
			accessor: 'audit_range_maximum',
			filterPath: ':audit_range_maximum',
		},
	];
}
