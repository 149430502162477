'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';

// components
import { Button, Icon, Tooltip } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import WorkplaceEditableCells from './components/workplaceEditableCells/workplaceEditableCells';
import getColumns from 'reactDataWrapperColumns/administration/workplaces.columns';

import {
	fetchWorkplaces,
	addWorkplaces,
	deleteWorkplaces,
	editWorkplaces,
	editMultipleWorkplaces,
} from './workplaces.service';

import {
	setWorkplace,
	updateWorkplace,
	resetWorkplace,
} from './store/workplaces.actions';

// constants/phrases/tools
import moment from 'moment';
import constants from 'services/constants';
import phrases from './workplaces.phrases';
import getEditedValues from 'services/utils/getEditedValues';
import _has from 'lodash/has';

const reduxKey = '/administration/workplaces';
class Workplace extends Component {
	constructor(props) {
		super(props);

		this.state = {
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.renderLink = this.renderLink.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);
		this.getExtraFilters = this.getExtraFilters.bind(this);

		this.columns = getColumns(reduxKey);
	}

	addEntry() {
		const { workplace } = this.props;

		const payload = {
			name: workplace.name,
			external_name: workplace.externalName ?? undefined,
			type: workplace.type?.value ?? null,
			state: workplace.state?.value ?? null,
			time_zone: workplace.timezone?.value ?? null,
			market: workplace.market?.value?.id ?? null,
			territory: workplace.territory?.value?.id ?? null,

			// default dates set in reducer mess up multi edit login
			opened: workplace.opened ?? moment().format(constants.shortDate),
			closed: workplace.closed ?? '9999-12-31',

			// optional fields
			sort_order: workplace.sortOrder ?? undefined,
			grid: workplace.grid?.value?.id ?? undefined,
			company: workplace.company?.value ?? undefined,
			store_area_type: workplace.storeAreaType?.value ?? undefined,
			store_continent_tag: workplace.storeContinentTag?.value ?? undefined,
			store_forecast_tag: workplace.storeForecastTag?.value ?? undefined,
			store_location_type: workplace.storeLocationType?.value ?? undefined,
			store_size: workplace.storeSize ?? undefined,
			store_first_year_forecast: workplace.storeFirstYearForecast ?? undefined,
			franchise_store: !!workplace.franchiseStore,
			audit_number: workplace.auditNumber ?? undefined,
			opening_offset: workplace.openingOffset,
			closing_offset: workplace.closingOffset,
			food_control_report_url: workplace.foodControlReportUrl ?? undefined,
			department_code: workplace.departmentCode || null,
			legacy: workplace.legacy,
			show_in_joe_app: workplace.showInJoeApp ?? undefined,
		};

		return addWorkplaces(payload);
	}

	editEntry() {
		const { workplace, defaultWorkplace } = this.props;

		const editedValues = getEditedValues({
			oldData: defaultWorkplace,
			newData: workplace,
		});

		const payload = {
			id: workplace.id,
			external_name: workplace.externalName,
			name: editedValues.name,
			type: editedValues.type,
			state: editedValues.state,
			time_zone: editedValues.timezone,
			territory: editedValues.territory?.id,
			opened: editedValues.opened,

			// optional fields
			sort_order: editedValues.sortOrder,
			closed: editedValues.closed,
			grid: editedValues.grid?.id,
			store_area_type: editedValues.storeAreaType,
			store_continent_tag: editedValues.storeContinentTag,
			store_forecast_tag: editedValues.storeForecastTag,
			store_location_type: editedValues.storeLocationType,
			store_size: editedValues.storeSize,
			store_first_year_forecast: editedValues.storeFirstYearForecast,
			franchise_store: editedValues.franchiseStore,
			audit_number: editedValues.auditNumber,
			opening_offset: editedValues.openingOffset,
			closing_offset: editedValues.closingOffset,
			food_control_report_url: editedValues.foodControlReportUrl,
			show_in_joe_app: editedValues.showInJoeApp ?? undefined,
			...(_has(editedValues, 'departmentCode')
				? {
						department_code: editedValues.departmentCode || null,
				  }
				: {}),
			legacy: editedValues.legacy,
		};

		return editWorkplaces(payload);
	}

	editMultiple(selectedRows) {
		const { workplace } = this.props;

		const payload = {
			...(workplace.name && {
				name: workplace.name,
			}),
			...(workplace.externalName && {
				external_name: workplace.externalName,
			}),
			...(workplace.type && {
				type: workplace.type.value,
			}),
			...(workplace.state && {
				state: workplace.state.value,
			}),
			...(workplace.timezone && {
				time_zone: workplace.timezone.value,
			}),
			...(workplace.territory?.value && {
				territory: workplace.territory.value.id,
			}),
			...(workplace.opened && {
				opened: workplace.opened,
			}),
			...(workplace.closed && {
				closed: workplace.closed,
			}),
			...(workplace.sortOrder && {
				sort_order: workplace.sortOrder,
			}),
			...(workplace.grid?.value && {
				grid: workplace.grid.value.id,
			}),
			...(workplace.storeAreaType && {
				store_area_type: workplace.storeAreaType.value,
			}),
			...(workplace.storeContinentTag && {
				store_continent_tag: workplace.storeContinentTag.value,
			}),
			...(workplace.storeForecastTag && {
				store_forecast_tag: workplace.storeForecastTag.value,
			}),
			...(workplace.storeLocationType && {
				store_location_type: workplace.storeLocationType.value,
			}),
			...(workplace.storeSize && {
				store_size: workplace.storeSize,
			}),
			...(workplace.storeFirstYearForecast && {
				store_first_year_forecast: workplace.storeFirstYearForecast,
			}),
			...((workplace.franchiseStore === true ||
				workplace.franchiseStore === false) && {
				franchise_store: workplace.franchiseStore,
			}),
			...(workplace.auditNumber && {
				audit_number: workplace.auditNumber,
			}),
			...(workplace.openingOffset && {
				opening_offset: workplace.openingOffset,
			}),
			...(workplace.closingOffset && {
				closing_offset: workplace.closingOffset,
			}),
			...(workplace.legacy && {
				legacy: workplace.legacy,
			}),
			...(workplace.foodControlReportUrl && {
				food_control_report_url: workplace.foodControlReportUrl,
			}),
			...(workplace.showInJoeApp && {
				show_in_joe_app: workplace.showInJoeApp,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editMultipleWorkplaces({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteWorkplaces(id);
	}

	setInitialEditValues(data) {
		const { setWorkplace } = this.props;

		const payload = {
			id: data.id,
			name: data.name,
			externalName: data.external_name,
			type: {
				value: data.type,
				label: data.type,
			},
			state: {
				value: data.state,
				label: data.state,
			},
			timezone: {
				value: data.time_zone,
				label: data.time_zone,
			},
			market: data.market.name,
			territory: data.territory.name,
			opened: data.opened,

			// optional fields
			closed: data.closed,
			sortOrder: data.sort_order,
			...(data.grid && {
				grid: {
					value: data.grid,
					label: data.grid.name,
				},
			}),
			...(data.store_area_type && {
				storeAreaType: {
					value: data.store_area_type.id,
					label: data.store_area_type.name,
				},
			}),
			...(data.store_continent_tag && {
				storeContinentTag: {
					value: data.store_continent_tag.id,
					label: data.store_continent_tag.name,
				},
			}),
			...(data.store_forecast_tag && {
				storeForecastTag: {
					value: data.store_forecast_tag.id,
					label: data.store_forecast_tag.name,
				},
			}),
			...(data.store_location_type && {
				storeLocationType: {
					value: data.store_location_type.id,
					label: data.store_location_type.name,
				},
			}),
			...(data.store_region && {
				storeRegion: data.store_region.name,
			}),
			...(data.store_size && {
				storeSize: data.store_size,
			}),
			...(data.store_first_year_forecast && {
				storeFirstYearForecast: data.store_first_year_forecast,
			}),
			...(data.audit_number && {
				auditNumber: data.audit_number,
			}),
			franchiseStore: data.franchise_store,
			openingOffset: data.opening_offset,
			closingOffset: data.closing_offset,
			legacy: data.legacy,
			foodControlReportUrl: data.food_control_report_url,
			departmentCode: data.department_code,
			showInJoeApp: data.show_in_joe_app,
			editMode: true,
		};

		setWorkplace(payload);
	}

	editStoreEntry(name, e) {
		const { updateWorkplace } = this.props;

		let value = e?.target?.value ?? e;

		if (name === 'opened' || name === 'closed') {
			value = moment.utc(value).format(constants.shortDate);
		}
		if (name === 'closed' && e === null) {
			value = '9999-12-31';
		}

		const payload = {
			[name]: value,
		};

		updateWorkplace(payload);
	}

	fetchData(state) {
		return fetchWorkplaces(state);
	}

	renderLink(e) {
		return (
			<Tooltip
				text={phrases.DETAILS_TOOLTIP}
				placement="right"
				renderData={(ref, onMouseEnter, onMouseLeave) => (
					<Button
						refProp={ref}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						type="inverted"
						shadow
						title="Rules"
						onClick={() =>
							store.dispatch(
								push(`${window.location.pathname}/${e.original.id}`)
							)
						}
						size="micro"
					>
						<Icon name="tune" />
					</Button>
				)}
			/>
		);
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}
			>
				{phrases.LEGACY}
			</Button>
		);
	}

	getExtraFilters() {
		const { customFilters } = this.props;

		const legacyFilter = this.state.legacyFilterOn
			? this.state.legacyFilter
			: '';

		const marketFilter = customFilters?.market?.value
			? `:market.id=='${customFilters.market.value}'`
			: '';

		let filter;
		if (legacyFilter) filter = legacyFilter;
		if (marketFilter)
			filter = filter ? `${filter};${marketFilter}` : marketFilter;

		return filter;
	}

	render() {
		const { workplace } = this.props;

		return (
			<ReactDataWrapper
				title={phrases.TABLE_TITLE}
				dataCy="workplaces-table"
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={20}
				reduxKey={reduxKey}
				manual
				accessAreasAllowedToEdit={[
					'Organisation/Workplaces::Edit',
					'Organization Admin',
				]}
				editableCells={WorkplaceEditableCells({
					workplace,
					editStoreEntry: this.editStoreEntry,
				})}
				actionsWidth={30}
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				editMultiple={this.editMultiple}
				deleteEntry={this.deleteEntry}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetWorkplace}
				compact
				extraFilters={this.getExtraFilters()}
				actions={this.renderLink}
				onModalCloseConfirm
				customAreaComponents={this.getLegacyFilterButton()}
			/>
		);
	}
}

Workplace.propTypes = {
	setWorkplace: PropTypes.func,
	resetWorkplace: PropTypes.func,
	updateWorkplace: PropTypes.func,
	workplace: PropTypes.object,
	customFilters: PropTypes.object,
	defaultWorkplace: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setWorkplace,
			updateWorkplace,
			resetWorkplace,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		workplace: store.workplaces.data.workplace,
		defaultWorkplace: store.workplaces.data.defaultWorkplace,
		customFilters: store.filterSortColumnsData.tables?.[reduxKey]?.custom,
	};
};

export default connectWithStore(Workplace, mapStateToProps, mapDispatchToProps);
