import React from 'react';
import { useNavigate } from 'react-router';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setValue, updateValue, resetState } from './store/tags.actions';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Tabs } from 'dumb';
import SubTable from 'reactDataWrapper/components/subTable';
import TagsGetEditableCells from './components/tagsGetEditableCells';
import getColumns from 'reactDataWrapperColumns/tagging/tags.columns';
import MarketTags from './components/marketTags/marketTags.container';

// services
import { fetchTags, addTag, editTag, deleteTag } from './tags.service';
import getEditedValues from 'services/utils/getEditedValues';

// utils
import _has from 'lodash/has';

// phrases
import phrases from './tags.phrases';
import WorkplaceTags from './components/workpalceTags/workplaceTags.container';

const Tags = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { value, originalValue } = useSelector((store) => store.tags);

	const addEntry = () => {
		const body = {
			type: value.type?.value,
			subtype: value.subType?.value,
			global_account: value.globalAccount?.value?.id,
			name: value.name,
			name_abbreviated: value.nameAbbreviated,
			exclude_productivity: value.excludeProductivity,
			exclude_base_pay: value.excludeBasePay,
			exclude_addon: value.excludeAddon,
			exclude_overtime: value.excludeOvertime,
			current_year_index: value.currentYearIndex,
			prior_year_index: value.priorYearIndex,
			color: value.color,
		};

		return addTag(body);
	};
	const editEntry = () => {
		const editedValues = getEditedValues({
			newData: value,
			oldData: originalValue,
		});

		const body = {
			id: value.id,
			...(_has(editedValues, 'globalAccount') && {
				global_account: editedValues.globalAccount?.id ?? null,
			}),
			name: editedValues.name ?? undefined,
			name_abbreviated: editedValues.nameAbbreviated ?? undefined,
			exclude_productivity: editedValues.excludeProductivity ?? undefined,
			exclude_base_pay: editedValues.excludeBasePay ?? undefined,
			exclude_addon: editedValues.excludeAddon ?? undefined,
			exclude_overtime: editedValues.excludeOvertime ?? undefined,
			current_year_index: editedValues.currentYearIndex ?? undefined,
			prior_year_index: editedValues.priorYearIndex ?? undefined,
			color: editedValues.color ?? undefined,
		};

		return editTag(body);
	};
	const deleteEntry = (id) => {
		return deleteTag(id);
	};
	const setInitialEditValues = (row) => {
		const payload = {
			id: row.id,
			globalAccount: row.global_account
				? {
						value: row.global_account ?? null,
						label: row.global_account?.name ?? '',
				  }
				: null,
			type: row.type,
			subType: row.sub_type,
			name: row.name,
			nameAbbreviated: row.name_abbreviated,
			excludeProductivity: row.exclude_productivity,
			excludeBasePay: row.exclude_base_pay,
			excludeAddon: row.exclude_addon,
			excludeOvertime: row.exclude_overtime,
			currentYearIndex: row.current_year_index,
			priorYearIndex: row.prior_year_index,
			color: row.color,
			editMode: true,
		};

		dispatch(setValue(payload));
	};
	const onResetState = () => dispatch(resetState());
	const onChange = (value, name) => {
		const payload = {
			[name]: value,
		};

		dispatch(updateValue(payload));
	};

	return (
		<ReactDataWrapper
			dataCy="tags-table"
			title={phrases.TITLE}
			accessAreasAllowedToEdit={[
				'Global BI Configuration',
				'Sales Configuration',
			]}
			columns={getColumns(navigate)}
			fetchData={fetchTags}
			filterable
			defaultPageSize={50}
			reduxKey="/tagging/tags"
			manual
			createEntry={addEntry}
			editEntry={editEntry}
			deleteEntry={deleteEntry}
			editableCells={TagsGetEditableCells({
				value,
				onChange,
			})}
			subcomponent={(row) => {
				const tabContent = [
					{
						name: 'Market tags',
						component: <MarketTags tagId={row.original.id} />,
					},
					{
						name: 'Workplace tags',
						component: <WorkplaceTags tagId={row.original.id} />,
					},
				];
				return (
					<SubTable>
						<Tabs tabContent={tabContent} />
					</SubTable>
				);
			}}
			setInitialEditValues={setInitialEditValues}
			onModalClose={onResetState}
		/>
	);
};

export default Tags;
