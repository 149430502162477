import { Divider, List, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

export const JoeDivider = styled(Divider)({
	color: 'rgb(255 255 255 / 44%)',
});

export const JoeNavList = styled(List)(({ theme }) => ({
	color: '#fff',

	'& .MuiListItem-root': {
		paddingLeft: 14,
		paddingRight: 24,

		[theme.breakpoints.up('sm')]: {
			paddingLeft: 18,
		},

		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)',

			'& .MuiListItemIcon-root': {
				transform: 'translate(6px, 0px)',
				transition: theme.transitions.create(['transform']),
			},
		},
	},

	'& .MuiListItemIcon-root': {
		minWidth: 0,
		marginRight: 16,
		transition: theme.transitions.create(['transform']),
	},

	'& .MuiSvgIcon-root': {
		fontSize: 22,
		color: 'rgba(255, 255, 255, 0.5)',
	},

	'& .MuiListItemText-primary': {
		fontSize: 16,
		textTransform: 'capitalize',
	},
}));

export const innerStyling = (theme: Theme) => ({
	position: 'absolute',
	top: '100px',
	left: '50%',
	transform: 'translate(-50%, 0)',
	width: '100%',
	bgcolor: 'background.drawer',
	boxShadow: 1,

	[theme.breakpoints.up('sm')]: {
		width: 480,
	},
});

export const sectionStyling = {
	padding: 4,
};
