import React, { useEffect, useState } from 'react';
import { fetchPartnershipCodes } from 'modules/structure/globalAdministration/joePayPartnerships/partnershipCodesTable.service';
import { Modal } from 'dumb';
import { Box, Tooltip, Button, Stack } from '@mui/material';
import { copyToClipboard } from 'services/utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { useAppSelector } from 'appState/storeHooks';
import { useGate } from 'statsig-react';
import useMediaQuery from '@mui/material/useMediaQuery';
import appImage from './joe-app.png';
import iosImage from './ios.png';
import androidImage from './android.png';
import styled from '@emotion/styled';
import moment from 'moment';

const StepList = styled.ol`
	list-style-position: inside;
	text-align: left;
`;

export default function UnredeemedPartnershipCodeModal() {
	const [partnershipCode, setPartnershipCode] =
		useState<IPartnershipCode | null>(null);
	const [hasClaimed, setHasClaimed] = useState(false);
	const { activeUser } = useAppSelector((state) => ({
		activeUser: state.userData?.user,
	}));
	const isMobile = useMediaQuery('(max-width:600px)');
	const isTestUser = useGate('juicers_on_the_app').value;

	const setUnredeemedPartnershipCodes = async () => {
		try {
			const result: IApiRes<IPartnershipCode> = await fetchPartnershipCodes();

			const isExpired = (code: IPartnershipCode) =>
				code.expires_at && moment(code.expires_at).isBefore(moment());

			const hasRedeemedCode = result.data.some(
				(code) => code.status === 'active' && code.redeemed && !isExpired(code)
			);

			const unredeemedPartnershipCode = result.data.find(
				(code) => code.status === 'active' && !code.redeemed && !isExpired(code)
			);

			if (!hasRedeemedCode && unredeemedPartnershipCode) {
				setPartnershipCode(unredeemedPartnershipCode);
			}
		} catch (error) {
			// Do no interrupt the user experience if the partnership codes are not fetched
			console.error('Error fetching partnership codes', error);
		}
	};

	const handleCloseModal = () => {
		// Close the modal
		setPartnershipCode(null);
	};

	const handleCopyToClipboard = () => {
		try {
			if (!partnershipCode) return;
			setHasClaimed(true);
			copyToClipboard(partnershipCode.code);
			setFeedback('Successfully copied code', 1);
		} catch (error) {
			setFeedback('Error copying code', 0);
		}
	};

	useEffect(() => {
		setUnredeemedPartnershipCodes();
	}, []);

	if (!isTestUser || !partnershipCode) return null;

	const code = partnershipCode.code;
	const fullName = activeUser?.user.person.full_name ?? 'Juicer';

	return (
		<Modal
			header="Claim your benefits today!"
			onConfirmClick={handleCloseModal}
			confirmButtonLabel={hasClaimed ? 'Got it!' : 'Later...'}
		>
			<Stack sx={{ maxWidth: 500 }} spacing={2}>
				<Stack direction="row">
					<Stack spacing={4}>
						<Box sx={{ textAlign: 'justify' }}>
							{!isMobile && (
								<Box
									sx={{ maxHeight: 400, float: 'right' }}
									component="img"
									src={appImage}
									alt="Joe %26 The Juice app"
								/>
							)}
							<h3>Hello {fullName},</h3>
							<p>
								We&apos;re always looking for ways to make your work experience
								better. That&apos;s why we&apos;re excited to share a special
								offer just for you, our valued team members, through the{' '}
								<Box component="span" sx={{ fontWeight: 'bold' }}>
									Joe Loyalty App
								</Box>
								!
							</p>
							<p>
								To get your hands on some great perks, follow these steps before
								your next shift:
							</p>
							<StepList>
								<li>Download the Joe Loyalty App.</li>
								<li>Open the app.</li>
								<li>Go to your profile (it&apos;s in the top right corner).</li>
								<li>Claim the promo code we&apos;ve provided.</li>
								<li>Start enjoying your discounts!</li>
							</StepList>
							<p>
								Thanks for being such an important part of our team. We
								appreciate all that you do. Here&apos;s to more perks coming
								your way! 🙏
							</p>
						</Box>
					</Stack>
				</Stack>
				<Tooltip title="Copy to clipboard" disableInteractive>
					<Button
						sx={{
							width: '100%',
							borderRadius: '2px',
							boxShadow:
								'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1)',
						}}
						size="large"
						endIcon={<ContentCopyIcon />}
						onClick={handleCopyToClipboard}
					>
						{hasClaimed ? code : 'CLICK HERE TO CLAIM'}
					</Button>
				</Tooltip>
				{isMobile && (
					<Stack direction="row" spacing={2}>
						<a href="https://joeandthejuice.app.link/q1blKFKNyHb">
							<Box
								sx={{ width: '100%' }}
								component="img"
								src={iosImage}
								alt="Joe & The Juice app (iOS)"
							/>
						</a>
						<a href="https://joeandthejuice.app.link/q1blKFKNyHb">
							<Box
								sx={{ width: '100%' }}
								component="img"
								src={androidImage}
								alt="Joe & The Juice app (Android)"
							/>
						</a>
					</Stack>
				)}
			</Stack>
		</Modal>
	);
}
