import { ActionType } from 'types/redux';
import { IGridReduxValue } from '../types';

export const SET_GRID = 'GRIDS/SET';
export const SET_SORT_ORDER = 'GRIDS/SET_SORT_ORDER';
export const UPDATE_GRID = 'GRIDS/UPDATE';
export const RESET_DATA = 'GRIDS/RESET_DATA';

export function resetData(): ActionType {
	return {
		type: RESET_DATA,
	};
}

export function setGrid(payload: IGridReduxValue): ActionType {
	return {
		type: SET_GRID,
		payload,
	};
}

export function updateGrid(payload: Partial<IGridReduxValue>): ActionType {
	return {
		type: UPDATE_GRID,
		payload,
	};
}
