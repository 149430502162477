'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { InputCollectionSelect, Button, Icon } from 'dumb';
import ModalBatch from './components/modalBatch/modalBatch.container';
import _get from 'lodash/get';

import {
	setProductSetOption,
	updateProductSetOptions,
	resetProductSetOptions,
	updateBatchForm,
	resetBatch,
} from './store/productSetOptions.actions';

import {
	fetchProductSetOptions,
	addProductSetOption,
	deleteProductSetOption,
	fetchProductVariants,
} from './productSetOptions.service';

class ProductSets extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);

		this.state = {
			showModal: false,
		};

		this.fetchData = this.fetchData.bind(this);
		this.fetchProductVariantsBatch = this.fetchProductVariantsBatch.bind(this);
		this._toggleModal = this._toggleModal.bind(this);

		this.columns = [
			{
				Header: 'Product variant name',
				id: 'product_variant',
				accessor: (d) => _get(d, 'product_variant.name', null),
				filterPath: ':product_variant.name',
			},
		];
	}

	fetchProductVariantsBatch(state) {
		const { discountProductSetOptions } = this.props;

		const currentList = _get(discountProductSetOptions, 'data.listData', []);
		const excludedIds = currentList.map((x) => `'${x.product_variant.id}'`);
		return fetchProductVariants(state, excludedIds);
	}

	deleteEntry(id) {
		return deleteProductSetOption(id);
	}

	setInitialEditValues(data) {
		this.props.setProductSetOption(data);
	}

	getEditableCells() {
		const { defaultValues, discountProductSetOptions } = this.props;

		const currentList = _get(discountProductSetOptions, 'data.listData', []);
		const excludedIds = currentList.map((x) => `'${x.product_variant.id}'`);

		return [
			{
				header: 'Product variant name',
				value: (
					<InputCollectionSelect
						id="product_variant"
						placeholder="select product variant"
						value={
							_get(defaultValues, 'product_variant.id', false)
								? {
										value: _get(defaultValues, 'product_variant.id', ''),
										label: `${_get(defaultValues, 'product_variant.name', '')}`,
								  }
								: null
						}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'product_variant'
							)
						}
						clearable
						cache
						apiPath="/product/product_variants"
						params={{
							limit: 30,
							filter:
								excludedIds && excludedIds.length > 0
									? `:id=!IN=[${excludedIds}]`
									: '',
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
		];
	}

	editStoreEntry(e) {
		const value = e.target ? e.target.value : e;
		this.props.updateProductSetOptions({
			product_variant: value,
		});
	}

	fetchData(state) {
		const { productSet } = this.props;
		return fetchProductSetOptions(productSet.id, state);
	}

	_toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetBatch();
	}

	render() {
		const {
			productVariants,
			discountProductSetOptions,
			updateBatchForm,
			batchOptions,
			productSet,
		} = this.props;

		const currentList = _get(discountProductSetOptions, 'data.listData', []);
		const excludedIds = currentList.map((x) => `'${x.product_variant.id}'`);

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					title={this.props.title}
					columns={this.columns}
					totalEntries={this.state.totalEntries}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`sale/discount_product_set_options/${this.props.productSet.id}`}
					manual
					editableCells={this.getEditableCells()}
					editMultiple={this.editMultiple}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetProductSetOptions}
					deleteEntry={this.deleteEntry}
					actionRender={
						<>
							<Button
								type="inverted"
								label="Batch"
								shadow
								onClick={() => this._toggleModal()}
							>
								<Icon name="add" />
							</Button>
						</>
					}
				/>

				<ModalBatch
					modalVisible={this.state.showModal}
					handleClose={this._toggleModal}
					fetchProductVariants={this.fetchProductVariantsBatch}
					batchOptions={batchOptions}
					updateBatchForm={updateBatchForm}
					productVariants={productVariants}
					addProductSetOption={addProductSetOption}
					productSet={productSet}
					excludedIds={excludedIds}
				/>
			</>
		);
	}
}

ProductSets.propTypes = {
	productSet: PropTypes.object,
	defaultValues: PropTypes.object,
	title: PropTypes.string,
	discountProductSetOptions: PropTypes.object,

	resetBatch: PropTypes.func,
	updateProductSetOptions: PropTypes.func,
	setProductSetOption: PropTypes.func,
	resetProductSetOptions: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchOptions: PropTypes.object,
	productVariants: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setProductSetOption,
			updateProductSetOptions,
			resetProductSetOptions,
			updateBatchForm,
			resetBatch,
		},
		dispatch
	);
};

const mapStateToProps = (initialStore, ownProps) => (store) => {
	return {
		defaultValues:
			store.discountsProductSetOptions.data.defaultProductSetOptions,
		batchOptions: store.discountsProductSetOptions.data.batchOptions,
		productVariants: store.listData['product/product_variants/batch'],
		discountProductSetOptions:
			store.listData[
				`sale/discount_product_set_options/${ownProps.productSet.id}`
			],
	};
};

export default connectWithStore(
	ProductSets,
	mapStateToProps,
	mapDispatchToProps
);
