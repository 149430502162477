'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';
import { Tip } from 'dumb/tips';

import phrases from './../../../../productSets.phrases';

class BatchStepForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showOverlay: false,
			loadingModal: false,
		};
	}

	editStoreEntry(e, type) {
		const { batchOptions } = this.props;
		const value = e.target ? e.target.value : e;

		this.props.updateBatchForm({
			...batchOptions,
			...{ [type]: value },
		});
	}

	render() {
		const { batchOptions, toggleOverlay, excludedIds } = this.props;

		return (
			<>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							className="modal-batch-select"
							id="ing-cat"
							label="Product variants"
							placeholder="Select product variants"
							value={batchOptions.product_variant}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'product_variant')
							}
							multi
							cache
							apiPath="/product/product_variants"
							params={{
								limit: 30,
								filter:
									excludedIds && excludedIds.length > 0
										? `:id=!IN=[${excludedIds}]`
										: '',
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="medium"
							onClick={() => toggleOverlay()}
						>
							<span className="icon icon--more" />
						</Button>
					</ButtonGroup>
				</ModalRow>
				<Tip content={phrases.MODAL_BATCH_STEP_FORM_TIP} />
			</>
		);
	}
}

BatchStepForm.propTypes = {
	batchOptions: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	excludedIds: PropTypes.array,
};

BatchStepForm.defaultProps = {};

export default BatchStepForm;
