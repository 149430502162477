import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { IRoute } from './../../../services/navigation';

// components
import {
	ListItem,
	ListItemText,
	ListItemIcon,
	Collapse,
	Typography,
	Tooltip,
} from '@mui/material';
import {
	JoeNavList,
	JoeNavNested,
	ListItemTextStyling,
} from './drawerReusableNavigationComponents';

// icons
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

// utils
import _uniqBy from 'lodash/uniqBy';
import { isDevelopment } from 'services/environment';

type Props = {
	filteredRoutes: IRoute[];
	powerBIReports: any[];
	splitLocation: string[];
	juicerId: number;
	setHoveredListItem: (property: string) => void;

	handleToggleCollapsed: (copiedElement: any) => void;
	getRightIconParentSecondary: (
		copiedElement: any,
		hasChildren: boolean
	) => JSX.Element;
	hovered: boolean;
	expanded: Record<string, boolean>;
	handleSectionClick: () => void;
};

const DrawerNavigationList = ({
	filteredRoutes,
	powerBIReports,
	splitLocation,
	juicerId,
	setHoveredListItem,
	handleToggleCollapsed,
	getRightIconParentSecondary,
	hovered,
	expanded,
	handleSectionClick,
}: Props) => {
	const removeDuplicates = <T,>(arr: T[], check: keyof T): T[] => {
		return arr.filter((item, index) => {
			const i = arr.findIndex((x) => x[check] === item[check]);
			return index === i;
		});
	};

	return (
		<JoeNavList>
			{[...filteredRoutes]
				.filter((el) => !el.index)
				.map((filteredRoute) => {
					if (filteredRoute.index) return null;

					const copiedElement = { ...filteredRoute };

					if (powerBIReports?.length && copiedElement.path === 'reports') {
						const childrenWithApendedPowerBIReports = [
							...(copiedElement?.children ?? []),
							...powerBIReports,
						];

						copiedElement.children = removeDuplicates(
							childrenWithApendedPowerBIReports,
							'path'
						);
					}

					const hasChildren = !!copiedElement?.children?.length;
					const iconToDisplay = copiedElement.icon ? (
						<copiedElement.icon />
					) : (
						<ArticleOutlinedIcon />
					);
					const item = <ListItemIcon>{iconToDisplay}</ListItemIcon>;
					const locationPath = splitLocation[1] || '/';

					// show allowed access control areas to Ludwig, Tuna and Filip
					const showAllowedRoles =
						juicerId === 2803 || juicerId === 6679 || juicerId === 9669;
					const allowedAccessControlLevelsString =
						copiedElement.allowedAccessControlLevels?.join(', ');

					// get first word in a title - to be used when running e2e test
					const titleFirstWord = copiedElement.title?.split?.(' ')[0] || '';

					return (
						<Fragment key={copiedElement.path}>
							<ListItem
								sx={{ fontSize: 12 }}
								button
								onMouseEnter={() => setHoveredListItem(copiedElement.path)}
								onMouseLeave={() => setHoveredListItem('')}
								onClick={() =>
									copiedElement.external
										? window.open(copiedElement.path, '_blank')
										: handleToggleCollapsed(copiedElement)
								}
								secondaryAction={getRightIconParentSecondary(
									copiedElement,
									hasChildren
								)}
								component={Link}
								selected={locationPath === copiedElement.path}
								to={copiedElement.external ? '' : copiedElement.path}
								// we are rendering the new property "data-cy-link" so we can test all links
								data-cy-link={copiedElement.external ? copiedElement.path : ''}
								data-cy={`drawer-list-item-${titleFirstWord}`}
							>
								{hovered ? (
									item
								) : (
									<Tooltip
										title={copiedElement.title}
										enterDelay={0}
										placement="right"
									>
										{item}
									</Tooltip>
								)}
								<Tooltip
									title={allowedAccessControlLevelsString}
									open={showAllowedRoles ? undefined : false}
								>
									<ListItemText
										primaryTypographyProps={{ noWrap: true }}
										primary={copiedElement.title}
									/>
								</Tooltip>
							</ListItem>

							{/* Children nested list */}
							{hasChildren ? (
								<Collapse
									in={expanded[copiedElement.title]}
									timeout="auto"
									unmountOnExit
								>
									<JoeNavNested disablePadding>
										{copiedElement.children
											?.filter((el) => !el.index)
											.map((child) => {
												const allowedAccessControlLevelsString =
													child.allowedAccessControlLevels?.join(', ');

												return (
													<Fragment key={`child-${child.title}`}>
														{child?.section && (
															<ListItem
																data-cy="drawer-children-divider"
																sx={{
																	backgroundColor: isDevelopment()
																		? 'rgba(183, 144, 163, 0.65)'
																		: 'rgba(79, 82, 85, 0.5)',
																}}
															>
																<Typography
																	sx={{
																		...ListItemTextStyling,
																		fontSize: 12,
																	}}
																>
																	{child.section}
																</Typography>
															</ListItem>
														)}
														<ListItem
															sx={{ pl: 4, fontSize: 12 }}
															button
															onMouseEnter={() =>
																setHoveredListItem(child.path)
															}
															onMouseLeave={() => setHoveredListItem('')}
															onClick={() =>
																child.external
																	? window.open(child.path, '_blank')
																	: handleSectionClick()
															}
															secondaryAction={getRightIconParentSecondary(
																child,
																false
															)}
															selected={splitLocation[2] === child.path}
															component={child.external ? 'li' : Link}
															to={
																child.external
																	? ''
																	: copiedElement.path + '/' + child.path
															}
														>
															<Tooltip
																title={allowedAccessControlLevelsString}
																open={showAllowedRoles ? undefined : false}
															>
																<ListItemText
																	primaryTypographyProps={{
																		noWrap: true,
																		sx: { color: 'rgb(255 255 255 / 75%)' },
																	}}
																	primary={child.title}
																/>
															</Tooltip>
														</ListItem>
													</Fragment>
												);
											})}
									</JoeNavNested>
								</Collapse>
							) : null}
						</Fragment>
					);
				})}
		</JoeNavList>
	);
};

export default DrawerNavigationList;
