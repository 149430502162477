/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';

// components
import { InputCollectionSelect } from 'dumb';
import { DateFilterSelector, FilterProvider } from 'reactDataWrapper/utilities';

// lodash
import _get from 'lodash/get';

// enums
import collectionSelectEnums from 'services/enums/collectionSelect';

export default function getColumns(reduxKey) {
	return [
		{
			Header: 'Id',
			id: 'id',
			accessor: 'id',
			filterPath: ':id',
			width: 75,
		},
		{
			Header: 'Name',
			id: 'name',
			accessor: 'name',
			filterPath: ':name',
		},
		{
			Header: 'External Name',
			id: 'external-name',
			accessor: 'external_name',
			filterPath: ':external_name',
		},
		{
			Header: 'Market',
			id: 'market',
			accessor: (d) => _get(d, 'market.name', ''),
			filterPath: ':market.name',
			Filter: ({ column }) => (
				<FilterProvider
					reduxKey={reduxKey}
					columnId={column.id}
					provide={(filterValue, persistToFilterStorage) => (
						<InputCollectionSelect
							id={column.id}
							name="market"
							value={filterValue}
							handleChange={(key, value) => {
								persistToFilterStorage({ handle: key, value });
							}}
							styleType={collectionSelectEnums.TYPE_IN_TABLE}
							cache
							apiPath="/administration/markets"
							params={{
								limit: 30,
							}}
							optionFormat={(entry) => ({
								value: entry.id,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>
					)}
				/>
			),
		},
		{
			Header: 'Type',
			id: 'type',
			accessor: 'type',
			filterPath: ':type',
		},
		{
			Header: 'State',
			id: 'state',
			accessor: 'state',
			filterPath: ':state',
		},
		{
			Header: 'Time zone',
			id: 'time-zone',
			accessor: 'time_zone',
			filterPath: ':time_zone',
		},
		{
			Header: 'Territory',
			id: 'teritory',
			accessor: (d) => _get(d, 'territory.name', ''),
			filterPath: ':territory.name',
		},
		{
			Header: 'Opened',
			id: 'opened',
			accessor: 'opened',
			filterPath: ':opened',
			Filter: ({ column }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="from"
				/>
			),
		},
		{
			Header: 'Closed',
			id: 'closed',
			accessor: 'closed',
			filterPath: ':closed',
			Filter: ({ column }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="from"
				/>
			),
		},
		{
			Header: 'Grid',
			id: 'grid',
			accessor: (d) => _get(d, 'grid.name', ''),
			filterPath: ':grid.name',
		},
		{
			Header: 'Area type',
			id: 'area-type',
			accessor: (d) => _get(d, 'store_area_type.name', ''),
			filterPath: ':store_area_type.name',
		},
		{
			Header: 'Continent tag',
			id: 'continent-tag',
			accessor: (d) => _get(d, 'store_continent_tag.name', ''),
			filterPath: ':store_continent_tag.name',
		},
		{
			Header: 'Forecast tag',
			id: 'forecast-tag',
			accessor: (d) => _get(d, 'store_forecast_tag.name', ''),
			filterPath: ':store_forecast_tag.name',
		},
		{
			Header: 'Location type',
			id: 'location-type',
			accessor: (d) => _get(d, 'store_location_type.name', ''),
			filterPath: ':store_location_type.name',
		},
		{
			Header: 'Store region',
			id: 'store-region',
			accessor: (d) => _get(d, 'store_region.name', ''),
			filterPath: ':store_region.name',
			filterable: false,
			sortable: false,
		},
		{
			Header: 'Food control report url',
			id: 'food-control-report-url',
			accessor: 'food_control_report_url',
			filterPath: ':food_control_report_url',
			filterable: false,
			sortable: false,
		},
		{
			Header: 'M2',
			id: 'square-meters',
			accessor: 'store_size',
			filterPath: ':store_size',
		},
		{
			Header: 'First year forecast',
			id: 'first-year-forecast',
			accessor: 'store_first_year_forecast',
			filterPath: ':store_first_year_forecast',
		},
		{
			Header: 'Audit number',
			id: 'audit-number',
			accessor: 'audit_number',
			filterPath: ':audit_number',
		},
		{
			Header: 'Department code',
			id: 'department-code',
			accessor: 'department_code',
			filterPath: ':department_code',
		},
		{
			Header: 'Sort order',
			id: 'sort-order',
			accessor: 'sort_order',
			filterPath: ':sort_order',
		},
		{
			Header: 'Franchise',
			id: 'franchise',
			accessor: 'franchise_store',
			filterPath: ':franchise_store',
			Cell: (d) => (
				<input
					id={`franchise-store-${d.original?.id ?? ''}`}
					type="checkbox"
					disabled
					checked={d.value}
				/>
			),
		},
		{
			Header: 'Workplace salary account',
			id: 'workplace_salary_account',
			accessor: (d) =>
				_get(d, 'workplace_salary_account.global_account.name', ''),
			filterPath: ':workplace_salary_account.global_account.name',
			show: false,
		},
		{
			Header: 'Opening offset',
			id: 'opening-offset',
			accessor: 'opening_offset',
			filterPath: ':opening_offset',
		},
		{
			Header: 'Closing offset',
			id: 'closing-offset',
			accessor: 'closing_offset',
			filterPath: ':closing_offset',
		},
		{
			Header: 'Legacy',
			id: 'legacy',
			accessor: 'legacy',
			filterPath: ':legacy',
			Cell: (d) => (
				<input
					id={`franchise-store-${d.original?.id ?? ''}`}
					type="checkbox"
					disabled
					checked={d.value}
				/>
			),
		},
		{
			Header: 'Show in Joe App',
			id: 'show-in-joe-app',
			accessor: 'show_in_joe_app',
			filterPath: ':show_in_joe_app',
			Cell: (d) => (
				<input
					id={`show-in-joe-app-${d.original?.id ?? ''}`}
					type="checkbox"
					disabled
					checked={d.value}
				/>
			),
		},
	];
}
