import React from 'react';
import GradeScales from './components/gradeScales/gradeScales.container';
import ModuleCategories from "globalAdministration/modulesAndExams/components/moduleCategories/moduleCategories.container";
import Modules from "globalAdministration/modulesAndExams/components/modules/modules.container";

export function ModulesAndExams() {
	return (
		<>
			<GradeScales />
			<ModuleCategories />
			<Modules />
		</>
	);
}
