import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isMobile } from 'detectMobile.vanilla';
import onClickOutside from 'react-onclickoutside';

// Componnets
import { Button, CheckBox, Loader, ButtonFeedback as Feedback } from 'dumb';
import Cleave from 'cleave.js/react';

// Other
import './input-smart.css';

// Services
import _debounce from 'lodash/debounce';

class InputSmart extends Component {
	constructor(props) {
		super(props);

		this.date = new Date();
		this.typingTimer = null;
		this.saveInterval = 1000;

		this.state = {
			value: this.props.value === null ? undefined : this.props.value,
			editedValue: this.props.value === null ? undefined : this.props.value,
			key: this.date.getTime(),
			editable: false,
			loading: false,
			cleaveDate: null,
			cleaveDateRawValue: '',
			responseStatus: '',
		};

		// this.onSubmit = this.onSubmit.bind(this);
		this.onSubmit = _debounce(this.onSubmit, 3000, { trailing: true }).bind(
			this
		);
		this.delayedOnChange = _debounce(this.delayedOnChange, 300, {
			trailing: true,
		}).bind(this);
		this.onFocus = this.onFocus.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this.onCleaveDateInit = this.onCleaveDateInit.bind(this);
		this.cleaveDateChange = this.cleaveDateChange.bind(this);
	}

	delayedOnChange() {
		const { editedValue } = this.state;
		const { onChange, customParams } = this.props;

		const value = customParams(editedValue);

		// set the state to loading
		this.setState(() => ({
			loading: true,
			value: editedValue,
		}));
		// make the api call and update the value
		onChange(value)
			.then((que) => {
				// remove the loading icon
				this.setState(() => ({
					loading: false,
					responseStatus: 'success',
				}));
				this._resetFeedbackStatus();
			})
			.catch(() => {
				this.setState(() => ({
					value: this.props.value,
					key: this.date.getTime(),
					loading: false,
					responseStatus: 'error',
				}));
				this._resetFeedbackStatus();
			});
	}

	_resetFeedbackStatus() {
		setTimeout(() => {
			this.setState(() => ({
				responseStatus: '',
			}));
		}, 2000);
	}

	// when the element looses focus
	_onBlur() {
		const { value, editedValue } = this.state;

		// if the initial and changed value aren't the same submit them
		if (editedValue !== value) {
			this.delayedOnChange();
		}
	}

	handleClickOutside() {
		if (!isMobile() && this.state.editable) {
			this.onSubmit();
			this.setState(() => ({ editable: false }));
			this.props.toggleInputs({ disabled: true });
		}
	}

	// sets the input as editable once selected
	selectInput() {
		this.setState(() => ({ editable: true }));

		this.props.toggleInputs({ disabled: false });
	}

	onFocus() {
		this.props.toggleInputs({ disabled: false });
	}

	onToggle() {
		this.setState((prevState) => ({ editable: !prevState.editable }));
	}

	// get's called on every key stroke on the username input field
	onChange(e) {
		// persist the event to not lose the reference
		// e.persist();
		// e.preventDefault();
		if (event === undefined) return;

		this.setState(() => ({ editedValue: event.target.value }));

		// call the delayed function and pass the persisted event var
		// *if we don't persist then by the time the debouncing is done the reference to e gets lost*

		// this.delayedOnChange(e);
	}

	handleToggleClick() {
		this.setState(() => ({ value: !this.state.value }));

		this.setState(() => ({
			loading: true,
		}));

		// If suplied onChange
		this.props
			.onChange(this.props.customParams(!this.state.value))
			.then(() => {
				this.setState(() => ({
					loading: false,
					responseStatus: 'success',
				}));
				this._resetFeedbackStatus();
			})
			.catch(() => {
				this.setState(() => ({
					value: this.props.value,
					key: this.date.getTime(),
					loading: false,
					responseStatus: 'error',
				}));
				this._resetFeedbackStatus();
			});
	}

	_handleKeyDown(event) {
		// enter
		if (event.which === 13) {
			if (!isMobile()) {
				// submit the value
				this._onBlur();

				this.setState(() => ({ editable: false }));
				this.props.toggleInputs({ disabled: true });
			}
		}
		// esc
		if (event.which === 27) {
			// set the value back to the original one and disable the input
			this.setState((prevState) => ({
				editable: false,
				editedValue: prevState.value,
			}));
			this.props.toggleInputs({ disabled: true });
		}
	}

	_handleKeyUp(event) {
		if (!isMobile()) {
			clearTimeout(this.typingTimer);
			this.typingTimer = setTimeout(this.onSubmit, this.saveInterval);
		}
	}

	_renderFeedback() {
		const { loading, responseStatus } = this.state;

		if (loading && !responseStatus) return null;

		return <Feedback showFeedback={this.state.responseStatus} />;
	}

	onSubmit() {
		if (isMobile()) this.onToggle();

		// if (this.props.value !== this.state.value)  <-- This line was conflicting?

		if (this.props.autoSubmit) {
			this.setState(() => ({
				loading: true,
			}));

			this.props
				.onSubmit(this.props.customParams(this.state.value))
				.then(() => {
					this.setState(() => ({
						loading: false,
						responseStatus: 'success',
						// filip
					}));
					this._resetFeedbackStatus();
				})
				.catch(() => {
					this.setState(() => ({
						value: this.props.value,
						// loading: false,
						key: this.date.getTime(),
						responseStatus: 'error',
						loading: false,
					}));
					this._resetFeedbackStatus();
				});
		}
	}

	// cleave stuff
	onCleaveDateInit(cleave) {
		this.setState({ cleaveDate: cleave });
	}

	cleaveDateChange(event) {
		this.setState({ editedValue: event.target.value });
	}

	render() {
		const {
			label,
			type,
			className,
			action,
			placeholder,
			disabled,
			tabIndex,
			readOnly,
		} = this.props;

		const { editable, loading, value, key, editedValue } = this.state;

		const cx = classnames('j-input-smart', {
			// 'j-input-smart--type2': type,
			className: className,
		});

		const inputClass = classnames('j-input-smart__input', {
			'j-input-smart__input--enabled': (!disabled || !readOnly) && !isMobile(),
			'j-input-smart__input--toggle': type === 'toggle',
		});

		return (
			<div className={cx}>
				<div
					className="j-input-smart__element"
					onClick={(event) => this.selectInput(event)}
				>
					<div
						className={inputClass}
						onClick={(event) => this.selectInput(event)}
					>
						{type === 'text' && (
							<input
								type="text"
								readOnly={readOnly}
								disabled={disabled}
								value={editedValue}
								placeholder={placeholder}
								onChange={(event) => this.onChange(event)}
								onKeyDown={(event) => this._handleKeyDown(event)}
								onKeyUp={(event) => this._handleKeyUp(event)}
								tabIndex={disabled ? null : tabIndex}
								onBlur={this._onBlur}
							/>
						)}

						{type === 'password' && (
							<input
								type={type}
								readOnly={readOnly}
								disabled={disabled}
								value={editedValue}
								placeholder={placeholder}
								onChange={(event) => this.onChange(event)}
								onKeyDown={(event) => this._handleKeyDown(event)}
								onKeyUp={(event) => this._handleKeyUp(event)}
								tabIndex={disabled ? null : tabIndex}
								onBlur={this._onBlur}
							/>
						)}

						{type === 'email' && (
							<input
								type="email"
								value={editedValue}
								readOnly={readOnly}
								disabled={disabled}
								placeholder={placeholder}
								onChange={(e) => this.onChange(e)}
								onKeyDown={(event) => this._handleKeyDown(event)}
								onKeyUp={(event) => this._handleKeyUp(event)}
								tabIndex={disabled ? null : tabIndex}
								onBlur={this._onBlur}
							/>
						)}

						{type === 'number' && (
							<Cleave
								key={key}
								options={{
									numeral: true,
								}}
								placeholder={placeholder}
								onChange={(e) => this.onChange(e)}
								readOnly={readOnly}
								disabled={disabled}
								value={editedValue}
								onKeyDown={(event) => this._handleKeyDown(event)}
								onKeyUp={(event) => this._handleKeyUp(event)}
								tabIndex={disabled ? null : tabIndex}
								onBlur={this._onBlur}
							/>
						)}

						{type === 'date' && (
							<Cleave
								key={key}
								options={{
									date: true,
									datePattern: ['Y', 'm', 'd'],
									delimiter: '-',
								}}
								placeholder={placeholder}
								onChange={this.cleaveDateChange}
								readOnly={readOnly}
								disabled={disabled}
								value={editedValue}
								onKeyDown={(event) => this._handleKeyDown(event)}
								onKeyUp={(event) => this._handleKeyUp(event)}
								tabIndex={disabled ? null : tabIndex}
								onBlur={this._onBlur}
								onInit={this.onCleaveDateInit}
							/>
						)}

						{type === 'tel' && (
							// <PhoneInput
							// 	className="j-input-smart__element__phone"
							// 	placeholder={placeholder}
							// 	value={editedValue}
							// 	onChange={e => this.onChange(e)}
							// 	readOnly={readOnly}
							// 	disabled={disabled}
							// 	onCountryChange={e => this.onCountryChange(e)}
							// 	onKeyDown={event => this._handleKeyDown(event)}
							// 	onKeyUp={event => this._handleKeyUp(event)}
							// 	tabIndex={disabled ? null : tabIndex}
							// 	onBlur={this._onBlur}
							// />

							<input
								type="tel"
								value={editedValue}
								readOnly={readOnly}
								disabled={disabled}
								placeholder={placeholder}
								onChange={(e) => this.onChange(e)}
								onKeyDown={(event) => this._handleKeyDown(event)}
								onKeyUp={(event) => this._handleKeyUp(event)}
								tabIndex={disabled ? null : tabIndex}
								onBlur={this._onBlur}
							/>
						)}

						{type === 'toggle' && (
							<CheckBox
								checked={value}
								onClick={() => this.handleToggleClick()}
								readOnly={readOnly}
								disabled={disabled}
								tabIndex={disabled ? null : tabIndex}
								onBlur={this._onBlur}
							/>
						)}
					</div>
					<label>{label}</label>
				</div>
				{loading && <Loader size="small" loading />}

				{this._renderFeedback()}

				{action && isMobile() && (
					<div className="j-input-smart__action">
						<Button
							disabled={loading || disabled}
							type="inverted"
							size="tiny"
							onClick={editable ? () => this.onSubmit() : () => this.onToggle()}
							state={editable ? 'success' : 'secondary'}
						>
							{editable ? (
								<span className="icon icon--done" />
							) : (
								<span className="icon icon--edit" />
							)}
						</Button>
					</div>
				)}
			</div>
		);
	}
}

InputSmart.defaultProps = {
	action: true,
	type: 'text',
	placeholder: '',
	onSubmit: () => console.log('onSubmit prop is empty!'),
	onChange: () => {},
	disabled: false,
	toggleInputs: () => {},
};

InputSmart.propTypes = {
	// shared props
	className: PropTypes.string,
	type: PropTypes.oneOf(['text', 'number', 'date', 'tel', 'email', 'toggle']),
	label: PropTypes.string,
	value: PropTypes.any,
	placeholder: PropTypes.string,
	action: PropTypes.bool,
	disabled: PropTypes.bool,
	tabIndex: PropTypes.number,
	autoSubmit: PropTypes.bool,
	readOnly: PropTypes.bool,

	// Methods
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	customParams: PropTypes.func,
	toggleInputs: PropTypes.func,
};

export default onClickOutside(InputSmart);
