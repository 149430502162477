import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './card.css';

export default class Card extends PureComponent {
	render() {
		const { children, className, nospace, type, dataCy, style } = this.props;

		const classN = classnames('j-card', {
			[`${className}`]: className,
			[`j-card--nospace`]: nospace,
			[`j-card--${type}`]: type,
		});

		return (
			<div className={classN} data-cy={dataCy}>
				{children}
			</div>
		);
	}
}

Card.defaultProps = {
	dataCy: '',
};

Card.propTypes = {
	// shared props
	children: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
		PropTypes.element,
	]).isRequired,
	className: PropTypes.string,
	nospace: PropTypes.bool,
	type: PropTypes.string,
	dataCy: PropTypes.string,
};
