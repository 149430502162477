import React from 'react';

// interfaces
import { SelectOption } from 'types/select';
import { IWorkplaceTagsReduxValue } from './../types';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import { updateworkplaceTag } from './../store/workplaceTags.actions';

// components
import { InputCollectionSelect } from 'dumb';
import getMarketColumns from 'reactDataWrapperColumns/organization/markets.columns';

// misc
import phrases from './../workplaceTags.phrases';

type Props = {
	source: 'workplace' | 'tag';
};

const WorkplaceTagsEditableCells = ({
	source,
}: Props): { header: string; value: JSX.Element }[] => {
	const dispatch = useAppDispatch();

	// hooks
	const { workplaceTag } = useAppSelector((state) => ({
		workplaceTag: state.workplaceTags.data.workplaceTag,
	}));

	const editStoreEntry = (
		name: keyof IWorkplaceTagsReduxValue,
		value: SelectOption | null | string
	): void => {
		const valueToSet = value;

		const payload = {
			[name]: valueToSet,
		};

		dispatch(updateworkplaceTag(payload));
	};

	return [
		...(source === 'workplace'
			? [
					{
						header: phrases.TAG,
						value: (
							<InputCollectionSelect
								id="tags"
								dataCy="workplace-tag-tag-select"
								label={phrases.TAG}
								placeholder={phrases.TAG_PLACEHOLDER}
								value={workplaceTag.tag}
								handleChange={(key, value) => editStoreEntry('tag', value)}
								required
								cache
								apiPath="/tagging/tags"
								params={{
									limit: 50,
								}}
								optionFormat={(entry) => ({
									value: entry,
									label: entry.name,
								})}
								inputFilterFormat={(input) => `:name=like='%${input}%'`}
							/>
						),
					},
			  ]
			: []),
		...(source === 'tag'
			? [
					{
						header: phrases.WORKPLACE,
						value: (
							<InputCollectionSelect
								id="workplace"
								dataCy="workplace-tag-market-select"
								label={phrases.WORKPLACE}
								placeholder={phrases.WORKPLACE_PLACEHOLDER}
								value={workplaceTag.workplace}
								handleChange={(key, value) =>
									editStoreEntry('workplace', value)
								}
								required
								cache
								apiPath="/administration/workplaces"
								params={{
									limit: 30,
								}}
								optionFormat={(entry) => ({
									value: entry,
									label: entry.name,
								})}
								inputFilterFormat={(input) => `:name=like='%${input}%'`}
								tableTitle={phrases.WORKPLACES}
								tableReduxKey="workplace-tags-modal-batch/administration/workplaces"
								tableColumns={getMarketColumns(
									'workplace-tags-modal-batch/administration/workplaces'
								)}
							/>
						),
					},
			  ]
			: []),
	];
};

export default WorkplaceTagsEditableCells;
