// State
import { store } from 'appState';
import * as posConfigurationLayout from '../posConfigurationLayout.actions';

// Services
import { get, post, remove } from 'api.vanilla.service';
import { set as setFeedback } from 'feedback.vanilla.service';
import { formatErrorMessage } from 'api/helpers';

// Tools
import _get from 'lodash/get';

/**
 * @function getLayoutTiles
 * @param {int} groupId
 * @description Get tiles in grouup
 */
export function getLayoutTiles(groupId) {
	const urlTiles = `/pos/tile_layout_tiles?filter=:tile_layout_group=='${groupId}'&limit=300`;

	return get(urlTiles).then((response) => {
		const tileData = response.data;

		// Remove all tiles from state
		store.dispatch(posConfigurationLayout.fetching(false)); // CATCH ALL <----
		store.dispatch(posConfigurationLayout.clearTiles());
		store.dispatch(posConfigurationLayout.setTilesInGroup(tileData));
		store.dispatch(posConfigurationLayout.setLoading(false));
	});
}

export function cleanUp() {
	store.dispatch(posConfigurationLayout.cleanUp());
}

/**
 * @function updateTilePosition
 * @param dragIndex
 * @param hoverIndex
 * @param tiles
 * @returns {{dragIndex: *, hoverIndex: *, tiles: *}}
 */
export function updateTilePosition(dragIndex, hoverIndex, tiles) {
	// corrigate for array starting at 0 where tile.position starts at 1
	// dragIndex = dragIndex;
	// hoverIndex = hoverIndex;

	// Get tile being dragged
	const dragTile = tiles.find((tile) => tile.position === dragIndex);

	// filter out the one being dragged in order to prevent duplicates and map filtered array back to object literaty/dictionary
	const updatedTiles = tiles.filter((tile) => tile.id !== dragTile.id);

	// Set the hovered tile to be the dragged tile
	updatedTiles[hoverIndex] = dragTile;
	updatedTiles[hoverIndex].position = hoverIndex;

	// If a tile is present at the hovered index
	if (tiles[hoverIndex]) {
		// If a tile is moved from a lower position to a higher position
		if (hoverIndex > dragIndex) {
			let consequtivePosition = hoverIndex - 1;
			updatedTiles[consequtivePosition] = tiles[hoverIndex];

			// update position of updated tile
			updatedTiles[consequtivePosition].position = consequtivePosition;

			// Loop through tiles in reverse order to subtract positions.
			Object.keys(tiles)
				.reverse()
				.map((key) => {
					// If there is a tile after and the tile after is heigher than the items being hovered on
					if (
						tiles[consequtivePosition] &&
						consequtivePosition < Number(key) &&
						consequtivePosition > dragIndex
					) {
						const tileWithNewPosition = tiles[consequtivePosition];
						consequtivePosition--;
						updatedTiles[consequtivePosition] = tileWithNewPosition;
						updatedTiles[consequtivePosition].position = consequtivePosition;
					}
				});
		}

		// If a tile is moved from a higher position to a lower position
		else if (hoverIndex < dragIndex) {
			// Below is working and suitable for pushing in a new tile
			let consequtivePosition = hoverIndex + 1;
			updatedTiles[consequtivePosition] = tiles[hoverIndex];

			// update position of updated tile
			updatedTiles[consequtivePosition].position = consequtivePosition;

			// Move all consequtive positions one up
			Object.keys(tiles).map((key) => {
				// If there is a tile after and the tile after is heigher than the items being hovered on
				if (
					tiles[consequtivePosition] &&
					consequtivePosition > Number(key) &&
					consequtivePosition < dragIndex
				) {
					const tileWithNewPosition = tiles[consequtivePosition];
					consequtivePosition++;
					updatedTiles[consequtivePosition] = tileWithNewPosition;
					updatedTiles[consequtivePosition].position = consequtivePosition;
				}
			});
		}
	}

	// this.setRotatedTiles(updatedTiles);
	store.dispatch(posConfigurationLayout.removeTile(dragTile.id));
	store.dispatch(posConfigurationLayout.setTilesInGroup(updatedTiles));
	rotateTiles(updatedTiles);
}

/**
 * @function rotateTiles
 * @returns {Promise.<TResult>}
 */
export function rotateTiles(updatedTiles, isArray) {
	// Loader
	store.dispatch(posConfigurationLayout.fetching(true));

	let rotations = updatedTiles;

	if (!isArray) {
		rotations = Object.keys(updatedTiles).reduce(
			(acc, key) => {
				return [
					...acc.rotations,
					{
						id: updatedTiles[key].id,
						position: updatedTiles[key].position,
					},
				];
			},
			{ rotations: [] }
		);
	}

	const uri = `/pos/tile_layout_tiles/position_rotations`;
	return post(uri, rotations)
		.then((res) => {
			store.dispatch(posConfigurationLayout.fetching(false));
			return res;
		})
		.catch((err) => {
			store.dispatch(posConfigurationLayout.fetching(false));

			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addTile
 * @param {object} data
 * @description Add product TILE
 */
export function addTile(data) {
	return post('/pos/tile_layout_tiles', data)
		.then((res) => {
			setFeedback('Added product tile', 1);
			const result = res.data;

			store.dispatch(posConfigurationLayout.setTilesInGroup(result));

			return result;
		})
		.catch((error) => {
			const errorMsg = formatErrorMessage(error);
			setFeedback(errorMsg, 0);
			throw error;
		});
}

/**
 * @function updateTile
 * @param {number} productTileId
 * @description Edit tile
 */
export function updateTile(productTileObject) {
	const url = `/pos/tile_layout_tiles/${productTileObject.id}`;

	let payload = {
		background_color: productTileObject.background_color,
		description: productTileObject.description,
		id: productTileObject.id,
		product: productTileObject.product
			? productTileObject.product.id
			: undefined,
		name: productTileObject.name,
		title_color: productTileObject.title_color,
		type: productTileObject.type,
		flair: _get(productTileObject, 'flair.id', null),
	};

	// Bellow code needs fixing!
	if (productTileObject.type === 'Group') {
		payload = {
			...payload,
			tile_layout_group: _get(
				productTileObject,
				'tile_layout_group.id',
				productTileObject.tile_layout_group.tile_layout_group
			),
		};
	}

	return post(url, payload)
		.then((res) => {
			setFeedback('Updated product tile', 1);
			const result = res.data[0];

			store.dispatch(posConfigurationLayout.updateTile(result));

			return res;
		})
		.catch((error) => {
			const errorMsg = formatErrorMessage(error);
			setFeedback(errorMsg, 0);
		});
}

/**
 * @function removeTile
 * @param tileId
 * @returns {Promise.<TResult>|*|{anyOf}}
 */
export async function removeTile(tileId = null) {
	// Loade
	store.dispatch(posConfigurationLayout.fetching(true));

	const uri = `/pos/tile_layout_tiles/${tileId}?cascade=true`;
	return remove(uri)
		.then(() => {
			store.dispatch(posConfigurationLayout.fetching(false));
			store.dispatch(posConfigurationLayout.removeTile(tileId));
		})
		.catch((err) => {
			store.dispatch(posConfigurationLayout.fetching(false));
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			store.dispatch(posConfigurationLayout.fetching(false));
		});
}
