import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import DayPickerSingleDateController from 'react-dates/lib/components/DayPickerSingleDateController';
import DateWeekNumber from '../components/weekNumber';
import { InputCleave, Button, Icon , ErrorTip } from 'dumb';

import { renderCustomCaption } from '../dates.utils';

// misdc
import phrases from '../dates.phrases';
import moment from 'moment';
import constants from 'services/constants';
import './singleDatePickerInput.css';

export default class SingleDatePickerInput extends PureComponent {
	constructor(props) {
		super(props);

		// Create portal wrapper
		this.node = document.createElement('div');
		this.node.classList.add('j-date-modal');
		if (this.props.zIndex) this.node.style.zIndex = this.props.zIndex;

		this.state = {
			showCalendar: false,
		};

		// Binds
		this.onToggleCalendar = this.onToggleCalendar.bind(this);
		this.onSingleDateChange = this.onSingleDateChange.bind(this);
	}

	componentWillUnmount() {
		if (document.querySelectorAll('body .j-date-modal').length) {
			document.body.removeChild(this.node);
		}
	}

	onSingleDateChange(date) {
		const { onChange } = this.props;

		let value;
		if (date?.target?.value === '' || date === null) value = null;
		else value = date;

		onChange(value);

		if (moment.isMoment(date)) this.onToggleCalendar();
	}

	onToggleCalendar() {
		if (this.state.showCalendar) {
			document.body.removeChild(this.node);
		} else {
			document.body.appendChild(this.node);
		}

		this.setState((prev) => ({ showCalendar: !prev.showCalendar }));
	}

	initialVisibleMonth(date) {
		if (
			!moment.isMoment(date) ||
			moment(date).isSame(this.props.dateFormat, 'year')
		)
			return moment();
		else return date;
	}

	onFocusInput(e) {
		if (this.props.toggleOnFocus) {
			this.onToggleCalendar();
		}
	}

	render() {
		const {
			selectedDate,
			className,
			showWeekNumber,
			disabled,
			withPortal,
			errors,
			numberOfMonths,
			placeholder,
			label,
			noClockButton,
			id,
			clearable,
			clearButtonDisabled,
			dataCy,
		} = this.props;
		const { date, showCalendar } = this.state;

		const classN = classnames('j-date-single-date-picker', {
			[`${className}`]: className,
			'j-date-single-date-picker--no-clock': noClockButton,
			'j-date-single-date-picker--clearable': clearable,
		});

		return (
			<>
				<div>
					<div className={classN}>
						<InputCleave
							label={label}
							id={id}
							placeholder={placeholder}
							disabled={disabled}
							options={{
								date: true,
								delimiter: '-',
								datePattern: ['Y', 'm', 'd'],
							}}
							value={selectedDate}
							// This causes safari issues
							// onBlur={() => this.onToggleCalendar()}
							onChange={this.onSingleDateChange} // DEFAULT VALUE 9999-12-31
							onFocus={() => this.onFocusInput(date)}
						/>

						{clearable && selectedDate && !disabled && (
							<Icon
								onClick={(e) => {
									e.stopPropagation();
									this.onSingleDateChange(null);
								}}
								className={classnames('j-date-single-date-picker__clear', {
									'j-date-single-date-picker__clear--disabled':
										clearButtonDisabled,
								})}
								name="clear"
							/>
						)}

						{!noClockButton && (
							<Button
								dataCy={dataCy}
								type="inverted"
								size="large"
								disabled={disabled}
								shadow
								onClick={this.onToggleCalendar}
							>
								<Icon name="date_range" />
							</Button>
						)}
					</div>
					{errors &&
						errors.length > 0 &&
						errors.map((e) => <ErrorTip key={e} content={e} />)}
				</div>

				{showCalendar &&
					ReactDOM.createPortal(
						<DayPickerSingleDateController
							ref={(input) => (this.calendarElement = input)}
							onDateChange={this.onSingleDateChange}
							onOutsideClick={this.onToggleCalendar}
							renderDayContents={(day) => {
								return (
									<DateWeekNumber showWeekNumber={showWeekNumber} day={day} />
								);
							}}
							renderMonthElement={renderCustomCaption}
							initialVisibleMonth={() => this.initialVisibleMonth(date)}
							date={
								moment(selectedDate, this.props.dateFormat, true).isValid()
									? moment(selectedDate, this.props.dateFormat)
									: undefined
							}
							numberOfMonths={numberOfMonths}
							hideKeyboardShortcutsPanel
							withPortal={withPortal}
							firstDayOfWeek={1}
						/>,
						this.node
					)}
			</>
		);
	}
}

SingleDatePickerInput.defaultProps = {
	appendToBody: false,
	placeholder: phrases.DATE_PLACEHOLDER,
	numberOfMonths: 2,
	withPortal: true,
	showWeekNumber: false,
	toggleOnFocus: true,
	dateFormat: constants.shortDate,
	noClockButton: false,
	clearable: false,
};

SingleDatePickerInput.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string.isRequired,

	// Predefined initial states
	selectedDate: PropTypes.string,
	disabled: PropTypes.bool,
	numberOfMonths: PropTypes.number,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	dateFormat: PropTypes.string,
	dataCy: PropTypes.string,

	// Function & Methods
	onChange: PropTypes.func,

	// Extra options
	showWeekNumber: PropTypes.bool,
	errors: PropTypes.arrayOf(PropTypes.string),
	withPortal: PropTypes.bool,
	toggleOnFocus: PropTypes.bool,
	noClockButton: PropTypes.bool,
	clearable: PropTypes.bool,
	clearButtonDisabled: PropTypes.bool,
	zIndex: PropTypes.number,
};
