import { ActionType } from 'types/redux';
import { IGradeScaleReduxValue } from '../types';

export const SET_GRADE_SCALE =
	'GRADE_SCALES/SET_GRADE_SCALE';
export const UPDATE_GRADE_SCALE =
	'GRADE_SCALES/UPDATE_GRADE_SCALE';
export const RESET_STATE = 'GRADE_SCALES/RESET_STATE';

export function setGradeScales(
	payload: IGradeScaleReduxValue
): ActionType {
	return {
		type: SET_GRADE_SCALE,
		payload,
	};
}

type updateGradeScale = Partial<IGradeScaleReduxValue>;
export function updateGradeScale(
	payload: updateGradeScale
): ActionType {
	return {
		type: UPDATE_GRADE_SCALE,
		payload,
	};
}

export function resetState(): ActionType {
	return {
		type: RESET_STATE,
	};
}
