'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');

export default class ReportingCurrenciesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'currency',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				reference: {
					key: 'code',
					searchFields: ['code'],
					label: '<%= code %>',
					list: '/administration/currencies',
				},
			},
		];
		this.settings = {
			name: 'ReportingCurrenciesList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			pageTitle: true,
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),
			translationPath: 'REPORTING.REPORTING_CURRENCIES',
			endpoint: __endpoints.collection.reporting.reporting_currencies,
		};
		this.init();
		return this;
	}
}
