// interface
import { ActionType } from 'types/redux';
import {
	IPosConfigurationVersionCurrenciesReduxValue,
	IPosConfigurationVersionCurrenciesReducer,
} from '../types';

import {
	RESET_STATE,
	SET_POS_CONFIGURATION_VERSION_CURRENCY,
	UPDATE_POS_CONFIGURATION_VERSION_CURRENCY,
} from './posConfigurationVersionCurrencies.actions';

const defaultState: IPosConfigurationVersionCurrenciesReducer = {
	data: {
		defaultPosConfigurationVersionCurrency: {
			currency: null,
			markup: '',
			fixedRate: 0,
			dynamic: false,
		},
	},
};

function reducer(
	state = defaultState,
	action: ActionType
): IPosConfigurationVersionCurrenciesReducer {
	const { payload, type } = action;

	switch (type) {
	case SET_POS_CONFIGURATION_VERSION_CURRENCY: {
		const castedPayload =
				payload as IPosConfigurationVersionCurrenciesReduxValue;

		return {
				...state,
				data: {
					...state.data,
					defaultPosConfigurationVersionCurrency: castedPayload,
				},
			};
	}

	case UPDATE_POS_CONFIGURATION_VERSION_CURRENCY: {
		const castedPayload =
				payload as Partial<IPosConfigurationVersionCurrenciesReduxValue>;

		return {
				...state,
				data: {
					...state.data,
					defaultPosConfigurationVersionCurrency: {
						...state.data.defaultPosConfigurationVersionCurrency,
						...castedPayload,
					},
				},
			};
	}

	case RESET_STATE: {
		return defaultState;
	}

	default:
		// never return default state in default case, Filip!!!
		return state;
	}
}

export default reducer;
