import { fetchCompleteCollection } from 'api/fetchCompleteCollection';
import { post, remove, patch } from 'api.vanilla.service';
import { IFetchDataProps } from 'types/reactDataWrapper';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { ChangeTypeOfKeys } from 'types/utils';

const BASE_URL = '/pos/workplace_receipts';

const handleError = (err: unknown) => {
	const errorMessage = formatErrorMessage(err);
	setFeedback(errorMessage, 0);
};

export type Payload = ChangeTypeOfKeys<IWorkplaceReceipt, 'workplace', number>;

export async function fetchWorkplaceReceipts(
	state: IFetchDataProps,
	workplaceIds: number[] = []
) {
	const getIdString = (id: number) => `'${id}'`;
	const params = {
		filter: workplaceIds
			? `:workplace.id=IN=[${workplaceIds.map(getIdString).join(',')}]`
			: undefined,
	};

	try {
		return await fetchCompleteCollection(BASE_URL, params, state.headers);
	} catch (err) {
		handleError(err);
	}
}

export async function createWorkplaceReceipt(payload: Omit<Payload, 'id'>) {
	try {
		return await post(BASE_URL, payload);
	} catch (err) {
		handleError(err);
	}
}

export async function editWorkplaceReceipt(payload: Payload) {
	try {
		return await post(BASE_URL + `/${payload.id}`, payload);
	} catch (err) {
		handleError(err);
	}
}

export async function deleteWorkplaceReceipt(workplaceReceiptId: number) {
	try {
		return await remove(BASE_URL + `/${workplaceReceiptId}`);
	} catch (err) {
		handleError(err);
	}
}

export async function batchCreateWorkplaceReceipt(
	payloads: Omit<Payload, 'id'>[]
) {
	try {
		return await post(BASE_URL, { batch: payloads });
	} catch (err) {
		handleError(err);
	}
}

export async function batchEditWorkplaceReceipt(payloads: Payload[]) {
	try {
		return await patch(BASE_URL, { batch: payloads });
	} catch (err) {
		handleError(err);
	}
}

export async function batchDeleteWorkplaceReceipt(
	workplaceReceiptIds: number[]
) {
	try {
		const params = {
			filter: `:id=IN=[${workplaceReceiptIds
				.map((id) => `'${id}'`)
				.join(',')}]`,
		};
		return await remove(BASE_URL, null, params);
	} catch (err) {
		handleError(err);
	}
}
