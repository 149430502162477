'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import phrases from './terminateEmployee.phrases';
import { isMobile } from 'detectMobile.vanilla';
import { set as setFeedback } from 'feedback.vanilla.service.js';

// component
import {
	Modal,
	Loader,
	Input,
	Button,
	InputCollectionSelect,
	SectionCollapsable,
	FileUpload,
	SingleDatePickerInput,
	Tooltip,
} from 'dumb';
import ShiftsTable from './components/shiftsTable';
import { Typography, Box, alpha, styled } from '@mui/material';

// utils
import { copyToClipboard } from 'utils';
import constants from 'services/constants';
import moment from 'moment';

import _get from 'lodash/get';

const StyledTypography = styled(Typography)(({ theme }) => ({
	width: '100%',
	textAlign: 'center',
	[theme.breakpoints.up('sm')]: {
		width: 'initial',
	},
}));

class TerminateEmployee extends Component {
	constructor(props) {
		super(props);

		this.state = {
			employment: null,
			copied: false,
		};

		this.handleCopyOffboardingLink = this.handleCopyOffboardingLink.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this._onDateSelectWrapper = this._onDateSelectWrapper.bind(this);
	}

	componentDidMount() {
		const {
			fetchAssignedShifts,
			getTerminationReasons,
			resetState,
			defaultEmployment,
		} = this.props;

		resetState(fetchAssignedShifts(getTerminationReasons()));

		// if component mounted with default employment, set it
		// thi sis the case when we're transfering an employee from one tenant to another and user chooses to also terminate his current employment
		if (defaultEmployment) {
			const value = this.getEmploymentFormattedValue(defaultEmployment);

			this.handleChange('employment', value);
		}
	}

	componentWillUnmount() {
		this.props.resetTerminateThroughTenantTransferData();
	}

	handleChange(target, event) {
		let val = null;

		// Target is react-date selector
		if (target === 'opening_date' || target === 'closing_date') val = event;
		// else its and ordinary input component or a react-select component
		else val = event && event.target ? event.target.value : event;

		this.setState(() => ({ employment: val }));
	}

	handleSubmit() {
		if (_get(this.state, 'employment.value', false)) {
			this.props.handleSubmit(this.state.employment.value);
		} else {
			console.info('Missing position value');
		}
	}

	handleCopyOffboardingLink(offboardingLink) {
		try {
			copyToClipboard(offboardingLink);
			this.setState(() => ({ copied: true }));
		} catch (error) {
			setFeedback(
				'Copying didnt work. Grap it here: ' + offboardingLink,
				0,
				15000
			);
		}
	}

	_onDateSelectWrapper(e) {
		const { onDateSelect } = this.props;

		let value;
		if (moment.isMoment(e)) value = e;
		else value = moment.utc();

		onDateSelect(value);
	}

	getEmploymentFormattedValue(entry) {
		return {
			value: entry.id,
			label: `${entry.company.name} - ${
				entry.current_employment_position &&
				entry.current_employment_position.moneyball_sub_position.description
			}`,
		};
	}

	render() {
		const {
			isOpen,
			loading,
			handleClose,
			onReasonSelect,
			selectedReason,
			assignedShifts,
			fetchingShifts,
			terminationReasons,
			selectedDate,
			personId,
			handleFileUpload,
			uploadingTerminationDocument,
			resignationDocument,
			otherDocument,
			personData,
			defaultEmployment,
			employmentsList,
		} = this.props;
		const { employment } = this.state;

		const isFormValid = !!(
			selectedReason &&
			selectedDate.format(constants.shortDate) !== '9999-12-31' &&
			_get(this.state, 'employment.value', false)
		);

		const email = personData?.person_email_address?.email_address ?? '';
		const bodyText = phrases.OFFBOARDING_BODY_TEXT;
		const offboardingLink = `https://joejuice.typeform.com/to/a5oheQ#firstname=${encodeURIComponent(
			personData.first_name.replace(' ', '%20')
		)}%26lastname=${encodeURIComponent(
			personData.last_name.replace(' ', '%20')
		)}%26juicerid=${encodeURIComponent(
			personData.id
		)}%26market=${encodeURIComponent(
			personData.market?.name.replace(' ', '%20')
		)}`;
		const noMarketAssignedToUser = !personData.market;

		const mailToLink =
			'mailto:' +
			email +
			'?subject=Thank you for your time - your feedback is important to us&body=' +
			bodyText +
			offboardingLink +
			phrases.SIGNATURE;

		// get number of active employments
		const activeEmployments = employmentsList.filter((entry) => {
			const today = moment.utc();
			const employmentStarted = moment.utc(entry.started, constants.shortDate);
			const employmentEnded = moment.utc(entry.ended, constants.shortDate);

			if (
				employmentStarted.isSameOrBefore(today, 'day') &&
				employmentEnded.isSameOrAfter(today, 'day')
			)
				return true;
			else return false;
		});

		return (
			<Modal
				onClose={handleClose}
				valid={isFormValid}
				confirmButtonLabel="Terminate"
				header="End Employment"
				onConfirmClick={this.handleSubmit}
				className="terminate-modal"
				isOpen={isOpen}
				defaultStyles
				loading={loading}
			>
				<SectionCollapsable isOpen disabled title={phrases.SELECT_EMPLOYMENT}>
					<InputCollectionSelect
						label={phrases.SELECT_EMPLOYMENT}
						value={employment}
						name="market"
						id="select-employee"
						handleChange={this.handleChange}
						placeholder="Select employment"
						apiPath="/hr/employments"
						disabled={!!defaultEmployment}
						params={{
							limit: 300,
							filter: `:person.id=='${personId}';:ended=ge='${moment
								.utc()
								.format(constants.shortDate)}'`,
						}}
						optionFormat={this.getEmploymentFormattedValue}
					/>
				</SectionCollapsable>

				<SectionCollapsable isOpen disabled title={phrases.ASSIGNED_SHIFTS}>
					{fetchingShifts ? (
						<Loader />
					) : (
						<ShiftsTable
							employment={employment}
							assignedShifts={assignedShifts}
							selectedDate={selectedDate}
						/>
					)}

					<Typography
						sx={{
							mt: 1,
						}}
						className="terminate-modal__warning"
					>
						*{phrases.TERMINATION_WARNING}
					</Typography>
					<Typography className="terminate-modal__warning">
						*{phrases.EFFECTED_SHIFTS}
					</Typography>
				</SectionCollapsable>

				<SectionCollapsable isOpen disabled title={phrases.TERMINATION_DATE}>
					<div className="terminate-modal__group">
						<SingleDatePickerInput
							label="Date"
							type="single"
							appendToBody
							fixed
							onChange={this._onDateSelectWrapper}
							selectedDate={selectedDate.format(constants.shortDate)}
							numberOfMonths={isMobile() ? 1 : 2}
							noClockButton
						/>
						<div className="terminate-modal__group__spacing">
							<Input
								id="reason-select"
								label={phrases.REASON}
								type="select"
								value={selectedReason}
								onChange={(event) => onReasonSelect(event.value)}
								options={terminationReasons}
								clearable={false}
							/>
						</div>
					</div>
				</SectionCollapsable>

				<SectionCollapsable
					isOpen
					disabled
					title={phrases.TERMINATION_DOCUMENT}
				>
					<div className="terminate-modal__group">
						<FileUpload
							label={phrases.RESIGNATION_DOCUMENT}
							thumbnail
							onChange={(e) => handleFileUpload(e, 'resignationDocument')}
							loading={uploadingTerminationDocument}
							file={resignationDocument}
						/>
						<div className="terminate-modal__group__spacing">
							<FileUpload
								disabled={!resignationDocument}
								label={phrases.OTHER}
								thumbnail
								onChange={(e) => handleFileUpload(e, 'otherDocument')}
								loading={uploadingTerminationDocument}
								file={otherDocument}
							/>
						</div>
					</div>
				</SectionCollapsable>

				<SectionCollapsable isOpen disabled title={phrases.OFFBOARDING}>
					{noMarketAssignedToUser ? (
						<Typography
							variant="subtitle2"
							component="span"
							sx={{
								color: (theme) => alpha(theme.palette.secondary.light, 0.7),
							}}
						>
							{phrases.NO_MARKET_ASSIGNED_TO_USER}
						</Typography>
					) : (
						<div className="terminate-modal__group terminate-modal__group--centered">
							<Tooltip
								text={phrases.COMPOSE_OFFBOARDING_EMAIL_TOOLTIP}
								zIndex="600"
								renderData={(ref, onMouseEnter, onMouseLeave) => (
									<Button
										size="tiny"
										type="success"
										shadow
										refProp={ref}
										onMouseEnter={onMouseEnter}
										onMouseLeave={onMouseLeave}
									>
										<a href={mailToLink}>{phrases.COMPOSE_OFFBOARDING_EMAIL}</a>
									</Button>
								)}
							/>

							<div className="terminate-modal__group__button-spacing">
								<StyledTypography variant="subtitle1" component="p">
									{phrases.OR}
								</StyledTypography>
							</div>

							<Tooltip
								text={phrases.COPY_OFFBOARDING_LINK_TOOLTIP}
								zIndex="600"
								renderData={(ref, onMouseEnter, onMouseLeave) => (
									<Button
										size="tiny"
										type="success"
										shadow
										refProp={ref}
										onMouseEnter={onMouseEnter}
										onMouseLeave={onMouseLeave}
										disabled={this.state.copied}
										onClick={() => {
											this.handleCopyOffboardingLink(offboardingLink);
										}}
									>
										{this.state.copied
											? phrases.COPIED
											: phrases.COPY_OFFBOARDING_LINK}
									</Button>
								)}
							/>
						</div>
					)}
				</SectionCollapsable>

				{/* warning that an employee has more then 1 employment */}
				{activeEmployments.length > 1 && (
					<Box
						mt={2}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Typography variant="h6">{phrases.WARNING}</Typography>
						<Typography className="terminate-modal__warning">
							{phrases.WARNING_TEXT}
						</Typography>
					</Box>
				)}
			</Modal>
		);
	}
}

TerminateEmployee.defaultProps = {
	isOpen: false,
	loading: false,
	assignedShifts: [],
	fetchingShifts: false,
};

TerminateEmployee.propTypes = {
	isOpen: PropTypes.bool,
	personId: PropTypes.number,
	fetchingShifts: PropTypes.bool,
	loading: PropTypes.bool,
	handleSubmit: PropTypes.func,
	handleClose: PropTypes.func,
	onDateSelect: PropTypes.func,
	onReasonSelect: PropTypes.func,
	assignedShifts: PropTypes.array,
	fetchAssignedShifts: PropTypes.func,
	selectedReason: PropTypes.number,
	terminationReasons: PropTypes.array,
	getTerminationReasons: PropTypes.func,
	selectedDate: PropTypes.object,
	resetState: PropTypes.func,
	uploadingTerminationDocument: PropTypes.bool,
	handleFileUpload: PropTypes.func,
	otherDocument: PropTypes.object,
	personData: PropTypes.object,
	resignationDocument: PropTypes.object,
	defaultEmployment: PropTypes.object,
	resetTerminateThroughTenantTransferData: PropTypes.func,
	employmentsList: PropTypes.array,
};

export default TerminateEmployee;
