'use strict';

import React from 'react';

// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { IWorkplaceTagsReduxValue, IWorkplaceTagsCRUD } from './types';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import { setworkplaceTag, resetState } from './store/workplaceTags.actions';

// components
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/shiftplanning/workplaceTags.columns';
import WorkplaceTagsEditableCells from './components/workplaceTagsEditableCells';

// services
import {
	fetchWorkplaceTags,
	deleteWorkplaceTags,
	editWorkplaceTag,
	addWorkplaceTag,
} from './workplaceTags.service';

// constants/phrases/tools
import phrases from './workplaceTags.phrases';
import getEditedValues from 'services/utils/getEditedValues';

type WorkplaceTagsProps = {
	workplaceId?: number;
	tagId?: number;
};

const WorkplaceTags = ({
	workplaceId,
	tagId,
}: WorkplaceTagsProps): JSX.Element => {
	const reduxKey = `/shiftplanning/workplace_tags-${
		workplaceId ? 'workplace' : 'tag'
	}-${workplaceId || tagId}`;

	// hooks
	const { workplaceTag, defaultWorkplaceTag, tableKey } = useAppSelector(
		(state) => ({
			workplaceTag: state.workplaceTags.data.workplaceTag,
			defaultWorkplaceTag: state.workplaceTags.data.defaultWorkplaceTag,
			tableKey: state.workplaceTags.tableKey,
		})
	);
	const dispatch = useAppDispatch();

	const editEntry = () => {
		if (!workplaceTag.id || !defaultWorkplaceTag) return Promise.resolve(true);

		const editedValues = getEditedValues<typeof workplaceTag>({
			oldData: defaultWorkplaceTag,
			newData: workplaceTag,
		});

		const payload: Partial<IWorkplaceTagsCRUD> = {
			workplace: workplaceId || editedValues?.workplace?.id,
			tag: tagId || editedValues?.tag?.id,
		};

		return editWorkplaceTag(workplaceTag.id, payload);
	};

	const createEntry = () => {
		console.log('createEntry', workplaceTag);
		// if (!defaultWorkplaceTag) return Promise.resolve(true);

		// const editedValues = getEditedValues<typeof workplaceTag>({
		// 	oldData: defaultWorkplaceTag,
		// 	newData: workplaceTag,
		// });

		const payload: Partial<IWorkplaceTagsCRUD> = {
			workplace: workplaceTag?.workplace?.value.id,
			tag: tagId,
		};

		return addWorkplaceTag(payload);
	};

	const deleteEntry = (id: number) => {
		return deleteWorkplaceTags(id);
	};

	const setInitialEditValues = (data: IShiftplanningWorkplaceTag) => {
		const payload: IWorkplaceTagsReduxValue = {
			id: data.id,
			workplace: {
				value: data.workplace,
				label: data.workplace.name,
			},
			tag: {
				value: data.tag,
				label: data.tag.name,
			},
			singleEditMode: true,
		};

		dispatch(setworkplaceTag(payload));
	};

	const source = workplaceId ? 'workplace' : 'tag';

	return (
		<>
			<ReactDataWrapper
				dataCy="workplace-tags-table"
				key={tableKey}
				title={phrases.TABLE_TITLE}
				columns={getColumns(source)}
				fetchData={(
					state: IFetchDataProps
				): ReturnType<typeof fetchWorkplaceTags> =>
					fetchWorkplaceTags({ workplaceId, tagId, state })
				}
				filterable
				defaultPageSize={10}
				reduxKey={reduxKey}
				manual
				accessAreasAllowedToEdit={[
					'Organisation/Workplaces::Edit',
					'Organization Admin',
				]}
				editableCells={WorkplaceTagsEditableCells({
					source,
				})}
				actionsWidth={80}
				editEntry={editEntry}
				createEntry={createEntry}
				deleteEntry={deleteEntry}
				setInitialEditValues={setInitialEditValues}
				onModalClose={() => dispatch(resetState())}
				onModalCloseConfirm
			/>
		</>
	);
};

export default WorkplaceTags;
