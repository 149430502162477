'use strict';

import React, { Component } from 'react';
import { connectWithStore } from 'appState';

import PropTypes from 'prop-types';

// Components
import AppLayoutComponent from './appLayout.component';

// styles for children components (they are TS components and can't import css themselves)
import './components/posLayout/posLayout.css';

class AppLayout extends Component {
	render() {
		const {
			posFullConfigurationsVariantsList,
			activeTile,
			addLayoutGroup,
			addTile,
			deleteLayoutGroup,
			removeTile,
			fetching,
			grid,
			gridDimensions,
			gridMetaData,
			gridSize,
			groupItemInFocus,
			groups,
			imageAssets,
			layout,
			page,
			rotateTiles,
			rotateLayoutGroup,
			setMenuItemInFocus,
			tilesInGroup,
			updateLayoutGroup,
			updateTile,
			layoutId,
			type,
			subType,
		} = this.props;

		return (
			<AppLayoutComponent
				posFullConfigurationsVariantsList={posFullConfigurationsVariantsList}
				activeTile={activeTile}
				addLayoutGroup={addLayoutGroup}
				addTile={addTile}
				deleteLayoutGroup={deleteLayoutGroup}
				removeTile={removeTile}
				fetching={fetching}
				grid={grid}
				gridDimensions={gridDimensions}
				gridMetaData={gridMetaData}
				gridSize={gridSize}
				groupItemInFocus={groupItemInFocus}
				groups={groups}
				imageAssets={imageAssets}
				layout={layout}
				page={page}
				rotateTiles={rotateTiles}
				setMenuItemInFocus={setMenuItemInFocus}
				tilesInGroup={tilesInGroup}
				updateLayoutGroup={updateLayoutGroup}
				updateTile={updateTile}
				layoutId={layoutId}
				type={type}
				rotateLayoutGroup={rotateLayoutGroup}
				subType={subType}
			/>
		);
	}
}

AppLayout.propTypes = {
	posFullConfigurationsVariantsList: PropTypes.array,
	rotateLayoutGroup: PropTypes.func,
	layoutId: PropTypes.number,
	tilesInGroup: PropTypes.array,
	groupItemInFocus: PropTypes.number,
	activeTile: PropTypes.object,
	fetching: PropTypes.bool,
	grid: PropTypes.object,
	gridDimensions: PropTypes.array,
	gridMetaData: PropTypes.object,
	gridSize: PropTypes.number,
	groups: PropTypes.object,
	imageAssets: PropTypes.array,
	addLayoutGroup: PropTypes.func,
	addTile: PropTypes.func,
	deleteLayoutGroup: PropTypes.func,
	removeTile: PropTypes.func,
	updateLayoutGroup: PropTypes.func,
	updateTile: PropTypes.func,
	layout: PropTypes.object,
	page: PropTypes.number,
	rotateTiles: PropTypes.func,
	setMenuItemInFocus: PropTypes.func,
	type: PropTypes.string,
	subType: PropTypes.string,
};

const mapStateToPropsFactory = (store) => {
	return {
		fetchingTile: store.salesConfigurationPOSLayout.fetchingTile,
	};
};

export default connectWithStore(AppLayout, mapStateToPropsFactory);
