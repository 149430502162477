import React from 'react';
import PropTypes, { string } from 'prop-types';
import classnames from 'classnames';

// Inputs
import { ErrorTip, Label } from 'dumb';
import CollectionSelect from 'collectionSelect';

// enums
import collectionSelectEnums from '../../../services/enums/collectionSelect';

// Interfaces
import { SelectOption } from 'types/select';
import { IColumnHeader } from '../../../../../types/reactDataWrapper';

import './input.css';

type StyleTypes =
	typeof collectionSelectEnums[keyof typeof collectionSelectEnums];

type Props<T> = {
	id?: string | number;
	label?: string;
	disabled?: boolean;
	className?: string;
	errors?: string[];
	name?: string;
	multi?: boolean;
	value?: SelectOption<T> | SelectOption<T>[] | null;
	placeholder?: string;
	clearable?: boolean;
	size?: string;
	tableReduxKey?: string;
	tableColumns?: IColumnHeader<T>[];
	tableTitle?: string;
	styleType?: StyleTypes;
	dataCy?: string;
	searchable?: boolean;
	required?: boolean;
	options?: SelectOption<T>[];
	handleChange?: (key: string, value: SelectOption) => void;
	cache?: boolean;
	apiFilter?: string;
	apiPath?: string;
	apiFormat?: (payload: IApiRes<any>) => IApiRes<T>;
	optionFormat?: (entry: any) => SelectOption<T>;
	inputFilterFormat?: (input: string) => string;
	params?: {
		limit?: number;
		filter?: string;
		sort?: string;
	};
};

export default function InputCollectionSelect<T>(props: Props<T>): JSX.Element {
	const showOverlayButton =
		props.tableColumns && props.tableTitle && props.tableReduxKey;

	const inputClassName = classnames('j-input', {
		'j-input--required-extended': showOverlayButton,
		'j-input--disabled': props.disabled,
		'j-input--label': props.label,
		[`j-input--${props.size}`]: !!props.size,
		[`${props.className}`]: props.className,
	});

	const selectClassName = classnames('j-input__select', {
		[`j-input__select--${props.size}`]: !!props.size,
		'j-input__select--in-table':
			props.styleType === collectionSelectEnums.TYPE_PHONE,
	});

	return (
		<div className={inputClassName} data-cy={props.dataCy}>
			{/* Label */}
			{props.label && (
				<Label disabled={props.disabled} htmlFor={props.id as string}>
					{props.label}
				</Label>
			)}
			<CollectionSelect
				{...props}
				value={props.value}
				placeholder={props.placeholder}
				id={props.id}
				name={props.id}
				disabled={props.disabled}
				clearable={props.clearable}
				className={selectClassName}
				options={props.options}
			/>
			{props.errors &&
				props.errors.length > 0 &&
				props.errors.map((e) => <ErrorTip key={e} content={e} />)}
		</div>
	);
}

InputCollectionSelect.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	value: PropTypes.any, // ANY
	errors: PropTypes.arrayOf(PropTypes.string),
	placeholder: PropTypes.string,
	clearable: PropTypes.bool,
	required: PropTypes.bool,
	size: PropTypes.string,
	tableReduxKey: PropTypes.string,
	tableColumns: PropTypes.array,
	tableTitle: PropTypes.string,
	dataCy: PropTypes.string,
	styleType: string,

	// methods
	onChange: PropTypes.func,
};

InputCollectionSelect.defaultProps = {
	clearable: true,
	params: {
		limit: 300,
	},
};
