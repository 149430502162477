import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Label, ErrorTip } from 'dumb';

import './textarea.css';

const Textarea = ({
	id,
	className,
    errors,
	value,
	rows,
	cols,
	onChange,
	placeholder,
	label,
}) => {
	const classN = classnames('j-textarea', {
		[`${className}`]: className,
	});

	return (
		<div className={classN}>
			{/* Label */}
			{label && <Label htmlFor={id}>{label}</Label>}

			{/* Input */}
			<textarea
				className="j-textarea__input"
				onChange={onChange}
				id={id}
				placeholder={placeholder}
				name={id}
				value={value}
				cols={cols}
				rows={rows}
			/>
			{errors &&
				errors.length > 0 &&
				errors.map((e) => <ErrorTip key={e} content={e} />)}
			{/* Require label */}
			{/* {requiredlabel && <InputRequired>Required</InputRequired>} */}
		</div>
	);
};

Textarea.defaultProps = {
	rows: 1,
	columns: 1,
	onChange: () => console.info('Did you forget to pass onChange function?'),
};

Textarea.propTypes = {
	// shared props
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	className: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
	value: PropTypes.string,
	cols: PropTypes.number,
	rows: PropTypes.number,
	placeholder: PropTypes.string,

	// methods
	onChange: PropTypes.func,
};

export default Textarea;
