import {
	RESET_STATE,
	LOADING,
	LOADING_PRODUCT,
	EDIT_GROUP,
	EDIT_PRODUCT_TILE,
	ADD_PRODUCT_VARIANT,
	REMOVE_PRODUCT_VARIANT,
} from './addTileModal.actions';

const defaultState = {
	ui: {
		loading: false,
		loadingProduct: false,
	},
	data: {
		productVariantList: [],
		productTile: {
			background_color: '464646',
			description: '',
			id: null,
			name: '',
			pos_configuration_tile_layout_group: null,
			position: null,
			tile_picture_asset: null,
			title_color: 'ffffff',
			type: 'Product',
		},
		group: {
			background_color: '464646',
			name: '',
			description: '',
			pos_configuration_tile_layout: null,
			position: null,
			show_in_menu: false,
			tile_position_focus: 1,
			title_color: 'ffffff',
		},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case ADD_PRODUCT_VARIANT: {
			const exist = state.data.productVariantList.some(
				(x) => (x?.position ?? null) === payload?.position
			);
			let productVariantList = state.data.productVariantList;

			if (exist) {
				productVariantList = productVariantList.map((x) => {
					if (x?.position === (payload?.position ?? null)) {
						return payload;
					} else {
						return x;
					}
				});
			} else {
				productVariantList = [...state.data.productVariantList, payload];
			}

			return {
				...state,
				data: {
					...state.data,
					productVariantList,
				},
			};
		}

		case REMOVE_PRODUCT_VARIANT: {
			const productVariantList = state.data.productVariantList.filter(
				(item) => item.position !== payload
			);

			return {
				...state,
				data: {
					...state.data,
					productVariantList,
				},
			};
		}

		case EDIT_PRODUCT_TILE: {
			return {
				...state,
				data: {
					...state.data,
					productTile: payload,
				},
			};
		}

		case EDIT_GROUP: {
			return {
				...state,
				data: {
					...state.data,
					group: payload,
				},
			};
		}

		case LOADING: {
			return {
				...state,
				ui: { ...state.ui, loading: payload },
			};
		}

		case LOADING_PRODUCT: {
			return {
				...state,
				ui: { ...state.ui, loadingProduct: payload },
			};
		}

		case RESET_STATE: {
			return defaultState;
		}

		default:
			return state;
	}
}

export default reducer;
