import React, {
	useContext,
	useState,
	useEffect,
	useCallback,
	useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

// interfaces
import { IRoute, IFlattenRoute } from '../../services/navigation';

// Action
import { showSearchModal } from '../../services/globalSearch/actions';

// Ui
import { Link } from 'react-router-dom';
import {
	Box,
	Modal,
	Grow,
	TextField,
	ListItemIcon,
	ListItem,
	ListItemText,
	Paper,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
	JoeDivider,
	JoeNavList,
	innerStyling,
	sectionStyling,
} from './globalSearchModalComponents';

// Utils
import { flattenRoutes } from '../../services/navigation';
import { JoeRouterProvider } from '../../../../router/config/routerProvider';
import useKeyPress from '../../hooks/useKeyPress';
import Fuse from 'fuse.js';

type Props = {
	showModal: boolean;
	setDrawerHower: () => void;
};

const GlobalSearchModal = ({
	showModal,
	setDrawerHower,
}: Props): JSX.Element => {
	const dispatch = useDispatch();
	const { filteredRoutes } = useContext<{ filteredRoutes: IRoute[] }>(
		JoeRouterProvider
	);
	const downPress = useKeyPress('ArrowDown');
	const upPress = useKeyPress('ArrowUp');
	const enterPress = useKeyPress('Enter');
	const [cursor, setCursor] = useState(0);
	const [flattenedRoutes, setFlattenedRoutes] = useState<IFlattenRoute[]>([]);
	const [searchResults, setSearchResults] = useState<IFlattenRoute[]>([]);
	const [recommendedRoutes, setRecommendedRoutes] = useState<IFlattenRoute[]>(
		[]
	);
	// only initialise fuse once flattened routes change (in reality it means only initialize once)
	const fuse = useMemo(() => {
		const options = {
			keys: ['keywords', 'title', 'path', 'fullPath'],
			threshold: 0.2,
		};
		return new Fuse(flattenedRoutes, options);
	}, [flattenedRoutes]);

	const handleCloseModal = useCallback(() => {
		dispatch(showSearchModal(false));
		setDrawerHower();
	}, [dispatch, setDrawerHower]);

	useEffect(() => {
		setSearchResults([]);
	}, [showModal]);

	// handle arrow DOWN key press to navigate through the list
	useEffect(() => {
		const list = searchResults.length || recommendedRoutes.length;
		if (list && downPress) {
			setCursor((prevState) => {
				if (prevState === list - 1) return 0;
				return prevState + 1;
			});
		}
	}, [searchResults.length, recommendedRoutes.length, downPress]);

	// handle arrow UO key press to navigate through the list
	useEffect(() => {
		const list = searchResults.length || recommendedRoutes.length;
		if (list && upPress) {
			setCursor((prevState) => {
				if (prevState === 0) return list - 1;
				return prevState - 1;
			});
		}
	}, [searchResults.length, recommendedRoutes.length, upPress]);

	// handle enter key press to navigate to the selected item
	useEffect(() => {
		const list = searchResults.length ? searchResults : recommendedRoutes;

		if (list.length && enterPress) {
			dispatch(push(list[cursor]?.fullPath));
			handleCloseModal();
		}
	}, [
		enterPress,
		searchResults,
		recommendedRoutes,
		cursor,
		dispatch,
		handleCloseModal,
	]);

	// Flatten routes and store localy
	useEffect(() => {
		if (filteredRoutes.length) {
			setFlattenedRoutes(flattenRoutes(filteredRoutes));
		}
	}, [filteredRoutes]);

	// set recommended routes based on the flattened routes
	useEffect(() => {
		if (flattenedRoutes.length) {
			const listOfMostUsedPages = [
				{ name: 'shift-planner', order: 1 },
				{ name: 'my-shifts', order: 2 },
				{ name: 'hr', order: 3 },
				{ name: 'shift-marketplace', order: 4 },
				{ name: 'juicers', order: 5 },
				{ name: 'reports', order: 6 },
				{ name: 'salary', order: 7 },
			];

			const routesArrayFillteredByRecommended = flattenedRoutes
				.map((route) => {
					const item = listOfMostUsedPages.find((x) => x.name === route.path);
					if (item) return { ...route, order: item.order || 9 };
				})
				.filter((x) => x)
				.sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0))
				.slice(0, 4) as IFlattenRoute[];
			setRecommendedRoutes(routesArrayFillteredByRecommended);
		}
	}, [flattenedRoutes]);

	const getNavigationSearchResults = (query: string) => {
		setCursor(0);

		if (flattenedRoutes.length) {
			const results = fuse.search<IFlattenRoute>(query).slice(0, 4);

			// NASTY ASS HACK to put shift-planner at the top
			results.forEach((result, i) => {
				if (result.item.path === 'shift-planner') {
					results.splice(i, 1);
					results.unshift(result);
				}
			});

			// return the flattened routes in same format
			const formattedResults = results.map((entry) => {
				return entry.item;
			});

			setSearchResults(formattedResults);
		}
	};

	const renderListItem = (route: IFlattenRoute, i: number) => {
		if (!route?.fullPath) {
			console.log(route);
			console.error('route.fullPath is undefined. CHECK:', route.title);
		}
		return (
			<ListItem
				key={i}
				sx={
					cursor === i
						? {
								backgroundColor: 'rgba(255, 255, 255, 0.08)',
						  }
						: {}
				}
				// active={i === cursor}
				// button
				onClick={handleCloseModal}
				component={Link}
				to={route.fullPath}
			>
				<ListItemIcon>
					<ArrowForwardIosIcon />
				</ListItemIcon>
				<ListItemText
					sx={{ textTransform: 'capitalize' }}
					primaryTypographyProps={{
						sx: {
							color: 'rgb(255 255 255)',
						},
					}}
					secondaryTypographyProps={{
						sx: {
							fontSize: 14,
							color: 'rgb(255 255 255 / 45%)',
						},
					}}
					primary={route.title}
					secondary={route.description}
				/>
			</ListItem>
		);
	};

	const renderRecommendations = () => {
		return (
			<Grow in={!!recommendedRoutes.length}>
				<Paper
					sx={{
						bgcolor: 'background.drawer',
						arginBottom: 2,
					}}
					elevation={0}
				>
					<JoeDivider>Search results</JoeDivider>
					<JoeNavList>
						{recommendedRoutes.map((result, i) => {
							return renderListItem(result, i);
						})}
					</JoeNavList>
				</Paper>
			</Grow>
		);
	};

	const renderSearchResults = () => {
		return (
			<Grow in={!!searchResults.length}>
				<Paper
					sx={{
						bgcolor: 'background.drawer',
						marginBottom: 2,
					}}
					elevation={0}
				>
					<JoeDivider>Search results</JoeDivider>
					<JoeNavList>
						{searchResults.map((result, i) => {
							return renderListItem(result, i);
						})}
					</JoeNavList>
				</Paper>
			</Grow>
		);
	};

	return (
		<Modal
			open={showModal}
			onClose={handleCloseModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={innerStyling}>
				<Box sx={sectionStyling}>
					<TextField
						color="secondary"
						autoFocus
						autoComplete="off"
						sx={{
							borderColor: 'primary.light',

							'& .MuiOutlinedInput-root': {
								color: '#fff',
								fontSize: 14,
								borderColor: 'rgba(255, 255, 255, 0.5)',
							},

							'& .MuiOutlinedInput-notchedOutline': {
								color: '#fff',
								borderColor: 'rgba(255, 255, 255, 0.5)',
								borderWidth: 2,
							},

							'& .Mui-focused': {
								'&  .MuiOutlinedInput-notchedOutline': {
									borderColor: 'rgba(255, 255, 255, 1)',
								},
							},

							'& .MuiInputLabel-root': {
								color: '#fff !important',
								fontSize: 14, // THIS shuold NOT BE sait, shuold be removed when global font fixed
							},
						}}
						placeholder="Search keywords"
						fullWidth
						id="global-serch-input"
						label="Global search"
						inputProps={{
							onKeyDown: (e) => {
								if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
									e.preventDefault();
								}
							},
						}}
						onChange={(e) => {
							getNavigationSearchResults(e.target.value);
						}}
					/>
				</Box>

				{searchResults.length ? renderSearchResults() : null}
				{recommendedRoutes.length && !searchResults.length
					? renderRecommendations()
					: null}
			</Box>
		</Modal>
	);
};

GlobalSearchModal.propTypes = {
	showModal: PropTypes.bool,
	setDrawerHower: PropTypes.func,
};

export default GlobalSearchModal;
