import React, { ChangeEvent, useState, useCallback } from 'react';
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import { FormErrors } from 'types/utils';
import {
	createDegreeOfTranslation,
	editDegreeOfTranslation,
	fetchDegreeOfTranslations,
	deleteDegreeOfTranslation,
} from './degreeOfTranslations.service';
import { Input, InputCollectionSelect } from 'dumb';
import { ZodError, z } from 'zod';

const reduxKey = 'nutrition/degreeOfTranslations';

const schema = z.object({
	name: z.string().min(1),
	language: z.number().positive(),
	ingredient_allergen_degree_of: z.number().positive(),
});

function DegreeOfTranslations() {
	const [formErrors, setFormErrors] = useState<
		FormErrors<IIngredientAllergenDegreeOfTranslation>
	>({});
	const [formData, setFormData] = useState<
		Partial<IIngredientAllergenDegreeOfTranslation>
	>({});

	const handleCreateDegreeOfTranslation = useCallback(() => {
		const toCreate = {
			...formData,
			language: formData.language?.id,
			ingredient_allergen_degree_of: formData.ingredient_allergen_degree_of?.id,
		};

		return new Promise((resolve, reject) => {
			const validation = schema.safeParse(toCreate);
			if (validation.success) {
				return resolve(createDegreeOfTranslation(validation.data));
			} else {
				return reject(validation.error);
			}
		});
	}, [formData]);

	const handleModalClose = useCallback(() => {
		setFormData({});
		setFormErrors({});
	}, [setFormData, setFormErrors]);

	const handleEditDegreeOfTranslation = useCallback(() => {
		const schemaWithId = schema.extend({ id: z.number() });
		const toEdit = {
			...formData,
			language: formData.language?.id,
			ingredient_allergen_degree_of: formData.ingredient_allergen_degree_of?.id,
		};

		return new Promise((resolve, reject) => {
			const validation = schemaWithId.safeParse(toEdit);
			if (validation.success) {
				return resolve(editDegreeOfTranslation(validation.data));
			} else {
				return reject(validation.error);
			}
		});
	}, [formData]);

	return (
		<ReactDataWrapper
			title="Degree Of Translations"
			columns={[
				{
					Header: 'Name',
					id: 'name',
					accessor: 'name',
					filterPath: ':name',
				},
				{
					Header: 'Degree Of',
					id: 'degree_of',
					accessor: 'ingredient_allergen_degree_of.name',
					filterPath: ':ingredient_allergen_degree_of.name',
				},
				{
					Header: 'Language',
					id: 'language',
					accessor: 'language.name',
					filterPath: ':language.name',
				},
			]}
			reduxKey={reduxKey}
			fetchData={fetchDegreeOfTranslations}
			editEntry={handleEditDegreeOfTranslation}
			setInitialEditValues={setFormData}
			createEntry={handleCreateDegreeOfTranslation}
			deleteEntry={deleteDegreeOfTranslation}
			accessAreasAllowedToEdit={['Sales Configuration']}
			onModalClose={handleModalClose}
			defaultPageSize={10}
			onError={(e: ZodError) => {
				if (e instanceof ZodError) {
					setFormErrors(e.flatten().fieldErrors);
					return;
				}

				return e;
			}}
			filterable
			deleteConfirm
			editableCells={[
				{
					header: 'Name',
					value: (
						<Input
							id="name"
							placeholder="Enter name"
							errors={formErrors.name}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								setFormData({ ...formData, name: e.target.value })
							}
							required
							value={formData.name}
						/>
					),
				},
				{
					header: 'Degree Of',
					value: (
						<InputCollectionSelect
							id="degree_of"
							label="Degree Of"
							placeholder="Select Degree Of"
							errors={formErrors.ingredient_allergen_degree_of}
							value={
								formData.ingredient_allergen_degree_of
									? {
											label: formData.ingredient_allergen_degree_of.name,
											value: formData.ingredient_allergen_degree_of.id,
									  }
									: undefined
							}
							handleChange={(_, value) =>
								setFormData({
									...formData,
									ingredient_allergen_degree_of: value?.value,
								})
							}
							required
							cache
							apiPath="/product/ingredient_allergen_degree_ofs"
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>
					),
				},
				{
					header: 'Language',
					value: (
						<InputCollectionSelect
							id="language"
							label="Language"
							placeholder="Select Language"
							errors={formErrors.language}
							value={
								formData.language
									? {
											label: formData.language.name,
											value: formData.language.id,
									  }
									: undefined
							}
							handleChange={(_, value) =>
								setFormData({ ...formData, language: value?.value })
							}
							required
							cache
							apiPath="/administration/languages"
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>
					),
				},
			]}
		/>
	);
}

export default DegreeOfTranslations;
