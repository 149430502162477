import { ActionType } from 'types/redux';
import { IModuleCategoryReduxValue, IModuleCategoryReduxState } from '../types';

import {
    SET_MODULE_CATEGORY,
    UPDATE_MODULE_CATEGORY,
    RESET_STATE,
} from './moduleCategories.actions';

const defaultState: IModuleCategoryReduxState = {
    data: {
        defaultModuleCategory: null,
        moduleCategory: {
            name: null,
            sort_order: null,
        },
    },
};

function reducer(
    state = defaultState,
    action: ActionType
): IModuleCategoryReduxState {
    const { type, payload } = action;

    switch (type) {
        case SET_MODULE_CATEGORY: {
            const castedPayload = payload as IModuleCategoryReduxValue;

            return {
                ...state,
                data: {
                    moduleCategory: castedPayload,
                    defaultModuleCategory: castedPayload,
                },
            };
        }

        case UPDATE_MODULE_CATEGORY: {
            const castedPayload = payload as Partial<IModuleCategoryReduxValue>;

            return {
                ...state,
                data: {
                    ...state.data,
                    moduleCategory: {
                        ...state.data.moduleCategory,
                        ...castedPayload,
                    },
                },
            };
        }

        case RESET_STATE: {
            return defaultState;
        }

        default:
            return state;
    }
}

export default reducer;
