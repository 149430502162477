'use strict';
import { get } from 'api.vanilla.service';
import endpoints from 'endpoints.service';
import { store } from 'appState';

export const FETCH_PHP_REPORT = 'PHP_REPORT/FETCH_PHP_REPORT';
export const SET_PHP_REPORT = 'PHP_REPORT/SET_PHP_REPORT';
export const SET_REPORT_TYPE = 'PHP_REPORT/SET_REPORT_TYPE';
export const SET_REPORT_USE_AUDIT_GRIDS = 'PHP_REPORT/SET_REPORT_USE_AUDIT_GRIDS';
export const SET_PHP_REPORT_DATES = 'PHP_REPORT/SET_PHP_REPORT_DATES';

/**
 * @param {boolean} fetchingPhpReport
 */
export function fetchPhpReport(fetchingPhpReport = false) {
	return {
		type: FETCH_PHP_REPORT,
		payload: {
			fetchingPhpReport
		}
	};
}

/**
 * @param {object} payload
 */
export function setPhpReport(payload) {
	return {
		type: SET_PHP_REPORT,
		payload
	};
}

export function setReportType(payload) {
	return {
		type: SET_REPORT_TYPE,
		payload
	};
}

export function setReportUseAuditGrids(payload) {
	return {
		type: SET_REPORT_USE_AUDIT_GRIDS,
		payload
	};
}

export function setReportDates(payload) {
	return {
		type: SET_PHP_REPORT_DATES,
		payload
	};
}

export const fetchHalfHourlyReport = (marketId, workplaceId, period, useAuditGrids) => {
	store.dispatch(fetchPhpReport(true));
	const endpoint = `${endpoints.collection.reporting.shiftplanner_reports.index.path}/markets/${marketId}/workplaces/${workplaceId}/half_hourly_entries`;
	const params = {
		from: period.from,
		to: period.to,
		type: 'Week',
		audit_grids: useAuditGrids,
	};

	return get(endpoint, params)
		.then(response => {
			store.dispatch(fetchPhpReport(false));
			return response;
		})
		.catch(() => {
			store.dispatch(fetchPhpReport(false));
		});
};

export const fetchHourlyReport = (marketId, workplaceId, period, useAuditGrids) => {
	store.dispatch(fetchPhpReport(true));
	const endpoint = `${endpoints.collection.reporting.shiftplanner_reports.index.path}/markets/${marketId}/workplaces/${workplaceId}/hourly_entries`;
	const params = {
		from: period.from,
		to: period.to,
		type: 'Week',
		audit_grids: useAuditGrids,
	};

	return get(endpoint, params)
		.then(response => {
			store.dispatch(fetchPhpReport(false));
			return response;
		})
		.catch(() => {
			store.dispatch(fetchPhpReport(false));
		});
};
