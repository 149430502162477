import { ActionType } from 'types/redux';
import {
	IWorkplaceTagsReduxValue,
	IWorkplaceTagsReduxValueBatch,
} from '../types';

export const SET_WORKPLACE_TAG = 'WORKPLACE_TAGS/SET_WORKPLACE_TAG';
export const UPDATE_WORKPLACE_TAG = 'WORKPLACE_TAGS/UPDATE_WORKPLACE_TAG';
export const RESET_STATE = 'WORKPLACE_TAGS/RESET_STATE';

// redux stuff
export const EDIT_BATCH_FORM = 'WORKPLACE_TAGS/EDIT_BATCH_FORM';
export const SET_BATCH_FORM_DATA = 'WORKPLACE_TAGS/SET_BATCH_FORM_DATA';
export const EDIT_BATCH_LIST_ITEM = 'WORKPLACE_TAGS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM = 'WORKPLACE_TAGS/REMOVE_BATCH_LIST_ITEM';
export const RERENDER_TABLE = 'WORKPLACE_TAGS/RERENDER_TABLE';

export function setworkplaceTag(payload: IWorkplaceTagsReduxValue): ActionType {
	return {
		type: SET_WORKPLACE_TAG,
		payload,
	};
}

type updateworkplaceTag = Partial<IWorkplaceTagsReduxValue>;
export function updateworkplaceTag(payload: updateworkplaceTag): ActionType {
	return {
		type: UPDATE_WORKPLACE_TAG,
		payload,
	};
}

export function resetState(): ActionType {
	return {
		type: RESET_STATE,
	};
}

export function editBatchForm(
	payload: Partial<IWorkplaceTagsReduxValue>
): ActionType {
	return {
		type: EDIT_BATCH_FORM,
		payload,
	};
}

export function setBatchFormData(
	payload: IWorkplaceTagsReduxValueBatch[]
): ActionType {
	return {
		type: SET_BATCH_FORM_DATA,
		payload,
	};
}

type editBatchListItem = Partial<IWorkplaceTagsReduxValueBatch>;
export function editBatchListItem(payload: editBatchListItem): ActionType {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload: string): ActionType {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function rerenderTable(): ActionType {
	return {
		type: RERENDER_TABLE,
	};
}
