import { ActionType } from 'types/redux';
import { IGridEntriesReduxValue } from '../types';

export const SET_GRID_ENTRY = 'GRID_ENTRIES/SET';
export const UPDATE_GRID_ENTRY = 'GRID_ENTRIES/UPDATE';
export const RESET_DATA = 'GRID_ENTRIES/RESET_DATA';

export function resetData(): ActionType {
	return {
		type: RESET_DATA,
	};
}

export function setGridEntry(payload: IGridEntriesReduxValue): ActionType {
	return {
		type: SET_GRID_ENTRY,
		payload,
	};
}

export function updateGridEntry(
	payload: Partial<IGridEntriesReduxValue>
): ActionType {
	return {
		type: UPDATE_GRID_ENTRY,
		payload,
	};
}
