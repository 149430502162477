import { ActionType } from 'types/redux';
import { IGradeLevelReduxState, IGradeLevelReduxValue } from '../types';
import { RESET_STATE, SET_GRADE_LEVEL, UPDATE_GRADE_LEVEL } from "./gradeLevels.actions";

const defaultState: IGradeLevelReduxState = {
    data: {
        defaultGradeLevel: null,
        gradeLevel: {
            name: null,
            value: null,
            color: null,
        },
    },
};

function reducer(
    state = defaultState,
    action: ActionType
): IGradeLevelReduxState {
    const { type, payload } = action;

    switch (type) {
        case SET_GRADE_LEVEL: {
            const castedPayload = payload as IGradeLevelReduxValue;

            return {
                ...state,
                data: {
                    gradeLevel: castedPayload,
                    defaultGradeLevel: castedPayload,
                },
            };
        }

        case UPDATE_GRADE_LEVEL: {
            const castedPayload = payload as Partial<IGradeLevelReduxValue>;

            return {
                ...state,
                data: {
                    ...state.data,
                    gradeLevel: {
                        ...state.data.gradeLevel,
                        ...castedPayload,
                    },
                },
            };
        }

        case RESET_STATE: {
            return defaultState;
        }

        default:
            return state;
    }
}

export default reducer;
