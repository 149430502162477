import React, { useEffect, useState } from 'react';

// Redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import { setCurrentStoreFront } from './store/storeFrontImages.actions';

// Components
import ImageBox from './components/imageBox';

// Dumb
import { Loader } from 'dumb';

// Services
import {
	getStoreFrontImageById,
	removeStoreFront,
	createStoreFront,
	updateStoreFront,
} from './storeFrontImages.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

// Utils
import { convertBinaryToBase64 } from '../../../../../common/services/utils';

// Interfaces
import { base64 } from './types';

// Phrases
import phrases from './storeFrontImage.phrases';

// Mui
import { Box, Button, Typography } from '@mui/material';

type Props = {
	workplaceId: string;
};

export default function StoreFrontImage({ workplaceId }: Props): JSX.Element {
	const id = parseInt(workplaceId);
	const dispatch = useAppDispatch();
	const currentStoreFront = useAppSelector(
		(store) => store.storeFronts.currentStoreFront
	);

	const [temporaryDisplayImage, setTemporaryDisplayImage] = useState<
		File | undefined
	>(undefined);

	const [loading, setLoading] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [showLoader, setShowLoader] = useState(false);

	const handleOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const target = e?.target;
		const file = target?.files?.[0];

		setTemporaryDisplayImage(file);
	};

	const handleDelete = () => {
		setLoadingDelete(true);
		setTemporaryDisplayImage(undefined);
		setShowLoader(true);

		if (temporaryDisplayImage) {
			setLoadingDelete(false);
			setShowLoader(false);
		}

		if (currentStoreFront)
			removeStoreFront(currentStoreFront?.id).finally(() => {
				setLoadingDelete(false);
				setShowLoader(false);
			});
	};

	const handleUpdate = () => {
		setLoading(true);
		setShowLoader(true);
		setLoadingDelete(true);

		convertImageAndReturnStoreFrontPayload().then((response) => {
			updateStoreFront(currentStoreFront.id, response).finally(() => {
				setTemporaryDisplayImage(undefined);
				setLoading(false);
				setShowLoader(false);
				setLoadingDelete(false);
			});
		});
	};

	const handleAttachmentUpload = () => {
		setLoading(true);
		setShowLoader(true);
		setLoadingDelete(true);

		convertImageAndReturnStoreFrontPayload()?.then((response) => {
			createStoreFront(response).finally(() => {
				setTemporaryDisplayImage(undefined);
				setLoading(false);
				setShowLoader(false);
				setLoadingDelete(false);
			});
		});
	};

	const convertImageAndReturnStoreFrontPayload = () => {
		return convertBinaryToBase64(temporaryDisplayImage)
			.then((image64: typeof base64) => {
				return {
					workplace: id,
					image: {
						filename: `storefront_${id}`,
						data: image64,
					},
				};
			})
			.catch((error) => {
				const errorMessage = formatErrorMessage(error);
				setFeedback(errorMessage, 0);
				throw error;
			});
	};

	const showImageByApiOrState = () => {
		if (!temporaryDisplayImage && !currentStoreFront) return null;

		return (
			<ImageBox
				src={
					temporaryDisplayImage
						? URL.createObjectURL(temporaryDisplayImage)
						: currentStoreFront?.image?.url
				}
			/>
		);
	};

	const enableDragging = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();

		const imageFile = event.dataTransfer.files[0];

		if (!imageFile.type.includes('image')) {
			setFeedback(phrases.FILE_NOT_SUPPORTED, 0, 1500);
			return;
		}

		setTemporaryDisplayImage(imageFile);
	};

	useEffect(() => {
		getStoreFrontImageById(id);
		return () => {
			dispatch(setCurrentStoreFront(undefined));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const handleLeaving = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
	};

	const disableButton =
		temporaryDisplayImage === undefined && currentStoreFront === undefined;

	const secondDisableButtonCondition =
		currentStoreFront && temporaryDisplayImage === undefined;

	return (
		<Box>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<h2>{phrases.STORE_FRONT_IMAGE}</h2>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					padding: (theme) => theme.spacing(2),
					backgroundColor: (theme) => theme.palette.common.white,
					boxShadow: (theme) => theme.shadows[1],
				}}
			>
				<Box display="flex">
					<input
						accept="image/*"
						type="file"
						id="select-image"
						style={{ display: 'none' }}
						onChange={handleOnchange}
					/>
					<label htmlFor="select-image">
						<Button variant="contained" component="span">
							{phrases.UPLOAD_IMAGE}
						</Button>
					</label>
					<Box>
						<Button
							disabled={disableButton || loadingDelete}
							onClick={handleDelete}
							sx={{ marginLeft: 1 }}
							variant="contained"
							component="span"
						>
							{phrases.DELETE}
						</Button>

						<Button
							disabled={
								disableButton || secondDisableButtonCondition || loading
							}
							onClick={
								temporaryDisplayImage && currentStoreFront
									? handleUpdate
									: handleAttachmentUpload
							}
							sx={{ marginLeft: 1, marginRight: 1 }}
							variant="contained"
							component="span"
						>
							{temporaryDisplayImage && currentStoreFront
								? phrases.UPDATE
								: phrases.SAVE}
						</Button>
					</Box>

					{showLoader && <Loader />}
				</Box>
				{!currentStoreFront && !temporaryDisplayImage && (
					<>
						<input
							accept="image/*"
							type="file"
							id="select-image"
							style={{ display: 'none' }}
							onChange={handleOnchange}
						/>
						<Box
							sx={{
								width: 250,
								height: 150,
								marginTop: 2,
								marginBottom: 1,
								'&:hover': {
									opacity: 0.65,
								},
							}}
							onDragOver={enableDragging}
							onDrop={handleDrop}
							onDragLeave={handleLeaving}
						>
							<label
								style={{
									width: 250,
									height: 150,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									border: 'grey dashed 3px',
								}}
								htmlFor="select-image"
							>
								<Typography variant="body1">{phrases.DROP_AN_IMAGE}</Typography>
							</label>
						</Box>
					</>
				)}

				{showImageByApiOrState()}
			</Box>
		</Box>
	);
}
