'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import { ErrorTip, Label } from 'dumb';

import cx from 'classnames';

import './toggle.css';

function Toggle({
	id,
	toggled,
	label,
	onClick,
	size,
	disabled,
	type,
	refProp,
	onMouseEnter,
	onMouseLeave,
	errors,
	dataCy,
}) {
	const onClickHandler = () => {
		if (disabled) return;

		onClick(!toggled);
	};

	return (
		<div
			className="j-toggle"
			ref={refProp}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{label && <Label htmlFor={id}>{label}</Label>}
			<label
				className={cx('j-toggle__wrapper', {
					'j-toggle__wrapper--with-label': !!label,
					'j-toggle__wrapper--disabled': disabled,
				})}
			>
				<span>
					<input className="j-toggle__wrapper__input" type="checkbox" />
					<span
						onClick={onClickHandler}
						data-cy={dataCy}
						className={cx('j-toggle__wrapper__control', {
							'j-toggle__wrapper__control--toggled': toggled,
							[`j-toggle__wrapper__control--${size}`]: !!size,
							[`j-toggle__wrapper__control--${type}`]: !!type,
						})}
					/>
				</span>
			</label>
			{errors &&
				errors.length > 0 &&
				errors.map((e) => <ErrorTip key={e} content={e} />)}
		</div>
	);
}

Toggle.defaultProps = {
	toggled: false,
	id: 'toggleSwitch',
	size: 'medium',
	disabled: false,
	onClick: () => {},
	type: 'dark',
	dataCy: '',
};

Toggle.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	toggled: PropTypes.bool,
	disabled: PropTypes.bool,
	label: PropTypes.string,
	onClick: PropTypes.func,
	size: PropTypes.string,
	type: PropTypes.oneOf(['light', 'dark']),
	refProp: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	errors: PropTypes.arrayOf(PropTypes.string),
	dataCy: PropTypes.string,
};

export default Toggle;
