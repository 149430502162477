import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// components
import { Input, InputPhone, ModalRow } from 'dumb';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';

// icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// utils
import { copyToClipboard } from 'services/utils';
import moment from 'moment';
import _get from 'lodash/get';
import { validAccess } from 'accessControl';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './staffBody.phrases';

const RenderCopyToClipboardIcon = ({ tooltipText, itemToCopy }) => {
	const handleCopyToClipboard = () => {
		try {
			copyToClipboard(itemToCopy);
			setFeedback(phrases.COPIED_TO_CLIPBOARD, 1, 5000, true);
		} catch (error) {
			setFeedback(
				`${phrases.COPIED_TO_CLIPBOARD_ERROR}: ${itemToCopy}`,
				0,
				10000,
				true
			);
		}
	};

	return (
		<Tooltip title={tooltipText}>
			<IconButton size="small" onClick={handleCopyToClipboard}>
				<ContentCopyIcon fontSize="22px" />
			</IconButton>
		</Tooltip>
	);
};

class StaffBody extends PureComponent {
	constructor(props) {
		super(props);

		this._handlePinChange = this._handlePinChange.bind(this);
		this._handlePhoneNumberChange = this._handlePhoneNumberChange.bind(this);
		this._handleEmailChange = this._handleEmailChange.bind(this);
		this.copyToClipboard = this.copyToClipboard.bind(this);
	}

	_handlePinChange(e) {
		if (!isNaN(e.target.value)) {
			this.props.updatePinCode(Number(e.target.value));
		}
	}

	_handlePhoneNumberChange(e) {
		const value = e.target ? e.target.value : e;
		this.props.updatePhoneNumber(value);
	}

	_handleEmailChange(e) {
		const value = e.target ? e.target.value : e;
		this.props.updateEmail(value);
	}

	_getSenioritytHoursWorked() {
		const { details, seniority } = this.props;

		const started = _get(details, 'started', null);
		const allMonths =
			started && moment().diff(moment(started, 'YYYY-MM-DD'), 'months');

		const years = Math.floor(allMonths / 12);
		const months = years ? allMonths - years * 12 : allMonths;

		const humanYear = years ? moment.duration(years, 'years').humanize() : '';
		const humanMonths = months
			? moment.duration(months, 'months').humanize()
			: '';
		const oneMonth = !years && !months ? '1 month' : '';

		const hoursWorked =
			humanYear + (months && years ? ', ' : '') + humanMonths + oneMonth;
		const seniorityValue = seniority?.total_hours_worked
			? `(${seniority.total_hours_worked} hrs)`
			: '';

		return `${hoursWorked} ${seniorityValue}`;
	}

	copyToClipboard(value) {
		if (!value) return;

		try {
			copyToClipboard(value);
			setFeedback(phrases.COPIED_TO_CLIPBOARD, 1, 5000, true);
		} catch (error) {
			setFeedback(
				`${phrases.COPIED_TO_CLIPBOARD_ERROR}: ${value}`,
				0,
				10000,
				true
			);
		}
	}

	renderCopyToClipboardIcon(itemToCopy, tooltipText) {
		const onCopyClick = () => this.copyToClipboard(itemToCopy);

		return (
			<Tooltip title={tooltipText}>
				<IconButton size="small" onClick={onCopyClick}>
					<ContentCopyIcon fontSize="22px" />
				</IconButton>
			</Tooltip>
		);
	}

	render() {
		const {
			editMode,
			userPhoneNumber,
			userEmail,
			userPin,
			optionsArray,
			viewingLoggedInUser,
			contentPadding,
		} = this.props;

		const managerRole = validAccess(undefined, [
			'Shiftplan Manager',
			'Shiftplan Admin',
		]);

		const inputsDisabled = !editMode || !managerRole;

		return (
			<Box
				sx={{
					paddingX: contentPadding,
					marginTop: 2,
				}}
			>
				<ModalRow>
					{optionsArray &&
						optionsArray.map((option, index) => {
							return (
								<Box
									key={index}
									sx={{
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<Typography variant="subtitle2">{option.label}</Typography>
									{option.item}
								</Box>
							);
						})}
				</ModalRow>

				<ModalRow>
					{inputsDisabled ? (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								marginTop: 2,
							}}
						>
							<Typography
								variant="subtitle2"
								sx={{
									color: 'text.secondary',
								}}
							>
								Phone number
							</Typography>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Typography variant="body1">{userPhoneNumber}</Typography>
								<RenderCopyToClipboardIcon
									itemToCopy={userPhoneNumber}
									tooltipText="Copy phone number"
								/>
							</Box>
						</Box>
					) : (
						<InputPhone
							label="Phone number"
							placeholder="Enter phone number"
							id="phone"
							phoneNumber={userPhoneNumber}
							onChange={this._handlePhoneNumberChange}
							disabled={inputsDisabled}
							enableLink
							enableValueCopy
							copyTooltipZindex={600}
							dataCy="staff-details-modal-phone-input"
						/>
					)}
				</ModalRow>

				<ModalRow>
					{inputsDisabled ? (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Typography
								variant="subtitle2"
								sx={{
									color: 'text.secondary',
								}}
							>
								Email
							</Typography>

							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Typography variant="body1">{userEmail}</Typography>

								<RenderCopyToClipboardIcon
									itemToCopy={userEmail}
									tooltipText="Copy email"
								/>
							</Box>
						</Box>
					) : (
						<Input
							label="Email"
							placeholder="Enter valid email"
							id="email"
							type="email"
							onChange={this._handleEmailChange}
							value={userEmail}
							disabled={inputsDisabled}
							enableValueCopy
							copyTooltipZindex={600}
							dataCy="staff-details-modal-email-input"
						/>
					)}
				</ModalRow>

				{viewingLoggedInUser && (
					<ModalRow>
						{!editMode ? (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<Typography
									variant="subtitle2"
									sx={{
										color: 'text.secondary',
									}}
								>
									Pin code
								</Typography>

								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Typography variant="body1">{userPin || ''}</Typography>

									<RenderCopyToClipboardIcon
										itemToCopy={userPin}
										tooltipText="Copy pin"
									/>
								</Box>
							</Box>
						) : (
							<Input
								label="Pin code"
								placeholder="Enter 4 digit pin"
								id="email"
								onChange={this._handlePinChange}
								value={userPin || ''}
								maxLength={4}
								enableValueCopy
								copyTooltipZindex={600}
								disabled={!viewingLoggedInUser}
								dataCy="staff-details-modal-pin-code-input"
							/>
						)}
					</ModalRow>
				)}

				<ModalRow>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Typography
							variant="subtitle2"
							sx={{
								color: 'text.secondary',
							}}
						>
							Senority/ Hours worked
						</Typography>
						<Typography variant="body1">
							{this._getSenioritytHoursWorked()}
						</Typography>
					</Box>
				</ModalRow>
			</Box>
		);
	}
}

StaffBody.propTypes = {
	userPhoneNumber: PropTypes.string,
	userEmail: PropTypes.string,
	userPin: PropTypes.number,
	editMode: PropTypes.bool,
	optionsArray: PropTypes.arrayOf(Object),
	details: PropTypes.object,
	seniority: PropTypes.object,

	// Func
	updatePhoneNumber: PropTypes.func.isRequired,
	updateEmail: PropTypes.func.isRequired,
	updatePinCode: PropTypes.func.isRequired,
	viewingLoggedInUser: PropTypes.bool,
	contentPadding: PropTypes.number,
};

StaffBody.defaultProps = {
	editMode: false,
};

RenderCopyToClipboardIcon.propTypes = {
	itemToCopy: PropTypes.string,
	tooltipText: PropTypes.string,
};

export default StaffBody;
