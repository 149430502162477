import { IFetchDataProps } from 'types/reactDataWrapper';
import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './gridEntries.phrases';
import { IGridEntryCRUD } from './types';

export const API_PATH = '/hr/grid_entries';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw err;
}

export function fetchGridEntries(
	id: number,
	state: IFetchDataProps
): Promise<IApiRes> {
	let filter = `:grid.id=='${id}'`;

	if (state.filter) filter = `${filter};${state.filter}`;

	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(API_PATH, params, null, state.headers)
		.then((res: IApiRes) => res)
		.catch(handleError);
}

export const createGridEntries = (
	payload: IGridEntryCRUD
): Promise<IApiRes> => {
	return post(API_PATH, payload)
		.then((response: IApiRes) => {
			setFeedback(phrases.GRID_ENTRY_ADDED, 1);

			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);

			setFeedback(errorMessage, 0);
		});
};

export function editGridEntries(
	id: number,
	data: Partial<IGridEntryCRUD>
): Promise<IApiRes> {
	return post(`${API_PATH}/${id}`, data)
		.then((res: IApiRes) => {
			setFeedback(phrases.GRID_ENTRY_EDITED, 1);
			return res;
		})
		.catch(handleError);
}

export const deleteGridEntries = (id: number): Promise<IApiRes> => {
	return remove(`${API_PATH}/${id}`)
		.then((response: IApiRes) => {
			setFeedback(phrases.GRID_ENTRY_DELETED, 1);
			return response;
		})
		.catch(handleError);
};
