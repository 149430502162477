import { IFetchDataProps } from 'types/reactDataWrapper';
import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './moduleCategories.phrases';

function handleError(err: Error): Error {
    const error = formatErrorMessage(err);
    setFeedback(error, 0);

    throw error;
}

export function fetchModuleCategories(state: IFetchDataProps): Promise<IApiRes> {
    const params = {
        limit: state.limit,
        sort: state.sort,
        offset: state.offset,
        filter: state.filter,
    };

    return get(
        '/training/module_categories',
        params,
        null,
        state.headers
    )
        .then((res: IApiRes) => res)
        .catch(handleError);
}

export function addModuleCategories(data: Record<string, unknown>): Promise<IApiRes> {
    return post(`/training/module_categories`, data)
        .then((res: IApiRes) => {
            setFeedback(phrases.MODULE_CATEGORY_ADDED, 1);
            return res;
        })
        .catch(handleError);
}

export function editModuleCategories(id: number, data: Record<string, unknown>): Promise<IApiRes> {
    return post(`/training/module_categories/${id}`, data)
        .then((res: IApiRes) => {
            setFeedback(phrases.MODULE_CATEGORY_EDITED, 1);
            return res;
        })
        .catch(handleError);
}

export function deleteModuleCategories(id: number): Promise<IApiRes> {
    return remove(`/training/module_categories/${id}`)
        .then((res: IApiRes) => {
            setFeedback(phrases.MODULE_CATEGORY_DELETED, 1);
            return res;
        })
        .catch(handleError);
}
