import React from 'react';
import PropTypes from 'prop-types';

import { push } from 'redux-first-history';
import store from 'appState/store';
import { Auth0Provider } from '@auth0/auth0-react';
import config from 'config';

const Auth0ProviderWithHistory = ({ children }) => {
	const domain = 'auth.joejuice.com';
	const clientId = config.auth0.clientId;
	const connection = config.auth0.connection;

	const onRedirectCallback = (appState) => {
		store.dispatch(push(appState?.returnTo || window.location.pathname));
	};

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			connection={connection}
			responseType={'openid profile token id_token'}
			useRefreshTokens
			useRefreshTokensFallback
			cacheLocation="localstorage"
			onRedirectCallback={onRedirectCallback}
			authorizationParams={{
				redirect_uri: window.location.origin,
				audience: 'https://api2test.joejuice.com/latest/',
				scope: 'openid',
			}}
		>
			{children}
		</Auth0Provider>
	);
};

Auth0ProviderWithHistory.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Auth0ProviderWithHistory;
