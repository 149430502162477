// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { IWorkplaceTagsCRUD, IWorkplaceTagsBatchCRUD } from './types';

import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './workplaceTags.phrases';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw error;
}

type fetchWorkplaceTags = {
	state: IFetchDataProps;
	workplaceId?: number;
	tagId?: number;
};
/**
 * @function fetchWorkplaceTags
 * @description Fetch all companies
 * @param {object} state
 */
export function fetchWorkplaceTags({
	state,
	workplaceId,
	tagId,
}: fetchWorkplaceTags): Promise<IApiRes<IShiftplanningWorkplaceTag>> {
	let filter = state.filter || '';

	if (workplaceId)
		filter = filter
			? `${filter};:workplace.id=='${workplaceId}'`
			: `:workplace.id=='${workplaceId}'`;
	if (tagId)
		filter = filter ? `${filter};:tag.id=='${tagId}'` : `:tag.id=='${tagId}'`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/shiftplanning/workplace_tags', params, null, state.headers)
		.then((res: IApiRes<IShiftplanningWorkplaceTag>) => res)
		.catch(handleError);
}

/**
 * @function addWorkplaceTags
 * @description adds workplaceTag
 */
export function addWorkplaceTags(
	data: IWorkplaceTagsBatchCRUD
): Promise<IApiRes<IShiftplanningWorkplaceTag>> {
	return post('/shiftplanning/workplace_tags', data)
		.then((res: IApiRes<IShiftplanningWorkplaceTag>) => {
			setFeedback(phrases.WORKPLACE_TAGS_ADDED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function addWorkplaceTags
 * @description adds workplaceTag
 */
export function addWorkplaceTag(
	data: IWorkplaceTagsCRUD
): Promise<IApiRes<IShiftplanningWorkplaceTag>> {
	return post('/shiftplanning/workplace_tags', data)
		.then((res: IApiRes<IShiftplanningWorkplaceTag>) => {
			setFeedback(phrases.WORKPLACE_TAGS_ADDED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function editWorkplaceTag
 * @description edits workplaceTag
 */
export function editWorkplaceTag(
	id: number,
	data: Partial<IWorkplaceTagsCRUD>
): Promise<IApiRes<IShiftplanningWorkplaceTag>> {
	return post(`/shiftplanning/workplace_tags/${id}`, data)
		.then((res: IApiRes<IShiftplanningWorkplaceTag>) => {
			setFeedback(phrases.WORKPLACE_TAG_EDITED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function editWorkplaceTags
 * @description edits multiple market tags
 */
export function editWorkplaceTags(
	data: Partial<IWorkplaceTagsCRUD>[]
): Promise<IApiRes<IShiftplanningWorkplaceTag>> {
	return patch('/shiftplanning/workplace_tags', { batch: data })
		.then((res: IApiRes<IShiftplanningWorkplaceTag>) => {
			setFeedback(phrases.WORKPLACE_TAGS_EDITED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function deleteWorkplaceTags
 * @description deletes companies
 */
export function deleteWorkplaceTags(
	id: number
): Promise<IApiRes<IShiftplanningWorkplaceTag>> {
	return remove(`/shiftplanning/workplace_tags/${id}`)
		.then((res: IApiRes<IShiftplanningWorkplaceTag>) => {
			setFeedback(phrases.WORKPLACE_TAGS_DELETED, 1);
			return res;
		})
		.catch(handleError);
}
