'use strict';

import PropTypes from 'prop-types';
import React from 'react';

import PosLayoutComponent from './posLayout.component';
import PosItemList from './../appLayout/components/posItemList/posItemList.component';

import { withDragDropContext } from 'utils';

const PosLayout = (props) => {
	const {
		activeTile,
		addTile,
		addLayoutGroup,
		deleteLayoutGroup,
		removeTile,
		fetching,
		grid,
		gridDimensions,
		gridMetaData,
		gridSize,
		groupItemInFocus,
		groups,
		imageAssets,
		layout,
		page,
		posConfigurationsVariantsList,
		rotateTiles,
		setMenuItemInFocus,
		setPage,
		tiles,
		tilesInGroup,
		updateLayoutGroup,
		updateTile,
		updateTilePosition,
		posFullConfigurationsVariantsList,
		posConfUi,
	} = props;

	return (
		<div className="sales-pos-configuration">
			<div className="sales-pos-configuration__content-wrapper">
				<PosItemList
					posConfigurationsVariantsList={posFullConfigurationsVariantsList}
				/>
				<PosLayoutComponent
					activeTile={activeTile}
					deleteLayoutGroup={deleteLayoutGroup}
					addLayoutGroup={addLayoutGroup}
					addTile={addTile}
					removeTile={removeTile}
					fetching={fetching}
					grid={grid}
					gridDimensions={gridDimensions}
					gridMetaData={gridMetaData}
					gridSize={gridSize}
					groupItemInFocus={groupItemInFocus}
					groups={groups}
					imageAssets={imageAssets}
					layout={layout}
					page={page}
					posConfigurationsVariantsList={posConfigurationsVariantsList}
					rotateTiles={rotateTiles}
					setMenuItemInFocus={setMenuItemInFocus}
					setPage={setPage}
					tiles={tiles}
					tilesInGroup={tilesInGroup}
					updateLayoutGroup={updateLayoutGroup}
					updateTile={updateTile}
					updateTilePosition={updateTilePosition}
					ui={posConfUi}
					layoutId={props.layoutId}
					type={props.type}
				/>
			</div>
		</div>
	);
};

PosLayout.defaultProps = {
	phrases: {},
	layout: null,
	layoutId: null,
};

PosLayout.propTypes = {
	layoutId: PropTypes.number,
	setMenuItemInFocus: PropTypes.func,
	updateTile: PropTypes.func,
	deleteLayoutGroup: PropTypes.func,
	removeTile: PropTypes.func,
	tilesInGroup: PropTypes.array,
	groupItemInFocus: PropTypes.number,
	activeTile: PropTypes.object,
	fetching: PropTypes.bool,
	grid: PropTypes.object,
	type: PropTypes.string,
	gridDimensions: PropTypes.array,
	gridMetaData: PropTypes.object,
	gridSize: PropTypes.number,
	groups: PropTypes.object,
	imageAssets: PropTypes.array,
	posConfigurationsVariantsList: PropTypes.array,
	posFullConfigurationsVariantsList: PropTypes.array,
	posConfUi: PropTypes.object,
	addTile: PropTypes.func,
	addLayoutGroup: PropTypes.func,
	layout: PropTypes.object,
	page: PropTypes.number,
	rotateTiles: PropTypes.func,
	setPage: PropTypes.func,
	tiles: PropTypes.array,
	updateLayoutGroup: PropTypes.func,
	updateTilePosition: PropTypes.func,
};

export default withDragDropContext(PosLayout);
