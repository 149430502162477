import { isMobile } from 'detectMobile.vanilla';

export default ({ accessTokenData, powerBIFilter, layoutType }) => {
	if (!accessTokenData) return;

	// models url: https://github.com/microsoft/powerbi-models/blob/master/src/models.ts

	const mobile = isMobile();

	// 2 is mobile, 0 is desktop
	let layoutTypeToUse = mobile ? 2 : 0;
	if (typeof layoutType === 'number') layoutTypeToUse = layoutType;

	return {
		type: 'report', // Supported types: report, dashboard, tile, visual and qna
		id: accessTokenData.power_bi_report_id,
		embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${accessTokenData.power_bi_report_id}`,
		accessToken: accessTokenData.token,
		tokenType: 1,
		settings: {
			layoutType: layoutTypeToUse,
			panes: {
				filters: {
					expanded: false,
					visible: false,
				},
			},
		},
		...(powerBIFilter && {
			filters: [powerBIFilter],
		}),
	};
};
