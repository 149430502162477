export default {
	TABLE_TITLE: 'Workplace tags',
	WORKPLACE_TAGS_ADDED: 'Workplace tag added',
	WORKPLACE_TAG_EDITED: 'Workplace tag edited',
	WORKPLACE_TAGS_EDITED: 'Workplace tags edited',
	WORKPLACE_TAGS_DELETED: 'Workplace tag deleted',

	// batch
	MODAL_TITLE: 'Add workplace to the tag',

	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	TAG: 'Tag',
	TAG_PLACEHOLDER: 'Select tags...',
	WORKPLACE: 'Workplace',
	WORKPLACES: 'Workplaces',
	WORKPLACE_PLACEHOLDER: 'Select workplace...',
	ADD_ROW_TOOLTIP: 'Click to add row',
	DELETE_ROW_TOOLTIP: 'Remove row',
	ADD: 'Add',
	MODAL_BATCH_TIP_ONE:
		'You can only enter minutes from start or minutes from end, not both',
	MODAL_BATCH_TIP_TWO:
		'"Default length" cannot be longer then "Minutes from end" as the Tag will finish after the shift',

	SUBMIT: 'Confirm',
	NEXT: 'Next',
	BACK: 'Back',
};
