import { ActionType } from 'types/redux';
import { SET_GRID_ENTRY, UPDATE_GRID_ENTRY } from './gridEntries.actions';
import { IGridEntriesReduxValue, IGridEntryReduxState } from '../types';

const defaultState: IGridEntryReduxState = {
	data: {
		defaultGridEntry: null,
		gridEntry: {
			employees: null,
			minimum: null,
			maximum: null,
			optimal: null,
			auditRangeMinimum: null,
			auditRangeMaximum: null,
		},
	},
};

function reducer(
	state = defaultState,
	action: ActionType
): IGridEntryReduxState {
	const { type, payload } = action;

	switch (type) {
	case SET_GRID_ENTRY: {
		const castedPayload = payload as IGridEntriesReduxValue;

		return {
				...state,
				data: {
					gridEntry: castedPayload,
					defaultGridEntry: castedPayload,
				},
			};
	}

	case UPDATE_GRID_ENTRY: {
		const castedPayload = payload as Partial<IGridEntryReduxState>;

		return {
				...state,
				data: {
					...state.data,
					gridEntry: {
						...state.data.gridEntry,
						...castedPayload,
					},
				},
			};
	}

	default:
		return state;
	}
}

export default reducer;
