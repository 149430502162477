export const ENQUEUE_SNACKBAR = 'NOTIFICATIONS/ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'NOTIFICATIONS/CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'NOTIFICATIONS/REMOVE_SNACKBAR';

/**
 * @function enqueueSnackbar
 * @param {object} notification - {options: {key: string}... message: string}
 */
export const enqueueSnackbar = (notification) => {
	const key = notification.options?.key ?? new Date().getTime() + Math.random();

	const message =
		typeof notification.message === 'string'
			? notification.message
			: JSON.stringify(notification.message);

	return {
		type: ENQUEUE_SNACKBAR,
		notification: {
			...notification,
			message,
			key,
		},
	};
};

export const closeSnackbar = (key) => ({
	type: CLOSE_SNACKBAR,
	dismissAll: !key, // dismiss all if no key has been defined
	key,
});

export const removeSnackbar = (key) => ({
	type: REMOVE_SNACKBAR,
	key,
});
