'use strict';

import React, { ChangeEvent } from 'react';
import { IFetchDataProps } from 'types/reactDataWrapper';
import { IGradeScaleReduxValue} from './types';
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/administration/gradeScales.columns';
import { Input, Tabs } from 'dumb';
import { fetchGradeScales, editGradeScales, addGradeScales, deleteGradeScales } from './gradeScales.service';
import { setGradeScales, resetState, updateGradeScale } from './store/gradeScales.actions';
import phrases from './gradeScales.phrases';
import gradeLevelPhrases from './components/gradeLevels/gradeLevels.phrases';
import getEditedValues from 'services/utils/getEditedValues';
import SubTable from "../../../../../common/components/reactDataWrapper/components/subTable";
import GradeLevels from "./components/gradeLevels/gradeLevels.container";

const reduxKey = '/global-administration/modules-and-exams/grade-scales';

const GradeScales = (): JSX.Element => {
	const { gradeScale, defaultGradeScale } = useAppSelector(
		(state) => ({
			gradeScale: state.gradeScales.data.gradeScale,
			defaultGradeScale: state.gradeScales.data.defaultGradeScale,
		})
	);

	const dispatch = useAppDispatch();

	const addEntry = () => {
		return addGradeScales({
			name: gradeScale?.name,
		});
	};

	const editEntry = () => {
		if (!gradeScale.id || !defaultGradeScale) {
			return Promise.resolve(true);
		}

		const editedValues = getEditedValues({
			oldData: defaultGradeScale,
			newData: gradeScale,
		});

		return editGradeScales(gradeScale.id, {
			name: editedValues?.name,
		});
	};

	const deleteEntry = (id: number) => {
		return deleteGradeScales(id);
	};

	const setInitialEditValues = (data: IAdministrationGradeScale) => {
		const payload: IGradeScaleReduxValue = {
			id: data.id,
			name: data.name,
		};

		dispatch(setGradeScales(payload));
	};

	const editGradeScaleEntry = (
		name: string,
		value: string
	): void => {
		const payload = {
			[name]: value,
		};

		dispatch(updateGradeScale(payload));
	};

	const getEditableCells = () => {
		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={gradeScale.name}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							editGradeScaleEntry('name', event.target.value)
						}
						dataCy="grade-scale-name"
						required
					/>
				),
			},
		];
	};

	const subcomponent = (row: any) => {
		const tabContent = [
			{
				name: gradeLevelPhrases.TABLE_TITLE,
				component: <GradeLevels gradeScaleId={row.original.id} />,
			},
		];
		return (
			<SubTable>
				<Tabs tabContent={tabContent} />
			</SubTable>
		);
	};

	return (
		<ReactDataWrapper
			title={phrases.TABLE_TITLE}
			dataCy="grade-scales-table"
			columns={getColumns()}
			fetchData={(state: IFetchDataProps): ReturnType<typeof fetchGradeScales> => fetchGradeScales(state)}
			filterable
			defaultPageSize={5}
			reduxKey={reduxKey}
			manual
			accessAreasAllowedToEdit={['Global BI Configuration']}
			editableCells={getEditableCells()}
			actionsWidth={80}
			createEntry={addEntry}
			editEntry={editEntry}
			deleteEntry={deleteEntry}
			setInitialEditValues={setInitialEditValues}
			onModalClose={() => dispatch(resetState())}
			onModalCloseConfirm
			compact
			subcomponent={subcomponent}
		/>
	);
};

export default GradeScales;
