import {
	SET_POWER_BI_REPORTS,
	SET_POWER_BI_REPORT_ACCESS_TOKEN_DATA,
	RESET_POWER_BI_REPORT_ACCESS_TOKEN_DATA,
	SET_POWER_BI_REPORTS_UNMODIFIED,
} from './powerBIReport.actions';

const initialState = {
	accessTokenData: null,
	reports: [],
	reportsUnmodified: [],
};

function reducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_POWER_BI_REPORTS:
			return {
				...state,
				reports: payload,
			};

		case SET_POWER_BI_REPORTS_UNMODIFIED:
			return {
				...state,
				reportsUnmodified: payload,
			};

		case SET_POWER_BI_REPORT_ACCESS_TOKEN_DATA:
			return {
				...state,
				accessTokenData: payload,
			};

		case RESET_POWER_BI_REPORT_ACCESS_TOKEN_DATA:
			return {
				...state,
				accessTokenData: initialState.accessTokenData,
			};

		default:
			return state;
	}
}

export default reducer;
