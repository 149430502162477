import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Style
import { isMobile } from 'detectMobile.vanilla';
import useMediaQuery from '@mui/material/useMediaQuery';

// Redux
import { setPowerBIReports, setPowerBIReportsUnmodified } from 'reports/powerBIReport/store/powerBIReport.actions';
import { showSearchModal } from 'services/globalSearch/actions';
import { setDrawerOpen } from 'services/appSettings/actions';
import _debounce from 'lodash/debounce';

// UI
import {
	ListItem,
	ListItemText,
	ListItemIcon,
	IconButton,
	Box,
	Fade,
	Backdrop,
	Fab,
	Tooltip,
} from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ViewSidebarOutlinedIcon from '@mui/icons-material/ViewSidebarOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import TenantSelect from 'smart/tenantSelect/tenantSelect';
import GlobalSearchInput from 'smart/globalSearchInput/globalSearchInput';
import GlobalSearchModal from 'smart/globalSearchModal/globalSearchModal';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerNavigationList from './components/drawerNavigationList';
// drawer building blocks
import {
	Drawer,
	JoeDivider,
	JoeNavList,
} from './components/drawerReusableNavigationComponents';

// Utils
import { fetchPowerBIReports } from './drawer.service';
import { formatPowerBIReports } from 'services/navigation';
import HotkeysService from 'hotkeys.service';

const getDebouncedPowerBIReports = _debounce(
	async (userId, dispatch) => {
		const powerBIReports = await fetchPowerBIReports(userId);

		const powerBIReportsFormatted = formatPowerBIReports({ data: powerBIReports });
		dispatch(setPowerBIReports(powerBIReportsFormatted));
		dispatch(setPowerBIReportsUnmodified(powerBIReports));
	},
	50,
	{ trailing: true }
);

// used in client/router/app.js
export function DrawerContainer({
	filteredRoutes,
	userId,
	handleLogout,
	isAuthenticated,
}) {
	const [expanded, setExpanded] = useState({});
	const [hovered, setHovered] = useState(!isMobile());
	const [hoveredListItem, setHoveredListItem] = useState('');

	const dispatch = useDispatch();
	const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split('/');

	const { showModal } = useSelector(
		(state) => ({
			showModal: state.globalSearch.showModal,
		}),
		shallowEqual
	);
	const { accessToken, drawerShown, juicerId, powerBIReports } = useSelector(
		(state) => ({
			drawerShown: state.appSettings.drawerShown,
			juicerId: state.userData?.user?.user?.person?.id ?? null,
			powerBIReports: state.powerBIReport?.reports,
			accessToken: state?.userData?.accessToken,
		})
	);

	const isAboveMdBreakpoint = useMediaQuery((theme) =>
		theme.breakpoints.up('md')
	);
	const isAboveSmBreakpoint = useMediaQuery((theme) =>
		theme.breakpoints.up('sm')
	);

	const isHoveredOrDrawerShown = drawerShown || hovered;

	useEffect(() => {
		async function fetchReports() {
			const userIsAuthenticated = isAuthenticated && accessToken;

			if (userIsAuthenticated || process.env.CYPRESS_TEST === 'true') {
				getDebouncedPowerBIReports(userId, dispatch);
			}
		}

		fetchReports();
	}, [userId, accessToken, isAuthenticated, dispatch]);

	useEffect(() => {
		const hotkeysShortcuts = 'ctrl+k, cmd+k';

		HotkeysService.defineHotkeys({
			keys: hotkeysShortcuts,
			callback: (event, handler) => {
				event.preventDefault();
				switch (handler.key) {
					case 'cmd+k':
					case 'ctrl+k':
						dispatch(showSearchModal(true));
						break;
					default:
						break;
				}
			},
		});

		return () => {
			HotkeysService.unbind(hotkeysShortcuts);
		};
	}, [dispatch]);

	useEffect(() => {
		if (!isHoveredOrDrawerShown) {
			setExpanded([]);
		}
	}, [isHoveredOrDrawerShown]);

	const handleClickAway = () => {
		if (isAboveMdBreakpoint) return;
		dispatch(setDrawerOpen(false));
		setHovered(false);
	};

	const handleToggleCollapsed = (element) => {
		if (!element?.children?.length) {
			if (isAboveMdBreakpoint) return;
			return handleClickAway();
		}

		setExpanded({ ...expanded, [element.title]: !expanded[element.title] });
	};

	const handleSectionClick = () => {
		handleClickAway();
	};

	const getRightIconParentSecondary = (element, hasChildren) => {
		if (!isHoveredOrDrawerShown) {
			return null;
		}

		if (hasChildren && expanded[element.title]) {
			return (
				<Fade
					in={isHoveredOrDrawerShown}
					{...(isHoveredOrDrawerShown ? { timeout: 200 } : {})}>
					<KeyboardArrowDownOutlinedIcon />
				</Fade>
			);
		}

		if (hasChildren && hoveredListItem === element.path) {
			return <KeyboardArrowDownOutlinedIcon />;
		}

		if (element.external) {
			return (
				<Fade
					in={isHoveredOrDrawerShown}
					{...(isHoveredOrDrawerShown ? { timeout: 1000 } : {})}>
					<OpenInNewOutlinedIcon />
				</Fade>
			);
		}

		return null;
	};

	const toggleNavigationSwitch = () => {
		dispatch(setDrawerOpen(!drawerShown));
	};

	return (
		<>
			{!isAboveMdBreakpoint ? (
				<Backdrop
					sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={isHoveredOrDrawerShown}
					onClick={handleClickAway}></Backdrop>
			) : null}
			<Drawer
				sx={{
					position: 'relative',
					zIndex: (theme) => theme.zIndex.drawer + 2,
				}}
				open={isHoveredOrDrawerShown}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				variant={'permanent'}
				anchor="left">
				<Box
					sx={{
						marginBottom: '45px',
					}}>
					{/* Tenancy */}
					<JoeDivider />
					<Box
						sx={{
							display: 'flex',
						}}>
						<Box
							sx={{
								width: {
									xs: '100%',
									sm: isHoveredOrDrawerShown ? 'calc(100% - 48px)' : '100%',
								},
							}}>
							<TenantSelect
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
								}}
								toggled={isHoveredOrDrawerShown}
							/>
						</Box>
						{isAboveSmBreakpoint ? (
							<Tooltip
								title={'Lock navigation to left side'}
								enterDelay={0}
								placement="right">
								<IconButton
									sx={(theme) => ({
										color: drawerShown
											? 'rgb(255 255 255 / 100%)'
											: 'rgb(255 255 255 / 40%)',
										width: '48px',
										marginRight: theme.spacing(1),
										transition: theme.transitions.create('color'),
										backgroundColor: 'transparent',
										'&:hover': {
											color: 'rgb(255 255 255 / 100%)',
										},
										transform: 'rotate(180deg)',
									})}
									aria-label="toggle navigation drawer"
									onClick={toggleNavigationSwitch}>
									<ViewSidebarOutlinedIcon
										sx={{
											width: '20px',
										}}
									/>
								</IconButton>
							</Tooltip>
						) : null}
					</Box>
					<JoeDivider />

					{/* Search */}
					<GlobalSearchInput toggled={isHoveredOrDrawerShown} />

					<JoeDivider />

					{/* Main nav */}
					<nav aria-label="main">
						<DrawerNavigationList
							filteredRoutes={filteredRoutes}
							expanded={expanded}
							handleToggleCollapsed={handleToggleCollapsed}
							handleSectionClick={handleSectionClick}
							setHoveredListItem={setHoveredListItem}
							getRightIconParentSecondary={getRightIconParentSecondary}
							powerBIReports={powerBIReports}
							splitLocation={splitLocation}
							juicerId={juicerId}
							hovered={hovered}
						/>
					</nav>
					<JoeDivider />
				</Box>

				<JoeNavList
					sx={{
						position: 'fixed',
						bottom: 0,
						left: 0,
						width: 'inherit',
						overflow: 'hidden',
						paddingBottom: '8px !important',
					}}>
					<ListItem sx={{ fontSize: 12 }} button onClick={handleLogout}>
						{hovered ? (
							<ListItemIcon>
								<LogoutOutlinedIcon />
							</ListItemIcon>
						) : (
							<Tooltip title={'Logout'} enterDelay={0} placement="right">
								<ListItemIcon>
									<LogoutOutlinedIcon />
								</ListItemIcon>
							</Tooltip>
						)}
						<ListItemText
							primaryTypographyProps={{ noWrap: true }}
							primary={'Log out'}
						/>
					</ListItem>
				</JoeNavList>

				{showModal ? (
					<GlobalSearchModal
						setDrawerHower={() => setHovered(false)}
						showModal={showModal}
					/>
				) : null}
			</Drawer>

			{!isAboveMdBreakpoint ? (
				<Fab
					sx={{
						position: 'fixed',
						bottom: (theme) => theme.spacing(1),
						left: (theme) => theme.spacing(1),
						zIndex: (theme) => theme.zIndex.drawer,
						boxShadow: 'none',
					}}
					color="secondary"
					aria-label="navigation"
					onClick={() => setHovered(true)}>
					<MenuIcon />
				</Fab>
			) : null}
		</>
	);
}

DrawerContainer.propTypes = {
	filteredRoutes: PropTypes.array,
	userId: PropTypes.string,
	isAuthenticated: PropTypes.bool,
	handleLogout: PropTypes.func,
};
