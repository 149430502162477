'use strict';

import PropTypes from 'prop-types';
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import React, { PureComponent } from 'react';

import { ReactDataWrapper } from 'reactDataWrapper';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import phrases from './../vacationsIllness.phrases';
import constants from 'services/constants';
import moment from 'moment';
import { getStatusColor } from 'reactDataWrapperColumns/shiftplanning/requests.columns';

import { Chip } from '@mui/material';

// actions
import {
	updateVacationData,
	setVacationData,
	resetState,
} from './store/vacationsTable.actions';

// api service
import {
	fetchVacations,
	deleteVacation,
	editVacation,
	createVacation,
} from './vacations.service';

import {
	getFactorSelectDisabled,
	getSelectedDate,
} from '../vacationsIllness.utilities';

import { Input, SingleDatePickerInput, InputCollectionSelect } from 'dumb';

const _getReduxKey = (customEndpoint, personId) => {
	const reduxKey = `${customEndpoint || 'shiftplanning/vacations'}-${personId}`;
	return reduxKey;
};

class VacationsContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.setVacationData = this.setVacationData.bind(this);
		this.updateVacationData = this.updateVacationData.bind(this);

		// when used in appbar -> staff details modal context, we need to use a different endpoint and therefor redux key
		this.reduxKey = _getReduxKey(
			this.props.customEndpoint,
			this.props.personId
		);
		this.columns = [
			{
				Header: 'Subject',
				id: 'subject',
				accessor: (d) => d.subject,
				filterPath: ':subject',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: (d) => d.description,
				filterPath: ':description',
			},
			{
				Header: 'From',
				id: 'from',
				accessor: (d) => _get(d, 'period.from', null),
				filterPath: ':period.from',
			},
			{
				Header: 'To',
				accessor: (d) => _get(d, 'period.to', null),
				id: 'to',
				filterPath: ':period.to',
			},
			{
				Header: 'Half day',
				width: 65,
				id: 'factor',
				accessor: (d) => d,
				filterPath: ':factor',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							type="checkbox"
							checked={d.original.factor === 0.5}
							disabled
						/>
					);
				},
			},
			{
				Header: 'Status',
				id: 'status',
				accessor: (d) => d.request?.status ?? 'Approved',
				filterPath: ':request.status',
				Cell: (d) => {
					const status = d.value;
					return (
						<Chip label={status} size="small" color={getStatusColor(status)} />
					);
				},
			},
		];
	}

	/**
	 * @method deleteEntry
	 * @param {*} id
	 */
	deleteEntry(id) {
		const { deleteShiftplannerVacation, customEndpoint } = this.props;

		return deleteVacation(id, customEndpoint).then((res) => {
			if (deleteShiftplannerVacation) deleteShiftplannerVacation(id);
			return res;
		});
	}

	/**
	 * @method editEntry
	 * @param {*} id
	 */
	editEntry() {
		const {
			vacationData,
			personId,
			vacationFromListData,
			updateShiftplannerVacation,
			customEndpoint,
		} = this.props;

		// find original vacation from table
		const originalVacation = vacationFromListData.find(
			(entry) => entry.id === vacationData.id
		);

		if (!originalVacation) return Promise.resolve(false);

		const fromDateChanged = !moment
			.utc(vacationData.from)
			.isSame(moment.utc(originalVacation.period.from), 'day');
		const toDateChanged = !moment
			.utc(vacationData.to)
			.isSame(moment.utc(originalVacation.period.to), 'day');

		// check if period changed from original
		const period = {
			...(fromDateChanged && {
				from: moment.utc(vacationData.from).format(constants.shortDate),
			}),
			...(toDateChanged && {
				to: moment.utc(vacationData.to).format(constants.shortDate),
			}),
		};

		const payload = {
			id: vacationData.id,
			person: personId,
			factor: vacationData.factor,
			...(!_isEmpty(period) && { period }),
			...(vacationData.subject && { subject: vacationData.subject }),
			...(vacationData.description && {
				description: vacationData.description,
			}),
		};

		return editVacation(payload, customEndpoint).then((res) => {
			if (updateShiftplannerVacation) updateShiftplannerVacation(res.data[0]);
			return res;
		});
	}

	/**
	 * @method addEntry
	 * @description crates an vacation with period, subject and description
	 */
	addEntry() {
		const { vacationData, personId, addShiftplannerVacation, customEndpoint } =
			this.props;

		const payload = {
			person: personId,
			period: {
				from: moment.utc(vacationData.from).format(constants.shortDate),
				to: moment.utc(vacationData.to).format(constants.shortDate),
			},
			...(vacationData.subject && { subject: vacationData.subject }),
			...(vacationData.description && {
				description: vacationData.description,
			}),
			...(vacationData.factor && { factor: vacationData.factor }),
		};

		return createVacation(payload, customEndpoint).then((res) => {
			if (addShiftplannerVacation) addShiftplannerVacation(res.data);
			return res;
		});
	}

	/**
	 * @method setVacationData
	 * @param {Object} data - row data from reactDataWrapper
	 * @description sets vacation data in store for the edit modal
	 */
	setVacationData(data) {
		const { setVacationData } = this.props;

		const payload = {
			id: data.id,
			...(data.description && { description: data.description }),
			...(data.subject && { subject: data.subject }),
			factor: data.factor,
			from: data.period.from,
			to: data.period.to,
		};

		setVacationData(payload);
	}

	/**
	 * @method fetchData
	 * @param {Object} state
	 * @description gets vacations from api
	 */
	fetchData(state) {
		const { personId, customEndpoint } = this.props;

		return fetchVacations(state, personId, customEndpoint);
	}

	getEditableCells() {
		const { vacationData } = this.props;
		return [
			{
				header: 'Preset',
				value: (
					<InputCollectionSelect
						dataCy="shiftplanner-vacations-preset-select"
						value={vacationData.preset}
						handleChange={(_, value) => {
							this.updateVacationData('preset', value);
						}}
						cache
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => {
							return {
								value: {
									subject: entry.subject,
									description: entry.description,
								},
								label: entry.subject,
							};
						}}
						apiPath="/shiftplanning/vacation_description_presets"
						placeholder="Select a vacation preset"
					/>
				),
			},
			{
				header: 'Subject',
				value: (
					<Input
						onChange={(event) => this.updateVacationData('subject', event)}
						placeholder="Subject"
						value={vacationData.subject}
						dataCy="shiftplanner-vacations-subject-input"
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						onChange={(value) => this.updateVacationData('description', value)}
						value={vacationData.description}
						placeholder="Description"
						dataCy="shiftplanner-vacations-description-input"
					/>
				),
			},
			{
				header: 'From',
				value: (
					<SingleDatePickerInput
						onChange={(date) => this.updateVacationData('from', date)}
						selectedDate={getSelectedDate('from', vacationData)}
						noClockButton
						dataCy="shiftplanner-vacations-from-date-input"
					/>
				),
			},
			{
				header: 'To',
				value: (
					<SingleDatePickerInput
						onChange={(date) => this.updateVacationData('to', date)}
						selectedDate={getSelectedDate('to', vacationData)}
						noClockButton
						dataCy="shiftplanner-vacations-to-date-input"
					/>
				),
			},
			{
				header: 'Half day',
				value: (
					<Input
						type="checkbox"
						checked={vacationData.factor === 0.5}
						onChange={(event) => this.updateVacationData('factor', event)}
						disabled={getFactorSelectDisabled(vacationData)}
					/>
				),
			},
		];
	}

	/**
	 * @method updateVacationData
	 * @param {String} name - name of the input subject/description/from/to
	 * @param {String} value - value of any of the inputs
	 */
	updateVacationData(name, e) {
		const { updateVacationData, vacationData } = this.props;

		let value;
		if (name === 'factor') value = e.target.checked ? 0.5 : 1;
		else value = e?.target?.value ?? e;

		// if period changed set factor to full day again
		// if it did change, check if changed to more then a day
		const setFactorToFullDay =
			name === 'from' || name === 'to'
				? !moment
						.utc(value)
						.isSame(
							moment.utc(name === 'from' ? vacationData.to : vacationData.from),
							'day'
						)
				: false;

		const payload = {
			[name]: value,
			...(setFactorToFullDay && {
				factor: 1,
			}),
			...(name === 'preset' && {
				subject: e?.value.subject,
				description: e?.value.description,
			}),
		};

		// set the value in the store
		updateVacationData(payload);
	}

	render() {
		const { resetState, modalZIndex } = this.props;

		return (
			<ReactDataWrapper
				dataCy="vacations-table"
				title={phrases.VACATIONS_TABLE}
				columns={this.columns}
				editEntry={this.editEntry}
				createEntry={this.addEntry}
				fetchData={this.fetchData}
				accessAreasAllowedToEdit={[
					'Employment Admin',
					'Person Admin',
					'Shiftplan Manager',
					'Shiftplan Admin',
				]}
				defaultSorted={[
					{
						id: 'from',
						desc: false,
					},
				]}
				deleteEntry={(id) => this.deleteEntry(id)}
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setVacationData}
				filterable
				defaultPageSize={10}
				reduxKey={this.reduxKey}
				onModalClose={resetState}
				manual
				modalZIndex={modalZIndex}
			/>
		);
	}
}

VacationsContainer.propTypes = {
	updateVacationData: PropTypes.func,
	vacationData: PropTypes.object,
	setVacationData: PropTypes.func,
	personId: PropTypes.number,
	resetState: PropTypes.func,
	vacationFromListData: PropTypes.array,
	modalZIndex: PropTypes.bool,
	addShiftplannerVacation: PropTypes.func,
	updateShiftplannerVacation: PropTypes.func,
	deleteShiftplannerVacation: PropTypes.func,
	customEndpoint: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateVacationData,
			setVacationData,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store, props) => {
	const { customEndpoint, personId } = props;
	const reduxKey = _getReduxKey(customEndpoint, personId);

	return {
		vacationData: store.vacationsTable.data.vacationData,
		vacationFromListData: _get(
			store,
			`listData.[${reduxKey}].data.listData`,
			[]
		),
	};
};

export default connectWithStore(
	VacationsContainer,
	mapStateToProps,
	mapDispatchToProps
);
