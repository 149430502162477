'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';
import { DateFilterSelector, FilterProvider } from 'reactDataWrapper/utilities';

// constants/phrases
import phrases from './../../../ingredientAllergens.phrases';
import collectionSelectEnums from 'services/enums/collectionSelect';
import moment from 'moment';
import constants from 'services/constants';

// lodash
import _get from 'lodash/get';

class IngredientAllergensBatchStepForm extends Component {
	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		const value = e?.target?.value ?? e;

		updateBatchForm({
			[type]: value,
		});
	}

	render() {
		const { batchFormData, useGlobalResource } = this.props;

		return (
			<>
				<ModalRow>
					<InputCollectionSelect
						id="ingredient"
						label={phrases.MODAL_BATCH_INGREDIENT}
						placeholder={phrases.MODAL_BATCH_INGREDIENT_PLACEHOLDER}
						value={batchFormData.ingredients}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'ingredients')
						}
						cache
						apiPath="/product/ingredients"
						params={{
							limit: 30,
							filter: ":legacy=='false'",
						}}
						multi
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableTitle={phrases.MODAL_BATCH_INGREDIENT_TABLE_NAME}
						tableReduxKey="ingredient-allergens-batch-modal/product/ingredients"
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: (d) => _get(d, 'name', ''),
								filterPath: ':name',
							},
							{
								Header: 'Ingredient category',
								id: 'ingredient_category',
								accessor: (d) => _get(d, 'ingredient_category.name', ''),
								filterPath: ':ingredient_category.name',
							},
							{
								Header: 'Fuel',
								id: 'fuel',
								accessor: (d) => _get(d, 'fuel', ''),
								filterPath: ':fuel',
							},
							{
								Header: 'Asset Collection',
								id: 'asset_collection',
								accessor: (d) => _get(d, 'asset_collection.name', null),
								filterPath: ':asset_collection.name',
							},
							{
								Header: 'Ingredient Pricing Group',
								id: 'ingredient_pricing_group',
								accessor: (d) => _get(d, 'ingredient_pricing_group.name', null),
								filterPath: ':ingredient_pricing_group.name',
							},
							{
								Header: 'List name',
								id: 'list_name',
								accessor: 'list_name',
								filterPath: ':list_name',
							},
							{
								Header: 'Operation type',
								id: 'operation_type',
								accessor: 'operation_type',
								filterPath: ':operation_type',
							},
							{
								Header: 'Availability administration',
								id: 'availabilityAdministration',
								accessor: 'availability_administration',
								filterPath: ':availability_administration',
								filterable: false,
								Cell: (d) => {
									return (
										<Input
											type="checkbox"
											checked={_get(
												d,
												'original.availability_administration',
												false
											)}
											disabled
										/>
									);
								},
							},
							{
								Header: 'Legacy',
								id: 'legacy',
								accessor: (d) => _get(d, 'legacy', ''),
								filterPath: ':legacy',
								filterable: false,
								Cell: (d) => {
									return (
										<Input
											type="checkbox"
											checked={_get(d, 'original.legacy', false)}
											disabled
										/>
									);
								},
							},
						]}
					/>
				</ModalRow>
				<ModalRow>
					<InputCollectionSelect
						id={`degree-of`}
						label={phrases.MODAL_BATCH_DEGREE}
						placeholder={phrases.MODAL_BATCH_DEGREE_PLACEHOLDER}
						value={batchFormData.degree}
						handleChange={(key, value) => this.editStoreEntry(value, 'degree')}
						clearable={false}
						cache
						apiPath="/product/ingredient_allergen_degree_ofs"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				</ModalRow>
				<ModalRow>
					<InputCollectionSelect
						id="allergen"
						label={phrases.MODAL_BATCH_ALLERGEN}
						placeholder={phrases.MODAL_BATCH_ALLERGEN_PLACEHOLDER}
						value={batchFormData.allergen}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'allergen')
						}
						clearable={false}
						cache
						apiPath="/product/allergens"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				</ModalRow>

				{!useGlobalResource && (
					<ModalRow>
						<InputCollectionSelect
							id="market"
							label={phrases.MODAL_BATCH_MARKET}
							placeholder={phrases.MODAL_BATCH_MARKET_PLACEHOLDER}
							value={batchFormData.markets}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'markets')
							}
							cache
							multi
							apiPath="/organization/markets"
							params={{
								limit: 30,
								filter: `:closed=ge='${moment
									.utc()
									.format(constants.shortDate)}'`,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
							tableTitle={phrases.MODAL_BATCH_MARKET_TABLE_NAME}
							tableReduxKey="ingredient-allergens-batch-modal/organization/markets"
							tableColumns={[
								{
									Header: 'Name',
									accessor: 'name',
									id: 'name',
									filterPath: ':name',
								},
								{
									Header: 'Country',
									id: 'country',
									accessor: (d) => _get(d, 'country.name', ''),
									filterPath: ':country.name',
									Filter: ({ column }) => (
										<FilterProvider
											reduxKey="ingredient-allergens-batch-modal/organization/markets"
											columnId={column.id}
											provide={(filterValue, persistToFilterStorage) => (
												<InputCollectionSelect
													id={column.id}
													name="country"
													value={filterValue}
													handleChange={(key, value) => {
														persistToFilterStorage({ handle: key, value });
													}}
													apiPath="/administration/countries"
													params={{
														limit: 30,
													}}
													inputType
													styleType={collectionSelectEnums.TYPE_IN_TABLE}
													optionFormat={(entry) => ({
														value: entry.id,
														label: entry.name,
													})}
													inputFilterFormat={(input) =>
														`:name=like='%${input}%'`
													}
												/>
											)}
										/>
									),
								},
								{
									Header: 'Market region',
									id: 'market-region',
									accessor: (d) => _get(d, 'market_region.name', ''),
									filterPath: ':market_region.name',
								},
								{
									Header: 'Tax label',
									id: 'tax-label',
									accessor: 'tax_label',
									filterPath: ':tax_label',
								},
								{
									Header: 'Default break tag',
									id: 'default-break-tag',
									accessor: (d) => _get(d, 'default_break_tag.name', ''),
									filterPath: ':default_break_tag.name',
								},
								{
									Header: 'Clockin alignment required',
									id: 'clocking-alignment-required',
									accessor: 'clocking_alignment_required',
									filterPath: ':clocking_alignment_required',
									Cell: (d) => (
										<Input
											id={`${d.original.id}-clocking-alignment-required`}
											type="checkbox"
											checked={d.value}
											disabled
										/>
									),
									filterable: false,
								},
								{
									Header: 'Break registration',
									id: 'break-registration',
									accessor: 'break_registration',
									filterPath: ':break_registration',
									Cell: (d) => (
										<Input
											id={`${d.original.id}-break-registration`}
											type="checkbox"
											checked={d.value}
											disabled
										/>
									),
									filterable: false,
								},
								{
									Header: 'Opened',
									id: 'opened',
									accessor: 'opened',
									filterPath: ':opened',
									Filter: ({ column }) => (
										<DateFilterSelector
											reduxKey="ingredient-allergens-batch-modal/organization/markets"
											columnId={column.id}
											dateIdentifier="from"
										/>
									),
								},
								{
									Header: 'Closed',
									accessor: 'closed',
									id: 'closed',
									filterPath: ':closed',
									Filter: ({ column }) => (
										<DateFilterSelector
											reduxKey="ingredient-allergens-batch-modal/organization/markets"
											columnId={column.id}
											dateIdentifier="to"
										/>
									),
								},
							]}
						/>
					</ModalRow>
				)}
			</>
		);
	}
}

IngredientAllergensBatchStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	useGlobalResource: PropTypes.bool,
};

export default IngredientAllergensBatchStepForm;
