'use strict';

import React, { ChangeEvent } from 'react';
import { IFetchDataProps } from 'types/reactDataWrapper';
import { IModuleCategoryReduxValue } from './types';
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/administration/moduleCategories.columns';
import { Input } from 'dumb';
import { fetchModuleCategories, editModuleCategories, addModuleCategories, deleteModuleCategories } from './moduleCategories.service';
import { setModulesCategories, resetState, updateModuleCategories } from './store/moduleCategories.actions';
import phrases from './moduleCategories.phrases';
import getEditedValues from 'services/utils/getEditedValues';

const reduxKey = '/global-administration/modules-and-exams/module-categories';

const ModuleCategories = (): JSX.Element => {
    const { moduleCategory, defaultModuleCategory } = useAppSelector(
        (state) => ({
            moduleCategory: state.moduleCategories.data.moduleCategory,
            defaultModuleCategory: state.moduleCategories.data.defaultModuleCategory,
        })
    );

    const dispatch = useAppDispatch();

    const addEntry = () => {
        return addModuleCategories({
            name: moduleCategory?.name,
            sort_order: moduleCategory?.sort_order,
        });
    };

    const editEntry = () => {
        if (!moduleCategory.id || !defaultModuleCategory) {
            return Promise.resolve(true);
        }

        const editedValues = getEditedValues({
            oldData: defaultModuleCategory,
            newData: moduleCategory,
        });

        return editModuleCategories(moduleCategory.id, {
            name: editedValues?.name,
            sort_order: editedValues?.sort_order,
        });
    };

    const deleteEntry = (id: number) => {
        return deleteModuleCategories(id);
    };

    const setInitialEditValues = (data: IAdministrationModuleCategory) => {
        const payload: IModuleCategoryReduxValue = {
            id: data.id,
            name: data.name,
            sort_order: data.sort_order,
        };

        dispatch(setModulesCategories(payload));
    };

    const editModuleCategoryEntry = (
        name: string,
        value: string
    ): void => {
        const payload = {
            [name]: value,
        };

        dispatch(updateModuleCategories(payload));
    };

    const getEditableCells = () => {
        return [
            {
                header: 'Name',
                value: (
                    <Input
                        id="name"
                        placeholder="Enter name..."
                        value={moduleCategory.name}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            editModuleCategoryEntry('name', event.target.value)
                        }
                        dataCy="module-category-name"
                        required
                    />
                ),
            },
            {
                header: 'Sort Order',
                value: (
                    <Input
                        id="sort-order"
                        placeholder="Enter sort order..."
                        value={moduleCategory.sort_order}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            editModuleCategoryEntry('sort_order', event.target.value)
                        }
                        dataCy="module-category-sort-order"
                        required
                    />
                ),
            },
        ];
    };

    return (
        <ReactDataWrapper
            title={phrases.TABLE_TITLE}
            dataCy="module-categories-table"
            columns={getColumns()}
            fetchData={(state: IFetchDataProps): ReturnType<typeof fetchModuleCategories> => fetchModuleCategories(state)}
            filterable
            defaultPageSize={10}
            reduxKey={reduxKey}
            manual
            accessAreasAllowedToEdit={['Global BI Configuration']}
            editableCells={getEditableCells()}
            actionsWidth={80}
            createEntry={addEntry}
            editEntry={editEntry}
            deleteEntry={deleteEntry}
            setInitialEditValues={setInitialEditValues}
            onModalClose={() => dispatch(resetState())}
            onModalCloseConfirm
            compact
        />
    );
};

export default ModuleCategories;
