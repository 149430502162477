import { IFetchDataProps } from 'types/reactDataWrapper';

import { get } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw error;
}

export function fetchPartnershipCodes(
	state: IFetchDataProps = {
		limit: 100,
		sort: '',
		offset: 0,
		filter: '',
		headers: '',
	}
): Promise<IApiRes<IPartnershipCode>> {
	return get(
		'/administration/joe_pay_partnerships/codes',
		state,
		null,
		state.headers
	).catch(handleError);
}
