'use strict';

import React, { ChangeEvent } from 'react';
import { IFetchDataProps } from 'types/reactDataWrapper';
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/administration/gridEntries.columns';
import {
	API_PATH,
	createGridEntries,
	deleteGridEntries,
	editGridEntries,
	fetchGridEntries,
} from './gridEntries.service';
import phrases from './gridEntries.phrases';
import { useAppDispatch, useAppSelector } from 'appState/storeHooks';
import { Input } from 'dumb';
import getEditedValues from 'services/utils/getEditedValues';
import {IGridEntriesReduxValue, IGridEntryCRUD} from './types';
import { setGridEntry, updateGridEntry } from './store/gridEntries.actions';
import { resetData } from '../../store/grids.actions';
import { useParams } from 'react-router';

type Props = {
	id: number;
};

const GridEntries = ({ id }: Props): JSX.Element => {
	const { gridEntry, defaultGridEntry } = useAppSelector((state) => ({
		gridEntry: state.gridEntries.data.gridEntry,
		defaultGridEntry: state.gridEntries.data.defaultGridEntry,
	}));

	const dispatch = useAppDispatch();
	const { gridId } = useParams();

	const setInitialEditValues = (data: IAdministrationGridEntry) => {
		const payload: IGridEntriesReduxValue = {
			id: data.id,
			employees: data.employees,
			maximum: data.maximum,
			minimum: data.minimum,
			optimal: data.optimal,
			auditRangeMinimum: data.audit_range_minimum,
			auditRangeMaximum: data.audit_range_maximum,
		};

		dispatch(setGridEntry(payload));
	};

	const updateGridEntryState = (
		name: keyof IGridEntriesReduxValue,
		value: IGridEntriesReduxValue[keyof IGridEntriesReduxValue]
	): void => {
		const payload = {
			[name]: value,
		};

		dispatch(updateGridEntry(payload));
	};

	const handleCreate = () => {
		const payload: IGridEntryCRUD = {
			grid: gridId,
			employees: gridEntry.employees,
			maximum: gridEntry.maximum,
			minimum: gridEntry.minimum,
			optimal: gridEntry.optimal,
			audit_range_minimum: gridEntry.auditRangeMinimum,
			audit_range_maximum: gridEntry.auditRangeMaximum,
		};

		return createGridEntries(payload);
	};

	const editGridEntry = () => {
		if (!gridEntry.id || !defaultGridEntry) {
			return Promise.resolve(true);
		}

		const editedValues = getEditedValues({
			oldData: defaultGridEntry,
			newData: gridEntry,
		});

		const payload: Partial<IGridEntryCRUD> = {
			employees: editedValues?.employees,
			minimum: editedValues?.minimum,
			maximum: editedValues?.maximum,
			optimal: editedValues?.optimal,
			audit_range_minimum: editedValues?.auditRangeMinimum,
			audit_range_maximum: editedValues?.auditRangeMaximum,
		};

		return editGridEntries(gridEntry.id, payload);
	};

	const removeGridEntry = (id: number) => {
		return deleteGridEntries(id);
	};

	const getEditableCells = () => {
		return [
			{
				header: 'Employees',
				value: (
					<Input
						id="employees"
						type="number"
						placeholder="Amount of employees"
						dataCy="grid-entries-employees-input"
						value={gridEntry.employees}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							updateGridEntryState('employees', Number(e.target.value))
						}
					/>
				),
			},
			{
				header: 'Minimum',
				value: (
					<Input
						id="minimum"
						type="minimum"
						placeholder="Minimum"
						dataCy="grid-entries-minimum-input"
						value={gridEntry.minimum}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							updateGridEntryState('minimum', Number(e.target.value))
						}
					/>
				),
			},
			{
				header: 'Maximum',
				value: (
					<Input
						id="maximum"
						type="number"
						placeholder="Maximum"
						dataCy="grid-entries-maximum-input"
						value={gridEntry.maximum}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							updateGridEntryState('maximum', Number(e.target.value))
						}
					/>
				),
			},
			{
				header: 'Optimal',
				value: (
					<Input
						id="optimal"
						type="number"
						placeholder="Optimal"
						dataCy="grid-entries-optimal-input"
						value={gridEntry.optimal}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							updateGridEntryState('optimal', Number(e.target.value))
						}
					/>
				),
			},
			{
				header: 'Minimum (Audit range)',
				value: (
					<Input
						id="audit-range-minimum"
						type="number"
						placeholder="Minimum (Audit range)"
						dataCy="grid-entries-audit-range-minimum-input"
						value={gridEntry.auditRangeMinimum}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							updateGridEntryState('auditRangeMinimum', Number(e.target.value))
						}
					/>
				),
			},
			{
				header: 'Maximum (Audit range)',
				value: (
					<Input
						id="audit-range-maximum"
						type="number"
						placeholder="Maximum (audit range)"
						dataCy="grid-entries-audit-range-maximum-input"
						value={gridEntry.auditRangeMaximum}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							updateGridEntryState('auditRangeMaximum', Number(e.target.value))
						}
					/>
				),
			},
		];
	};

	return (
		<ReactDataWrapper
			title={phrases.TABLE_TITLE}
			dataCy="grid-entries-table"
			columns={getColumns()}
			fetchData={(
				state: IFetchDataProps
			): ReturnType<typeof fetchGridEntries> => fetchGridEntries(id, state)}
			setInitialEditValues={setInitialEditValues}
			filterable
			defaultPageSize={20}
			reduxKey={API_PATH}
			manual
			accessAreasAllowedToEdit={['Global BI Configuration']}
			actionsWidth={80}
			onModalCloseConfirm
			onModalClose={() => dispatch(resetData())}
			compact
			createEntry={handleCreate}
			editEntry={editGridEntry}
			deleteEntry={removeGridEntry}
			editableCells={getEditableCells()}
		/>
	);
};

export default GridEntries;
