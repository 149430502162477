'use strict';

import React from 'react';

import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/administration/partnershipCodes.columns';
import { fetchPartnershipCodes } from './partnershipCodesTable.service';

const reduxKey = '/administration/partnerships';

const PartnershipCodesTable = (): JSX.Element => {
	return (
		<ReactDataWrapper
			title="JoeApp Benefits"
			columns={getColumns()}
			fetchData={fetchPartnershipCodes}
			filterable
			defaultPageSize={10}
			reduxKey={reduxKey}
			manual
		/>
	);
};

export default PartnershipCodesTable;
