import React from 'react';
import PropTypes from 'prop-types';
import phrases from './../reactDataWrapper.phrases';

import { Button } from 'dumb';

function LegacyFilterButton({ onClick, active }) {
	return (
		<Button type={active ? '' : 'inverted'} shadow onClick={onClick}>
			{phrases.LEGACY_FILTER}
		</Button>
	);
}

LegacyFilterButton.propTypes = {
	onClick: PropTypes.func,
	active: PropTypes.bool
};

export default LegacyFilterButton;
