'use strict';

import React, { ChangeEvent, MutableRefObject } from 'react';
import { IFetchDataProps } from 'types/reactDataWrapper';
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/administration/grids.columns';
import {
	API_PATH,
	createGrid,
	deleteGrid,
	editGrid,
	fetchGrids,
} from './grids.service';
import phrases from './grids.phrases';
import { Button, Icon, Input, Tooltip } from 'dumb';
import { push } from 'redux-first-history';
import { useAppDispatch, useAppSelector } from 'appState/storeHooks';
import { resetData, setGrid, updateGrid } from './store/grids.actions';
import { IGridReduxValue } from './types';
import getEditedValues from 'services/utils/getEditedValues';

const Grids = (): JSX.Element => {
	const { grid, defaultGrid } = useAppSelector((state) => ({
		grid: state.grids.data.grid,
		defaultGrid: state.grids.data.defaultGrid,
	}));

	const dispatch = useAppDispatch();

	const goToDetailsPage = (id: number) => () => {
		dispatch(push(`${window.location.pathname}/${id}`));
	};

	const setInitialEditValues = (data: IAdministrationGrid) => {
		const payload: IGridReduxValue = {
			id: data.id,
			name: data.name,
		};

		dispatch(setGrid(payload));
	};

	const updateGridState = (
		name: keyof IGridReduxValue,
		value: IGridReduxValue[keyof IGridReduxValue]
	): void => {
		const payload: Partial<IGridReduxValue> = {
			[name]: value,
		};

		dispatch(updateGrid(payload));
	};

	const handleCreate = () => {
		const payload: IGridReduxValue = {
			name: grid.name,
		};

		return createGrid(payload);
	};

	const editGridEntry = () => {
		if (!grid.id || !defaultGrid) {
			return Promise.resolve(true);
		}

		const editedValues = getEditedValues({
			oldData: defaultGrid,
			newData: grid,
		});

		const payload = {
			name: editedValues?.name,
		};

		return editGrid(grid.id, payload);
	};

	const deleteGridEntry = (id: number) => {
		return deleteGrid(id);
	};

	const getEditableCells = () => {
		return [
			{
				header: 'Name',
				value: (
					<Input
						id="entry-type"
						placeholder="Enter a name"
						dataCy="grids-name-input"
						value={grid.name}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							updateGridState('name', e.target.value)
						}
					/>
				),
			},
		];
	};

	return (
		<ReactDataWrapper
			title={phrases.TABLE_TITLE}
			dataCy="grids-table"
			columns={getColumns()}
			fetchData={(state: IFetchDataProps): ReturnType<typeof fetchGrids> =>
				fetchGrids(state)
			}
			filterable
			defaultPageSize={20}
			reduxKey={API_PATH}
			manual
			accessAreasAllowedToEdit={['Global BI Configuration']}
			onModalCloseConfirm
			onModalClose={() => dispatch(resetData())}
			actionsWidth={30}
			compact
			createEntry={handleCreate}
			editEntry={editGridEntry}
			deleteEntry={deleteGridEntry}
			editableCells={getEditableCells()}
			setInitialEditValues={setInitialEditValues}
			actions={(d: { original: IAdministrationGrid }) => (
				<Tooltip
					text={phrases.DETAILS_TOOLTIP}
					placement="left"
					renderData={(
						ref: MutableRefObject<undefined>,
						onMouseEnter: () => void,
						onMouseLeave: () => void
					) => (
						<Button
							shadow
							type="inverted"
							onClick={goToDetailsPage(d.original.id)}
							size="micro"
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						>
							<Icon name="tune" />
						</Button>
					)}
				/>
			)}
		/>
	);
};

export default Grids;
