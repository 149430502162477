import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import constants from 'services/constants';
import moment from 'moment';

import { Input, InputCollectionSelect, SingleDatePickerInput } from 'dumb';
import { useSelector, useDispatch } from 'react-redux';
import { setOnboardingData } from './createOnboardings.actions.js';

export function CreateOnboarding() {
	const dispatch = useDispatch();

	const handleOnChange = (key, val) => {
		dispatch(setOnboardingData(key, val));
	};

	const onboardingData = useSelector(
		(state) => state.createOnboarding.onboardingData
	);

	const marketFilter = onboardingData?.market?.value
		? `:market.id=='${onboardingData.market.value}'`
		: null;

	const moneyballSubPositionFilter = onboardingData?.moneyballSubPosition?.value
		? `:moneyball_sub_position.id=='${onboardingData.moneyballSubPosition.value}'`
		: null;

	// Clear company and employment type when market changes
	useEffect(() => {
		dispatch(setOnboardingData('company', null));
		dispatch(setOnboardingData('employmentType', null));
	}, [dispatch, onboardingData.market]);

	// Clear salary group when market or moneyball sub positions changes
	useEffect(() => {
		dispatch(setOnboardingData('salaryGroup', null));
	}, [dispatch, onboardingData.market, onboardingData.moneyballSubPosition]);

	return (
		<Grid columns={{ xs: 12 }}>
			<Typography variant="h4" pb={2} align="center">
				Create new Onboarding
			</Typography>

			<Grid item xs={12} pb={1}>
				<InputCollectionSelect
					id="market"
					required
					apiPath="/organization/markets"
					label="select market"
					placeholder="select market"
					name="market"
					value={onboardingData.market}
					handleChange={(key, entry) => handleOnChange(key, entry)}
					optionFormat={(entry) => ({
						value: entry.id,
						label: entry.name,
					})}
				/>
			</Grid>
			<Grid item xs={12} pb={1}>
				<Input
					id="firstname"
					required
					label="Enter First name"
					placeholder="First name"
					type="string"
					value={onboardingData.firstName}
					onChange={(e) => handleOnChange('firstName', e.target.value)}
				/>
			</Grid>
			<Grid item xs={12} pb={1}>
				<Input
					id="lastname"
					label="Enter Last name"
					required
					placeholder="Last name"
					type="string"
					value={onboardingData.lastName}
					onChange={(e) => handleOnChange('lastName', e.target.value)}
				/>
			</Grid>
			<Grid item xs={12} pb={1}>
				<Input
					id="email"
					required
					label="Enter email Address"
					placeholder="Email Address"
					type="string"
					value={onboardingData.emailAddress}
					onChange={(e) => handleOnChange('emailAddress', e.target.value)}
				/>
			</Grid>
			<Grid item xs={12} pb={1}>
				<Input
					id="phonenumber"
					required
					label="Enter Phone Number"
					placeholder="Phone Number"
					type="string"
					value={onboardingData.phoneNumber}
					onChange={(e) => handleOnChange('phoneNumber', e.target.value)}
				/>
			</Grid>
			<Grid item xs={12} pb={1}>
				<SingleDatePickerInput
					id="start_date"
					required
					label="Starting date"
					numberOfMonths={1}
					onChange={(date) => {
						return moment.isMoment(date)
							? handleOnChange(
									'startDate',
									date.utc().format(constants.shortDate)
							  )
							: date;
					}}
					selectedDate={onboardingData.startDate}
					appendToBody
					noClockButton
				/>
			</Grid>

			<Grid item xs={12} pb={1}>
				<InputCollectionSelect
					id="recruiter"
					required
					apiPath="/hr/persons"
					label="Select recruiter"
					placeholder="Select recruiter"
					name="recruiter"
					value={onboardingData.recruiter}
					handleChange={(key, entry) => handleOnChange(key, entry)}
					optionFormat={(entry) => ({
						value: entry.id,
						label: entry.full_name,
					})}
					inputFilterFormat={(input) =>
						`:full_name=like='%${input}%',:id=like='%${input}%'`
					}
				/>
			</Grid>

			<Grid item xs={12} pb={1}>
				<InputCollectionSelect
					id="company"
					apiPath="/administration/companies"
					label="select company"
					placeholder="select company"
					name="company"
					value={onboardingData.company}
					disabled={!marketFilter}
					params={{
						limit: 30,
						filter: marketFilter,
					}}
					required
					key={marketFilter}
					handleChange={(key, entry) => handleOnChange(key, entry)}
					optionFormat={(entry) => ({
						value: entry.id,
						label: entry.name,
					})}
				/>
			</Grid>
			<Grid item xs={12} pb={1}>
				<InputCollectionSelect
					id="employmentType"
					required
					apiPath="/hr/employment_types"
					label="select Employment type"
					placeholder="Employment type"
					name="employmentType"
					value={onboardingData.employmentType}
					disabled={!marketFilter}
					key={marketFilter}
					params={{
						limit: 30,
						filter: marketFilter,
					}}
					handleChange={(key, entry) => handleOnChange(key, entry)}
					optionFormat={({ id, description, type }) => ({
						value: id,
						label: description || type,
					})}
				/>
			</Grid>
			<Grid item xs={12} pb={1}>
				<InputCollectionSelect
					id="moneyballSubPosition"
					required
					apiPath="/hr/moneyball_sub_positions"
					label="select Moneyball sub positions"
					placeholder="Moneyball sub positions"
					name="moneyballSubPosition"
					value={onboardingData.moneyballSubPosition}
					handleChange={(key, entry) => handleOnChange(key, entry)}
					optionFormat={(entry) => ({
						value: entry.id,
						label: entry.name,
					})}
				/>
			</Grid>
			<Grid item xs={12} pb={1}>
				<InputCollectionSelect
					id="salaryGroup"
					required
					apiPath="/salary/salary_groups"
					label="select salary group"
					placeholder="salary group"
					name="salaryGroup"
					disabled={!marketFilter || !moneyballSubPositionFilter}
					key={marketFilter + moneyballSubPositionFilter}
					params={{
						limit: 30,
						filter: `${marketFilter};${moneyballSubPositionFilter}`,
					}}
					moneyballSubPositionFilter
					value={onboardingData.salaryGroup}
					handleChange={(key, entry) => handleOnChange(key, entry)}
					optionFormat={(entry) => ({
						value: entry.id,
						label: entry.name,
					})}
				/>
			</Grid>
		</Grid>
	);
}
