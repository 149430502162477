import { IFetchDataProps } from 'types/reactDataWrapper';
import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './grids.phrases';
import { IGridReduxValue } from './types';

export const API_PATH = '/hr/grids';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw err;
}

export function fetchGrids(state: IFetchDataProps): Promise<IApiRes> {
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(API_PATH, params, null, state.headers)
		.then((res: IApiRes) => res)
		.catch(handleError);
}

export const createGrid = (payload: IGridReduxValue): Promise<IApiRes> => {
	return post(API_PATH, payload)
		.then((response: IApiRes) => {
			setFeedback(phrases.GRID_ADDED, 1);

			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);

			setFeedback(errorMessage, 0);
		});
};

export function editGrid(
	id: number,
	data: Record<string, unknown>
): Promise<IApiRes> {
	return post(`${API_PATH}/${id}`, data)
		.then((res: IApiRes) => {
			setFeedback(phrases.GRID_EDITED, 1);
			return res;
		})
		.catch(handleError);
}

export const deleteGrid = (id: number): Promise<IApiRes> => {
	return remove(`${API_PATH}/${id}`)
		.then((response: IApiRes) => {
			setFeedback(phrases.GRID_DELETED, 1);
			return response;
		})
		.catch(handleError);
};
