'use strict';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Modal from 'modal/modal.component';
// import ButtonLoader from 'buttonLoader';
import { ButtonLoader } from 'dumb';

class ImportCsvModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			file: {},
			fileName: '',
			fileSelected: false,
			importingCsv: false,
			csvImportError: false,
			csvImportSuccess: false,
		};

		this.onFileSelect = this.onFileSelect.bind(this);
	}

	onFileSelect(event) {
		// Get file
		const file = event?.target?.files?.[0];

		if (!file) return;

		// Get filename
		const fileName = file.name;

		this.setState(() => ({ fileName, importingCsv: true }));
		this.props.onChange(file, fileName).then(
			() => {
				this.setState(() => ({ importingCsv: false, csvImportSuccess: true }));
				setTimeout(() => {
					this.setState(
						() => ({ importingCsv: false, csvImportSuccess: false }),
						() => {
							this.props.handleClose();
						}
					);
				}, 3000);
			},
			() => {
				this.setState(() => ({ importingCsv: false, csvImportError: true }));
			}
		);
	}

	render() {
		const { fileName, importingCsv, csvImportError, csvImportSuccess } =
			this.state;

		const { translations, example } = this.props;

		return (
			<div className="import-csv-modal">
				<div className="import-csv-modal__import">
					<input
						type="file"
						accept=".csv"
						id="csvImport"
						onChange={this.onFileSelect}
					/>
					<label
						disabled={importingCsv}
						className="button button--primary"
						htmlFor="csvImport"
					>
						{!importingCsv && translations.PICK_CSV}
						<ButtonLoader loading={importingCsv} theme="light" />
					</label>
				</div>

				<div>
					{fileName && (
						<span className="import-csv-modal__file-name">
							&quot;{fileName}&quot;
						</span>
					)}

					{csvImportError && (
						<span className="import-csv-modal__file-error">
							{translations.ERROR}
						</span>
					)}

					{csvImportSuccess && (
						<span className="import-csv-modal__file-success">
							{translations.SUCCESS}
						</span>
					)}
				</div>

				<h5>{translations.CSVEXAMPLE}</h5>
				{example && <pre>{example.replace(/(.{60})/g, '$1\n')}</pre>}
			</div>
		);
	}
}

ImportCsvModal.propTypes = {
	example: PropTypes.string,
	translations: PropTypes.object.isRequired,
	onChange: PropTypes.func,
	handleClose: PropTypes.func,
};

export default Modal(ImportCsvModal);
