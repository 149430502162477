/* eslint-disable camelcase */
'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import config from 'config';
import { ReactDataWrapper } from 'reactDataWrapper';
import { DateFilterSelector } from 'reactDataWrapper/utilities';
import {
	Button,
	Input,
	InputCollectionSelect,
	FileUpload,
	SingleDatePickerInput,
	Tooltip,
} from 'dumb';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';
import { setShowModalAndData } from 'modules/common/components/sendDocuSignDocument/store/sendDocuSignDocument.actions';

// Tools
import { convertBinaryToBase64 } from 'utils';
import _get from 'lodash/get';
import phrases from './employmentPositionList.phrases';
import constants from 'services/constants';
import moment from 'moment';

import {
	setEmployment,
	setEmploymentPosition,
	updateEmploymentPosition,
	resetEmploymentPosition,
} from './store/employmentPositionList.actions';

// utils
import {
	fetchEmployment,
	fetchEmploymentPositions,
	editEmploymentPositions,
	addEmploymentPositions,
	deleteEmploymentPositions,
	deleteEmploymentDocument,
	uploadEmploymentDocument,
} from './employmentPositionList.service';
import { getUserHasAccessToSensitivePersonInformation } from 'accessControl';

import './employmentPositionList.css';

class EmploymentPositionList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isSelectOpen: false,
			loadingTrList: [],
			loaderEditFile: false,
			showActiveEmployments: false,
		};

		this.today = moment.utc().format(constants.shortDate);
		this.reduxKey = 'hr/employment_positions/detail';

		this.editEntry = this.editEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.getActiveButton = this.getActiveButton.bind(this);
		this.toggleActiveFilter = this.toggleActiveFilter.bind(this);
		this.getExtraFilters = this.getExtraFilters.bind(this);
		this._renderSendDocumentButton = this._renderSendDocumentButton.bind(this);

		this.columns = [
			{
				Header: 'Moneyball Sub pos',
				id: 'moneyball_sub_position.name',
				accessor: (d) => _get(d, 'moneyball_sub_position.name', ''),
				filterPath: ':moneyball_sub_position.name',
			},
			{
				Header: 'Salary group',
				id: 'salary_group',
				accessor: (d) => _get(d, 'salary_group.name', ''),
				filterPath: ':salary_group.name',
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: (d) => _get(d, 'employment_type.type'),
				filterPath: ':employment_type.type',
			},
			{
				Header: 'Type Description',
				id: 'type_description',
				accessor: (d) => _get(d, 'employment_type.description'),
				filterPath: ':employment_type.description',
			},
			// only show the column if person viewing has access to sensitive info
			...(getUserHasAccessToSensitivePersonInformation()
				? [
						{
							Header: 'Base salary',
							id: 'salary_group-salary_components',
							Cell: (d) =>
								d.value ? numeral(parseFloat(d.value)).format('0,0.00') : '',
							accessor: (d) => {
								let values = null;

								const salaryComponent = _get(
									d,
									'salary_group.salary_components'
								);
								if (salaryComponent.length) {
									values = salaryComponent
										.map((x) => {
											if (x?.global_salary_component?.name !== 'Base Pay')
												return null;
											if (
												moment
													.utc()
													.isBetween(x.active.from, x.active.to, null, '[]')
											) {
												return x.amount;
											}
											return null;
										})
										.filter((x) => x);
								}

								return values;
							},
							filterPath: ':type',
							filterable: false,
							sortable: false,
						},
				  ]
				: []),
			{
				Header: 'Contract',
				id: 'contract',
				width: 200,
				filterable: false,
				sortable: false,
				Cell: (d) => {
					const document = _get(d, 'original.document', null);
					const file = _get(d, 'original.document.document', null);

					if (file) {
						return (
							<>
								<FileUpload
									file={file}
									urlDownload
									onClear={() => this._deleteContract(document, d)}
								/>
							</>
						);
					} else {
						return (
							<>
								<FileUpload
									onChange={(file) => this.editUploadImage(file, d)}
									file={file}
									disabled={this.state.loadingTrList.some(
										(x) => x.row === d.viewIndex
									)}
									loading={this.state.loadingTrList.some(
										(x) => x.row === d.viewIndex && x.id === d.column.id
									)}
								/>
							</>
						);
					}
				},
			},
			{
				Header: 'From',
				id: 'period.from',
				accessor: (d) => _get(d, 'period.from', ''),
				filterPath: ':period.from',
				Filter: ({ column }) => (
					<DateFilterSelector
						reduxKey={this.reduxKey}
						columnId={column.id}
						dateIdentifier="from"
					/>
				),
			},
			{
				Header: 'To',
				id: 'period.to',
				accessor: (d) => _get(d, 'period.to', ''),
				filterPath: ':period.to',
				Filter: ({ column }) => (
					<DateFilterSelector
						reduxKey={this.reduxKey}
						columnId={column.id}
						dateIdentifier="to"
					/>
				),
			},
		];
	}

	componentDidMount() {
		const { employmentId, setEmployment } = this.props;

		fetchEmployment({ employmentId }).then((employment) => {
			setEmployment(employment);
		});
	}

	_deleteContract(document, row) {
		this.setState(() => ({
			loadingTrList: [
				...this.state.loadingTrList,
				{ row: row.viewIndex, id: row.column.id },
			],
		}));

		deleteEmploymentDocument(document.id)
			.then((x) => {
				const entry = { ...row.original, document: null };
				// Edit after upload
				store.dispatch(actions.editEntry({ reduxKey: this.reduxKey, entry }));
				this.setState(() => ({
					loadingTrList: this.state.loadingTrList.filter(
						(x) => x.row !== row.viewIndex && x.id !== row.column.id
					),
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingTrList: this.state.loadingTrList.filter(
						(x) => x.row !== row.viewIndex && x.id !== row.column.id
					),
				}));
			});
	}

	deleteEntry(id) {
		return deleteEmploymentPositions(id);
	}

	editEntry(passedId) {
		const { rowEntry, employmentId, personId } = this.props;

		const id = passedId || rowEntry.id;

		const payload = {
			...(rowEntry.employmentType && {
				employment_type: rowEntry.employmentType.value?.id,
			}),
			...((rowEntry.started || rowEntry.ended) && {
				period: {
					...(rowEntry.started && {
						from: moment.utc(rowEntry.started).format(constants.shortDate),
					}),
					to: rowEntry.ended
						? moment.utc(rowEntry.ended).format(constants.shortDate)
						: '9999-12-31',
				},
			}),
		};

		return editEmploymentPositions({ id, payload, employmentId, personId });
	}

	_getSelectedDate(date, name) {
		if (date) return moment.utc(date).format(constants.shortDate);

		return name === 'started' ? this.today : '9999-12-31';
	}

	addEntry() {
		const { rowEntry, employmentId, personId, companyId, employment } =
			this.props;

		const payload = {
			employment: employmentId,
			company: companyId,
			person: personId,
			market: employment?.company?.market?.id,
			moneyball_sub_position: rowEntry.moneyBallSubPosition?.value?.id,
			salary_group: rowEntry.salaryGroup?.value?.id,
			employment_type: rowEntry.employmentType?.value?.id,
			period: {
				from: moment.utc(rowEntry.started).format(constants.shortDate),
				to: rowEntry.ended
					? moment.utc(rowEntry.ended).format(constants.shortDate)
					: '9999-12-31',
			},
		};

		return addEmploymentPositions(payload);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			employmentType: data.employment_type
				? {
						value: data.employment_type,
						label: data.employment_type.type,
				  }
				: null,
			started: data.period.from,
			ended: data.period.to,
			editMode: true,
		};

		this.props.setEmploymentPosition(payload);
	}

	getEditableCells() {
		const { rowEntry, employment } = this.props;

		const moneyBallPositionExists = rowEntry.moneyBallSubPosition?.value;

		return [
			...(rowEntry.editMode
				? []
				: [
						{
							header: 'Moneyball Sub Position',
							value: (
								<InputCollectionSelect
									id="moneyballsub"
									required
									placeholder="Select moneyball..."
									value={rowEntry.moneyBallSubPosition}
									handleChange={(key, value) =>
										this.editStoreEntry(value, 'moneyBallSubPosition')
									}
									clearable
									cache
									apiPath="/hr/moneyball_sub_positions"
									params={{
										limit: 30,
									}}
									optionFormat={(entry) => ({
										value: entry,
										label: entry.name,
									})}
									inputFilterFormat={(input) => `:name=like='%${input}%'`}
									tableTitle={phrases.MONEYBALL_SUB_POSITION_TABLE_TITLE}
									tableReduxKey="employmentPositonsList-/hr/moneyball_sub_positions"
									tableColumns={[
										{
											Header: 'Name',
											id: 'name',
											accessor: 'name',
											filterPath: ':name',
										},
										{
											Header: 'Description',
											id: 'description',
											accessor: 'description',
											filterPath: ':description',
										},
										{
											Header: 'Productivity',
											id: 'productivity',
											accessor: 'productivity',
											filterPath: ':productivity',
										},
										{
											Header: 'Badge',
											id: 'badge',
											accessor: 'badge',
											filterPath: ':badge',
										},
										{
											Header: 'Team position category',
											id: 'teamPositionCategory',
											accessor: 'team_position_category',
											filterPath: ':team_position_category',
										},
										{
											Header: 'Role',
											id: 'role',
											accessor: 'role.name',
											filterPath: ':role.name',
										},
										{
											Header: 'Sort order',
											id: 'sortOrder',
											accessor: 'sort_order',
											filterPath: ':sort_order',
										},
										{
											Header: 'Can train',
											id: 'canTrain',
											accessor: 'can_train',
											filterPath: ':can_train',
											Cell: (d) => {
												return (
													<Input
														id={`${d.index}-canTrain`}
														disabled
														type="checkbox"
														checked={!!d.value}
													/>
												);
											},
											filterable: false,
										},
										{
											Header: 'Active from',
											id: 'activeFrom',
											accessor: (d) => _get(d, 'active.from', null),
											filterPath: ':active.from',
											Filter: ({ column }) => (
												<DateFilterSelector
													reduxKey="employmentPositonsList-/hr/moneyball_sub_positions"
													columnId={column.id}
													dateIdentifier="from"
													zIndex={600}
												/>
											),
										},
										{
											Header: 'Active to',
											id: 'activeTo',
											accessor: (d) => _get(d, 'active.to', null),
											filterPath: ':active.to',
											Filter: ({ column }) => (
												<DateFilterSelector
													reduxKey="employmentPositonsList-/hr/moneyball_sub_positions"
													columnId={column.id}
													dateIdentifier="to"
													zIndex={600}
												/>
											),
										},
									]}
								/>
							),
						},
						{
							header: 'Salary group',
							value: (
								<InputCollectionSelect
									id="salary_group"
									required
									className="employment-details-positions__input"
									placeholder="select salary group"
									value={rowEntry.salaryGroup}
									handleChange={(key, value) =>
										this.editStoreEntry(value, 'salaryGroup')
									}
									cache
									disabled={!moneyBallPositionExists}
									apiPath="/salary/salary_groups"
									params={{
										limit: 50,
										filter: `:moneyball_sub_position=='${rowEntry.moneyBallSubPosition?.value?.id}';:market=='${employment?.company?.market?.id}'`,
									}}
									optionFormat={(entry) => ({
										value: entry,
										label: entry.name,
									})}
									forceRefresh
									inputFilterFormat={(input) => `:name=like='%${input}%'`}
								/>
							),
						},
				  ]),

			{
				header: 'Type',
				value: (
					<InputCollectionSelect
						id="employment_type"
						required
						placeholder="Select employment type"
						value={rowEntry.employmentType}
						handleChange={(key, value) => {
							this.editStoreEntry(value, 'employmentType');
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.description || entry.type,
						})}
						apiPath="/hr/employment_types"
						disabled={rowEntry.editMode ? false : !rowEntry.salaryGroup}
						params={{
							filter: rowEntry.editMode
								? `:market.companies.employments.employment_positions.id=='${rowEntry.id}'`
								: `:market.id=='${employment?.company?.market?.id}'`,
						}}
						forceRefresh
					/>
				),
			},

			{
				header: 'Started',
				value: (
					<SingleDatePickerInput
						id="started"
						onChange={(event) => this.editStoreEntry(event, 'started')}
						selectedDate={this._getSelectedDate(rowEntry.started, 'started')}
						noClockButton
						clearable
					/>
				),
			},
			{
				header: 'Ended',
				value: (
					<SingleDatePickerInput
						id="ended"
						onChange={(event) => this.editStoreEntry(event, 'ended')}
						selectedDate={this._getSelectedDate(rowEntry.ended, 'ended')}
						noClockButton
						clearable
					/>
				),
			},
		];
	}

	editUploadImage(file, data) {
		const id = _get(data, 'original.employment.id', false);

		this.setState(() => ({
			loadingTrList: [
				...this.state.loadingTrList,
				{ row: data.viewIndex, id: data.column.id },
			],
		}));

		convertBinaryToBase64(file)
			.then((image64) => {
				let promise = null;

				if (id) {
					// Upload file
					uploadEmploymentDocument({
						employment: id,
						type: 'Contract',
						employment_position: _get(data, 'original.id', null),
						document: {
							filename: file.name,
							data: image64,
						},
					}).then((x) => {
						const entry = {
							..._get(data, 'original', {}),
							document: x.data[0],
						};

						// Edit after upload
						promise = store.dispatch(
							actions.editEntry({ reduxKey: this.reduxKey, entry })
						);
					});
				} else {
					console.info('There is missing ID of row!');
				}

				promise.finally(() => {
					this.setState(() => ({
						loadingTrList: this.state.loadingTrList.filter(
							(x) => x.row !== data.viewIndex && x.id !== data.column.id
						),
					}));
				});
			})
			.catch(() => {
				this.setState(() => ({
					loadingTrList: this.state.loadingTrList.filter(
						(x) => x.row !== data.viewIndex && x.id !== data.column.id
					),
				}));
			});
	}

	editStoreEntry(e, type) {
		const value = e?.target?.value ?? e;

		const payload = {
			[type]: value,
		};

		this.props.updateEmploymentPosition(payload);
	}

	fetchData(state) {
		const { employmentId, personId } = this.props;
		return fetchEmploymentPositions(state, employmentId, personId);
	}

	toggleActiveFilter() {
		this.setState((prevState) => ({
			showActiveEmployments: !prevState.showActiveEmployments,
		}));
	}

	getActiveButton() {
		return (
			<Button
				size="tiny"
				type={this.state.showActiveEmployments ? '' : 'inverted'}
				shadow
				onClick={this.toggleActiveFilter}
			>
				{phrases.ACTIVE}
			</Button>
		);
	}

	getExtraFilters() {
		return this.state.showActiveEmployments
			? `:period.from=le='${moment
					.utc()
					.format(constants.shortDate)}';:period.to=ge='${moment
					.utc()
					.format(constants.shortDate)}'`
			: null;
	}

	_renderSendDocumentButton(e) {
		const employmentPositionId = e.original?.id;

		if (!config.featureFlags?.enableDocuSign) return;

		const pendingEnvelopes = e.original?.pending_envelopes
			?.map((entry) => `${entry.type} (${entry.status})`)
			.join(', ');

		return (
			<Tooltip
				text={pendingEnvelopes}
				visible={!!pendingEnvelopes}
				renderData={(ref, onMouseEnter, onMouseLeave) => (
					<Button
						shadow
						onClick={() => setShowModalAndData({ employmentPositionId })}
						size="small"
						refProp={ref}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					>
						{phrases.SEND_DOCUMENT}
					</Button>
				)}
			/>
		);
	}

	render() {
		return (
			<div className="employment-details-positions">
				<ReactDataWrapper
					title={phrases.EMPLOYMENT_POSITIONS}
					columns={this.columns}
					totalEntries={this.state.totalEntries}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={5}
					accessAreasAllowedToEdit={['Employment Admin', 'Person Admin']}
					reduxKey={this.reduxKey}
					manual
					defaultSorted={[
						{
							id: 'period.from',
							desc: false,
						},
					]}
					editEntry={this.editEntry}
					editableCells={this.getEditableCells()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetEmploymentPosition}
					deleteEntry={this.deleteEntry}
					createEntry={this.addEntry}
					customAreaComponents={this.getActiveButton()}
					extraFilters={this.getExtraFilters()}
					actions={this._renderSendDocumentButton}
					actionsWidth={150}
				/>
			</div>
		);
	}
}

EmploymentPositionList.propTypes = {
	employmentId: PropTypes.number,
	rowEntry: PropTypes.object,
	setEmployment: PropTypes.func,
	setEmploymentPosition: PropTypes.func,
	updateEmploymentPosition: PropTypes.func,
	resetEmploymentPosition: PropTypes.func,
	employment: PropTypes.object,
	companyId: PropTypes.number,
	personId: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setEmployment,
			setEmploymentPosition,
			updateEmploymentPosition,
			resetEmploymentPosition,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		rowEntry: store.employmentPositionList.data.rowEntry,
		employment: store.employmentPositionList.employment,
	};
};

export default connectWithStore(
	EmploymentPositionList,
	mapStateToProps,
	mapDispatchToProps
);
