import React, { useCallback, useMemo } from 'react';

// interfaces
import { ITileInGroup, IImageAsset } from '../../../types';

// components
import ItemRow from './components/itemRow/itemRow';
import ItemGrid from './components/itemGrid/itemGrid';

// utils
import _get from 'lodash/get';

// enums/ phrases
import enums from './posLayout.enums';

type Props = {
	tilesInGroup: ITileInGroup[];
	subType: string;
	imageAssets: IImageAsset[];
	moveCard: (dragIndex: number, hoverIndex: number) => void;
	onDrop: () => void;
	toggleDeleteModal: (id: number) => void;
	openTileModalWrapper: (
		tile: Record<string, unknown>,
		mode?: string,
		position?: undefined
	) => void;
	handleProductVariantOnDrop: ({
		itemProps,
		position,
	}: {
		itemProps: Record<string, unknown>;
		position: number;
	}) => void;
};

const PosLayoutContentWrapper = ({
	tilesInGroup,
	subType,
	imageAssets,
	moveCard,
	onDrop,
	toggleDeleteModal,
	openTileModalWrapper,
	handleProductVariantOnDrop,
}: Props): JSX.Element => {
	const findImageAsset = useCallback(
		(entry: ITileInGroup) => {
			const imageAsset =
				subType === 'Grid'
					? imageAssets.find(
						(asset) =>
							asset.asset_collection.id ===
									_get(entry, 'product.asset_collection.id', null) &&
								asset.type === 'Product Tile'
					  )
					: imageAssets.find(
						(asset) =>
							asset.asset_collection.id ===
									_get(entry, 'product.asset_collection.id', null) &&
								asset.type === 'Row Layout Tile'
					  );

			return imageAsset;
		},
		[imageAssets, subType]
	);

	const sortedTilesInGroup = useMemo(() => {
		return tilesInGroup.sort((a, b) => a.position - b.position);
	}, [tilesInGroup]);

	return (
		<>
			{sortedTilesInGroup.map((entry, index) => {
				const imageAsset = findImageAsset(entry);

				return subType === 'Grid' ? (
					<ItemGrid
						index={index}
						id={entry.id}
						key={entry.id}
						name={entry.name}
						description={entry.description}
						backgroundColor={entry.background_color}
						textColor={entry.title_color}
						moveCard={(dragIndex: number, hoverIndex: number) =>
							moveCard(dragIndex, hoverIndex)
						}
						onDrop={onDrop}
						imageAsset={imageAsset}
						removeTile={toggleDeleteModal}
						openTileModal={() => openTileModalWrapper(entry as any, enums.EDIT)}
					/>
				) : (
					<ItemRow
						id={entry.id}
						index={index}
						key={entry.id}
						imageAsset={imageAsset}
						tile={entry}
						removeTile={toggleDeleteModal}
						editTile={(tile: any) => openTileModalWrapper(tile, enums.EDIT)}
						moveCard={(dragIndex: number, hoverIndex: number) =>
							moveCard(dragIndex, hoverIndex)
						}
						onDrop={onDrop}
						handleProductVariantOnDrop={handleProductVariantOnDrop}
					/>
				);
			})}
		</>
	);
};

export default PosLayoutContentWrapper;