import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ContentLoader from 'react-content-loader';
import { isMobile } from 'detectMobile.vanilla';
import classnames from 'classnames';

// Components
import { Card, InputSmart, InputSmartSelect } from 'dumb';
import InputPhoneWrapper from './contactsCard/components/inputPhoneWrapper/inputPhoneWrapper';

// Utils
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

// Phrases/ moment
import phrases from './../juicerDetails.phrases';
import moment from 'moment';

// Styles
import './personDetails.css';

class PersonDetailsContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			disabled: true,
			focus: false,
		};

		this._onSelectChange = this._onSelectChange.bind(this);
		this._postPersonData = this._postPersonData.bind(this);
		this.toggleInputs = this.toggleInputs.bind(this);
		this._postPersonalInformationData =
			this._postPersonalInformationData.bind(this);
		this._getGenderValue = this._getGenderValue.bind(this);
		this._changePhoneNumberInput = this._changePhoneNumberInput.bind(this);
		this._onContactInfoChange = this._onContactInfoChange.bind(this);
	}

	_onToggleExpand() {
		this.setState((prevState) => ({ open: !prevState.open }));
	}

	_onSelectChange(target, event, postMarket = false) {
		const { personId, postCustomData, postPersonData } = this.props;

		if (event) {
			const payload = {
				uri: 'persons',
				id: personId,
				value: event.value,
				key: target,
				payload: {
					[target]: event.value,
				},
			};

			if (postMarket) return postPersonData(payload);
			return postCustomData(payload);
		}
	}

	_postPersonalInformationData(params) {
		const { personId, postCustomData } = this.props;

		return postCustomData(
			{
				...params,
				payload: {
					...(!params.id && { person: personId }),
					[params.key]: params.value,
				},
			},
			params.uri
		);
	}

	_postPersonData(params) {
		const { postPersonData } = this.props;

		if (params.key === 'first_name' || params.key === 'last_name') {
			if (!params.value) return Promise.resolve(true);
		}

		return postPersonData(params);
	}

	_getLoaderLines() {
		if (isMobile())
			return (
				<>
					<rect x="0" y="0" rx="3" ry="3" width={200} height="37" />
					<rect x="0" y="50" rx="3" ry="3" width={350} height="37" />
					<rect x="0" y="100" rx="3" ry="3" width={350} height="37" />
					<rect x="0" y="150" rx="3" ry="3" width={350} height="37" />
					<rect x="0" y="200" rx="3" ry="3" width={350} height="37" />
					<rect x="0" y="250" rx="3" ry="3" width={350} height="37" />
					<rect x="0" y="300" rx="3" ry="3" width={350} height="37" />
					<rect x="0" y="350" rx="3" ry="3" width={350} height="37" />
				</>
			);

		return (
			<>
				<rect x="0" y="0" rx="3" ry="3" width={400} height="22" />
				<rect x="0" y="29" rx="3" ry="3" width={400} height="22" />
				<rect x="0" y="58" rx="3" ry="3" width={400} height="22" />
				<rect x="50" y="87" rx="3" ry="3" width={300} height="22" />
			</>
		);
	}

	/**
	 * @function toggleInputsActive
	 * @description sets the state of the component as being used or unsed
	 * @description therefor toggling all inputs in the group as enabled or disabled
	 * @param active {boolean}
	 */
	toggleInputs({ disabled }) {
		// check data type
		if (typeof disabled !== 'boolean') return;

		this.setState(() => ({ disabled }));
	}

	_getGenderValue() {
		const { personData } = this.props;

		return personData.gender
			? {
					value: personData.gender,
					label: personData.gender,
			  }
			: null;
	}

	_onContactInfoChange(handle, params) {
		const { postCustomData, personData } = this.props;

		params.payload = {
			...(!params.id && { person: personData.id }),
			[params.key]: params.value,
		};

		return postCustomData(params, handle);
	}

	/**
	 * @function _changePhoneNumberInput
	 * @param {Number} phoneNumber
	 * @description wrapper for
	 */
	_changePhoneNumberInput(phoneNumber) {
		const { personData } = this.props;

		return this._onContactInfoChange('person_phone_number', {
			uri: 'person_phone_numbers',
			id: _get(personData, 'person_phone_number.id', null),
			value: phoneNumber,
			key: 'phone_number',
		});
	}

	render() {
		const {
			personId,
			personData,
			loading,
			userHasAccessToSensitivePersonInfo,
		} = this.props;

		const cx = classnames('', {
			'person-details': !isMobile(),
		});

		const cardSection = classnames('', {
			// used to get rid of margin when mobile
			'person-details__card-section': !isMobile(),
			'person-details__card-section--extra-width':
				!isMobile() && !userHasAccessToSensitivePersonInfo,
		});

		const birthDate = _get(personData, 'birth_date', '');
		const age = moment().diff(birthDate, 'years', false);

		return (
			<div className={cx}>
				<Card className="juicer-details__small">
					{loading && (
						<ContentLoader
							height={isMobile() ? 340 : 120}
							speed={2}
							primaryColor="#f3f3f3"
							secondaryColor="#ecebeb"
						>
							{this._getLoaderLines()}
						</ContentLoader>
					)}

					{!loading && !_isEmpty(personData) && (
						<div className="person-details__main-section">
							<h3 className="card-name">
								{userHasAccessToSensitivePersonInfo
									? phrases.PERSONAL_DETAILS_CARD
									: phrases.CONTACT_DETAILS_CARD}
							</h3>
							<div className={cardSection}>
								<InputSmart
									id="first_name"
									label="First name"
									customParams={(value) => ({
										id: personId,
										value,
										uri: 'persons',
										key: 'first_name',
									})}
									placeholder="Enter first name..."
									onChange={this._postPersonData}
									value={_get(personData, 'first_name', '')}
									tabIndex={0}
									toggleInputs={this.toggleInputs}
									readOnly={this.state.disabled}
								/>
								<InputSmart
									id="_name"
									label="Middle name"
									customParams={(value) => ({
										id: personId,
										value,
										uri: 'persons',
										key: 'middle_name',
									})}
									placeholder="Enter middle name..."
									onChange={this._postPersonData}
									value={_get(personData, 'middle_name', '')}
									tabIndex={0}
									toggleInputs={this.toggleInputs}
									readOnly={this.state.disabled}
								/>
								<InputSmart
									label="Last name"
									customParams={(value) => ({
										id: personId,
										value,
										uri: 'persons',
										key: 'last_name',
									})}
									placeholder="Enter last name..."
									onChange={this._postPersonData}
									value={_get(personData, 'last_name', '')}
									tabIndex={0}
									toggleInputs={this.toggleInputs}
									readOnly={this.state.disabled}
								/>

								{/* show if user doesn't have access to sensitive info */}
								{/* this is a duplicate of the same component found in client/modules/structure/hr/juicers/components/juicerDetails/components/contactsCard/contactsCard.component.js */}
								{!userHasAccessToSensitivePersonInfo && (
									<InputSmart
										id="email"
										type="email"
										placeholder="email@joejuice.com"
										label="Primary Email"
										customParams={(value) => ({
											uri: 'person_email_addresses',
											id: _get(personData, 'person_email_address.id', null),
											value,
											key: 'email_address',
										})}
										onChange={(params) =>
											this._onContactInfoChange('person_email_address', params)
										}
										value={_get(
											personData,
											'person_email_address.email_address',
											''
										)}
										tabIndex={0}
										toggleInputs={this.toggleInputs}
										readOnly={this.state.disabled}
									/>
								)}

								{/* show if user has access to sensitive info */}
								{userHasAccessToSensitivePersonInfo && (
									<>
										<div className="person-details__main-section__birth-day">
											<InputSmart
												type="date"
												label="Birth day"
												placeholder="[yyyy-mm-dd]"
												customParams={(value) => ({
													id: personId,
													value,
													uri: 'persons',
													key: 'birth_date',
												})}
												onChange={this._postPersonData}
												value={birthDate}
												tabIndex={0}
												toggleInputs={this.toggleInputs}
												readOnly={this.state.disabled}
											/>

											{age && age > 0 ? (
												<span className="person-details__main-section__birth-day__age">
													({age})
												</span>
											) : null}
										</div>
										<InputSmartSelect
											label="Nationality"
											value={
												personData.nationality && {
													value: personData.nationality.id,
													label: personData.nationality.name,
												}
											}
											name="nationality"
											onChange={this._onSelectChange}
											placeholder="Nationality"
											apiPath="/administration/nationalities"
											params={{
												limit: 300,
											}}
											tabIndex={0}
											toggleInputs={this.toggleInputs}
										/>
										<InputSmart
											id="_person_personal_information"
											label="Social Security Number"
											customParams={(value) => ({
												id: _get(
													personData,
													'person_personal_information.id',
													''
												),
												value,
												uri: 'person_personal_information',
												key: 'social_security_number',
											})}
											placeholder="Social Security Number"
											onChange={this._postPersonalInformationData}
											value={_get(
												personData,
												'person_personal_information.social_security_number',
												''
											)}
											key={_get(
												personData,
												'person_personal_information.social_security_number',
												''
											)}
											tabIndex={0}
											toggleInputs={this.toggleInputs}
											readOnly={this.state.disabled}
										/>
										<InputSmartSelect
											label="Gender"
											name="gender"
											onChange={this._onSelectChange}
											placeholder="Select gender..."
											options={[
												{ value: 'Male', label: 'Male' },
												{ value: 'Female', label: 'Female' },
												{ value: 'Other', label: 'Other' },
											]}
											value={this._getGenderValue()}
											tabIndex={0}
											toggleInputs={this.toggleInputs}
										/>
									</>
								)}

								<InputSmartSelect
									label="Active Market"
									value={
										personData.market && {
											value: personData.market.id,
											label: personData.market.name,
										}
									}
									name="market"
									onChange={(target, event) =>
										this._onSelectChange(target, event, true)
									}
									onSelectResetsInput
									placeholder="[empty]"
									apiPath="/administration/markets"
									params={{
										limit: 300,
									}}
									tabIndex={0}
									toggleInputs={this.toggleInputs}
								/>

								{/* this is a duplicate of the same component found in client/modules/structure/hr/juicers/components/juicerDetails/components/contactsCard/contactsCard.component.js */}
								{!userHasAccessToSensitivePersonInfo && (
									<div className="j-input-smart">
										<InputPhoneWrapper
											phoneNumber={_get(
												personData,
												'person_phone_number.phone_number',
												''
											)}
											onChange={this._changePhoneNumberInput}
											readOnly={this.state.disabled}
											toggleInputs={this.toggleInputs}
										/>
									</div>
								)}

								<InputSmart
									id="recruiter"
									placeholder="N/A"
									label="Recruiter"
									value={_get(personData, 'onboardings[0].recruiter.name', '')}
									readOnly
									disabled
								/>

								<InputSmart
									id="recruitment-channel"
									placeholder="N/A"
									label="Recruitment channel"
									value={_get(
										personData,
										'onboardings[0].recruitment_channel',
										''
									)}
									readOnly
									disabled
								/>
							</div>
						</div>
					)}
				</Card>
			</div>
		);
	}
}

PersonDetailsContainer.propTypes = {
	personId: PropTypes.number,
	personData: PropTypes.object,
	loading: PropTypes.bool,
	postPersonData: PropTypes.func,
	postCustomData: PropTypes.func,
	userHasAccessToSensitivePersonInfo: PropTypes.bool,
};

export default PersonDetailsContainer;
