import { IFetchDataProps } from 'types/reactDataWrapper';
import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './modules.phrases';

function handleError(err: Error): Error {
    const error = formatErrorMessage(err);
    setFeedback(error, 0);

    throw error;
}

export function fetchModules(state: IFetchDataProps): Promise<IApiRes> {
    const params = {
        limit: state.limit,
        sort: state.sort,
        offset: state.offset,
        filter: state.filter,
    };

    return get(
        '/training/modules',
        params,
        null,
        state.headers
    )
        .then((res: IApiRes) => res)
        .catch(handleError);
}

export function addModules(data: Record<string, unknown>): Promise<IApiRes> {
    return post(`/training/modules`, data)
        .then((res: IApiRes) => {
            setFeedback(phrases.MODULE_ADDED, 1);
            return res;
        })
        .catch(handleError);
}

export function editModules(id: number, data: Record<string, unknown>): Promise<IApiRes> {
    return post(`/training/modules/${id}`, data)
        .then((res: IApiRes) => {
            setFeedback(phrases.MODULE_EDITED, 1);
            return res;
        })
        .catch(handleError);
}

export function deleteModules(id: number): Promise<IApiRes> {
    return remove(`/training/modules/${id}`)
        .then((res: IApiRes) => {
            setFeedback(phrases.MODULE_DELETED, 1);
            return res;
        })
        .catch(handleError);
}
