import {
	UPDATE_INGREDIENT_ALLERGEN,
	SET_INGREDIENT_ALLERGEN,
	RESET_INGREDIENT_ALLERGEN,
} from './productVariantIngredientAllergens.actions';

const defaultState = {
	ui: {},
	data: {
		defaultIngredientAllergen: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_INGREDIENT_ALLERGEN: {
			return {
				...state,
				data: {
					...state.data,
					defaultIngredientAllergen: payload,
				},
			};
		}

		case UPDATE_INGREDIENT_ALLERGEN: {
			return {
				...state,
				data: {
					...state.data,
					defaultIngredientAllergen: {
						...state.data.defaultIngredientAllergen,
						ingredient_allergen_degree_of:
							payload.ingredient_allergen_degree_of,
						ingredient: payload.ingredient,
						allergen: payload.allergen,
						market: payload.market,
					},
				},
			};
		}

		case RESET_INGREDIENT_ALLERGEN: {
			return {
				...state,
				data: {
					...state.data,
					defaultIngredientAllergen: {},
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
