import * as Sentry from '@sentry/browser';
// Actions
import { showPageLoader } from '../smart/pageLoader/store/pageLoader.actions';
import { dispatchToggleUserModal } from '../smart/missingMoneyballNotificationModal/store/missingMoneyballNotificationModal.actions';
import TYPE_ENUMS from '../smart/missingMoneyballNotificationModal/missingMoneyballNotificationModal.enum';
import config from 'config';

// Services
import { UserService } from 'user';
import { get } from 'api.vanilla.service';
import _get from 'lodash/get';
import _flatten from 'lodash/flatten';
import _isEmpty from 'lodash/isEmpty';
import store from 'appState/store';
import { setUser, setTenants, setActiveTenant } from 'user/actions';

import LogRocket from 'logrocket';

const __endpoints = require('endpoints.service');

// auth0-js setup
const webAuth = {}; // new auth0.WebAuth(auth0Config);

class AuthService {
	constructor() {
		this.accessToken = null;

		// Fifteen minute renewal var
		this.tokenRenewalTimeout = null;

		// Retrieve access roles after authorisation
		this.accessRolesEndpoint = (userId) =>
			__endpoints.collection.profile.users.update.path +
			userId +
			'/access_roles';
		this.userTenantsEndpoint = (userId) => `/profile/users/${userId}/tenants`;
	}

	/**
	 * @getUserAccessRoles
	 *
	 * @memberof AuthService
	 */
	getUserAccessRoles(userId) {
		return get(this.accessRolesEndpoint(userId))
			.then((response) => {
				// Check if user is set as a tester . If yes, force redirect
				const isStageUser = response.data[0]?.user?.is_stage_user;
				if (isStageUser && window.location.hostname === 'wp2.joejuice.com') {
					window.location.replace('http://wp2-stage.joejuice.com');

					return;
				}

				// Extract userroles from user and remove duplicates
				const userRoles = [
					...new Set([
						...response.data[0].custom_roles.map((entry) => entry.role),
						...response.data[0].responsibility_roles.map((entry) => entry.role),
						...response.data[0].moneyball_roles.map((entry) => entry.role),
					]),
				];

				const areas = _flatten([
					...new Set([
						...response.data[0].custom_roles.map((entry) =>
							entry.areas.map((area) => area)
						),
						...response.data[0].responsibility_roles.map((entry) =>
							entry.areas.map((area) => area)
						),
						...response.data[0].moneyball_roles.map((entry) =>
							entry.areas.map((area) => area)
						),
					]),
				]);

				const user = {
					userRoles,
					user: response.data[0].user,
					areas,
					responsibilities: {
						moneyballRoles: response.data[0].moneyball_roles,
						responsibilityRoles: response.data[0].responsibility_roles,
						customRoles: response.data[0].custom_roles,
					},
				};

				try {
					if (
						_isEmpty(user.responsibilities.moneyballRoles) &&
						_isEmpty(user.responsibilities.responsibilities) &&
						_isEmpty(user.responsibilities.customRoles)
					)
						this.dispatchToggleUserModalWrapper(TYPE_ENUMS.OTHER);

					if (!user.user.person?.id) {
						dispatchToggleUserModal(TYPE_ENUMS.NO_USER_REFERENCE);
					}

					if (_isEmpty(user, 'user.person')) {
						dispatchToggleUserModal(TYPE_ENUMS.NO_USER_REFERENCE);
					}
				} catch (e) {
					console.log('NotificationModal issue:', e);
				}

				const personId = _get(user, 'user.user.person.id', null);
				if (personId) {
					Sentry.setUser({
						email: user?.user?.email,
						id: user?.user?.username,
					});
					LogRocket.identify(personId);
				}

				// Set the currect user and change state afterwards
				store.dispatch(setUser(user));

				// User is logged in. Set context of Sentry
				Sentry.setUser({ email: user.email, id: user.username });
				const logrocketId = user?.user?.person
					? user.user.person.id
					: user.user.username;
				LogRocket.identify(logrocketId);
				// notify news wrapper that the user is set so it can start making requests

				// Hide page laoder
				showPageLoader(false);
				document.body.classList.add('logged');
			})
			.catch((e) => {
				console.info('', e);
			});
	}

	getUserTenants(userId) {
		return get(this.userTenantsEndpoint(userId)).then(({ data }) => {
			// get currently active tenant - if set (whole userData redux store is persisted)
			const state = store.getState();
			const activeTenant = state.userData?.activeTenant ?? null;

			store.dispatch(setTenants(data));

			if (activeTenant === null) store.dispatch(setActiveTenant(data[0]));
		});
	}

	/**
	 * @function dispatchToggleUserModalWrapper
	 * @param {String} type - indicates what modal text to show
	 * @description wrapper for showing missing moneyball positions modal
	 * It triggers a tracking function for google analytics
	 */
	dispatchToggleUserModalWrapper(type) {
		dispatchToggleUserModal(type);
	}

	/**
	 * @function login
	 * @memberOf AuthService
	 * @description Redirect to auythorize endpoint to login to the app.
	 */
	login() {
		webAuth.authorize();
	}

	/**
	 * @function logout
	 * @memberOf AuthService
	 * @description Logs user out, clears relevant tokens and returns to main
	 */
	logout() {
		// Show pageLoader
		showPageLoader(true);

		// Clear timeout
		clearTimeout(this.tokenRenewalTimeout);

		// Clear all access tokens
		UserService.clearUser();

		webAuth.logout({
			returnTo: window.location.origin,
			// not sure if this is needed as I don't know where the logout function comes from
			// if it's from the auth0 hook, returnTo now needs to live under logoutParams
			logoutParams: {
				returnTo: window.location.origin,
			},
		});

		try {
			// ! caches is not defined - this throws an error on logout
			// If caches exist, clear them on logout
			if (caches) {
				caches.keys().then((cacheNames) => {
					cacheNames.forEach((cacheName) => {
						if (cacheName.includes('runtime')) caches.delete(cacheName);
					});
				});
			}
		} catch (error) {
			console.info(error);
		}
	}

	/**
	 * @function getUserIdFromUserObject
	 * @descriptipn Gets user id from user object fetched from useAuth auth0 hook
	 */
	getUserIdFromUserObject(user) {
		return config.api.host === 'localhost' ? 1 : user?.sub.split('|')[1];
	}
}

export default new AuthService();
