'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { store, connectWithStore } from 'appState';

import TerminateEmployee from './terminateEmployee.component';
import { Button } from 'dumb';
import { convertBinaryToBase64 } from 'utils';
import constants from 'services/constants';
import _get from 'lodash/get';

// redux
import {
	setTerminationDate,
	setTerminationReason,
	setAssignedShifts,
	setTerminationReasons,
	resetState,
	setEmploymentDocument,
	toggleModalVisible,
	resetTerminateThroughTenantTransferData,
} from './store/terminateEmployee.actions';
// action from rdw to update the employment after termination
import { setListData } from 'reactDataWrapper/reactDataWrapper.actions';
import { setEmploymentTerminated } from './../employmentsList/components/tenantTransferModal/store/tenantTransferModal.actions';

import {
	fetchAssignedShifts,
	postEmployeeTermination,
	fetchTerminationReasons,
} from './store/terminateEmployee.service';

import './terminateEmployee.css';

class TerminateEmployeeContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fetchingShifts: false,
			postingTermination: false,
			uploadingTerminationDocument: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.fetchAssignedShifts = this.fetchAssignedShifts.bind(this);
		this.getTerminationReasons = this.getTerminationReasons.bind(this);
		this.handleFileUpload = this.handleFileUpload.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
	}

	fetchAssignedShifts() {
		const { setAssignedShifts, personId } = this.props;
		this.setState(() => ({ fetchingShifts: true }));

		fetchAssignedShifts(personId)
			.then((shifts) => {
				this.setState(() => ({ fetchingShifts: false }));

				setAssignedShifts(shifts.data);
			})
			.catch(() => this.setState(() => ({ fetchingShifts: false })));
	}

	getTerminationReasons() {
		const { setTerminationReasons } = this.props;

		fetchTerminationReasons().then((reasons) => {
			setTerminationReasons(sortTerminationReasons(reasons.data));
		});
	}

	/**
	 * @function handleSubmit
	 * @param {Number} employmentId - id of the employment
	 * @description terminates the employee and uploads the document if uploaded
	 */
	handleSubmit(employmentId) {
		const {
			selectedDate,
			selectedReason,
			resignationDocument,
			otherDocument,
			setListData,
			employmentsList,
			toggleModalVisible,
			setEmploymentTerminated,
			defaultEmployment,
		} = this.props;

		this.setState(() => ({ postingTermination: true }));

		postEmployeeTermination({
			employmentId,
			selectedReason,
			resignationDocument,
			otherDocument,
			selectedDate: selectedDate.format(constants.shortDate),
		})
			.then((res) => {
				this.setState(() => ({ postingTermination: false }));

				// remove employment document from store if present
				if (resignationDocument || otherDocument) store.dispatch(resetState());

				// update employment data in rdw
				setListData({
					reduxKey: 'hr/employments',
					listData: employmentsList.map((entry) => {
						if (entry.id === employmentId) {
							return {
								...entry,
								...(_get(res, 'data[0].end_date', false) && {
									ended: res.data[0].end_date,
								}),
								...(_get(
									res,
									'data[0].termination_reason.description',
									false
								) && {
									termination: {
										id: res.data[0].id,
										termination_reason: {
											description: res.data[0].termination_reason.description,
											type: res.data[0].termination_reason.type,
										},
									},
								}),
							};
						}
						return entry;
					}),
				});

				// if we open this modal through tenant transfer flow, make sure to navigate back to it
				if (defaultEmployment !== null) {
					setEmploymentTerminated(true);
				}

				toggleModalVisible();
			})
			.catch(() => this.setState(() => ({ postingTermination: false })));
	}

	/**
	 * @function handleFileUpload
	 * @param {Object} data - uploaded data object
	 * @description takes the uploaded document, parses to base 64 and saves in store
	 */
	handleFileUpload(data, name) {
		const { setEmploymentDocument } = this.props;

		this.setState(() => ({
			uploadingTerminationDocument: true,
		}));

		convertBinaryToBase64(data).then((base64Document) => {
			this.setState(() => ({
				uploadingTerminationDocument: false,
			}));

			// save the document in the store
			setEmploymentDocument({
				name,
				type: name === 'resignationDocument' ? 'Resignation' : 'Other',
				filename: data.name,
				data: base64Document,
			});
		});
	}

	handleModalClose() {
		const { toggleModalVisible, defaultEmployment, setEmploymentTerminated } =
			this.props;

		// if we want to toggle close the modal and we open it through tenancy transfer flow, make sure to update the state of tenancy transfer modal
		if (defaultEmployment !== null) setEmploymentTerminated(false);

		toggleModalVisible();
	}

	render() {
		const { postingTermination, uploadingTerminationDocument } = this.state;

		const {
			setTerminationReason,
			selectedDate,
			setTerminationDate,
			selectedReason,
			assignedShifts,
			terminationReasons,
			resetState,
			personId,
			resignationDocument,
			otherDocument,
			personData,
			modalVisible,
			toggleModalVisible,
			defaultEmployment,
			resetTerminateThroughTenantTransferData,
			employmentsList,
		} = this.props;

		return (
			<div className="terminate-employee">
				<Button type="negative" onClick={toggleModalVisible}>
					End Employment
				</Button>

				{modalVisible && (
					<TerminateEmployee
						personId={personId}
						personData={personData}
						handleSubmit={this.handleSubmit}
						fetchAssignedShifts={this.fetchAssignedShifts}
						selectedReason={selectedReason}
						onReasonSelect={setTerminationReason}
						selectedDate={selectedDate}
						onDateSelect={setTerminationDate}
						isOpen={modalVisible}
						handleClose={this.handleModalClose}
						assignedShifts={assignedShifts}
						fetchingShifts={this.state.fetchingShifts}
						terminationReasons={terminationReasons}
						getTerminationReasons={this.getTerminationReasons}
						loading={postingTermination}
						resetState={resetState}
						handleFileUpload={this.handleFileUpload}
						uploadingTerminationDocument={uploadingTerminationDocument}
						resignationDocument={resignationDocument}
						otherDocument={otherDocument}
						defaultEmployment={defaultEmployment}
						resetTerminateThroughTenantTransferData={
							resetTerminateThroughTenantTransferData
						}
						employmentsList={employmentsList}
					/>
				)}
			</div>
		);
	}
}

TerminateEmployeeContainer.propTypes = {
	personId: PropTypes.number,
	selectedDate: PropTypes.object,
	setTerminationDate: PropTypes.func,
	setTerminationReason: PropTypes.func,
	selectedReason: PropTypes.number,
	assignedShifts: PropTypes.array,
	setAssignedShifts: PropTypes.func,
	setTerminationReasons: PropTypes.func,
	terminationReasons: PropTypes.array,
	resetState: PropTypes.func,
	setEmploymentDocument: PropTypes.func,
	otherDocument: PropTypes.object,
	resignationDocument: PropTypes.object,
	personData: PropTypes.object,
	employmentsList: PropTypes.array,
	setListData: PropTypes.func,
	modalVisible: PropTypes.bool,
	defaultEmployment: PropTypes.object,
	toggleModalVisible: PropTypes.func,
	setEmploymentTerminated: PropTypes.func,
	resetTerminateThroughTenantTransferData: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setTerminationDate,
			setTerminationReason,
			setTerminationReasons,
			setAssignedShifts,
			resetState,
			setEmploymentDocument,
			setListData,
			toggleModalVisible,
			setEmploymentTerminated,
			resetTerminateThroughTenantTransferData,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		selectedDate: store.employeeTermination.data.date,
		selectedReason: store.employeeTermination.data.selectedReason,
		terminationReasons: store.employeeTermination.data.reasons,
		assignedShifts: store.employeeTermination.data.shifts,
		resignationDocument: store.employeeTermination.data.resignationDocument,
		otherDocument: store.employeeTermination.data.otherDocument,
		employmentsList: _get(
			store,
			`listData.['hr/employments'].data.listData`,
			[]
		),
		modalVisible: store.employeeTermination.ui.modalVisible,
		defaultEmployment: store.employeeTermination.ui.defaultEmployment,
	};
};

const sortTerminationReasons = (reasons) =>
	reasons.sort((reason1, reason2) => (reason1.type < reason2.type ? -1 : 1));

export default connectWithStore(
	TerminateEmployeeContainer,
	mapStateToProps,
	mapDispatchToProps
);
