import moment, { Moment } from 'moment';
export { default as formatErrorMessage } from './formatErrorMessage';
export { default as fetchPostman } from './postman';

export function toAPIDateString(date: Date | Moment) {
	const format = 'YYYY-MM-DD';

	if (moment.isMoment(date)) {
		return date.format(format);
	}

	return moment(date).format(format);
}
