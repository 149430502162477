'use strict';

import React, {ChangeEvent, useState} from 'react';
import { IFetchDataProps } from 'types/reactDataWrapper';
import { IModuleReduxValue } from './types';
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/administration/modules.columns';
import { fetchModules, editModules, addModules, deleteModules } from './modules.service';
import { resetState, setModules, updateModule } from './store/modules.actions';
import phrases from './modules.phrases';
import getEditedValues from 'services/utils/getEditedValues';
import { SelectOption } from "types/select";
import LegacyFilterButton from "../../../../../common/components/reactDataWrapper/components/legacyFilterButton";
import ModuleEditableCells from "./components/moduleEditableCells";

const reduxKey = '/global-administration/modules-and-exams/modules';

const Modules = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const {
        module,
        defaultModule,
    } = useAppSelector(
        (state) => ({
            module: state.modules.data.module,
            defaultModule: state.modules.data.defaultModule,
            legacyFilterOn: true,
        })
    );

    const [legacyFilterOn, updateLegacyFilterState] = useState(true);

    const addEntry = () => {
        return addModules({
            name: module?.name,
            sort_order: module?.sort_order,
            type: module?.type?.value,
            grade_scale: module?.grade_scale?.value,
            moneyball_position: module?.moneyball_position?.value,
            badge_category: module?.badge_category?.value,
            module_category: module?.module_category?.value,
            legacy: module?.legacy,
        });
    };

    const editEntry = () => {
        if (!module.id || !defaultModule) {
            return Promise.resolve(true);
        }

        const editedValues = getEditedValues({
            oldData: defaultModule,
            newData: module,
        });

        return editModules(module.id, {
            name: editedValues?.name,
            sort_order: editedValues?.sort_order,
            type: editedValues?.type,
            grade_scale: editedValues?.grade_scale,
            moneyball_position: editedValues?.moneyball_position,
            badge_category: editedValues?.badge_category,
            module_category: editedValues?.module_category,
            legacy: editedValues?.legacy,
        });
    };

    const deleteEntry = (id: number) => {
        return deleteModules(id);
    };

    const setInitialEditValues = (data: IAdministrationModule) => {
        const payload: IModuleReduxValue = {
            id: data.id,
            name: data.name,
            sort_order: data.sort_order,
            type: {
                value: data.type,
                label: data.type,
            },
            grade_scale: {
                value: data.grade_scale.id,
                label: data.grade_scale.name,
            },
            moneyball_position: {
                value: data.moneyball_position.id,
                label: data.moneyball_position.name,
            },
            badge_category: {
                value: data.badge.badge_category.id,
                label: data.badge.badge_category.name,
            },
            module_category: {
                value: data.module_category.id,
                label: data.module_category.name,
            },
            legacy: data?.legacy,
        };

        dispatch(setModules(payload));
    };

    const editModuleEntry = (
        name: string,
        value: string | SelectOption | boolean | undefined
    ): void => {
        const payload = {
            [name]: value,
        };

        dispatch(updateModule(payload));
    };

    const getExtraFilters = () => {
        let filter;

        if (legacyFilterOn) {
            filter = ':legacy==false';
        }

        return filter;
    }

    const getLegacyFilterButton = () => {
        return (
            <LegacyFilterButton
                onClick={() => updateLegacyFilterState(!legacyFilterOn)}
                active={legacyFilterOn}
            />
        );
    }

    return (
        <ReactDataWrapper
            title={phrases.TABLE_TITLE}
            dataCy="modules-table"
            columns={getColumns()}
            fetchData={(state: IFetchDataProps): ReturnType<typeof fetchModules> => fetchModules(state)}
            filterable
            defaultPageSize={20}
            reduxKey={reduxKey}
            manual
            accessAreasAllowedToEdit={['Global BI Configuration']}
            editableCells={ModuleEditableCells({
                module,
                editModuleEntry,
            })}
            actionsWidth={80}
            createEntry={addEntry}
            editEntry={editEntry}
            deleteEntry={deleteEntry}
            setInitialEditValues={setInitialEditValues}
            onModalClose={() => dispatch(resetState())}
            onModalCloseConfirm
            extraFilters={getExtraFilters()}
            compact
            customAreaComponents={getLegacyFilterButton()}
        />
    );
};

export default Modules;
