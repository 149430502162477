// Services
import { get } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchPowerBIReports
 * @param {Number} userId - logged in user id
 * @description gets all reprots available to user and sorts them alphabetically
 * NOTE: api doesn't support FIQL on this enpoint hence frontend sorting
 */
export function fetchPowerBIReports(
	userId: number
): Promise<IProfileUsersPowerBiReport[]> {
	return get(`/profile/users/${userId}/power_bi_reports`)
		.then((res: IApiRes<IProfileUsersPowerBiReport>) => {
			return res.data.sort((a, b) => {
				if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
				else return 1;
			});
		})
		.catch((err: Error) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}
