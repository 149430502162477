export default {
	TABLE_TITLE: 'POS configurations',
	POS_CONFIGURATION_ADDED: 'POS configuration added',
	POS_CONFIGURATIONS_ADDED: 'POS configurations added',
	POS_CONFIGURATIONS_DUPLICATED: 'POS configurations duplicated',
	POS_CONFIGURATION_EDITED: 'POS configuration edited',
	POS_CONFIGURATIONS_EDITED: 'POS configurations edited',
	POS_CONFIGURATION_DELETED: 'POS configuration deleted',

	// batch
	MODAL_BATCH_CREATE_POS_CONFIGURATION: 'Add Pos configuration',
	MODAL_BATCH_DUPLICATE_POS_CONFIGURATION: 'Duplicate Pos configuration',
	MODAL_BATCH_MAIN_MENU_TITLE: 'Add or duplicate pos Configuration',

	MODAL_BATCH_STEP_FORM_TITLE_ADD: 'Add POS configuration(s)',
	MODAL_BATCH_STEP_MARKET: 'Market',
	MODAL_BATCH_STEP_TENANT: 'Tenant',
	MODAL_BATCH_STEP_MARKET_PLACEHOLDER: 'Select market...',
	MODAL_BATCH_STEP_TENANT_PLACEHOLDER: 'Select tenant...',

	MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION: 'POS Configuration Version',
	MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION_PLACEHOLDER:
		'Select POS Configuration Version...',
	MODAL_BATCH_STEP_FORM_TITLE_DUPLICATE: 'Duplicate POS configuration(s)',
	MODAL_BATCH_STEP_FORM_DUPLICATE_TIP:
		'POS Configuration name and description will be prefilled based on chosen POS Configuration Versions',
	MODAL_BATCH_STEP_LIST_CONFIRM_DUPLICATE_BUTTON_LABEL: 'Duplicate',
	MODAL_BATCH_TO_STAY_TAX: 'To stay tax',
	MODAL_BATCH_TO_STAY_TAX_PLACEHOLDER: 'Select to stay tax...',
	MODAL_BATCH_TO_GO_TAX: 'To go tax',
	MODAL_BATCH_TO_GO_TAX_PLACEHOLDER: 'Select to go tax...',
	MODAL_BATCH_ACTIVE_FROM: 'Active from',
	MODAL_BATCH_ACTIVE_TO: 'Active to',

	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_DESCRIPTION: 'Description',
	MODAL_BATCH_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_FORM_MARKET_TABLE_TITLE: 'Markets',
	MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_VERSION_TABLE_TITLE:
		'Pos configuration versions',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_FORM_MARKETS_TABLE_ACTIVE_FILTER: 'Active',
	MODAL_BATCH_TIP: 'Both tax rate fields are required to duplicate the data.',
};
