import { SelectOption } from "types/select";

export enum TypeEnum {
    EXAMS = 'Exams',
    TRAINING = 'Training',
}

export function getTypeSelectOptions(): SelectOption[] {
    return Object.values(TypeEnum).map((value: TypeEnum) => ({
        value,
        label: value,
    }));
}