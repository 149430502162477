import React, { Component } from 'react';
import PropTypes from 'prop-types';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	setPowerBIReportAccessTokenData,
	resetPowerBIReportAccessTokenData,
} from './store/powerBIReport.actions';
import { withRouter } from 'services/utils';

// components
import { Loader } from 'dumb';
import { Box } from '@mui/material';

// services
import { powerBIGetAccessTokenData } from './powerBIReport.service';
import { set as setFeedback } from 'feedback.vanilla.service.js';

// power bi
import { PowerBIEmbed } from 'powerbi-client-react';

import embedConfig from './config/embedConfig';

// phrases
import phrases from './powerBiReport.phrases';

// lodash
import _isEmpty from 'lodash/isEmpty';

// styles
import './powerBIReport.css';
// const PowerBIEmbed = React.lazy(() => import(/* webpackChunkName: "powerbi-client-react" */ 'powerbi-client-react'));

class PowerBIReport extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			height: 0,
		};

		this.updateDimensions = this.updateDimensions.bind(this);
	}

	updateDimensions() {
		this.setState({ height: window.innerHeight });
	}

	// on mount we have to make another call to get the report id because we can't
	// count on navigation.utils to set the report in redux before this page mounts
	componentDidMount() {
		// remove bottom margin
		document.body.classList.add('no-margin-page');
		this.updateDimensions();
		window.addEventListener('resize', this.updateDimensions);
		const { setPowerBIReportAccessTokenData } = this.props;
		const powerBIReportId =
			this.props.params?.powerBIReportId || this.props.powerBIReportId;

		if (!powerBIReportId) return;

		this.setState(() => ({ loading: true }));

		// get access tokens
		powerBIGetAccessTokenData({ report: powerBIReportId })
			.then((res) => {
				setPowerBIReportAccessTokenData(res.data[0]);
			})
			.finally(() => {
				this.setState(() => ({ loading: false }));
			});
	}

	componentDidUpdate(prevProps) {
		const { setPowerBIReportAccessTokenData } = this.props;

		const prevReportId =
			prevProps.params?.powerBIReportId || prevProps.powerBIReportId;
		const powerBIReportId =
			this.props.params?.powerBIReportId || this.props.powerBIReportId;

		if (!powerBIReportId) return;
		if (prevReportId === powerBIReportId) return;

		// get access tokens
		powerBIGetAccessTokenData({ report: powerBIReportId }).then((res) =>
			setPowerBIReportAccessTokenData(res.data[0])
		);
	}

	componentWillUnmount() {
		document.body.classList.remove('no-margin-page');
		window.removeEventListener('resize', this.updateDimensions);
		this.props.resetPowerBIReportAccessTokenData();
	}

	render() {
		const { accessTokenData, powerBIFilter, layoutType } = this.props;

		const showPowerBiEmbedReport =
			!this.state.loading &&
			!_isEmpty(accessTokenData) &&
			process.env.CYPRESS_TEST !== 'true';

		return (
			<div
				className="power-bi-report"
				data-cy="power-bi-report"
				// 61 is the height of body element that's above all of our jtj views
				style={{ height: this.state.height - 61 }}>
				{this.state.loading && (
					<Box p={2}>
						<Loader loading />
					</Box>
				)}

				{_isEmpty(accessTokenData) && (
					<div className="power-bi-report__no-data">
						<h1>{phrases.NO_DATA}</h1>
					</div>
				)}

				{showPowerBiEmbedReport && (
					<PowerBIEmbed
						embedConfig={embedConfig({
							accessTokenData,
							powerBIFilter,
							layoutType,
						})}
						eventHandlers={
							new Map([
								[
									'error',
									function (event) {
										setFeedback(event.detail, 0);
									},
								],
							])
						}
						cssClassName="power-bi-report__report"
					/>
				)}
			</div>
		);
	}
}

PowerBIReport.propTypes = {
	params: PropTypes.object,
	accessTokenData: PropTypes.object,
	powerBIFilter: PropTypes.object,
	powerBIReportId: PropTypes.string,
	setPowerBIReportAccessTokenData: PropTypes.func,
	resetPowerBIReportAccessTokenData: PropTypes.func,
	layoutType: PropTypes.number,
};

const mapStateToProps = (store) => {
	return {
		accessTokenData: store.powerBIReport.accessTokenData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setPowerBIReportAccessTokenData,
			resetPowerBIReportAccessTokenData,
		},
		dispatch
	);
};

export default withRouter(
	connectWithStore(PowerBIReport, mapStateToProps, mapDispatchToProps)
);
