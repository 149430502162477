import 'common.css';
import 'media-queries.css';

// Css
import 'react-dates/lib/css/_datepicker.css';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'dumb/dates/datesGlobalStyle';

/* Normalize */
import 'packages/normalize.css';

/* modules/common/styles */
import 'animations.css';
import 'variables.css';
import 'fonts.css';
import 'icons.css';
import 'elements.css';
import 'icons_placeholders.css';
import 'utils.css';

/* modules/common/styles/packages */
import 'packages/overwrites/react-dates.css';
import 'packages/overwrites/react-select.css';

/* modules/common/components */
import 'inputs/editableInput.css';

/* Styling til input range komponenten. Npm pakken indeholder ikke styling og skal derfor appendes manuelt */
import 'style/components/inputRange/inputRange';
import 'style/_feedback';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React from 'react';
import ReactDOM from 'react-dom';

import Root from './router/root';

import { isProduction } from 'environment';
// import { updateActiveState } from './modules/common/components/navigation/navigation.service';

// Check that service workers are supported
if (isProduction() && 'serviceWorker' in navigator) {
	// Use the window load event to keep the page load performant
	window.addEventListener('load', () => {
		navigator.serviceWorker.register('/service-worker.js');
	});

	// navigator.serviceWorker.getRegistrations().then(function (registrations) {
	// 	for (const registration of registrations) {
	// 		registration.unregister();
	// 	}

	// });
}

// Moment + set up locale
global.moment = require('moment');

// Numeral
global.numeral = require('numeral');

// eslint-disable-next-line no-undef
moment.updateLocale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: 'seconds',
		m: '1 minute', // change that to "%d minute"
		mm: '%d minutes',
		h: '1 hour', // here too
		hh: '%d hours',
		d: '1 day', // and here
		dd: '%d days',
		M: '1 month', // and so on...
		MM: '%d months',
		y: '1 year',
		yy: '%d years',
	},
	week: {
		dow: 1, // Monday is the first day of the week.
		doy: 4, // The week that contains Jan 4th is the first week of the year.
	},
});

// Load dk as locale
numeral.register('locale', 'dk', {
	delimiters: {
		thousands: '.',
		decimal: ',',
	},
	abbreviations: {
		thousand: 't',
		million: 'm',
		billion: 'b',
		trillion: 'tr',
	},
	ordinal(number) {
		// eslint-disable-next-line
		number === 1 ? 'ende' : 'indstyve';
	},
	currency: {
		symbol: 'kr',
	},
	week: {
		dow: 1, // Monday is the first day of the week.
		doy: 4, // The week that contains Jan 4th is the first week of the year.
	},
});

numeral.locale('dk');

ReactDOM.render(<Root />, document.getElementById('root'));
