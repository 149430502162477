'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { Button, Icon, InputCollectionSelect } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// utils
import phrases from './../../../ingredientAllergens.phrases';

// lodash
import _get from 'lodash/get';

class IngredientAllergensBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e?.target?.value ?? e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			[type]: value,
		});
	}

	render() {
		const { batchListData, useGlobalResource } = this.props;

		return (
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.MODAL_BATCH_INGREDIENT}</th>
						<th>{phrases.MODAL_BATCH_DEGREE}</th>
						<th>{phrases.MODAL_BATCH_ALLERGEN_PLACEHOLDER}</th>
						{!useGlobalResource && <th>{phrases.MODAL_BATCH_MARKET}</th>}
						<th />
					</tr>
				</thead>
				<tbody>
					{batchListData.map((entry, i) => {
						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>

								<TableListCell>
									{_get(entry, 'ingredient.label', '')}
								</TableListCell>

								<TableListCell>
									<InputCollectionSelect
										id={`degree-of-${entry.identifier}`}
										placeholder={phrases.MODAL_BATCH_DEGREE_PLACEHOLDER}
										value={entry.degree}
										handleChange={(key, value) =>
											this._editListEntry(value, entry, 'degree')
										}
										clearable={false}
										cache
										apiPath="/product/ingredient_allergen_degree_ofs"
										params={{
											limit: 30,
										}}
										optionFormat={(entry) => ({
											value: entry.id,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
									/>
								</TableListCell>

								<TableListCell>
									<InputCollectionSelect
										id="allergen"
										placeholder={phrases.MODAL_BATCH_ALLERGEN_PLACEHOLDER}
										value={entry.allergen}
										handleChange={(key, value) =>
											this._editListEntry(value, entry, 'allergen')
										}
										clearable={false}
										cache
										apiPath="/product/allergens"
										params={{
											limit: 30,
										}}
										optionFormat={(entry) => ({
											value: entry.id,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
									/>
								</TableListCell>

								{!useGlobalResource && (
									<TableListCell>
										{_get(entry, 'market.label', '')}
									</TableListCell>
								)}

								<TableListCell>
									<Button
										id={`${entry.identifier}-delete`}
										type="negative"
										size="tiny"
										onClick={() => this._onRemoveEntry(entry.identifier)}
									>
										<Icon name="delete" />
									</Button>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		);
	}
}

IngredientAllergensBatchStepList.propTypes = {
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	useGlobalResource: PropTypes.bool,
};

export default IngredientAllergensBatchStepList;
