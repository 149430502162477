// components
import {
	Drawer as MuiDrawer,
	List,
	styled,
	Divider,
	Theme,
} from '@mui/material';
import {
	ThemePaletteWithCustomColours,
	ThemeWithCustomSettings,
} from './../types';
import { isDevelopment } from 'services/environment';

export const ListItemTextStyling = {
	fontFamily: 'Roboto Condensed',
	fontSize: 14,
	fontWeight: 600,
	letterSpacing: '0.5px',
	textTransform: 'uppercase',
};

export const sharedListStyling = {
	color: '#fff',

	'& .MuiSvgIcon-root': {
		fontSize: 22,
		color: 'rgba(255, 255, 255, 0.5)',
	},

	'& .MuiListItemText-primary': ListItemTextStyling,

	'& .MuiListItemSecondaryAction-root': {
		display: 'flex',
	},
};

// components
export const JoeNavList = styled(List)(({ theme }) => ({
	backgroundColor: isDevelopment()
		? (theme.palette as ThemePaletteWithCustomColours).customColours
			.navbarDevelopMain
		: theme.palette.secondary.dark,

	'& .MuiListItem-root': {
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(3),

		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
		},

		'&:hover': {
			backgroundColor: isDevelopment()
				? (theme.palette as ThemePaletteWithCustomColours).customColours
					.navbarDevelopSecondary
				: 'rgba(255, 255, 255, 0.08)',
		},

		// Very specific to JoeNavNested
		'&.Mui-selected': {
			backgroundColor: isDevelopment()
				? (theme.palette as ThemePaletteWithCustomColours).customColours
					.navbarDevelopMain
				: theme.palette.secondary.light,
		},
	},

	'& .MuiListItemIcon-root': {
		minWidth: 0,
		marginRight: theme.spacing(2),
	},

	...sharedListStyling,
}));

export const JoeNavNested = styled(List)(({ theme }) => ({
	backgroundColor: isDevelopment()
		? (theme.palette as ThemePaletteWithCustomColours).customColours
			.navbarDevelopSecondary
		: 'rgba(255, 255, 255, 0.08)',

	'& .MuiListItem-root': {
		paddingLeft: theme.spacing(6.75),
		paddingRight: 24,

		'&:hover': {
			backgroundColor: isDevelopment()
				? (theme.palette as ThemePaletteWithCustomColours).customColours
					.navbarDevelopSecondary
				: 'rgba(255, 255, 255, 0.08)',
		},
	},

	...sharedListStyling,
}));

export const JoeDivider = styled(Divider)({
	backgroundColor: 'rgba(255, 255, 255, 0.05)',
	borderBottom: 'none',
});

export const openedMixin = (theme: Theme) => {
	return {
		width: (theme as ThemeWithCustomSettings).settings.drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	};
};

export const closedMixin = (theme: Theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	// overflowX: 'hidden',
	width: 0,

	[theme.breakpoints.up('md')]: {
		width: `calc(${theme.spacing(7)} + 1px)`,
	},
});

export const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: (theme as ThemeWithCustomSettings).settings.drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',

	'& .MuiDrawer-paper': {
		backgroundColor: isDevelopment()
			? (theme.palette as ThemePaletteWithCustomColours).customColours
				.navbarDevelopMain
			: theme.palette.secondary.dark,
		boxShadow: theme.shadows[3],
		borderRadius: 0,
		zIndex: 250,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',

		...(open && {
			...openedMixin(theme),
		}),

		...(!open && {
			...closedMixin(theme),
		}),

		'& ul': {
			padding: 0,
		},
	},
}));
