'use strict';

import React from 'react';

import PosConfigurations from './components/posConfigurations/posConfigurations.component';
import PosConfigurationVersions from './components/posConfigurationVersions/posConfigurationVersions.component';
import WorkplaceReceipts from './components/workplaceReceipts/workplaceReceipts.component';

// styles
import './pos.css';

const POS = () => {
	return (
		<div className="pos">
			<PosConfigurations />
			<PosConfigurationVersions />
			<WorkplaceReceipts />
		</div>
	);
};

export default POS;
