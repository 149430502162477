import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import ContactsContainer from 'hr/contacts/contacts.container';
// import { OperationalResponsibilityOverview } from 'hr/operationalResponsibilityOverview';
import ShiftClockins from 'hr/shiftClockins/shiftClockins.container.jsx';
import EmploymentTypes from 'hr/employmentTypes/employmentTypes.container.tsx';
import Persons from 'hr/persons/persons.container.jsx';
import {
	MoneyballPositions,
	MoneyballPositionDetails,
} from 'hr/moneyballPositions';

import { Onboardings, OnboardingsDetails } from 'hr/onboardings';

import { Juicers, JuicerDetails, EmploymentDetails } from 'hr/juicers';

import TileDirectory from 'smart/tileDirectory/tileDirectory';

const OperationalResponsibilityOverview = React.lazy(() =>
	import(
		/* webpackChunkName: "OperationalResponsibilityOverview" */ 'hr/operationalResponsibilityOverview/operationalResponsibilityOverview.container'
	)
);

export const hrRoutes = [
	{
		path: 'hr',
		title: 'hr',
		description: 'Human resource management',
		allowedAccessControlLevels: ['transport'],
		element: <Outlet />,
		icon: require('@mui/icons-material/PeopleOutline').default,
		children: [
			{
				index: true,
				element: <TileDirectory pathName="hr" />,
			},
			{
				path: 'contacts',
				title: 'Contacts',
				description: 'Contact List',
				allowedAccessControlLevels: [
					'Shiftplan View',
					'Shiftplan Manager',
					'Person Admin',
					'System Joe Discount',
					'Contacts',
				],
				element: <ContactsContainer />,
			},
			{
				path: 'employment-types',
				title: 'Employment types',
				description: 'Manage Employment Types',
				allowedAccessControlLevels: ['Employment Admin'],
				element: <EmploymentTypes />,
			},
			{
				element: <Outlet />,
				path: 'juicers',
				title: 'Juicers',
				description: 'Manage juicers',
				allowedAccessControlLevels: ['Employment Admin', 'Person Admin'],
				children: [
					{
						index: true,
						element: <Juicers />,
					},
					{
						element: <JuicerDetails />,
						path: ':personId',
						title: 'Juicer details',
						allowedAccessControlLevels: ['Employment Admin', 'Person Admin'],
					},
					{
						element: <EmploymentDetails />,
						path: ':personId/employments/:employmentId',
						title: 'Employment details',
						allowedAccessControlLevels: ['Employment Admin', 'Person Admin'],
					},
				],
			},

			{
				element: <Outlet />,
				path: 'moneyball_positions',
				title: 'Moneyball positions',
				description: 'Manage Moneyball Positions',
				allowedAccessControlLevels: ['Onboarding Admin'],
				children: [
					{
						index: true,
						element: <MoneyballPositions />,
						allowedAccessControlLevels: ['Onboarding Admin'],
					},
					{
						element: <MoneyballPositionDetails />,
						path: ':moneyballPositionId',
						title: 'Moneyball positions details',
						allowedAccessControlLevels: ['Onboarding Admin'],
					},
				],
			},

			{
				path: 'onboarding',
				title: 'Onboarding',
				description: 'Manage Onboardings',
				allowedAccessControlLevels: ['Onboarding Admin'],
				element: <Outlet />,
				children: [
					{
						index: true,
						allowedAccessControlLevels: ['Onboarding Admin'],
						element: <Onboardings />,
					},

					{
						path: ':onboardingId',
						title: 'Onboarding details',
						allowedAccessControlLevels: ['Onboarding Admin'],
						element: <OnboardingsDetails />,
						hidden: true,
					},
				],
			},

			{
				path: 'operational-responsibility-overview',
				title: 'Operational responsibility overview',
				description: 'Manage managers across bars',
				allowedAccessControlLevels: ['Employment Admin', 'Person Admin'],
				element: (
					<Suspense fallback="loading">
						<OperationalResponsibilityOverview />
					</Suspense>
				),
			},
			{
				path: 'people',
				allowedAccessControlLevels: ['Employment Admin', 'Person Admin'],
				title: 'People',
				description: 'Manage people',
				element: <Persons />,
			},
			{
				path: 'shift-clockins',
				title: 'Shift clockins',
				description: 'Manage Shift Clockins',
				allowedAccessControlLevels: ['HQ HR Manager'], // TODO Needs review
				element: <ShiftClockins />,
			},
		],
	},
];
