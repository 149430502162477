import React from 'react';
import PropTypes from 'prop-types';

// components
import { Modal, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';
import { Tip } from 'dumb/tips';

// lodash
import _get from 'lodash/get';

// phrases
import phrases from './../../homeScreenRecommendations.phrases';

const DuplicateRecommendationModal = ({
	onClose,
	onConfirmClick,
	loading,
	tileLayoutToDuplicate,
	setTileLayoutToDuplicate,
	layoutId,
}) => {
	const onSelectChange = (key, value) => {
		const payload = {
			[key]: value,
		};

		setTileLayoutToDuplicate(payload);
	};

	return (
		<Modal
			isOpen
			loading={loading}
			onClose={onClose}
			onConfirmClick={onConfirmClick}
			confirmButtonLabel={phrases.DUPLICATE_CONFIRM_LABEL}
			header={phrases.DUPLICATE_TIME_SLOT}
		>
			<ModalRow>
				<InputCollectionSelect
					id="tileLayout"
					placeholder="Select tile layout..."
					value={tileLayoutToDuplicate.tileLayout}
					name="tileLayout"
					handleChange={onSelectChange}
					required
					cache
					apiPath="/pos/tile_layouts"
					params={{
						limit: 30,
						filter: `:type=='App';:legacy==false;:id!='${layoutId}'`,
					}}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
					clearable={false}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
					tableTitle="Tile layouts"
					tableColumns={[
						{
							Header: 'Name',
							id: 'name',
							accessor: 'name',
							filterPath: ':name',
						},
						{
							Header: 'Description',
							id: 'description',
							accessor: 'description',
							filterPath: ':description',
						},
						{
							Header: 'Type',
							id: 'type',
							accessor: 'type',
							filterPath: ':type',
						},
						{
							Header: 'Rows',
							id: 'rows',
							accessor: 'rows',
							filterPath: ':rows',
						},
						{
							Header: 'Columns',
							id: 'columns',
							accessor: 'columns',
							filterPath: ':columns',
						},
						{
							Header: 'Sub type',
							id: 'sub-type',
							accessor: 'sub_type',
							filterPath: ':sub_type',
						},
						{
							Header: 'Show case image',
							id: 'show-case-image',
							accessor: (d) => _get(d, 'show_case_image.name', null),
							filterPath: ':show_case_image.name',
						},
						{
							Header: 'Legacy',
							id: 'legacy',
							accessor: 'legacy',
							filterPath: ':legacy',
							filterable: false,
							Cell: (d) => {
								return (
									<input type="checkbox" checked={d.original.legacy} disabled />
								);
							},
						},
					]}
					tableReduxKey="/pos/tile_layouts-recommendations-duplicate-modal"
				/>
			</ModalRow>
			<ModalRow>
				<Tip content={phrases.DUPLICATE_TIME_SLOT_TIP} />
			</ModalRow>
		</Modal>
	);
};

DuplicateRecommendationModal.propTypes = {
	onClose: PropTypes.func,
	onConfirmClick: PropTypes.func,
	loading: PropTypes.bool,
	tileLayoutToDuplicate: PropTypes.object,
	setTileLayoutToDuplicate: PropTypes.func,
	layoutId: PropTypes.number,
};

export default DuplicateRecommendationModal;
