'use strict';

import React, {ChangeEvent, useState} from 'react';
import { IFetchDataProps } from 'types/reactDataWrapper';
import { IGradeLevelReduxValue } from './types';
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/administration/gradeLevels.columns';
import { Input } from 'dumb';
import { setGradeLevels, resetState, updateGradeLevels } from './store/gradeLevels.actions';
import phrases from './gradeLevels.phrases';
import getEditedValues from 'services/utils/getEditedValues';
import { addGradeLevels, deleteGradeLevels, editGradeLevels, fetchGradeLevels } from "./gradeLevels.service";
import ColorPicker from "../../../../../flairs/components/colorPicker/colorPicker";

type GradeLevelProps = {
    gradeScaleId: number;
};

const GradeLevels = ({ gradeScaleId }: GradeLevelProps): JSX.Element => {
    const reduxKey = `/global-administration/modules-and-exams/grade-levels'${gradeScaleId}'`;

    const { gradeLevel, defaultGradeLevel } = useAppSelector(
        (state) => ({
            gradeLevel: state.gradeLevels.data.gradeLevel,
            defaultGradeLevel: state.gradeLevels.data.defaultGradeLevel,
        })
    );

    const [colorPickerOpen, setColorPickerOpen] = useState(false);

    const dispatch = useAppDispatch();

    const addEntry = () => {
        return addGradeLevels({
            name: gradeLevel?.name,
            value: gradeLevel?.value,
            color: gradeLevel?.color,
            grade_scale: gradeScaleId,
        });
    };

    const editEntry = () => {
        if (!gradeLevel.id || !defaultGradeLevel) {
            return Promise.resolve(true);
        }

        const editedValues = getEditedValues({
            oldData: defaultGradeLevel,
            newData: gradeLevel,
        });

        return editGradeLevels(gradeLevel.id, {
            name: editedValues?.name,
            value: editedValues?.value,
            color: editedValues?.color,
        });
    };

    const deleteEntry = (id: number) => {
        return deleteGradeLevels(id);
    };

    const setInitialEditValues = (data: IAdministrationGradeLevel) => {
        const payload: IGradeLevelReduxValue = {
            id: data.id,
            name: data.name,
            value: data.value,
            color: data.color,
        };

        dispatch(setGradeLevels(payload));
    };

    const editGradeLevelEntry = (
        name: string,
        value: string
    ): void => {
        const payload = {
            [name]: value,
        };

        dispatch(updateGradeLevels(payload));
    };

    const getEditableCells = () => {
        return [
            {
                header: 'Name',
                value: (
                    <Input
                        id="name"
                        placeholder="Enter name..."
                        value={gradeLevel.name}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            editGradeLevelEntry('name', event.target.value)
                        }
                        dataCy="grade-level-name"
                        required
                    />
                ),
            },
            {
                header: 'Value',
                value: (
                    <Input
                        id="value"
                        placeholder="Enter value..."
                        value={gradeLevel.value}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            editGradeLevelEntry('value', event.target.value)
                        }
                        dataCy="grade-level-value"
                        required
                    />
                ),
            },
            {
                header: 'Color',
                value: (
                    <div className="tag__edit-modal-container">
                        <ColorPicker
                            isOpen={colorPickerOpen}
                            onClick={() => setColorPickerOpen(!colorPickerOpen)}
                            onColorChange={(newColor: string) => {
                                editGradeLevelEntry('color', newColor)
                                setColorPickerOpen(false);
                            }}
                            value={gradeLevel.color}
                        />
                    </div>
                ),
            },
        ];
    };

    return (
        <ReactDataWrapper
            title={phrases.TABLE_TITLE}
            dataCy="grade-levels-table"
            columns={getColumns()}
            fetchData={(state: IFetchDataProps): ReturnType<typeof fetchGradeLevels> => fetchGradeLevels(state, gradeScaleId)}
            filterable
            defaultPageSize={5}
            reduxKey={reduxKey}
            manual
            accessAreasAllowedToEdit={['Global BI Configuration']}
            editableCells={getEditableCells()}
            actionsWidth={80}
            createEntry={addEntry}
            editEntry={editEntry}
            deleteEntry={deleteEntry}
            setInitialEditValues={setInitialEditValues}
            onModalClose={() => dispatch(resetState())}
            onModalCloseConfirm
            compact
        />
    );
};

export default GradeLevels;
