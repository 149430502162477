import { ActionType } from 'types/redux';
import { IModuleCategoryReduxValue } from '../types';

export const SET_MODULE_CATEGORY =
    'MODULE_CATEGORIES/SET_MODULE_CATEGORY';
export const UPDATE_MODULE_CATEGORY =
    'MODULE_CATEGORIES/UPDATE_MODULE_CATEGORY';
export const RESET_STATE = 'MODULES_CATEGORIES/RESET_STATE';

export function setModulesCategories(
    payload: IModuleCategoryReduxValue
): ActionType {
    return {
        type: SET_MODULE_CATEGORY,
        payload,
    };
}

type updateModuleCategory = Partial<IModuleCategoryReduxValue>;
export function updateModuleCategories(
    payload: updateModuleCategory
): ActionType {
    return {
        type: UPDATE_MODULE_CATEGORY,
        payload,
    };
}

export function resetState(): ActionType {
    return {
        type: RESET_STATE,
    };
}
