import React from 'react';
import { DragLayer } from 'react-dnd';
import PropTypes from 'prop-types';

import './rowDragLayer.css';
import constants from '../../../../utilities/constants';

import _get from 'lodash/get';

const layerStyles = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 100,
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
};

function getItemStyles(props) {
	const { currentOffset } = props;

	if (!currentOffset) {
		return {
			display: 'none',
		};
	}

	const { x, y } = currentOffset;
	const xPosition =
		props.itemType === constants.TILE_TYPE.VARIANT
			? window.event.clientX - 110
			: x;
	const transform = `translate(${xPosition}px, ${y}px)`;

	return {
		transform: transform,
		WebkitTransform: transform,
	};
}

const CustomDragLayer = (props) => {
	const { item, itemType, isDragging } = props;

	if (!isDragging) {
		return null;
	}

	function renderItem(type, item) {
		const category = _get(item, 'product_category.name', '');
		const name = item.label;

		switch (type) {
			case constants.TILE_TYPE.VARIANT:
				return (
					<div className="preview-tile">
						<div className="preview-tile__category">
							<span>{category}</span>
						</div>
						<div className="preview-tile__name">
							<span>{name}</span>
						</div>
					</div>
				);
		}
	}

	return (
		<div style={layerStyles}>
			<div style={getItemStyles(props)}>{renderItem(itemType, item)}</div>
		</div>
	);
};

function collect(monitor) {
	return {
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		currentOffset: monitor.getSourceClientOffset(),
		isDragging: monitor.isDragging(),
	};
}

CustomDragLayer.propTypes = {
	item: PropTypes.object,
	itemType: PropTypes.string,
	isDragging: PropTypes.bool,
};

export default DragLayer(collect)(CustomDragLayer);
