import { ActionType } from 'types/redux';
import { IGridReduxState, IGridReduxValue } from '../types';
import { SET_GRID, UPDATE_GRID } from './grids.actions';
import { ICurrencyReduxValue } from '../../currencies/types';

const defaultState: IGridReduxState = {
	data: {
		defaultGrid: null,
		grid: {
			name: null,
		},
	},
};

function reducer(state = defaultState, action: ActionType): IGridReduxState {
	const { type, payload } = action;

	switch (type) {
	case SET_GRID: {
		const castedPayload = payload as IGridReduxValue;

		return {
				...state,
				data: {
					grid: castedPayload,
					defaultGrid: castedPayload,
				},
			};
	}

	case UPDATE_GRID: {
		const castedPayload = payload as Partial<ICurrencyReduxValue>;

		return {
				...state,
				data: {
					...state.data,
					grid: {
						...state.data.grid,
						...castedPayload,
					},
				},
			};
	}

	default:
		return state;
	}
}

export default reducer;
