import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { set as setFeedback } from 'feedback.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { addGlobalNotifications } from '../../actions/globalNotifications.actions';

// Comps
import {
	AppBar,
	Box,
	Toolbar,
	IconButton,
	Badge,
	MenuItem,
	Menu,
	ListItemIcon,
	ListItemText,
	Typography,
	Alert,
	useTheme,
	Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Breadcrumbs } from 'smart/breadcrumbs';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import AppBarStaffModalHeader from './components/appbarStaffModalHeader';

import StaffDetails from 'staffDetails';

// Utils
import phrases from './appbar.phrases';
import { validAccess } from 'accessControl';
import { isDevelopment } from 'services/environment';

export default function JoeAppBar(props) {
	const { activeUser } = props;
	const dispatch = useDispatch();
	const theme = useTheme();

	const alertStyles = {
		fontSize: theme.typography.fontSize,
	};

	const [staffDetailsVisible, setStaffDetailsVisible] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const isMenuOpen = Boolean(anchorEl);

	const notifications = useSelector(
		(store) => store.globalNotifications.notifications
	);

	const numberOfNotifications = notifications?.length;

	// eslint-disable-next-line no-undef
	const version = AppVersion;

	const getNotifications = useCallback(async () => {
		return fetch(
			'https://joejuiceassets.s3.eu-west-1.amazonaws.com/system_notifications/status.json',
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
			.then((response) => {
				if (!response.ok) {
					setFeedback('HTTP error ' + response.status, 0);
				}

				return response.json();
			})
			.then((json) => {
				dispatch(addGlobalNotifications(json.data));
			})
			.catch((error) => {
				const errorMsg = formatErrorMessage(error);
				setFeedback(errorMsg, 0);
			});
	}, [dispatch]);

	useEffect(() => {
		const myTimeout = setTimeout(getNotifications, 5000); // wait 5 seconds before fetching notifications
		const timerId = setInterval(getNotifications, 60 * 1000 * 5);
		return () => {
			clearTimeout(myTimeout);
			clearInterval(timerId);
		};
	}, [getNotifications]);

	const handleNotificationMenuClose = () => {
		setAnchorEl(null);
	};

	const handleNotificationMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const toggleStaffDetails = (toggle) => {
		setStaffDetailsVisible(toggle);
	};

	const notificationsMenuId = 'menu-notifications';
	const renderNotificationMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id={notificationsMenuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMenuOpen}
			onClose={handleNotificationMenuClose}
		>
			{numberOfNotifications &&
				notifications.map((item, i) => {
					return (
						<MenuItem
							key={i}
							sx={{
								maxWidth: '400px',
							}}
						>
							<ListItemIcon>
								<WarningAmberOutlinedIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText
								primary={item.title}
								secondary={item.description}
								sx={{ whiteSpace: 'break-spaces' }}
								data-cy="appbar-notification-item"
							></ListItemText>

							<Box
								sx={{
									width: '11px',
									height: '10px',
									borderRadius: '50%',
									backgroundColor: (theme) =>
										item.level === 'Error'
											? theme.palette.error.main
											: 'initial',
								}}
							/>
						</MenuItem>
					);
				})}
		</Menu>
	);

	const conditionallyRenderJoeAppBarStaffModalHeader = () => {
		const canRender = validAccess(undefined, ['PersonalVacationLogging']);

		if (!canRender) return;

		return <AppBarStaffModalHeader />;
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="static">
				<Toolbar sx={{ pl: 2, pr: 2 }}>
					<Breadcrumbs />
					<Box sx={{ flexGrow: 1 }} />
					<Box
						sx={{
							display: {
								md: 'flex',
							},
						}}
					>
						{/* Show api endpoint */}
						<Box display="flex" alignItems="center">
							{numberOfNotifications > 0 && (
								<IconButton
									size="large"
									aria-label={`show ${numberOfNotifications} new notifications`}
									aria-controls={notificationsMenuId}
									aria-haspopup="true"
									onClick={handleNotificationMenuOpen}
									color="inherit"
								>
									<Badge badgeContent={numberOfNotifications} color="error">
										<NotificationsIcon />
									</Badge>
								</IconButton>
							)}

							<Typography
								mx={1}
								variant="overline"
								fontFamily="Roboto Condensed"
								data-cy="appbar-version-text-element"
							>
								{`v${version}`}
							</Typography>
							{!process.env.NODE_ENV.includes('production') && (
								<>
									{isDevelopment() ? (
										<Tooltip title={phrases.USING_DEVELOPMENT_SERVER}>
											<InfoIcon />
										</Tooltip>
									) : (
										<Alert sx={alertStyles} severity="error">
											{phrases.USING_PRODUCTION_SERVER}
										</Alert>
									)}
								</>
							)}

							<IconButton
								size="large"
								edge="end"
								aria-label="account of current user"
								aria-haspopup="true"
								onClick={toggleStaffDetails}
								data-cy="appbar-account-button"
								color="inherit"
							>
								<AccountCircle />
							</IconButton>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>

			{renderNotificationMenu}

			{staffDetailsVisible && (
				<StaffDetails
					person={activeUser?.user?.person}
					marketId={
						activeUser?.responsibilities?.moneyballRoles[0]?.location.id
					}
					visible={staffDetailsVisible}
					toggleModalView={() => toggleStaffDetails(!staffDetailsVisible)}
					headerComponent={conditionallyRenderJoeAppBarStaffModalHeader}
				/>
			)}
		</Box>
	);
}

JoeAppBar.propTypes = {
	activeUser: PropTypes.object,
};
