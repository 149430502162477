import React, { ChangeEvent } from 'react';
import PropTypes from 'prop-types';

// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { SelectOption } from 'types/select';
import { IPosConfigurationVersionCurrenciesReduxValue } from './types';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import {
	setPosConfigurationVersionCurrency,
	updatePosConfigurationVersionCurrency,
	resetState,
} from './store/posConfigurationVersionCurrencies.actions';

// components
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/pos/posConfigurationVersionCurrencies.columns';
import { Input, InputCollectionSelect } from 'dumb';

// phrases/constants
import phrases from './posConfigurationVersionCurrencies.phrases';

// services/ utils
import {
	fetchPosConfigurationVersionCurrencies,
	editPosConfigurationVersionCurrency,
	addPosConfigurationVersionCurrency,
	deletePosConfigurationVersionCurrency,
} from './posConfigurationVersionCurrencies.service';

const reduxKey = '/pos/pos_configuration_currencies';
const columns = getColumns();

type Props = {
	posConfigurationVersionId: number;
};
const PosConfigurationVersionCurrencies = ({
	posConfigurationVersionId,
}: Props): JSX.Element => {
	const { defaultPosConfigurationVersionCurrency } = useAppSelector(
		(store) => ({
			defaultPosConfigurationVersionCurrency:
				store.salesConfigurationPosConfigurationVersionCurrencies.data
					.defaultPosConfigurationVersionCurrency,
		})
	);
	const dispatch = useAppDispatch();

	const addEntry = () => {
		const payload = {
			pos_configuration_version: posConfigurationVersionId,
			currency: defaultPosConfigurationVersionCurrency.currency?.value ?? null,
			markup: defaultPosConfigurationVersionCurrency.markup.replace('%', ''),
			fixed_rate: defaultPosConfigurationVersionCurrency.fixedRate,
			dynamic: defaultPosConfigurationVersionCurrency.dynamic,
		};

		return addPosConfigurationVersionCurrency(payload);
	};

	const editEntry = () => {
		const payload = {
			id: defaultPosConfigurationVersionCurrency.id,
			markup: defaultPosConfigurationVersionCurrency.markup.replace('%', ''),
			fixed_rate: defaultPosConfigurationVersionCurrency.fixedRate,
			dynamic: defaultPosConfigurationVersionCurrency.dynamic,
		};

		return editPosConfigurationVersionCurrency(payload);
	};

	const deleteEntry = (id: number) => {
		return deletePosConfigurationVersionCurrency(id);
	};

	const setInitialEditValues = (data: IPosConfigurationCurrency) => {
		const payload: IPosConfigurationVersionCurrenciesReduxValue = {
			id: data.id,
			currency: {
				value: data.currency.id,
				label: data.currency.code,
			},
			markup: data.markup.toString(),
			fixedRate: data.fixed_rate,
			dynamic: data.dynamic,
			editMode: true,
		};

		dispatch(setPosConfigurationVersionCurrency(payload));
	};

	const getEditableCells = () => {
		const editMode = !!defaultPosConfigurationVersionCurrency.editMode;

		return [
			...(editMode
				? [
						{
							header: 'Currency',
							value: (
								<span data-cy="pos-configuration-version-currencies-currency">
									{defaultPosConfigurationVersionCurrency.currency?.label}
								</span>
							),
						},
				  ]
				: [
						{
							header: 'Currency',
							value: (
								<InputCollectionSelect
									id="currency"
									dataCy="pos-configuration-version-currencies-currency"
									placeholder="Select currency..."
									value={defaultPosConfigurationVersionCurrency.currency}
									handleChange={(key, value) =>
										editStoreEntry('currency', value)
									}
									cache
									apiPath="/administration/currencies"
									params={{
										limit: 30,
									}}
									optionFormat={(entry) => ({
										value: entry.id,
										label: entry.code,
									})}
									inputFilterFormat={(input) => `:code=like='%${input}%'`}
								/>
							),
						},
				  ]),
			{
				header: 'Markup',
				value: (
					<Input
						id="markup"
						placeholder="Enter markup in %..."
						dataCy="pos-configuration-version-currencies-markup"
						value={getMarkupValue(
							defaultPosConfigurationVersionCurrency.markup
						)}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							editStoreEntry('markup', event)
						}
					/>
				),
			},
			{
				header: 'Fixed rate',
				value: (
					<Input
						id="fixed-rate"
						placeholder="Enter fixed rate..."
						dataCy="pos-configuration-version-currencies-fixed-rate"
						value={defaultPosConfigurationVersionCurrency.fixedRate}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							editStoreEntry('fixedRate', event)
						}
					/>
				),
			},
			{
				header: 'Dynamic',
				value: (
					<Input
						id="dynamic"
						type="checkbox"
						dataCy="pos-configuration-version-currencies-dynamic"
						checked={defaultPosConfigurationVersionCurrency.dynamic}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							editStoreEntry('dynamic', event.target.checked)
						}
					/>
				),
			},
		];
	};

	const getMarkupValue = (
		markup: IPosConfigurationVersionCurrenciesReduxValue['markup']
	) => {
		if (!markup) return '';

		// remove % if present
		markup = markup.replace('%', '');

		return `${markup}%`;
	};

	const editStoreEntry = (
		name: keyof IPosConfigurationVersionCurrenciesReduxValue,
		e: ChangeEvent<HTMLInputElement> | SelectOption | boolean
	) => {
		let value;

		// if its a checkbox
		if (typeof e === 'boolean') value = e;
		// if value is SelectOption type
		else if ('label' in e && 'value' in e) value = e;
		// if its a regular input type
		else value = e.target.value;

		const updateObject = {
			[name]: value,
		};

		dispatch(updatePosConfigurationVersionCurrency(updateObject));
	};

	const fetchData = (state: IFetchDataProps) => {
		const extraFilter = `:pos_configuration_version.id=='${posConfigurationVersionId}'`;

		return fetchPosConfigurationVersionCurrencies(state, extraFilter);
	};

	const resetStateHandler = () => dispatch(resetState());

	return (
		<ReactDataWrapper
			dataCy="pos-configuration-version-currencies-table"
			accessAreasAllowedToEdit={['Sales Configuration']}
			title={phrases.TABLE_TITLE}
			columns={columns}
			fetchData={fetchData}
			filterable
			defaultPageSize={5}
			reduxKey={`${reduxKey}-${posConfigurationVersionId}`}
			manual
			createEntry={addEntry}
			editEntry={editEntry}
			deleteEntry={deleteEntry}
			setInitialEditValues={setInitialEditValues}
			editableCells={getEditableCells()}
			onModalClose={resetStateHandler}
		/>
	);
};

PosConfigurationVersionCurrencies.propTypes = {
	posConfigurationVersionId: PropTypes.number,
};

export default PosConfigurationVersionCurrencies;
