import { get, post, remove } from 'api.vanilla.service';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { formatErrorMessage } from 'api/helpers';
import { IFetchDataProps } from 'types/reactDataWrapper';
import { ChangeTypeOfKeys } from 'types/utils';

const BASE_URL = '/product/ingredient_allergen_degree_of_translations';

const handleError = (err: unknown) => {
	const errorMessage = formatErrorMessage(err);
	setFeedback(errorMessage, 0);
	return Promise.reject(err);
};

type Payload = ChangeTypeOfKeys<
	IIngredientAllergenDegreeOfTranslation,
	'language' | 'ingredient_allergen_degree_of',
	number
>;

export function fetchDegreeOfTranslations(state: IFetchDataProps) {
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(BASE_URL, params, null, state.headers).catch(handleError);
}

export function createDegreeOfTranslation(payload: Omit<Payload, 'id'>) {
	return post(BASE_URL, payload).catch(handleError);
}

export function deleteDegreeOfTranslation(id: number) {
	return remove(`${BASE_URL}/${id}`).catch(handleError);
}

export function editDegreeOfTranslation(payload: Payload) {
	return post(`${BASE_URL}/${payload.id}`, payload).catch(handleError);
}
