import { get, post, remove } from 'api.vanilla.service';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { formatErrorMessage } from 'api/helpers';
import { IFetchDataProps } from 'types/reactDataWrapper';
import { ChangeTypeOfKeys } from 'types/utils';

const BASE_URL = '/hr/employment_types';

const handleError = (err: unknown) => {
	const errorMessage = formatErrorMessage(err);
	setFeedback(errorMessage, 0);
	return Promise.reject(err);
};

type Payload = ChangeTypeOfKeys<IEmploymentType, 'market', number>;

export function fetchEmploymentTypes(state: IFetchDataProps) {
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(BASE_URL, params, null, state.headers).catch(handleError);
}

export function createEmploymentType(payload: Omit<Payload, 'id'>) {
	return post(BASE_URL, payload).catch(handleError);
}

export function deleteEmploymentType(id: number) {
	return remove(`${BASE_URL}/${id}`).catch(handleError);
}

export function editEmploymentType(payload: Payload) {
	return post(`${BASE_URL}/${payload.id}`, payload).catch(handleError);
}
