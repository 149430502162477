import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

// components
import {
	Input,
	SingleDatePickerInput,
	InputCollectionSelect,
	Toggle,
} from 'dumb';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

// utils
import { getSelectOptionsFromEnums } from 'services/utils';

// enums
import enums from './../../markets.enums';

// lodash
import _get from 'lodash/get';

/* 
	steps to replace everything the way it was

	step 1:
		remove temporaryModal function in this file and markets.container.jsx

	step 2:
		remove the prop editableCellsEdit and move the function 
			MarketEditableCells({
				market: this.props.market,
				editStoreEntry: this.editStoreEntry,
			})

		place it in editableCells prop

	step 3:
		make sure to remove editableCellsEdit and keep editableCells

	step 4:
		add the test back that has been commented out in markets.cy.js
		this is the test name (Receipt-type value is Default when creating a new entry)

	step 5:
	  remove this test (It doesnt add) in markets.cy.js

	step 6:
		uncomment lines 21 and 64 - 103 in markets.container.jsx and remove the promise on line 61 - 63

	step 7:
		make sure tests that are written to check if it fails gets removed

*/

// this is to be removed once we find out which team is creating markets.
const temporaryModal = () => {
	return [
		{
			value: (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						textAlign: 'center',
					}}
				>
					<Typography variant="h4">Cannot create a new market</Typography>
					<Typography sx={{ marginTop: 2 }} variant="h5">
						In order to create a new market, contact Frederik Trojaborg Julian
						at ftj@joejuice.com.
					</Typography>
				</Box>
			),
		},
	];
};

const MarketEditableCells = ({ market, editStoreEntry }) => {
	return [
		{
			header: 'Name',
			value: (
				<Input
					id="name"
					placeholder="Enter name..."
					value={market.name}
					required
					onChange={(e) => editStoreEntry('name', e.target.value)}
				/>
			),
		},
		{
			header: 'Country',
			value: market.editMode ? (
				<span>{market.country}</span>
			) : (
				<InputCollectionSelect
					id="country"
					placeholder="Select country..."
					value={market.country}
					handleChange={(key, value) => {
						editStoreEntry('country', value);
					}}
					cache
					apiPath="/administration/countries"
					params={{
						limit: 30,
					}}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
					tableTitle="Countries"
					tableColumns={[
						{
							Header: 'Name',
							id: 'Name',
							accessor: 'name',
							filterPath: ':name',
						},
						{
							Header: 'Alpha 2 code',
							accessor: 'alpha_2_code',
							id: 'alpha_2_code',
							filterPath: ':alpha_2_code',
						},
						{
							Header: 'Alpha 3 code',
							accessor: 'alpha_3_code',
							id: 'alpha_3_code',
							filterPath: ':alpha_3_code',
						},
						{
							Header: 'ISO Code',
							accessor: 'iso_3166_2_code',
							id: 'iso_3166_2_code',
							filterPath: ':iso_3166_2_code',
						},
					]}
					tableReduxKey="/administration/countries-organisation-market-modal"
				/>
			),
		},
		{
			header: 'Market region',
			value: market.editMode ? (
				<span>{market.marketRegion}</span>
			) : (
				<InputCollectionSelect
					id="marketRegion"
					placeholder="Select market region..."
					value={market.marketRegion}
					handleChange={(key, value) => {
						editStoreEntry('marketRegion', value);
					}}
					cache
					apiPath="/administration/market_regions"
					params={{
						limit: 30,
					}}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
					tableTitle="Market regions"
					tableColumns={[
						{
							Header: 'Name',
							accessor: 'name',
							id: 'name',
							filterPath: ':name',
						},
						{
							Header: 'Continent',
							id: 'continent',
							accessor: (d) => _get(d, 'continent.name', ''),
							filterPath: ':continent.name',
						},
						{
							Header: 'Sort order',
							accessor: 'sort_order',
							id: 'sort_order',
							filterPath: ':sort_order',
						},
					]}
					tableReduxKey="/administration/market_regions-organisation-market-modal"
				/>
			),
		},
		{
			header: 'Tax label',
			value: (
				<Input
					id="tax-label"
					placeholder="Enter tax label..."
					value={market.taxLabel}
					onChange={(e) => editStoreEntry('taxLabel', e.target.value)}
				/>
			),
		},
		{
			header: 'Default salary group',
			value: (
				<InputCollectionSelect
					id="salaryGroups"
					placeholder="Select default salary group..."
					value={market.defaultSalaryGroup}
					handleChange={(key, value) => {
						editStoreEntry('defaultSalaryGroup', value);
					}}
					cache
					apiPath="/salary/salary_groups"
					params={{
						limit: 30,
					}}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
					tableTitle="Default salary groups"
					tableColumns={[
						{
							Header: 'Market',
							id: 'market',
							accessor: (d) => _get(d, 'market.name', ''),
							filterPath: ':market.name',
						},
						{
							Header: 'Moneyball sub position',
							id: 'moneyball_sub_position',
							accessor: (d) => _get(d, 'moneyball_sub_position.name', ''),
							filterPath: ':moneyball_sub_position.name',
						},
						{
							Header: 'Moneyball position',
							id: 'moneyball_position',
							accessor: (d) =>
								_get(d, 'moneyball_sub_position.moneyball_position.name', ''),
							filterPath: ':moneyball_sub_position.moneyball_position.name',
						},
						{
							Header: 'Name',
							id: 'name',
							accessor: (d) => _get(d, 'name', ''),
							filterPath: ':name',
						},
						{
							Header: 'Description',
							id: 'description',
							accessor: (d) => _get(d, 'description', ''),
							filterPath: ':description',
						},
						{
							Header: 'From',
							id: 'active_from',
							accessor: (d) => _get(d, 'active.from', ''),
							filterPath: ':active.from',
							// eslint-disable-next-line react/prop-types
							Filter: ({ column }) => (
								<DateFilterSelector
									reduxKey="/salary/salary_groups-organisation-market-modal"
									// eslint-disable-next-line react/prop-types
									columnId={column.id}
									dateIdentifier="from"
									zIndex={600}
								/>
							),
						},
						{
							Header: 'To',
							id: 'active_to',
							accessor: (d) => _get(d, 'active.to', ''),
							filterPath: ':active.to',
							// eslint-disable-next-line react/prop-types
							Filter: ({ column }) => (
								<DateFilterSelector
									reduxKey="/salary/salary_groups-organisation-market-modal"
									// eslint-disable-next-line react/prop-types
									columnId={column.id}
									dateIdentifier="to"
									zIndex={600}
								/>
							),
						},
					]}
					tableReduxKey="/salary/salary_groups-organisation-market-modal"
				/>
			),
		},
		{
			header: 'Salary export format',
			value: (
				<InputCollectionSelect
					id="salaryExportFormat"
					placeholder="Select salary export format..."
					value={market.salaryExportFormat}
					handleChange={(key, value) => {
						editStoreEntry('salaryExportFormat', value);
					}}
					required
					options={getSelectOptionsFromEnums(enums.SALARY_EXPORT_FORMAT)}
					clearable={false}
				/>
			),
		},
		{
			header: 'Opened',
			value: (
				<SingleDatePickerInput
					id="opened"
					onChange={(event) => editStoreEntry('opened', event)}
					selectedDate={market.opened}
					noClockButton
				/>
			),
		},
		{
			header: 'Closed',
			value: (
				<SingleDatePickerInput
					id="closed"
					onChange={(event) => editStoreEntry('closed', event)}
					selectedDate={market.closed}
					noClockButton
				/>
			),
		},
		{
			header: 'Minute based shiftplanning',
			value: (
				<Toggle
					id="minuteBasedShiftplanning"
					onClick={(event) => editStoreEntry('minuteBasedShiftplaning', event)}
					toggled={market.minuteBasedShiftplaning}
				/>
			),
		},
		{
			header: 'Clockin alignment required',
			value: (
				<Toggle
					id="clockinAlignmentRequired"
					onClick={(event) => editStoreEntry('clockinAlignmentRequired', event)}
					toggled={market.clockinAlignmentRequired}
				/>
			),
		},
		{
			header: 'Break registration',
			value: (
				<Toggle
					id="break-registration"
					onClick={(event) => editStoreEntry('breakRegistration', event)}
					toggled={market.breakRegistration}
				/>
			),
		},
		{
			header: 'Auto close day',
			value: (
				<Toggle
					id="auto-close-day"
					onClick={(event) => editStoreEntry('autoCloseDay', event)}
					toggled={market.autoCloseDay}
				/>
			),
		},
		{
			header: 'Require approval for employee meals',
			value: (
				<Toggle
					id="require-approval-for-employee-meals"
					onClick={(event) =>
						editStoreEntry('requireApprovalForEmployeeMeals', event)
					}
					toggled={market.requireApprovalForEmployeeMeals}
				/>
			),
		},
		{
			header: 'Require approval for vacation',
			value: (
				<Toggle
					id="require-approval-for-vacation"
					onClick={(event) =>
						editStoreEntry('requireApprovalForVacation', event)
					}
					toggled={market.requireApprovalForVacation}
				/>
			),
		},
		{
			header: 'Skip end of day count',
			value: (
				<Toggle
					id="skip-end-of-day-count"
					dataCy="skip-end-of-day-count-toggle"
					onClick={(event) => editStoreEntry('skipEndOfDayCount', event)}
					toggled={market.skipEndOfDayCount}
				/>
			),
		},
		{
			header: 'Receipt type',
			value: (
				<InputCollectionSelect
					id="receipt-type"
					placeholder="Select receipt type..."
					value={market.receiptType}
					handleChange={(key, value) => {
						editStoreEntry('receiptType', value);
					}}
					required
					options={getSelectOptionsFromEnums(enums.RECEIPT_TYPE)}
					clearable={false}
					dataCy="receipt-type-select"
				/>
			),
		},
		{
			header: 'Default break tag',
			value: market.editMode ? (
				<span>{market.defaultBreakTag}</span>
			) : (
				<InputCollectionSelect
					id="defaultBreakTag"
					placeholder="Select default break tag..."
					value={market.defaultBreakTag}
					handleChange={(key, value) => {
						editStoreEntry('defaultBreakTag', value);
					}}
					cache
					apiPath="/tagging/tags"
					params={{
						limit: 30,
						filter: `:type=='Shiftplan';:subtype=='Break'`,
					}}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
					required
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
					tableTitle="Market regions"
					tableColumns={[
						{
							Header: 'Name',
							accessor: 'name',
							id: 'name',
							filterPath: ':name',
						},
						{
							Header: 'Type',
							accessor: 'type',
							id: 'type',
							filterPath: ':type',
						},
						{
							Header: 'Color',
							accessor: 'color',
							id: 'color',
							filterPath: ':color',
							Cell: (d) => (
								<div
									style={{
										backgroundColor: `#${d.value}`,
										height: 10,
										width: 10,
										borderRadius: 10,
									}}
								/>
							),
							filterable: false,
						},
						{
							Header: 'Current year index',
							accessor: 'current_year_index',
							id: 'current_year_index',
							filterPath: ':current_year_index',
							Cell: (d) => <Input type="checkbox" disabled checked={d.value} />,
							filterable: false,
						},
						{
							Header: 'Exclude addon',
							accessor: 'exclude_addon',
							id: 'exclude_addon',
							filterPath: ':exclude_addon',
							Cell: (d) => <Input type="checkbox" disabled checked={d.value} />,
							filterable: false,
						},
						{
							Header: 'Exclude base pay',
							accessor: 'exclude_base_pay',
							id: 'exclude_base_pay',
							filterPath: ':exclude_base_pay',
							Cell: (d) => <Input type="checkbox" disabled checked={d.value} />,
							filterable: false,
						},
						{
							Header: 'Exclude overtime',
							accessor: 'exclude_overtime',
							id: 'exclude_overtime',
							filterPath: ':exclude_overtime',
							Cell: (d) => <Input type="checkbox" disabled checked={d.value} />,
							filterable: false,
						},
						{
							Header: 'Exclude productivity',
							accessor: 'exclude_productivity',
							id: 'exclude_productivity',
							filterPath: ':exclude_productivity',
							Cell: (d) => <Input type="checkbox" disabled checked={d.value} />,
							filterable: false,
						},
						{
							Header: 'Prior year index',
							accessor: 'prior_year_index',
							id: 'prior_year_index',
							filterPath: ':prior_year_index',
							Cell: (d) => <Input type="checkbox" disabled checked={d.value} />,
							filterable: false,
						},
					]}
					tableReduxKey="/tagging/tags-organisation-market-modal"
				/>
			),
		},
		{
			header: 'Language',
			value: (
				<InputCollectionSelect
					id="language"
					placeholder="Select language..."
					value={market.language}
					handleChange={(key, value) => {
						editStoreEntry('language', value);
					}}
					cache
					apiPath="/administration/languages"
					params={{
						limit: 30,
					}}
					optionFormat={(entry) => ({
						value: entry.id,
						label: entry.name,
					})}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
				/>
			),
		},
	];
};

MarketEditableCells.propTypes = {
	editStoreEntry: PropTypes.func,
	market: PropTypes.object,
};

export { MarketEditableCells, temporaryModal };
