import React from 'react';

// interface
import { IColumnHeader } from 'types/reactDataWrapper';

export default function getColumns(): IColumnHeader[] {
	return [
		{
			Header: 'Company',
			id: 'company',
			accessor: (d: any) => d.company?.name ?? '',
			filterPath: ':company.name',
		},
		{
			Header: 'Workplace',
			id: 'workplace',
			accessor: (d: any) => d.workplace?.name ?? '',
			filterPath: ':workplace.name',
		},
		{
			Header: 'Moneyball',
			id: 'current_moneyball_sub_position',
			accessor: (d: any) =>
				d.current_moneyball_sub_position?.moneyball_position?.name ?? '',
			filterPath: ':current_moneyball_sub_position.moneyball_position.name',
		},
		{
			Header: 'Type',
			id: 'current_employment_position.employment_type.type',
			accessor: (d: any) =>
				d.current_employment_position?.employment_type?.type ?? '',
			width: 70,
			filterPath: ':current_employment_position.employment_type.type',
		},
		{
			Header: 'Salary export id',
			id: 'salary_export_id',
			width: 100,
			accessor: 'salary_export_id',
			filterPath: ':salary_export_id',
		},
		{
			Header: 'Has food permit',
			id: 'has_food_permit',
			minWidth: 50,
			accessor: 'has_food_permit',
			Cell: (d: any) => <input type="checkbox" checked={d.value} disabled />,
			filterPath: ':has_food_permit',
		},
		{
			Header: 'Employment position contract',
			id: 'employment_position_contract',
			width: 165,
			accessor: (d: any) => d.current_employment_position?.has_contract ?? null,
			Cell: (d: any) => <input type="checkbox" checked={d.value} disabled />,
			filterPath: ':current_employment_position.has_contract',
		},
		{
			Header: 'In salary export',
			id: 'include_in_salary_export',
			width: 100,
			accessor: 'include_in_salary_export',
			filterPath: ':include_in_salary_export',
			Cell: (d: any) => <input type="checkbox" checked={d.value} disabled />,
		},
		{
			Header: 'App allowance',
			id: 'appAllowance',
			width: 100,
			accessor: (d: any) => d.employee_meal_app_allowance?.name ?? '',
			filterPath: ':employee_meal_app_allowance.name',
		},
		{
			Header: 'Ended employment',
			id: 'termination',
			accessor: (d: any) => d.termination?.termination_reason ?? '',
			filterPath: ':termination.termination_reason.description',
			Cell: (d: any): JSX.Element | null => {
				if (d.value.description && d.value.type)
					return <span>{`${d.value.description} (${d.value.type})`}</span>;
				else return null;
			},
		},
		{
			Header: 'Started',
			id: 'started',
			width: 100,
			accessor: 'started',
			filterPath: ':started',
		},
		{
			Header: 'Ended',
			id: 'ended',
			width: 100,
			accessor: 'ended',
			filterPath: ':ended',
		},
	];
}
