import React, { useCallback, useState } from 'react';
import { ReactDataWrapper } from 'reactDataWrapper';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import {
	createWorkplaceReceipt,
	editWorkplaceReceipt,
	fetchWorkplaceReceipts,
	deleteWorkplaceReceipt,
	batchCreateWorkplaceReceipt,
	batchEditWorkplaceReceipt,
	batchDeleteWorkplaceReceipt,
	Payload,
} from './workplaceReceipts.service';
import { IColumnHeader, IFetchDataProps } from 'types/reactDataWrapper';
import { fetchWorkplaces } from 'modules/structure/organisation/workplaces/workplaces.service';
import { isInteger, uniq } from 'lodash';
import { FileUpload, InputCollectionSelect, Textarea } from 'dumb';
import convertBinaryToBase64 from 'services/utils/convertBinaryToBase64';
import { z, ZodError } from 'zod';
import { FormErrors } from 'types/utils';
import { useSelector } from 'react-redux';
import { selectListData } from 'reactDataWrapper/reactDataWrapper.selectors';

type Row = {
	id: IWorkplace['id'];
	workplace: IWorkplace;
	workplaceReceipt?: IWorkplaceReceipt;
};

type FormState = Partial<IWorkplaceReceipt>;

const fileAccept = 'image/png, image/jpeg';
const fileNameRegex = /^.*\.(jpg|JPG|png|PNG)$/gm;

const WorkplaceReceiptSchema = z.object({
	workplace: z.object({ id: z.number(), name: z.string() }),
	text: z.string().optional(),
	image: z
		.object({
			filename: z.string().regex(fileNameRegex),
			data: z.string().optional(),
			url: z.string().optional(),
		})
		.optional()
		.nullable(),
});

type ValidatedFormState = z.infer<typeof WorkplaceReceiptSchema>;

const reduxKey = 'workplace-receipts';

const handleFetch = async (state: IFetchDataProps) => {
	const workplacesResponse = await fetchWorkplaces(state);
	const { data: workplaces } = workplacesResponse;

	const workplaceIds: number[] = uniq<number>(
		workplaces.map((workplace: IWorkplace) => workplace.id)
	).filter(isInteger);

	const workplaceReceipts: IWorkplaceReceipt[] = await fetchWorkplaceReceipts(
		state,
		workplaceIds
	);

	const data = workplaces.map((workplace: IWorkplace) => {
		const workplaceReceipt = workplaceReceipts.find(
			(workplaceReceipt) => workplaceReceipt.workplace.id === workplace.id
		);
		return { id: workplace.id, workplace, workplaceReceipt };
	});

	return { ...workplacesResponse, data };
};

const getImagePayload = async (file: File) => ({
	filename: file.name,
	data: await convertBinaryToBase64(file),
});

const handleFileUpload = async (row: Row, file?: File) => {
	const workplaceReceipt = row.workplaceReceipt;
	const payload = getPayload({
		workplace: row.workplace,
		image: file ? await getImagePayload(file) : null,
		text: row.workplaceReceipt?.text,
	});
	if (workplaceReceipt) {
		await editWorkplaceReceipt({ ...payload, id: workplaceReceipt.id });
	} else {
		await createWorkplaceReceipt(payload);
	}
};

const getPayload = (formState: ValidatedFormState): Omit<Payload, 'id'> => {
	let image = formState.image;

	if (image?.url) {
		// Keep image
		image = undefined;
	}

	if (image?.data) {
		// Update image
		image = {
			filename: image.filename,
			data: image.data,
		};
	}

	return {
		workplace: formState.workplace.id,
		text: formState.text ?? '',
		image,
	};
};

export default function WorkplaceReceipts() {
	const [isLoading, setIsLoading] = useState(false);
	const [formState, setFormState] = useState<FormState>();
	const [formErrors, setFormErrors] = useState<FormErrors<FormState>>({});
	const listData = useSelector((state) => selectListData(state, reduxKey));

	const isBatch =
		listData?.ui?.selectedRows && listData.ui.selectedRows.length > 1;

	const columns: IColumnHeader<Row>[] = [
		{
			Header: 'Workplace',
			id: 'workplace',
			accessor: (d) => d.workplace.name ?? '',
			filterPath: ':name',
		},
		{
			Header: 'Text',
			id: 'text',
			accessor: (d) => d.workplaceReceipt?.text ?? '',
			sortable: false,
			filterable: false,
			filterPath: ':text',
		},
		{
			Header: 'Image',
			id: 'image',
			accessor: (d) => d.workplaceReceipt?.image?.filename ?? '',
			sortable: false,
			filterable: false,
			filterPath: ':image',
			Cell: (d) => {
				const handleChange = handleFileChange(d.original);
				return (
					<FileUpload
						onChange={handleChange}
						accept={fileAccept}
						onClear={handleChange}
						loading={isLoading}
						disabled={isLoading}
						file={d.original.workplaceReceipt?.image}
						thumbnail
					/>
				);
			},
		},
	];

	const editableCells = [
		...(!isBatch
			? [
					{
						header: 'Workplace',
						value: (
							<InputCollectionSelect
								id="workplace-receipt/workplace"
								placeholder="Select Workplace..."
								disabled={!!formState?.id}
								errors={formErrors.workplace}
								value={
									formState?.workplace
										? {
												value: formState.workplace.id,
												label: formState.workplace.name,
										  }
										: null
								}
								handleChange={(_key, option) =>
									setFormState({
										...formState,
										workplace: option
											? { id: option.value, name: option.label }
											: undefined,
									})
								}
								params={{
									filter: ':workplace_receipt==null',
								}}
								optionFormat={(entry) => ({
									value: entry.id,
									label: entry.name,
								})}
								apiPath="/administration/workplaces"
							/>
						),
					},
			  ]
			: []),
		{
			header: 'Text',
			value: (
				<Textarea
					id="text"
					errors={formErrors.text}
					rows={5}
					onChange={(e) => setFormState({ ...formState, text: e.target.value })}
					label="Text"
					value={formState?.text}
				/>
			),
		},
		{
			header: 'Image',
			value: (
				<div>
					<FileUpload
						errors={formErrors.image}
						accept={fileAccept}
						onChange={async (file: File) =>
							setFormState({
								...formState,
								image: await getImagePayload(file),
							})
						}
						onClear={() =>
							setFormState({
								...formState,
								image: null,
							})
						}
						file={formState?.image}
						thumbnail
					/>
				</div>
			),
		},
	];

	const handleFileChange = useCallback(
		(row: Row) => async (file?: File) => {
			setIsLoading(true);
			await handleFileUpload(row, file);
			setFeedback(`Successfully ${file ? 'uploaded' : 'deleted'} image`, 1);
			setIsLoading(false);
		},
		[setIsLoading]
	);
	const handleEditEntry = async () => {
		setIsLoading(true);
		const validatedForm = WorkplaceReceiptSchema.parse(formState);
		const payload = getPayload(validatedForm);
		let result;
		if (formState?.id) {
			result = await editWorkplaceReceipt({ ...payload, id: formState.id });
			setFeedback('Successfully updated receipt', 1);
		} else {
			result = await createWorkplaceReceipt(payload);
			setFeedback('Successfully created receipt', 1);
		}
		setIsLoading(false);
		return result;
	};

	const handleDeleteMultiple = async (rows: Row[]) => {
		const validIds = rows.flatMap((row) =>
			row.workplaceReceipt?.id ? [row.workplaceReceipt.id] : []
		);
		if (validIds.length > 0) {
			setIsLoading(true);
			await batchDeleteWorkplaceReceipt(validIds);
			setFeedback('Successfully deleted receipts', 1);
			setIsLoading(false);
		} else {
			setFeedback('No receipts found for the current selection', 0);
		}
	};

	const handleDeleteEntry = async (_rowId: number, row: Row) => {
		if (row.workplaceReceipt) {
			setIsLoading(true);
			await deleteWorkplaceReceipt(row.workplaceReceipt?.id);
			setFeedback('Successfully deleted receipt', 1);
			setIsLoading(false);
		} else {
			setFeedback('No receipt exists for this workspace', 0);
		}
	};

	const handleEditMultiple = async (rows: Row[]) => {
		setIsLoading(true);

		const createPayloads = rows.flatMap((row) =>
			!row.workplaceReceipt?.id
				? [
					getPayload({
							...formState,
							workplace: row.workplace,
						}),
				  ]
				: []
		);

		const updatePayloads = rows.flatMap((row) =>
			row.workplaceReceipt?.id
				? [
						{
							...getPayload({
								...formState,
								workplace: row.workplace,
							}),
							id: row.workplaceReceipt.id,
						},
				  ]
				: []
		);

		let data: IWorkplaceReceipt[] = [];
		if (createPayloads.length > 0) {
			const result = await batchCreateWorkplaceReceipt(createPayloads);
			data = [...data, result.data];
		}
		if (updatePayloads.length > 0) {
			const result = await batchEditWorkplaceReceipt(updatePayloads);
			data = [...data, result.data];
		}
		setFeedback('Successfully updated receipts', 1);
		setIsLoading(false);
		return { data };
	};

	const handleModalClose = useCallback(() => {
		setFormState(undefined);
		setFormErrors({});
	}, [setFormState, setFormErrors]);

	return (
		<ReactDataWrapper
			accessAreasAllowedToEdit={['Sales Configuration']}
			title="Workplace Receipts"
			columns={columns}
			fetchData={handleFetch}
			preventFetch={isLoading}
			filterable
			reduxKey={reduxKey}
			deleteMultiple={handleDeleteMultiple}
			editMultiple={handleEditMultiple}
			onError={(e: ZodError) => {
				setFormErrors(e.flatten().fieldErrors);
			}}
			enableMultiSelection
			manual
			editEntry={handleEditEntry}
			editableCells={editableCells}
			createEntry={handleEditEntry}
			setInitialEditValues={(row: Row) => {
				setFormState({
					...row.workplaceReceipt,
					workplace: row.workplace,
				});
			}}
			onModalClose={handleModalClose}
			removeEntryAfterDelete={false}
			deleteEntry={handleDeleteEntry}
			deleteConfirm
		/>
	);
}
