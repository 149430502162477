import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SmartTable, Card } from 'dumb';
import RowDragLayer from './components/rowDragLayer.component';

import constants from '../../../utilities/constants';
import './postItemList.css';

const PosItemList = ({
	enableRowClick,
	addTile,
	groupItemInFocus,
	lastInLine,
	posConfigurationsVariantsList,
}) => {
	const [loading, setLoading] = useState(false);

	const onRowItemClick = (clickedItem) => {
		setLoading(true);

		const position = lastInLine ? Number(lastInLine.position) + 1 : 1;

		const tile = {
			background_color: 'ffffff',
			name: clickedItem.label,
			tile_layout_sub_group: null,
			product: clickedItem.value,
			position,
			title_color: '464646',
			type: constants.TILE_TYPE.PRODUCT,
			tile_layout_group: groupItemInFocus,
			description: '',
		};

		addTile(tile).finally(() => setLoading(false));
	};

	const getFormattedData = () => {
		// get array of all unique categories and sort alphabetically
		const categories = posConfigurationsVariantsList
			.reduce((acc, product) => {
				const categoryGroup = product.product_category?.category_group;

				if (acc.includes(categoryGroup)) return acc;

				acc.push(categoryGroup);
				return acc;
			}, [])
			.sort((a, b) => a.localeCompare(b));

		// do some sorting here
		const sortedList = categories.reduce((acc, category) => {
			// find all products within the category and sort them
			const sortedProductsWithinCategory = posConfigurationsVariantsList
				.filter((entry) => entry.product_category?.category_group === category)
				.sort((a, b) => a.label.localeCompare(b.label));

			return acc.concat(sortedProductsWithinCategory);
		}, []);

		// Format data in collumns
		// For example if added fourth column (no headr, this data will be passed as well)
		return sortedList.map((product) => {
			return [
				{
					value: product.product_category?.name,
					data: { ...product, type: constants.TILE_TYPE.VARIANT }, // Passed as [0] object!
				},
				{ value: product.label },
			];
		});
	};

	const headers = [
		{ value: 'Product Category', width: 100, sortable: true },
		{ value: 'Product Variant', width: 300, sortable: true },
	];

	return (
		<Card className="pos-item-list" dataCy="sales-configuration-pos-item-list">
			<SmartTable
				headers={headers}
				data={getFormattedData()}
				draggableRows
				theme="dark"
				search
				formatDraggableOutput={(object) => object[0].data} // Passed as [0] object!
				onRowItemClick={enableRowClick && onRowItemClick}
				loading={loading}
			/>
			<RowDragLayer />
		</Card>
	);
};

PosItemList.propTypes = {
	posConfigurationsVariantsList: PropTypes.array,
	addTile: PropTypes.func,
	groupItemInFocus: PropTypes.number,
	lastInLine: PropTypes.object,
	enableRowClick: PropTypes.bool,
};

PosItemList.defaultProps = {
	posConfigurationsVariantsList: [],
};

export default PosItemList;
