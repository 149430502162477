import React from 'react';

/* eslint-disable react/prop-types */
import { IColumnHeader } from 'types/reactDataWrapper';

export default function getColumns(): IColumnHeader<IPosConfigurationCurrency>[] {
	const columns: IColumnHeader<IPosConfigurationCurrency>[] = [
		{
			Header: 'Currency',
			id: 'currency',
			accessor: (d) => d.currency.code,
			filterPath: ':currency.code',
		},
		{
			Header: 'Markup',
			id: 'markup',
			accessor: (d) => `${d.markup}%`,
			filterPath: ':markup',
		},
		{
			Header: 'Fixed rate',
			id: 'fixed-rate',
			accessor: 'fixed_rate',
			filterPath: ':fixed_rate',
		},
		{
			Header: 'Dynamic',
			id: 'dynamic',
			accessor: 'dynamic',
			filterPath: ':dynamic',
			Cell: (d) => (
				<input disabled type="checkbox" checked={d.value as boolean} />
			),
		},
	];

	return columns;
}
