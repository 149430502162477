import { ActionType } from 'types/redux';
import { IGradeLevelReduxValue } from '../types';

export const SET_GRADE_LEVEL =
    'GRADE_LEVELS/SET_GRADE_LEVEL';
export const UPDATE_GRADE_LEVEL =
    'GRADE_LEVELS/UPDATE_GRADE_LEVEL';
export const RESET_STATE = 'GRADE_LEVELS/RESET_STATE';

export function setGradeLevels(
    payload: IGradeLevelReduxValue
): ActionType {
    return {
        type: SET_GRADE_LEVEL,
        payload,
    };
}

type updateGradeLevel = Partial<IGradeLevelReduxValue>;
export function updateGradeLevels(
    payload: updateGradeLevel
): ActionType {
    return {
        type: UPDATE_GRADE_LEVEL,
        payload,
    };
}

export function resetState(): ActionType {
    return {
        type: RESET_STATE,
    };
}
