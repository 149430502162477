import React, { useState, useEffect } from 'react';

// interfaces
import { HeightToggle } from './../types';

// components
import { CSSTransition } from 'react-transition-group';
import { Button, Icon, ButtonGroup } from 'dumb';

// utilities
import cx from 'classnames';
import { isMobile } from 'detectMobile.vanilla';

type Props = {
	zIndex: number;
	height: number;
	visible: boolean;
	onClose: () => void;
	setHeight: (height: number) => void;
	list?: boolean;
	style?: Record<string, unknown>;
	onExit?: () => void;
	onEnter?: () => void;
	children?: React.ReactNode;
	render?: (height: number) => React.ReactNode;
	propHeight?: number;
};

const OverlayContent = ({
	visible,
	list,
	zIndex,
	style,
	onExit,
	onEnter,
	children,
	onClose,
	render,
	height,
	setHeight,
	propHeight,
}: Props) => {
	const [heightToggleUsed, setHeightToggleUsed] = useState<HeightToggle>();

	const overlayClassnames = cx('overlay', {
		'overlay--active': visible,
		'overlay--list': list,
	});

	const styles = {
		height: `${height}px`,
		...(zIndex && { zIndex: zIndex }),
		...style,
	};

	// can be: 1/3, 2/3, full
	const setHeightHandler = (heightToggle: HeightToggle) => () => {
		let height = 0;
		if (heightToggle === 'full') height = window.visualViewport?.height ?? 0;
		else if (heightToggle === '1/3')
			height = (window.visualViewport?.height ?? 0) / 3;
		else if (heightToggle === '2/3')
			height = (window.visualViewport?.height ?? 0) * 0.75;
		else if (heightToggle === 'propHeight' && propHeight) height = propHeight;

		setHeight(height);
		setHeightToggleUsed(heightToggle);
	};

	// on mount, check if passed height matches any of the height toggles
	useEffect(() => {
		if (height === window.visualViewport?.height)
			setHeightToggleUsed(HeightToggle.full);
		else if (height === (window.visualViewport?.height ?? 0) / 3)
			setHeightToggleUsed(HeightToggle['1/3']);
		else if (height === (window.visualViewport?.height ?? 0) * 0.75)
			setHeightToggleUsed(HeightToggle['2/3']);
		else if (propHeight) setHeightToggleUsed(HeightToggle.propHeight);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<CSSTransition
			classNames="overlay-transition"
			component="div"
			timeout={500}
			in={visible}
			mountOnEnter
			appear
			onExit={onExit}
			onEnter={onEnter}
			unmountOnExit
			style={styles}
			className={overlayClassnames}
		>
			<div data-cy="overlay">
				<div className="overlay__inner">
					{!isMobile() && (
						<div className="overlay__inner__resizer">
							<p>Select height:</p>

							<ButtonGroup>
								{propHeight && (
									<Button
										type={
											heightToggleUsed === 'propHeight' ? 'active' : 'inverted'
										}
										shadow
										onClick={setHeightHandler(HeightToggle.propHeight)}
									>
										Original
									</Button>
								)}
								<Button
									type={heightToggleUsed === '1/3' ? 'active' : 'inverted'}
									shadow
									onClick={setHeightHandler(HeightToggle['1/3'])}
								>
									33%
								</Button>
								<Button
									type={heightToggleUsed === '2/3' ? 'active' : 'inverted'}
									shadow
									onClick={setHeightHandler(HeightToggle['2/3'])}
								>
									66%
								</Button>
								<Button
									type={heightToggleUsed === 'full' ? 'active' : 'inverted'}
									shadow
									onClick={setHeightHandler(HeightToggle.full)}
								>
									100%
								</Button>
							</ButtonGroup>
						</div>
					)}
					<Icon
						className="overlay__inner__close-icon"
						name="close"
						onClick={onClose}
					/>
					{render ? render(height) : children}
				</div>
			</div>
		</CSSTransition>
	);
};

export default OverlayContent;
