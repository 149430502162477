'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Store
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	setInputValue,
	resetState,
	setLoader,
} from './store/passwordForms.actions';
import {
	updateUserActivationKey,
	createUserActivationKey,
} from './store/passwordForms.service';

// Components
import { Input, Button, Loader, Icon } from 'dumb';

// utils
import { getIsPasswordValid } from './utils';

// Other
import phrases from './passwordForms.phrases';
import './passwordForms.css';

class PasswordForms extends Component {
	constructor(props) {
		super(props);

		// Binds
		this._renderPage = this._renderPage.bind(this);
		this._submitActivation = this._submitActivation.bind(this);
		this._submitReset = this._submitReset.bind(this);
		this._onChangeInput = this._onChangeInput.bind(this);

		// Url params
		const params = new URL(document.location).searchParams;

		this.key = params.get('key');
		this.id = parseInt(params.get('id'));

		this.state = {
			showSuccess: false,
			showError: !this.key || !this.id,
			isResetPage: window.location.pathname.includes('reset'),
		};
	}

	componentWillUnmount() {
		this.props.resetState();
	}

	_submitActivation() {
		const { activePassword, setLoader } = this.props;
		event.preventDefault();
		setLoader(true);

		const payload = {
			id: this.id,
			key: this.key,
			password: activePassword,
		};

		updateUserActivationKey(payload)
			.then(() => {
				this.setState(() => ({ showSuccess: true }));
			})
			.finally(() => setLoader(false));
	}

	_submitReset() {
		const { resetEmail } = this.props;
		event.preventDefault();
		setLoader(true);

		createUserActivationKey({ email: resetEmail })
			.then(() => {
				this.setState(() => ({ showSuccess: true }));
				setLoader(false);
			})
			.catch(() => {
				setLoader(false);
			});
	}

	_handleOnClose() {
		window.location.href = 'https://e-campus.joejuice.com/';
	}

	_handleOnReset() {
		window.location.pathname = 'reset';
	}

	/**
	 * @function _onChangeInput
	 * @description Handler for onChange event for inputs (password, repeats)
	 */
	_onChangeInput(e, type) {
		const value = e.target ? e.target.value : e;
		this.props.setInputValue({ value, type });
	}

	_renderResetEmail() {
		const { resetEmail } = this.props;
		const isValid = resetEmail.length > 4;

		return (
			<div className="password__content">
				<h1>{phrases.HEADING__RESET}</h1>
				<Input
					id="email"
					type="email"
					label={phrases.EMAIL}
					value={resetEmail}
					onChange={(e) => this._onChangeInput(e, 'resetEmail')}
					placeholder={phrases.EMAIL_PLACEHOLDER}
				/>
				<Button
					className="password__content__submit"
					disabled={!isValid}
					onClick={this._submitReset}
				>
					{phrases.BUTTON__RESET}
				</Button>
			</div>
		);
	}

	_renderActivatePage() {
		const { activePassword, activeRepeat } = this.props;
		const isPasswordValid = getIsPasswordValid(activePassword, activeRepeat);

		const canSubmitPassword =
			isPasswordValid.lowerCase &&
			isPasswordValid.upperCase &&
			isPasswordValid.lenghtCase &&
			isPasswordValid.numberCase &&
			isPasswordValid.specialCharacterCase &&
			isPasswordValid.passwordsMatch;

		return (
			<div className="password__content">
				<h1>{phrases.HEADING__ACTIVATE}</h1>

				<div className="password__content__input-wrapper">
					<Input
						type="password"
						id="activate"
						label="Password"
						value={activePassword}
						onChange={(e) => this._onChangeInput(e, 'activePassword')}
						placeholder="Type your password..."
					/>
					<div className="password__content__input-wrapper__input">
						<Input
							type="password"
							id="activate-2"
							label="Repeat password"
							value={activeRepeat}
							onChange={(e) => this._onChangeInput(e, 'activeRepeat')}
							placeholder="Repeat your password..."
						/>
					</div>
				</div>

				<div className="password__content__button-wrapper">
					<Button
						className="password__content__submit"
						disabled={!canSubmitPassword}
						onClick={this._submitActivation}
					>
						{phrases.BUTTON__SUBMIT}
					</Button>
				</div>

				<div className="password__content__footnote">
					<div
						className={cx('password__content__footnote__row', {
							'password__content__footnote__row--valid':
								isPasswordValid.lenghtCase,
							'password__content__footnote__row--not-valid':
								!isPasswordValid.lenghtCase,
						})}
					>
						<div className="password__content__footnote__row__icon">
							<Icon name={isPasswordValid.lenghtCase ? 'check' : 'close'} />
						</div>
						{phrases.PASSWORD_LENGTH_PASS}
					</div>
					<div
						className={cx('password__content__footnote__row', {
							'password__content__footnote__row--valid':
								isPasswordValid.upperCase,
							'password__content__footnote__row--not-valid':
								!isPasswordValid.upperCase,
						})}
					>
						<div className="password__content__footnote__row__icon">
							<Icon name={isPasswordValid.upperCase ? 'check' : 'close'} />
						</div>
						{phrases.PASSWORD_UPERCASE_PASS}
					</div>
					<div
						className={cx('password__content__footnote__row', {
							'password__content__footnote__row--valid':
								isPasswordValid.lowerCase,
							'password__content__footnote__row--not-valid':
								!isPasswordValid.lowerCase,
						})}
					>
						<div className="password__content__footnote__row__icon">
							<Icon name={isPasswordValid.lowerCase ? 'check' : 'close'} />
						</div>
						{phrases.PASSWORD_LOWERCASE_PASS}
					</div>
					<div
						className={cx('password__content__footnote__row', {
							'password__content__footnote__row--valid':
								isPasswordValid.numberCase,
							'password__content__footnote__row--not-valid':
								!isPasswordValid.numberCase,
						})}
					>
						<div className="password__content__footnote__row__icon">
							<Icon name={isPasswordValid.numberCase ? 'check' : 'close'} />
						</div>
						{phrases.PASSWORD_ONE_DIGIT}
					</div>
					<div
						className={cx('password__content__footnote__row', {
							'password__content__footnote__row--valid':
								isPasswordValid.specialCharacterCase,
							'password__content__footnote__row--not-valid':
								!isPasswordValid.specialCharacterCase,
						})}
					>
						<div className="password__content__footnote__row__icon">
							<Icon
								name={isPasswordValid.specialCharacterCase ? 'check' : 'close'}
							/>
						</div>
						{phrases.PASSWORD_SPECIAL_CHARACTER}
					</div>
					<div
						className={cx('password__content__footnote__row', {
							'password__content__footnote__row--valid':
								isPasswordValid.passwordsMatch,
							'password__content__footnote__row--not-valid':
								!isPasswordValid.passwordsMatch,
						})}
					>
						<div className="password__content__footnote__row__icon">
							<Icon name={isPasswordValid.passwordsMatch ? 'check' : 'close'} />
						</div>
						{phrases.PASSWORD_MATCH}
					</div>
				</div>
			</div>
		);
	}

	_renderSuccess() {
		return (
			<div className="password__content">
				<h1>{phrases.HEADING__SUCCESS}</h1>
				<p>{phrases.SUBHEADING__SUCCESS}</p>

				<div className="password__content__buttons">
					<Button
						className="password__content__submit"
						onClick={() => this._handleOnClose()}
					>
						{phrases.BUTTON__HOME}
					</Button>
				</div>
			</div>
		);
	}

	_renderWrong() {
		return (
			<div className="password__content">
				<h1>{phrases.HEADING__FAIL}</h1>
				<p>{phrases.ERROR__OOPS}</p>

				<div className="password__content__buttons">
					<Button
						className="password__content__submit"
						onClick={() => this._handleOnReset()}
					>
						{phrases.BUTTON__RESET}
					</Button>
					<Button
						className="password__content__submit"
						onClick={() => this._handleOnClose()}
					>
						{phrases.BUTTON__HOME}
					</Button>
				</div>
			</div>
		);
	}

	_renderPage() {
		const { isResetPage, showError } = this.state;

		if (isResetPage) {
			return this._renderResetEmail();
		} else {
			if (showError) return this._renderWrong();
			return this._renderActivatePage();
		}
	}

	render() {
		const { loader } = this.props;
		const { showSuccess } = this.state;

		const className = cx('password', {});

		const renderSucessPage = showSuccess;
		const renderLoader = !showSuccess && loader;
		const renderPage = !showSuccess && !loader;

		return (
			<div className={className}>
				<div className="password__close">
					<Button
						type="inverted"
						size="tiny"
						onClick={() => this._handleOnClose()}
					>
						<Icon name="close" />
					</Button>
				</div>

				<div className="password__image" />

				{/* this are all mutually exclusive */}
				{renderSucessPage && this._renderSuccess()}
				{renderLoader && <Loader size="medium" />}
				{renderPage && this._renderPage()}
			</div>
		);
	}
}

PasswordForms.propTypes = {
	loader: PropTypes.bool,
	activePassword: PropTypes.string,
	activeRepeat: PropTypes.string,
	resetEmail: PropTypes.string,

	setInputValue: PropTypes.func,
	resetState: PropTypes.func,
	setLoader: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setInputValue,
			resetState,
			setLoader,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		activePassword: store.passwordForms.data.activePassword,
		activeRepeat: store.passwordForms.data.activeRepeat,
		resetEmail: store.passwordForms.data.resetEmail,
		loader: store.passwordForms.ui.loader,
	};
};

export default connectWithStore(
	PasswordForms,
	mapStateToProps,
	mapDispatchToProps
);
