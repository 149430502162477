import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import TileDirectory from 'smart/tileDirectory/tileDirectory';

const Suppliers = React.lazy(() =>
	import(
		/* webpackChunkName: "Suppliers" */ 'inventory/suppliers/suppliers.container.tsx'
	)
);

const Units = React.lazy(() =>
	import(/* webpackChunkName: "Units" */ 'inventory/units/units.container.tsx')
);

const GlobalRawProducts = React.lazy(() =>
	import(
		/* webpackChunkName: "GlobalRawProducts" */ 'inventory/globalRawProducts/globalRawProducts.container.tsx'
	)
);

const IdealTemplates = React.lazy(() =>
	import(
		/* webpackChunkName: "IdealTemplates" */ 'inventory/idealTemplates/workplaces.container.tsx'
	)
);

const StoreCalendar = React.lazy(() =>
	import(
		/* webpackChunkName: "StoreCalendar" */ 'inventory/storeCalendar/storeCalendar.container.tsx'
	)
);

const StoreInventory = React.lazy(() =>
	import(
		/* webpackChunkName: "StoreInventory" */ 'inventory/storeInventory/storeInventory.container.tsx'
	)
);

const InventoryControl = React.lazy(() =>
	import(
		/* webpackChunkName: "InventoryControl" */ 'inventory/inventoryControl/inventoryControl.container.tsx'
	)
);

export const getInventoryControlRoutes = (hasInventoryControl) => {
	return [
		{
			element: <Outlet />,
			path: 'inventory',
			title: 'inventory',
			allowedAccessControlLevels: ['Inventory Admin'],
			icon: require('@mui/icons-material/Inventory').default,
			children: [
				{
					index: true,
					element: <TileDirectory pathName="inventory" />,
				},
				{
					element: (
						<Suspense fallback="Loading">
							<Units />
						</Suspense>
					),
					path: 'units',
					title: 'Units',
					description: 'Manage Units',
					allowedAccessControlLevels: ['Inventory Admin'],
				},
				{
					element: (
						<Suspense fallback="Loading">
							<Suppliers />
						</Suspense>
					),
					path: 'suppliers',
					title: 'Suppliers',
					description: 'Manage Suppliers',
					allowedAccessControlLevels: ['Inventory Admin'],
				},
				{
					element: (
						<Suspense fallback="Loading">
							<GlobalRawProducts />
						</Suspense>
					),
					path: 'global-ingredients',
					title: 'Global Ingredients',
					description: 'Manage Global Ingredients',
					allowedAccessControlLevels: ['Inventory Admin'],
				},
				{
					element: (
						<Suspense fallback="Loading">
							<IdealTemplates />
						</Suspense>
					),
					path: 'ideal-templates',
					title: 'Ideal Templates',
					description: 'Manage Ideal Templates',
					allowedAccessControlLevels: ['Inventory Admin'],
				},
				{
					element: (
						<Suspense fallback="Loading">
							<StoreCalendar />
						</Suspense>
					),
					path: 'store-calendar',
					title: 'Store Calendar',
					description: 'Manage Store Calendar',
					allowedAccessControlLevels: ['Inventory Admin'],
				},
				{
					element: (
						<Suspense fallback="Loading">
							<StoreInventory />
						</Suspense>
					),
					path: 'store-inventory',
					title: 'Store Inventory',
					description: 'View Store Inventory',
					allowedAccessControlLevels: ['Inventory Admin'],
				},
				...(hasInventoryControl
					? [
							{
								element: (
									<Suspense fallback="Loading">
										<InventoryControl />
									</Suspense>
								),
								path: 'inventory-control',
								title: 'Inventory Control',
								description: 'Manage Inventory Control',
								allowedAccessControlLevels: ['Inventory Admin'],
							},
					  ]
					: []),
			],
		},
	];
};
