import { IColumnHeader } from 'types/reactDataWrapper';
import { Tag } from "dumb";
import React from "react";

export default function getColumns(): IColumnHeader<IAdministrationGradeLevel>[] {
    return [
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            filterPath: ':name',
        },
        {
            Header: 'Value',
            id: 'value',
            accessor: 'value',
            filterPath: ':value',
        },
        {
            Header: 'Color',
            id: 'color',
            accessor: 'color',
            filterPath: ':color',
            Cell: (d) =>
                d.value ? (
                    <Tag id="color" color={d.value}>
                        {d.value}
                    </Tag>
                ) : null,
        },
    ];
}
