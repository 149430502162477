'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import ShiftplannerReportContainerHotbar from './shiftplannerReport.hotbar';
import { Button, Loader, ButtonLoader } from 'dumb';
import Hotdamnbar from 'hotdamnbar';
import ShiftplannerMarketsReport from './components/shiftplannerMarkets.component';

// Store
import { connectWithStore, store } from 'appState';
import * as actions from './shiftplannerReport.actions';

// Services and tools
import PrintViewService from 'printView/printView.service';
import { fetchShiftplannerReport, fetchShiftplannerReportPrWorkplace, downloadShiftplannerCSV } from './services';
import { abortFetchShiftplannerReport } from './services/shiftplannerReport.service';
import _isEmpty from 'lodash/isEmpty';

// Other
import phrases from './shiftplannerReport.phrases';
import moment from 'moment';
import './shiftplannerReport.css';

class ShiftplannerReportContainer extends Component {
	constructor(props) {
		super(props);

		this.totalColSpan = 39;
		this.printId = 'shiftplannerReport';

		this.state = {
			loadingPdf: false,
			loadingCSV: false,
		};

		this.onHotbarChange = this.onHotbarChange.bind(this);
		this.abortCalls = this.abortCalls.bind(this);
		this.handleFetchShiftplannerPrWorkplace = this.handleFetchShiftplannerPrWorkplace.bind(this);
		this._downloadCSV = this._downloadCSV.bind(this);
		this._printToPdf = this._printToPdf.bind(this);
		this.PrintViewService = new PrintViewService({
			id: this.printId,
			title: 'Shiftplanner report',
		});
	}

	abortCalls() {
		// store.dispatch(actions.setLoadingReport(false));
		abortFetchShiftplannerReport();
	}

	onHotbarChange(event) {
		// Initiate loading and reset report
		store.dispatch(actions.cleanData());
		store.dispatch(actions.setLoadingReport(true));

		// Extract hotdamnbar data
		const endDate = event.results.Period.getEndDate();
		const startDate = event.results.Period.getStartDate();
		const marketsId = event.results.Markets.results ? event.results.Markets.results : null;
		const storesId = event.results.Stores.results ? event.results.Stores.results : null;
		const entriesType = event.results['Hourly division'].results ? event.results['Hourly division'].results[0] : null;
		const useAuditGrids = event.results['Grid options'].results ?
			event.results['Grid options'].results[0].value === 'AUDIT' :
			false;

		const hotbarData = {
			endDate,
			startDate,
			marketsId,
			storesId,
			entriesType,
			useAuditGrids,
		};

		store.dispatch(actions.showHalfHourly(entriesType.value !== 'HOURLY'));
		store.dispatch(actions.setHotbarData(hotbarData));

		// Fetch report with updated hotdamnbar changes
		return fetchShiftplannerReport(hotbarData)
			.then(() => {
				store.dispatch(actions.setLoadingReport(false));
			})
			.catch((e) => {
				store.dispatch(actions.setLoadingReport(false));
			});
	}

	/**
	 * @function handleFetchShiftplannerPrWorkplace
	 * @description Wrapper for fetching workplace data
	 * @param {Object} entry : The clicked workplace row
	 */
	handleFetchShiftplannerPrWorkplace(entry = {}) {
		return fetchShiftplannerReportPrWorkplace(this.props.hotbarData, entry);
	}

	/**
	 * @function _printToPdf
	 * @description Print to pdf method
	 */
	_printToPdf() {
		this.setState(() => ({
			loadingPdf: true,
		}));
		this.PrintViewService.printToPdf().then(() => {
			this.setState(() => ({
				loadingPdf: false,
			}));
		});
	}

	/**
	 * @function _downloadCSV
	 * @description Download CSV
	 */
	_downloadCSV() {
		const { hotbarData } = this.props;
		const period = {
			from: hotbarData.startDate.format('YYYY-MM-DD'),
			to: hotbarData.endDate.format('YYYY-MM-DD'),
		};
		const workplaces = hotbarData.storesId;
		const markets = hotbarData.marketsId;

		this.setState(() => ({
			loadingCSV: true,
		}));

		downloadShiftplannerCSV(markets, workplaces, period).then(() => {
			this.setState(() => ({
				loadingCSV: false,
			}));
		});
	}

	_renderReport() {
		const { shiftplannerReport, shiftplannerReportPrWorkplace, showHalfHourly, hotbarData } = this.props;

		if (_isEmpty(shiftplannerReport)) return <div>{phrases.NO_DATA}</div>;
		const yearDifference = hotbarData.endDate.format('YYYY') - hotbarData.startDate.format('YYYY');
		// Map over different markets
		return Object.keys(shiftplannerReport).map((marketName, i) => {
			// Map over stores and total in market
			return Object.keys(shiftplannerReport[marketName])
				.sort((a, b) => {
					// First entry should be average. Since keys are string we can find the 'average' key by checking for length.
					// Length of 'average' is 7 so we are just checking if its larger than 4.
					// All other entries are mapped by week number, e.g. '41' or '3'

					if (a.length > 4) {
						return -1;
					}

					if (yearDifference > 0) {
						if (
							shiftplannerReport?.[marketName]?.[a]?.total?.period === undefined ||
							shiftplannerReport?.[marketName]?.[b]?.total?.period === undefined
						)
							return 0;

						const date = moment(shiftplannerReport[marketName][a].total.period.to, 'YYYY-MM-DD');
						const date2 = moment(shiftplannerReport[marketName][b].total.period.to, 'YYYY-MM-DD');

						return date.diff(date2, 'weeks');
					}

					return a.length > 4 ? -1 : 1;
				})
				.map((entryName, k) => {
					// If a table is Empty
					if (
						_isEmpty(shiftplannerReport[marketName][entryName].stores) &&
						_isEmpty(shiftplannerReport[marketName][entryName].total)
					)
						return;

					// Construct report data in right order
					const reportData = [
						...shiftplannerReport[marketName][entryName].stores,
						shiftplannerReport[marketName][entryName].total,
					];

					const title = entryName === 'average' ? `${marketName}, Average Summary` : `${marketName}, Week ${entryName}`;

					const subtitle = `${reportData[0].period.from} - ${reportData[0].period.to}`;

					return (
						<ShiftplannerMarketsReport
							key={marketName + i + k}
							totalColSpan={this.totalColSpan}
							title={title}
							reportData={reportData}
							showHalfHourly={showHalfHourly}
							printId={this.printId}
							subtitle={subtitle}
							shiftplannerReportPrWorkplace={shiftplannerReportPrWorkplace}
							fetchShiftplannerReportPrWorkplace={this.handleFetchShiftplannerPrWorkplace}
						/>
					);
				});
		});
	}

	render() {
		const { loadingReport } = this.props;
		const { loadingCSV, loadingPdf } = this.state;

		return (
			<div className="shiftplanner-report">
				<div className="shiftplanner-report__header">
					<Hotdamnbar
						onChange={this.onHotbarChange}
						hotbar={ShiftplannerReportContainerHotbar}
						onFocusInitialFocus={this.abortCalls}
						onBlurCallback
					/>
					<div className="shiftplanner-report__buttons">
						<Button label={phrases.CSV} type="inverted" shadow onClick={this._downloadCSV}>
							{loadingCSV ? <ButtonLoader loading theme="dark" /> : <span className="icon icon--file_download" />}
						</Button>
						<Button label={phrases.PDF} type="inverted" shadow onClick={this._printToPdf}>
							{loadingPdf ? <ButtonLoader loading theme="dark" /> : <span className="icon icon--file_download" />}
						</Button>
					</div>
				</div>

				{loadingReport ? <Loader /> : <div id={this.printId}>{this._renderReport()}</div>}
			</div>
		);
	}
}

ShiftplannerReportContainer.propTypes = {
	shiftplannerReport: PropTypes.object,
	hotbarData: PropTypes.object,
	loadingReport: PropTypes.bool,
	showHalfHourly: PropTypes.bool,
	shiftplannerReportPrWorkplace: PropTypes.array,
};

const mapStateToPropsFactory = (store) => {
	return {
		shiftplannerReport: store.shiftplannerReport.data.shiftplannerReport,
		shiftplannerReportPrWorkplace: store.shiftplannerReport.data.shiftplannerReportPrWorkplace,
		hotbarData: store.shiftplannerReport.data.hotbarData,
		loadingReport: store.shiftplannerReport.ui.loadingReport,
		showHalfHourly: store.shiftplannerReport.ui.showHalfHourly,
	};
};

export default connectWithStore(ShiftplannerReportContainer, mapStateToPropsFactory);
