'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { validAccess } from 'accessControl';
import { push } from 'redux-first-history';

// components
import { Button, Input, InputCollectionSelect, SingleDatePickerInput, Icon, Modal, Tooltip } from 'dumb';
import TenantTransferModal from './components/tenantTransferModal/tenantTransferModal.component';
import getColumns from 'reactDataWrapperColumns/hr/employments.columns';

import { setEmploymentEditValues, updateEmploymentList, resetState } from './store/employmentsList.actions';
import {
	fetchEmploymentList,
	editEmploymentList,
	addEmploymentList,
	deleteEmploymentList,
} from './employmentsList.service';

import _get from 'lodash/get';

import phrases from './employmentsList.phrases';
import constants from 'services/constants';

import './employmentList.css';

import { removeTermination } from './../terminateEmployee/store/terminateEmployee.service';

const reduxKey = 'hr/employments';
class EmploymentList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeEmployments: false,
			showUndoTerminationModal: false,
			undoTerminationLoading: false,
			showTenantTransferModal: false,
		};

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.rollbackTermination = this.rollbackTermination.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this._getSelectedDate = this._getSelectedDate.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this._renderActions = this._renderActions.bind(this);
		this.hideTerminationModal = this.hideTerminationModal.bind(this);
		this._toggleActiveFilter = this._toggleActiveFilter.bind(this);
		this.getActionButtons = this.getActionButtons.bind(this);
		this.toggleShowTenantTransferModal = this.toggleShowTenantTransferModal.bind(this);

		this.columns = getColumns();
	}

	addEntry() {
		const { activeEmployments } = this.state;
		const { rowEntry, personId } = this.props;

		const payload = {
			id: rowEntry.id,
			person: personId,
			include_in_salary_export: rowEntry.includeInSalaryExport,
			has_food_permit: rowEntry.hasFoodPermit,
			company: _get(rowEntry, 'company.value', null),
			started: rowEntry.started
				? moment.utc(rowEntry.started, constants.shortDate).format(constants.shortDate)
				: moment.utc().format(constants.shortDate),
			...(rowEntry.ended && {
				ended: moment.utc(rowEntry.ended, constants.shortDate).format(constants.shortDate),
			}),

			...(rowEntry.workplace && {
				workplace: _get(rowEntry, 'workplace.value', null),
			}),
			...(rowEntry.salaryExportId && {
				salary_export_id: rowEntry.salaryExportId,
			}),
			...(rowEntry.employeeMealAppAllowance && {
				employee_meal_app_allowance: rowEntry.employeeMealAppAllowance.value?.id,
			}),
		};

		return addEmploymentList(payload)
			.then((res) => {
				// if no ended prop passed
				if (!_get(res, 'data[0].ended', false)) return false;

				if (activeEmployments)
					// check if ended prop is after todays date
					return moment.utc(res.data[0].ended).isAfter(Date.now(), 'day') ? res : true;
				return res;
			})
			.catch((e) => {
				throw e;
			});
	}

	editEntry() {
		const { rowEntry, employmentsList } = this.props;

		const payloadToUpdate = {
			id: rowEntry.id,
			started: moment.utc(rowEntry.started, constants.shortDate).format(constants.shortDate),
			ended: moment.utc(rowEntry.ended, constants.shortDate).format(constants.shortDate),

			include_in_salary_export: !!rowEntry.includeInSalaryExport,
			has_food_permit: !!rowEntry.hasFoodPermit,
			workplace: rowEntry.workplace?.value ?? null,
			...(rowEntry.salaryExportId && {
				salary_export_id: rowEntry.salaryExportId,
			}),
			...(rowEntry.terminationReason && {
				terminationReason: rowEntry.terminationReason.value,
				terminationId: rowEntry.terminationId,
			}),
			employee_meal_app_allowance: rowEntry.employeeMealAppAllowance?.value?.id ?? null,
		};

		// find the employment from rDW list
		const originalEmployment = employmentsList.find((entry) => entry.id === rowEntry.id);

		// find what values were updated
		const payload = {
			id: payloadToUpdate.id,
			has_food_permit: payloadToUpdate.has_food_permit,
			include_in_salary_export: payloadToUpdate.include_in_salary_export,
			...(payloadToUpdate.ended !== originalEmployment.ended && {
				ended: payloadToUpdate.ended,
			}),
			...(payloadToUpdate.started !== originalEmployment.started && {
				started: payloadToUpdate.started,
			}),
			...(payloadToUpdate.started !== originalEmployment.started && {
				started: payloadToUpdate.started,
			}),
			...(payloadToUpdate.salary_export_id !== originalEmployment.salary_export_id && {
				salary_export_id: payloadToUpdate.salary_export_id,
			}),
			...(payloadToUpdate.workplace !== originalEmployment?.workplace?.id && {
				workplace: payloadToUpdate.workplace,
			}),
			...(payloadToUpdate.terminationReason !==
				// eslint-disable-next-line camelcase
				originalEmployment?.termination?.termination_reason?.id && {
				terminationReason: payloadToUpdate.terminationReason,
				terminationId: payloadToUpdate.terminationId,
			}),
			...(payloadToUpdate.employee_meal_app_allowance !== originalEmployment.employee_meal_app_allowance && {
				employee_meal_app_allowance: payloadToUpdate.employee_meal_app_allowance,
			}),
		};

		return editEmploymentList(payload);
	}

	deleteEntry(id) {
		return deleteEmploymentList(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			type: {
				value: _get(data, 'type', ''),
				label: _get(data, 'type', ''),
			},
			company: _get(data, 'company.name', ''),
			...(data.workplace && {
				workplace: {
					value: _get(data, 'workplace.id', ''),
					label: _get(data, 'workplace.name', ''),
				},
			}),
			started: data.started ? moment.utc(data.started) : '',
			ended: data.ended ? moment.utc(data.ended) : '',
			includeInSalaryExport: _get(data, 'include_in_salary_export', ''),
			hasFoodPermit: _get(data, 'has_food_permit', ''),
			salaryExportId: _get(data, 'salary_export_id', ''),
			terminationId: _get(data, 'termination.id', ''),
			terminationReason: {
				value: _get(data, 'termination.termination_reason.id', ''),
				label: _get(data, 'termination.termination_reason.description', ''),
			},
			...(data.employee_meal_app_allowance && {
				employeeMealAppAllowance: {
					value: data.employee_meal_app_allowance.id,
					label: data.employee_meal_app_allowance.name,
				},
			}),
			editMode: true,
		};

		this.props.setEmploymentEditValues(payload);
	}

	_getSelectedDate(name) {
		const { rowEntry } = this.props;

		const date = rowEntry[name];

		if (date) return moment.utc(date).format(constants.shortDate);

		return name === 'started'
			? moment.utc().format(constants.shortDate)
			: moment.utc('9999-12-31').format(constants.shortDate);
	}

	getEditableCells() {
		const { rowEntry, user } = this.props;

		const editMode = !!rowEntry.editMode;
		const editWithTermination = !!rowEntry.terminationId && validAccess(user.areas, ['Employment Admin']) && editMode;

		return [
			{
				header: 'Company',
				value: editMode ? (
					<span>{rowEntry.company}</span>
				) : (
					<InputCollectionSelect
						id="company"
						apiPath="/administration/companies"
						placeholder={phrases.SELECT_COMPANY}
						name="company"
						value={_get(rowEntry, 'company', undefined)}
						handleChange={this.editStoreEntry}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
					/>
				),
			},
			{
				header: 'Workplace',
				value: (
					<InputCollectionSelect
						id="workplace"
						apiPath="/shiftplanning/workplaces"
						name="workplace"
						value={_get(rowEntry, 'workplace', undefined)}
						handleChange={this.editStoreEntry}
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						placeholder={phrases.SELECT_WORKPLACE}
					/>
				),
			},
			{
				header: 'Started',
				value: (
					<SingleDatePickerInput
						id="started"
						numberOfMonths={1}
						onChange={(date) => this.editStoreEntry('started', date)}
						selectedDate={this._getSelectedDate('started')}
						appendToBody
						noClockButton
					/>
				),
			},
			{
				header: 'Ended',
				value:
					editWithTermination || !editMode ? (
						<SingleDatePickerInput
							id="ended"
							numberOfMonths={1}
							onChange={(date) => this.editStoreEntry('ended', date)}
							selectedDate={this._getSelectedDate('ended')}
							appendToBody
							noClockButton
						/>
					) : (
						<span>{this._getSelectedDate('ended')}</span>
					),
			},
			{
				header: 'Has food permit',
				value: (
					<Input
						id="hasFoodPermit"
						type="checkbox"
						checked={_get(rowEntry, 'hasFoodPermit', false)}
						onChange={(event) => this.editStoreEntry('hasFoodPermit', event)}
					/>
				),
			},
			{
				header: 'In salary export',
				value: (
					<Input
						id="inSalaryExport"
						type="checkbox"
						checked={_get(rowEntry, 'includeInSalaryExport', false)}
						onChange={(event) => this.editStoreEntry('includeInSalaryExport', event)}
					/>
				),
			},
			{
				header: 'App allowance',
				value: (
					<InputCollectionSelect
						id="employeeMealAppAllowance"
						apiPath="/hr/employee_meal_app_allowances"
						name="employeeMealAppAllowance"
						value={rowEntry.employeeMealAppAllowance}
						handleChange={this.editStoreEntry}
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						clearable
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						placeholder={phrases.SELECT_APP_ALLOWANCE}
						tableTitle={phrases.EMPLOYEE_MEAL_APP_ALLOWANCE_TABLE_TITLE}
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: 'name',
								filterPath: ':name',
							},
							{
								Header: 'Description',
								id: 'desc',
								accessor: 'description',
								filterPath: ':description',
							},
							{
								Header: 'Coffee',
								id: 'coffee',
								accessor: 'coffee',
								filterPath: ':coffee',
							},
							{
								Header: 'Any item',
								id: 'anyItem',
								accessor: 'any_item',
								filterPath: ':any_item',
							},
						]}
						tableReduxKey="employmentsList-/hr/employee_meal_app_allowances"
					/>
				),
			},
			{
				header: 'Salary export id',
				value: (
					<Input
						id="salaryExportId"
						placeholder="Enter salary export id..."
						type="string"
						value={_get(rowEntry, 'salaryExportId', '')}
						onChange={(event) => this.editStoreEntry('salaryExportId', event)}
					/>
				),
			},
			...(editWithTermination
				? [
						{
							header: 'Termination reason',
							value: (
								<InputCollectionSelect
									id="terminationReason"
									placeholder="Select termination reason..."
									value={rowEntry.terminationReason}
									onChange={(event) => this.editStoreEntry('terminationReason', event)}
									apiPath="/hr/termination_reasons"
									params={{
										limit: 300,
										filter: `:legacy==false`,
									}}
									optionFormat={(entry) => ({
										value: entry.id,
										label: entry.description,
									})}
									inputFilterFormat={(input) => `:name=like='%${input}%'`}
								/>
							),
						},
				  ]
				: []),
		];
	}

	_renderActions(rowData) {
		const { user } = this.props;
		if (!validAccess(user?.areas, ['Employment Admin'])) return;

		const hasTermination = !!rowData.original?.termination;

		return (
			<>
				{hasTermination ? (
					<Tooltip
						placement="left"
						text={phrases.UNDO_TERMINATION}
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								type="inverted"
								shadow
								title="Rules"
								onMouseEnter={onMouseEnter}
								refProp={ref}
								onMouseLeave={onMouseLeave}
								onClick={() =>
									this.setState(() => ({
										showUndoTerminationModal: true,
										rowData: rowData.original,
									}))
								}
								size="micro"
							>
								<Icon name="settings_backup_restore" />
							</Button>
						)}
					/>
				) : null}
				<Button
					type="inverted"
					shadow
					title="Rules"
					onClick={
						() => {
							store.dispatch(push(`${window.location.pathname}/employments/${rowData.original.id}`));
						}

						// go('.employments', { employmentId: rowData.original.id })
					}
					size="micro"
				>
					<Icon name="tune" />
				</Button>
			</>
		);
	}

	rollbackTermination() {
		this.setState(() => ({
			undoTerminationLoading: true,
		}));
		removeTermination(this.state.rowData, reduxKey)
			.then(() => {
				this.setState(() => ({
					showUndoTerminationModal: false,
					undoTerminationLoading: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					undoTerminationLoading: false,
				}));
			});
	}

	editStoreEntry(type, e) {
		let value;

		// check if select cleared or value passed
		if (e === null) value = e;
		else {
			value =
				type === 'includeInSalaryExport' || type === 'hasFoodPermit' ? e.target.checked : e.target ? e.target.value : e;
		}

		const updateObject = {
			[type]: value,
		};

		this.props.updateEmploymentList(updateObject);
	}

	_toggleActiveFilter() {
		this.setState((prevState) => ({
			activeEmployments: !prevState.activeEmployments,
		}));
	}

	/**
	 * @function getAdditionalFilters
	 * @description returns buttons for custom area components
	 */
	getAdditionalFilters() {
		const { activeEmployments } = this.state;

		return (
			<Button size="tiny" type={activeEmployments ? '' : 'inverted'} shadow onClick={this._toggleActiveFilter}>
				{phrases.ACTIVE_EMPLOYMENTS_ACTIVE}
			</Button>
		);
	}

	fetchData(state) {
		return fetchEmploymentList(state, this.props.personId);
	}

	hideTerminationModal() {
		this.setState(() => ({
			showUndoTerminationModal: false,
		}));
	}

	getActionButtons() {
		const { selectedRows, tenants } = this.props;

		const canTransferEmployment = tenants.length > 1 && selectedRows.length > 0;

		return canTransferEmployment ? (
			<Tooltip
				text={phrases.TENANT_TRANSFER_TOOLTIP}
				placement="left"
				renderData={(ref, onMouseEnter, onMouseLeave) => (
					<Button
						type="inverted"
						label={phrases.TENANT_TRANSFER_BUTTON_LABEL}
						shadow
						onClick={this.toggleShowTenantTransferModal}
						refProp={ref}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					>
						<Icon name="transfer_within_a_station" />
					</Button>
				)}
			/>
		) : null;
	}

	toggleShowTenantTransferModal() {
		this.setState((prevState) => ({ showTenantTransferModal: !prevState.showTenantTransferModal }));
	}

	render() {
		const { user, selectedRows } = this.props;
		const { activeEmployments, showUndoTerminationModal } = this.state;

		const filterString = activeEmployments ? `:ended=ge='${moment().format(constants.shortDate)}'` : null;
		const showTenantTransferModal = this.state.showTenantTransferModal && selectedRows?.[0];

		return (
			<>
				<ReactDataWrapper
					filterable={false}
					title={phrases.EMPLOYMENT_LIST}
					columns={this.columns}
					fetchData={this.fetchData}
					extraFilters={filterString}
					defaultPageSize={5}
					disableFetchCsvButton
					reduxKey={reduxKey}
					manual
					editEntry={this.editEntry}
					editableCells={this.getEditableCells()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					accessAreasAllowedToEdit={['Employment Admin', 'Person Admin']}
					actionsWidth={60}
					deleteEntry={this.deleteEntry}
					inRowButtons={validAccess(user?.areas, ['Employment Admin'])}
					createEntry={validAccess(user?.areas, ['Employment Admin']) ? this.addEntry : null}
					actions={this._renderActions}
					customAreaComponents={this.getAdditionalFilters()}
					actionRender={this.getActionButtons()}
				/>

				{showUndoTerminationModal ? (
					<Modal
						onClose={this.hideTerminationModal}
						confirmButtonLabel={phrases.UNDO_TERMINATION_MODAL_CONFIRM_BUTTON_LABEL}
						type="confirmation"
						loading={this.state.undoTerminationLoading}
						onConfirmClick={this.rollbackTermination}
						onCancelClick={this.hideTerminationModal}
						header={phrases.UNDO_TERMINATION_MODAL_TITLE}
						isOpen={showUndoTerminationModal}
						zIndex="550"
					>
						<div className="rollback-termination">
							<p>{phrases.UNDO_TERMINATION_MODAL_HEADER}</p>
							<ol>
								<li>{phrases.UNDO_TERMINATION_MODAL_ITEM_ONE}</li>
								<li>{phrases.UNDO_TERMINATION_MODAL_ITEM_TWO}</li>
								<li>{phrases.UNDO_TERMINATION_MODAL_ITEM_THREE}</li>
								<li>{phrases.UNDO_TERMINATION_MODAL_ITEM_FOUR}</li>
							</ol>
						</div>
					</Modal>
				) : null}

				{showTenantTransferModal && (
					<TenantTransferModal employment={selectedRows[0]} onClose={this.toggleShowTenantTransferModal} />
				)}
			</>
		);
	}
}

EmploymentList.propTypes = {
	rowEntry: PropTypes.object,
	personId: PropTypes.number,
	updateEmploymentList: PropTypes.func,
	setEmploymentEditValues: PropTypes.func,
	resetState: PropTypes.func,
	user: PropTypes.obj,
	employmentsList: PropTypes.array,
	selectedRows: PropTypes.array,
	tenants: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setEmploymentEditValues,
			updateEmploymentList,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		rowEntry: store.employmentList.data.employmentEntry,
		user: _get(store, 'userData.user', null),
		employmentsList: _get(store, `listData.[${reduxKey}].data.listData`, []),
		selectedRows: store.listData?.[reduxKey]?.ui?.selectedRows ?? 0,
		tenants: store?.userData?.tenants ?? [],
	};
};

export default connectWithStore(EmploymentList, mapStateToProps, mapDispatchToProps);
