import React, { useState } from 'react';
import PropTypes from 'prop-types';

// interfaces
import { ITileInGroup } from '../../../types';

// redux
import { batch } from 'react-redux';
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import { editProductTile } from '../../../addTileModal/store/addTileModal.actions';
import {
	updatePositionsInApp,
	setActiveTile,
	setActiveTileType,
	setShowAddTileModal,
	setTilesInGroup,
	clearTiles,
} from '../../../posConfigurationLayout.actions';

// components
import { Box } from '@mui/material';
import { Card, Modal } from 'dumb';
import ItemDropZone from './components/itemDropZone/itemDropZone';
import AppLayoutLoader from '../appLayoutLoader/appLayoutLoader';
import AddTileModal from '../../../addTileModal/addTileModal';
import PosLayoutContentWrapper from './posLayoutContentWrapper';

// utils
import _get from 'lodash/get';
import _maxBy from 'lodash/maxBy';
import cx from 'classnames';

// enums/ phrases
import enums from './posLayout.enums';
import phrases from './posLayout.phrases';

type Props = {
	rotateTiles: (
		arg0: Record<string, unknown>,
		arg1: boolean
	) => Promise<IApiRes<ITileInGroup>>;
	removeTile: (arg0: number) => Promise<unknown>;
	layoutId: number;
	activeTile: Record<string, unknown>;
	updateTile: (arg0: unknown) => Promise<unknown>;
	addTile: (arg0: Record<string, unknown>) => Promise<unknown>;
	subType: string;
	disabled: boolean;
};

const PosLayout = ({
	rotateTiles,
	removeTile,
	layoutId,
	activeTile,
	updateTile,
	addTile,
	subType,
	disabled,
}: Props): JSX.Element => {
	const dispatch = useAppDispatch();
	const {
		posConfigurationsVariantsList,
		fetching,
		tilesInGroup,
		imageAssets,
		showAddTileModal,
		groupItemInFocus,
	} = useAppSelector((store) => ({
		imageAssets: store.salesConfigurationPOSLayout.assets,
		showAddTileModal: store.salesConfigurationPOSLayout.showAddTileModal,
		tilesInGroup: store.salesConfigurationPOSLayout.tilesInGroup,
		fetching: store.salesConfigurationPOSLayout.fetchingTile,
		posConfigurationsVariantsList:
			store.salesConfigurationPOSLayout.posConfigurationsVariantsList,
		groupItemInFocus: store.salesConfigurationPOSLayout.groupItemInFocus,
	}));

	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [isModalLoading, setIsModalLoading] = useState(false);
	const [currentDeleteId, setCurrentDeleteId] = useState<number | null>(null);
	const [positionOfTileToAdd, setPositionOfTileToAdd] = useState<number | null>(
		null
	);

	const toggleDeleteModal = (id: number) => {
		setCurrentDeleteId(currentDeleteId ? null : id);
		setIsConfirmModalOpen(!isConfirmModalOpen);
	};

	const openTileModalWrapper = (
		tile: Record<string, any>,
		mode = enums.ADD,
		position = undefined
	) => {
		// get last known position
		const tileGroup = _maxBy(
			Object.values(tilesInGroup),
			(group: ITileInGroup) => parseInt(`${group.position}`, 10)
		);

		if (!tileGroup) return;

		// increment by 1 to add tile to end
		let tilePosition: number = tileGroup.position + 1;

		// if we passed position, overwrite (we pass position when dragging product variant direct from posItemList to the layout)
		if (position) tilePosition = position;

		let editProductTileFunctionProps = {};

		switch (mode) {
		case enums.ADD:
			editProductTileFunctionProps = {
					background_color: 'ffffff',
					title_color: '464646',
					type: 'Product',
				};
			break;
		case enums.ADD_DROP:
			editProductTileFunctionProps = {
					id: tile.id,
					name: _get(tile, 'label', 'unnamed!'),
					product: { name: tile.label, id: tile.value },
					position: tilePosition,
					type: 'Product',
				};
			break;
		case enums.EDIT:
			editProductTileFunctionProps = tile;
			break;
		default:
			break;
		}

		batch(() => {
			dispatch(editProductTile(editProductTileFunctionProps));
			dispatch(setActiveTile(tile));
			dispatch(setActiveTileType('Product'));
			dispatch(setShowAddTileModal(true));
		});
	};

	const moveCard = (dragIndex: number, hoverIndex: number) => {
		dispatch(updatePositionsInApp(dragIndex, hoverIndex));
	};

	const onDrop = () => {
		const payload = tilesInGroup.map((tile, index) => {
			return { id: tile.id, position: index + 1 };
		});

		rotateTiles({ rotations: payload }, true);
	};

	const handleRemoveTile = () => {
		if (!currentDeleteId) return;

		setIsModalLoading(true);

		removeTile(currentDeleteId)
			.then(() => {
				setIsModalLoading(false);
				setCurrentDeleteId(null);
				setIsConfirmModalOpen(false);
			})
			.catch(() => setIsModalLoading(false));
	};

	const handleProductVariantOnDrop = ({
		itemProps,
		position,
	}: {
		itemProps: Record<string, unknown>;
		position: number;
	}) => {
		// this opens the modal with pre-entered values
		openTileModalWrapper(itemProps, enums.ADD_DROP);
		// we save intended position so that right after we add the new tile, we put it in it's intended position
		setPositionOfTileToAdd(position);
	};

	const handleAddTileModalClose = () => {
		dispatch(setShowAddTileModal(false));
	};

	// for lack of a better name, this function will be executed right after a tile is added, and before we do the cleanup logic
	// this is done because we need to have access to the added tile, so we can handle tile rotation logic
	const handlePostTileAddCallback = (addedTile: ITileInGroup[]) => {
		if (!positionOfTileToAdd) return;

		// use position to add tile to correct position
		const tileToAdd: ITileInGroup = {
			...addedTile[0],
			position: positionOfTileToAdd,
		};

		// update positions of existing tiles
		const updatedTiles = tilesInGroup.map((tile) => {
			if (tile.position >= positionOfTileToAdd) {
				tile.position += 1;
			}

			return tile;
		});

		updatedTiles.push(tileToAdd);

		// update tiles in api
		return rotateTiles({ rotations: updatedTiles }, true)
			.then((res) => {
				// replace tiles in redux
				dispatch(clearTiles());
				dispatch(setTilesInGroup(res.data));
			})
			.finally(() => {
				setPositionOfTileToAdd(null);
			});
	};

	return (
		<>
			<Card
				className={cx('pos-layout', {
					'pos-layout__row': subType !== 'Grid',
				})}>
				{fetching && <AppLayoutLoader loading />}

				{disabled && (
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: 'calc(50% - 125px)',
							pointerEvents: 'none',
							span: {
								fontSize: '16px',
								width: '250px',
								textAlign: 'center',
							},
						}}>
						<span>{phrases.LAYOUT_DISABLED}</span>
					</Box>
				)}
				<Box
					sx={{
						overflow: 'auto',
						display: 'flex',
						flexWrap: 'wrap',
					}}>
					<PosLayoutContentWrapper
						tilesInGroup={tilesInGroup}
						subType={subType}
						imageAssets={imageAssets}
						moveCard={moveCard}
						onDrop={onDrop}
						toggleDeleteModal={toggleDeleteModal}
						openTileModalWrapper={openTileModalWrapper}
						handleProductVariantOnDrop={handleProductVariantOnDrop}
					/>

					{!disabled && (
						<ItemDropZone
							openTileModal={() => openTileModalWrapper({ type: 'Product' })}
							onDrop={(props: Record<string, unknown>) =>
								openTileModalWrapper(props, enums.ADD_DROP)
							}
							subTypeRow={subType !== 'Grid'}
						/>
					)}
				</Box>
			</Card>

			{showAddTileModal && (
				<AddTileModal
					isOpen={showAddTileModal}
					handleClose={handleAddTileModalClose}
					layoutId={layoutId}
					imageAssets={imageAssets}
					addTile={addTile}
					updateTile={updateTile}
					layoutGroupId={groupItemInFocus}
					posConfigurationsVariantsList={posConfigurationsVariantsList}
					activeTile={activeTile}
					subTypeRow={subType !== 'Grid'}
					handlePostTileAddCallback={handlePostTileAddCallback}
				/>
			)}

			{isConfirmModalOpen && (
				<Modal
					header="Are you sure?"
					onClose={toggleDeleteModal}
					confirmButtonLabel="Yes, delete!"
					type="confirmation"
					onConfirmClick={handleRemoveTile}
					onCancelClick={toggleDeleteModal}
					isOpen={isConfirmModalOpen}
					loading={isModalLoading}
					zIndex="550"
				/>
			)}
		</>
	);
};

PosLayout.propTypes = {
	removeTile: PropTypes.func,
	layoutId: PropTypes.number,
	activeTile: PropTypes.object,
	updateTile: PropTypes.func,
	addTile: PropTypes.func,
	rotateTiles: PropTypes.func,
	subType: PropTypes.string,
	disabled: PropTypes.bool,
};

export default PosLayout;
