// interfaces
import { ActionType } from 'types/redux';
import { IPosConfigurationVersionCurrenciesReduxValue } from '../types';

export const SET_POS_CONFIGURATION_VERSION_CURRENCY =
	'SALES_CONFIGURATION_POS_CONFIGURATION_VERSION_CURRENCY/SET_POS_CONFIGURATION_VERSION_CURRENCY';
export const UPDATE_POS_CONFIGURATION_VERSION_CURRENCY =
	'SALES_CONFIGURATION_POS_CONFIGURATION_VERSION_CURRENCY/UPDATE_POS_CONFIGURATION_VERSION_CURRENCY';
export const RESET_STATE =
	'SALES_CONFIGURATION_POS_CONFIGURATION_VERSION_CURRENCY/RESET_STATE';

export function setPosConfigurationVersionCurrency(
	payload: IPosConfigurationVersionCurrenciesReduxValue
): ActionType {
	return {
		type: SET_POS_CONFIGURATION_VERSION_CURRENCY,
		payload,
	};
}

export function updatePosConfigurationVersionCurrency(
	payload: Partial<IPosConfigurationVersionCurrenciesReduxValue>
): ActionType {
	return {
		type: UPDATE_POS_CONFIGURATION_VERSION_CURRENCY,
		payload,
	};
}

export function resetState(): ActionType {
	return {
		type: RESET_STATE,
	};
}
