import { get, post, remove } from 'api.vanilla.service';
import { IFetchDataProps } from 'types/reactDataWrapper';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

const BASE_URL = '/administration/joe_pay_partnerships';

const handleError = (err: unknown) => {
	const errorMessage = formatErrorMessage(err);
	setFeedback(errorMessage, 0);
};

export async function fetchMarketPartnerships(state: IFetchDataProps) {
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	try {
		return await get(BASE_URL, params, state.headers);
	} catch (err) {
		handleError(err);
	}
}

export async function createMarketPartnership(
	marketPartnership: Omit<IPartnership, 'id'>
) {
	const payload = {
		...marketPartnership,
		market: marketPartnership.market.id,
	};

	try {
		return await post(BASE_URL, payload);
	} catch (err) {
		handleError(err);
	}
}

export async function deleteMarketPartnership(marketPartnershipId: number) {
	try {
		return await remove(BASE_URL + `/${marketPartnershipId}`);
	} catch (err) {
		handleError(err);
	}
}
