import { IColumnHeader } from 'types/reactDataWrapper';

export default function getColumns(): IColumnHeader<IAdministrationCurrencies>[] {
	return [
		{
			Header: 'Name',
			id: 'name',
			accessor: 'name',
			filterPath: ':name',
		},
	];
}
