/* eslint-disable react/prop-types */
import { IColumnHeader } from 'types/reactDataWrapper';

type SourceProps = 'workplace' | 'tag';

export default function getColumns(
	source: SourceProps
): IColumnHeader<IShiftplanningWorkplaceTag>[] {
	const columns: IColumnHeader<IShiftplanningWorkplaceTag>[] = [
		...(source === 'workplace'
			? [
					{
						Header: 'Tag',
						id: 'tag',
						accessor: (d: IShiftplanningWorkplaceTag) => d.tag.name,
						filterPath: ':tag.name',
					},
			  ]
			: [
					{
						Header: 'Workplace',
						id: 'workplace',
						accessor: (d: IShiftplanningWorkplaceTag) => d.workplace.name,
						filterPath: ':workplace.name',
					},
			  ]),
	];

	return columns;
}
