import { IFetchDataProps } from 'types/reactDataWrapper';
import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './gradeScales.phrases';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw error;
}

export function fetchGradeScales(state: IFetchDataProps): Promise<IApiRes> {
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(
		'/training/grade_scales',
		params,
		null,
		state.headers
	)
		.then((res: IApiRes) => res)
		.catch(handleError);
}

export function addGradeScales(data: Record<string, unknown>): Promise<IApiRes> {
	return post(`/training/grade_scales`, data)
		.then((res: IApiRes) => {
			setFeedback(phrases.GRADE_SCALE_ADDED, 1);
			return res;
		})
		.catch(handleError);
}

export function editGradeScales(id: number, data: Record<string, unknown>): Promise<IApiRes> {
	return post(`/training/grade_scales/${id}`, data)
		.then((res: IApiRes) => {
			setFeedback(phrases.GRADE_SCALE_EDITED, 1);
			return res;
		})
		.catch(handleError);
}

export function deleteGradeScales(id: number): Promise<IApiRes> {
	return remove(`/training/grade_scales/${id}`)
		.then((res: IApiRes) => {
			setFeedback(phrases.GRADE_SCALE_DELETED, 1);
			return res;
		})
		.catch(handleError);
}
