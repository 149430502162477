import React, { ChangeEvent } from 'react';
import { SelectOption } from 'types/select';
import { Input, InputCollectionSelect, Toggle } from 'dumb';
import {getTypeSelectOptions} from "../modules.utils";
import {IModuleReduxValue} from "../types";

type Props = {
    module: IModuleReduxValue;
    editModuleEntry: (
        name: string,
        value: string | SelectOption | boolean | undefined
    ) => void;
};

const ModuleEditableCells = ({
  module,
  editModuleEntry,
}: Props): { header: string; value: JSX.Element }[] => {
    return [
        {
            header: 'Name',
            value: (
                <Input
                    id="name"
                    placeholder="Enter name..."
                    value={module.name}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        editModuleEntry('name', event.target.value)
                    }
                    dataCy="module-name"
                    required
                />
            ),
        },
        {
            header: 'Type',
            value: (
                <InputCollectionSelect
                    id="type"
                    dataCy="module-type-select"
                    placeholder="Select type..."
                    value={module.type as SelectOption}
                    handleChange={(_: string, value: SelectOption) => {
                        editModuleEntry('type', value)
                    }}
                    required
                    cache
                    clearable={false}
                    options={getTypeSelectOptions()}
                    optionFormat={(entry) => ({
                        value: entry.name,
                        label: entry.name,
                    })}
                />
            )
        },
        {
            header: 'Grade Scale',
            value: (
                <InputCollectionSelect
                    id="grade-scale"
                    dataCy="module-grade-scale-select"
                    placeholder="Select Grade Scale..."
                    value={module.grade_scale as SelectOption}
                    handleChange={(_: unknown, value: SelectOption) =>
                        editModuleEntry('grade_scale', value)
                    }
                    required
                    cache
                    apiPath="/training/grade_scales"
                    clearable={false}
                    optionFormat={(entry: IAdministrationGradeScale) => ({
                        value: entry.id,
                        label: entry.name,
                    })}
                />
            )
        },
        {
            header: 'Badge Category',
            value: (
                <InputCollectionSelect
                    id="badge-category"
                    dataCy="module-badge-category-select"
                    placeholder="Select Badge Category..."
                    value={module.badge_category as SelectOption}
                    handleChange={(_: unknown, value: SelectOption) =>
                        editModuleEntry('badge_category', value)
                    }
                    required
                    cache
                    apiPath="/hr/badge_categories"
                    clearable={false}
                    optionFormat={(entry: IHrBadgeCategory) => ({
                        value: entry.id,
                        label: entry.name,
                    })}
                />
            )
        },
        {
            header: 'Moneyball Position',
            value: (
                <InputCollectionSelect
                    id="moneyball-position"
                    dataCy="module-moneyball-position-select"
                    placeholder="Select Moneyball Position..."
                    value={module.moneyball_position as SelectOption}
                    handleChange={(_: unknown, value: SelectOption) =>
                        editModuleEntry('moneyball_position', value)
                    }
                    cache
                    apiPath="/hr/moneyball_positions"
                    clearable={false}
                    optionFormat={(entry: IHrMoneyballPositions) => ({
                        value: entry.id,
                        label: entry.name,
                    })}
                />
            )
        },
        {
            header: 'Module Category',
            value: (
                <InputCollectionSelect
                    id="module-category"
                    dataCy="module-module-category-select"
                    placeholder="Select Module Category..."
                    value={module.module_category as SelectOption}
                    handleChange={(_: unknown, value: SelectOption) =>
                        editModuleEntry('module_category', value)
                    }
                    cache
                    apiPath="/training/module_categories"
                    clearable={false}
                    optionFormat={(entry: IAdministrationModuleCategory) => ({
                        value: entry.id,
                        label: entry.name,
                    })}
                />
            )
        },
        {
            header: 'Sort Order',
            value: (
                <Input
                    id="sort-order"
                    placeholder="Enter sort order..."
                    value={module.sort_order}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        editModuleEntry('sort_order', event.target.value)
                    }
                    dataCy="module-sort-order"
                />
            ),
        },
        {
            header: 'Legacy',
            value: (
                <Toggle
                    id="legacy"
                    dataCy="module-legacy-toggle"
                    toggled={module.legacy}
                    onClick={(event) => editModuleEntry('legacy', event)}
                />
            ),
        },
    ];
};

export default ModuleEditableCells;
