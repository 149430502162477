
export default {
	TYPEFORM_LINK : 'typeform',
	TERMINATE: 'Terminate',
	TERMINATION_DATE: 'End date and reason',
	REASON: 'Reason for ended employment',
	ASSIGNED_SHIFTS: 'Currently Assigned Shifts',
	NO_ASSIGNED_SHIFTS: 'No shifts assigned',
	TERMINATION_WARNING:
		'Terminating an employee will remove the employee from the organization and all their shifts.',
	EFFECTED_SHIFTS: 'Pattern marked shifts will be affected by termination.',
	SELECT_EMPLOYMENT: 'Select employment',
	WORKPLACE: 'Workplace',
	TIME: 'Time',
	DATE: 'Date',
	TERMINATION_ROLLED_BACK: 'Termination rolled back',
	TERMINATION_DOCUMENT: 'Resignation or termination',
	OFFBOARDING: 'OFFBOARDING',
	COMPOSE_OFFBOARDING_EMAIL: 'Compose offboarding email',
	COMPOSE_OFFBOARDING_EMAIL_TOOLTIP: 'Opens mailclient with typeform link',
	COPY_OFFBOARDING_LINK: 'Copy offboarding link',
	OFFBOARDING_BODY_TEXT: `Dear Juicer,
    %0D%0A %0D%0A
	Thank you for your time working in JOE %26 THE JUICE.
	%0D%0A %0D%0A
	We strive to improve, therefore we would truly appreciate it if you would take a couple of minutes to fill out the questions in the following typeform link as your feedback is essential for our further development.
	
	%0D%0A %0D%0A
	We wish you all the best in your future and remember; once a Juicer, always a Juicer.
	
	%0D%0A %0D%0A
	TYPEFORM LINK: `,
	COPY_OFFBOARDING_LINK_TOOLTIP: 'Copy offboarding link to clipboard',
	COPIED: 'Copied',
	EMPLOYMENT_DOCUMENT_TYPE: 'Employment document type',
	EMPLOYMENT_TERMINATED_DOCUMENT_UPLOADED:
		'Employment terminated and document uploaded!',
	EMPLOYMENT_TERMINATED: 'Employment terminated!',
	RESIGNATION_DOCUMENT: 'Resignation document',
	OTHER: 'Other',
	NO_MARKET_ASSIGNED_TO_USER:
		'User has no market reference, cannot get link or compose en email',
	WARNING: 'Warning',
	WARNING_TEXT:
		'This juicer has more then one active employment. Make sure all of this operational responsibilities are removed before terminating this juicer.',
	OR: 'or',
	SIGNATURE: `
	%0D%0A %0D%0A
	Best Regards,
	%0D%0A
	JOE %26 THE JUICE
	`,
};
