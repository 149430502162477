import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import actions from 'reactDataWrapper/reactDataWrapper.actions';

import { ModalStepper, ButtonTile, Overlay, Button } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import moment from 'moment';

// overlay table
import PosConfigurationVersionTable from './../../../posConfigurationVersions/posConfigurationVersions.component';

// batch add
import PosConfigurationsBatchStepFormAdd from './batchAdd/posConfigurationsBatchStepFormAdd';
import PosConfigurationsBatchStepListAdd from './batchAdd/posConfigurationsBatchStepListAdd';
// batch duplicate
import PosConfigurationsBatchStepFormDuplicate from './batchDuplicate/posConfigurationsBatchStepFormDuplicate';
import PosConfigurationsBatchStepListDuplicate from './batchDuplicate/posConfigurationsBatchStepListDuplicate';

import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _uniqBy from 'lodash/uniqBy';
import _isEmpty from 'lodash/isEmpty';

// columns
import TableColumns from './posConfigurationsModalBatch.columns';

// phrases/constants
import phrases from '../../posConfigurations.phrases';
import constants from 'services/constants';

class PosConfigurationsModalBatch extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format(constants.shortDate);
		this.modalRef = React.createRef();

		// very rare case where we have the table using the same url on the same page
		this.marketsUrl = 'posConfigurationBatch/organization/markets';
		this.posConfigurationVersionUrl =
			'posConfigurationBatch/pos/pos_configuration_versions';

		this.state = {
			showOverlay: false,
			overlayType: null,
			activeFilterMarketsOn: true,
			activeFilterMarkets: `:closed=ge='${this.today}'`,
			allowOverflow: true,
		};

		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this.handleToggleActiveFilter = this.handleToggleActiveFilter.bind(this);
		this._getIsBatchAddStepFormValid =
			this._getIsBatchAddStepFormValid.bind(this);
		this.resetSelectedRows = this.resetSelectedRows.bind(this);
		this._onSubmitDuplicateForm = this._onSubmitDuplicateForm.bind(this);
		this._batchDuplicateSubmit = this._batchDuplicateSubmit.bind(this);

		this.marketColumns = TableColumns.marketColumns;
	}

	// BATCH ADD FUNCTIONS
	_onSubmitAddForm() {
		const { batchFormData, addBatchList } = this.props;

		// get rid of duplicates
		const cleanMarkets = _uniqBy(batchFormData.markets, 'value.id');

		const listArray = cleanMarkets.map((entry) => {
			return {
				identifier: _uniqueId('row-'), // used to identify what to edit on step#2
				market: entry.value,
				name: batchFormData.name,
				description: batchFormData.description,
			};
		});

		addBatchList(listArray);

		this._goToStep(3);
	}

	_batchAddSubmit() {
		const { batchList, handleClose, addPosConfiguration } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchList.map((entry) => {
			return {
				market: _get(entry, 'market.id', null),
				name: entry.name,
				description: entry.description,
			};
		});

		addPosConfiguration({ batch: payload })
			.then(() => {
				this.resetSelectedRows(this.marketsUrl);

				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_getIsBatchAddStepFormValid() {
		const { batchFormData } = this.props;

		return !_isEmpty(batchFormData.markets);
	}

	// BATCH DUPLICATE FUNCTIONS
	_getIsBatchDuplicateStepFormValid() {
		const { batchFormData } = this.props;

		return (
			!_isEmpty(batchFormData.posConfigurationVersions) &&
			batchFormData.tenant &&
			!_isEmpty(batchFormData.markets)
		);
	}

	_onSubmitDuplicateForm() {
		const { batchFormData, addBatchList } = this.props;

		// get rid of duplicates
		const cleanMarkets = _uniqBy(batchFormData.markets, 'value');
		const cleanPosConfigurationVersions = _uniqBy(
			batchFormData.posConfigurationVersions,
			'value.id'
		);

		const listArray = _isEmpty(cleanMarkets)
			? cleanPosConfigurationVersions.map((entry) => {
					return {
						identifier: _uniqueId('row-'), // used to identify what to edit
						posConfigurationVersion: entry,
						tenant: batchFormData.tenant,
						market: {
							value: _get(entry, 'value.pos_configuration.market.id', null),
							label: _get(entry, 'value.pos_configuration.market.name', null),
						},
						newMarket: false,
						name: _get(entry, 'value.pos_configuration.name', ''),
						description: _get(entry, 'value.pos_configuration.description', ''),
						activeFrom:
							batchFormData.activeFrom ||
							moment.utc(batchFormData.activeFrom).format(constants.shortDate),
						activeTo: batchFormData.activeTo || '9999-12-31',
					};
			  })
			: cleanMarkets.reduce((acc, currentValue) => {
					const values = cleanPosConfigurationVersions.map((entry) => {
						return {
							identifier: _uniqueId('row-'), // used to identify what to edit
							posConfigurationVersion: entry,
							tenant: batchFormData.tenant,
							market: currentValue,
							newMarket: true,
							name: _get(entry, 'value.pos_configuration.name', ''),
							description: _get(
								entry,
								'value.pos_configuration.description',
								''
							),
							activeFrom:
								batchFormData.activeFrom ||
								moment.utc().format(constants.shortDate),
							activeTo: batchFormData.activeTo || '9999-12-31',
						};
					});

					return [...acc, ...values];
			  }, []);

		addBatchList(listArray);

		this._goToStep(5);
	}

	_batchDuplicateSubmit() {
		const { batchList, handleClose, addPosConfiguration } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchList.map((entry) => {
			return {
				pos_configuration_version: _get(
					entry,
					'posConfigurationVersion.value.id',
					null
				),
				name: entry.name,
				description: entry.description,
				...(entry.newMarket && {
					tenant: _get(entry, 'tenant.value.id', null),
					market: _get(entry, 'market.value.id', null),
					to_go_tax: _get(entry, 'toGoTax.value.id', null),
					to_stay_tax: _get(entry, 'toStayTax.value.id', null),
				}),
				...((entry.activeFrom || entry.activeTo) && {
					active: {
						...(entry.activeFrom && {
							from: moment.utc(entry.activeFrom).format(constants.shortDate),
						}),
						...(entry.activeTo && {
							to: moment.utc(entry.activeTo).format(constants.shortDate),
						}),
					},
				}),
			};
		});

		// duplicate pos configuration
		addPosConfiguration({ batch: payload }, true)
			.then(() => {
				this.resetSelectedRows(this.marketsUrl);
				this.resetSelectedRows(this.posConfigurationVersionUrl);

				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	// GENERAL MODAL FUNCTIONS
	onOverlayOpen() {
		const { batchFormData } = this.props;
		const { overlayType } = this.state;

		const dataToSetColumnsSelected =
			overlayType === this.marketsUrl
				? batchFormData.markets
				: batchFormData.posConfigurationVersions;

		if (_isEmpty(dataToSetColumnsSelected)) return;

		this.resetSelectedRows(overlayType);

		// set already selected data in rdw store as clicked
		dataToSetColumnsSelected.map((entry) => {
			store.dispatch(
				actions.addRowToSelection({
					reduxKey: overlayType,
					data: entry.value,
				})
			);
		});
	}

	fetchData(state) {
		const { fetchMarkets } = this.props;

		return fetchMarkets(state);
	}

	handleToggleOverlay(type) {
		const { listMarkets, listPosConfigurationVersions, updateBatchForm } =
			this.props;

		if (this.state.showOverlay) {
			if (this.state.overlayType === this.marketsUrl) {
				const selectedRowsInList = _get(listMarkets, 'ui.selectedRows', []);

				updateBatchForm({
					markets: selectedRowsInList.map((entry) => {
						return { label: entry.name, value: entry };
					}),
				});
			}
			if (this.state.overlayType === this.posConfigurationVersionUrl) {
				const selectedRowsInList = _get(
					listPosConfigurationVersions,
					'ui.selectedRows',
					[]
				);

				updateBatchForm({
					posConfigurationVersions: selectedRowsInList.map((entry) => {
						return { label: entry.name, value: entry };
					}),
				});
			}
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	handleToggleActiveFilter() {
		this.setState((prevState) => ({
			activeFilterMarketsOn: !prevState.activeFilterMarketsOn,
			activeFilterMarkets: prevState.activeFilterMarketsOn
				? ''
				: `:closed=ge='${this.today}'`,
		}));
	}

	getAdditionalPosConfigurationTableFilters() {
		const { activeFilterMarketsOn } = this.state;

		return (
			<Button
				size="tiny"
				type={activeFilterMarketsOn ? '' : 'inverted'}
				shadow
				onClick={this.handleToggleActiveFilter}
			>
				{phrases.MODAL_BATCH_STEP_FORM_MARKETS_TABLE_ACTIVE_FILTER}
			</Button>
		);
	}

	isConfirmButtonDisabled() {
		const { batchList } = this.props;

		return batchList
			.map((entry) => {
				if (entry?.toGoTax?.label && entry?.toStayTax?.label) return true;
			})
			.every((element) => element);
	}

	resetSelectedRows(reduxKey) {
		const { listMarkets, listPosConfigurationVersions } = this.props;

		if (listMarkets || listPosConfigurationVersions) {
			store.dispatch(actions.resetSelection({ reduxKey }));
		}
	}

	_goToStep(step) {
		const shouldAllowOverFlow = step === 2 || step === 4;

		this.setState(() => ({ allowOverflow: shouldAllowOverFlow }));

		this.modalRef.current.goToStep(step);
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			batchFormData,
			handleClose,
			activeTenant,
		} = this.props;

		const steps = [
			{
				component: (
					<div className="edit-modal-choice__content">
						<ButtonTile
							icon="add"
							type="inverted"
							shadow
							onClick={() => this._goToStep(2)}
						>
							{phrases.MODAL_BATCH_CREATE_POS_CONFIGURATION}
						</ButtonTile>
						<ButtonTile
							icon="add"
							type="inverted"
							shadow
							onClick={() => this._goToStep(4)}
						>
							{phrases.MODAL_BATCH_DUPLICATE_POS_CONFIGURATION}
						</ButtonTile>
					</div>
				),
				title: phrases.MODAL_BATCH_MAIN_MENU_TITLE,
				customFooter: <></>,
				loading: false,
				allowOverflow: true,
			},
			{
				component: (
					<PosConfigurationsBatchStepFormAdd
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						marketsUrl={this.marketsUrl}
						resetSelection={this.resetSelectedRows}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE_ADD,
				isValid: this._getIsBatchAddStepFormValid(),
				onBack: () => this._goToStep(1),
				onNext: () => this._onSubmitAddForm(),
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				noScroll: false,
			},
			{
				component: (
					<PosConfigurationsBatchStepListAdd
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchAddSubmit(),
				onBack: () => this._goToStep(2),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
			{
				component: (
					<PosConfigurationsBatchStepFormDuplicate
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						marketsUrl={this.marketsUrl}
						posConfigurationVersionUrl={this.posConfigurationVersionUrl}
						resetSelection={this.resetSelectedRows}
						activeTenant={activeTenant}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE_DUPLICATE,
				isValid: this._getIsBatchDuplicateStepFormValid(),
				onBack: () => this._goToStep(1),
				onNext: this._onSubmitDuplicateForm,
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				noScroll: false,
			},
			{
				component: (
					<PosConfigurationsBatchStepListDuplicate
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: this.isConfirmButtonDisabled(),
				onNext: this._batchDuplicateSubmit,
				onBack: () => this._goToStep(4),
				loading: this.state.loadingModal,
				confirmButtonLabel:
					phrases.MODAL_BATCH_STEP_LIST_CONFIRM_DUPLICATE_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		const isMarketTable = this.state.overlayType === this.marketsUrl;

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="pos-modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={handleClose}
						allowOverflow={this.state.allowOverflow}
					/>
				)}

				<Overlay
					zIndex={551}
					height={850}
					list
					visible={this.state.showOverlay}
					onClose={this.handleToggleOverlay}
				>
					{isMarketTable ? (
						<ReactDataWrapper
							accessAreasAllowedToEdit={['Sales Configuration']}
							title={phrases.MODAL_BATCH_STEP_FORM_MARKET_TABLE_TITLE}
							columns={this.marketColumns}
							fetchData={this.fetchData}
							filterable
							disableFetchCsvButton
							enableMultiSelection
							batchSelection
							defaultPageSize={20}
							customAreaComponents={this.getAdditionalPosConfigurationTableFilters()}
							extraFilters={this.state.activeFilterMarkets}
							onInitialization={this.onOverlayOpen}
							reduxKey={this.state.overlayType}
							manual
							actionsWidth={0}
							style={{
								maxHeight: '720px',
							}}
						/>
					) : (
						<PosConfigurationVersionTable
							batchSelection
							reduxKey={this.state.overlayType}
							defaulfPageSize={20}
							onInitialization={this.onOverlayOpen}
							style={{
								maxHeight: '720px',
							}}
						/>
					)}
				</Overlay>
			</>
		);
	}
}

PosConfigurationsModalBatch.defaultProps = {
	modalVisible: false,
	headers: [],
	editableCells: [],
	loading: false,
};

PosConfigurationsModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	updateBatchForm: PropTypes.func,
	handleClose: PropTypes.func,
	batchList: PropTypes.array,
	addBatchList: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchFormData: PropTypes.object,
	listMarkets: PropTypes.object,
	listPosConfigurationVersions: PropTypes.object,
	fetchMarkets: PropTypes.func,
	addPosConfiguration: PropTypes.func,
	activeTenant: PropTypes.object,
};

export default PosConfigurationsModalBatch;
