import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import ReportingCurrenciesList from './services/reportingCurrenciesList.service.js';

const reportingCurrenciesListReduxKey = '/reporting/reporting_currencies';
const reportingCurrenciesList = new ReportingCurrenciesList(reportingCurrenciesListReduxKey);
const reportingCurrenciesListProps = reportingCurrenciesList.getProps();

export function ReportingCurrencies() {
	useEffect(() => {
		reportingCurrenciesList.initView();
	}, []);

	return <ListComponent reduxKey={reportingCurrenciesListReduxKey} {...reportingCurrenciesListProps} />;
}
