import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// redux
import { useAppSelector } from 'appState/storeHooks';

import { useAuth0 } from '@auth0/auth0-react';
import AuthService from 'auth.service';
import _debounce from 'lodash/debounce';

// Actions

const getUserAccessRolesAndTenants = _debounce(
	(userId) => {
		AuthService.getUserAccessRoles(userId);
		AuthService.getUserTenants(userId);
	},
	50,
	{ trailing: true }
);

type Props = {
	children: React.ReactNode;
};

const AccessRightsProvider = ({ children }: Props) => {
	const accessToken = useAppSelector((store) => store?.userData?.accessToken);
	const { user } = useAuth0();
	const userId = AuthService.getUserIdFromUserObject(user);

	useEffect(() => {
		if (!userId || !accessToken) return;
		getUserAccessRolesAndTenants(userId);
	}, [userId, accessToken]);

	return <>{children}</>;
};

AccessRightsProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AccessRightsProvider;
