import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SalaryComponentDetailsComponent as SalaryComponentDetails } from 'salary/salaryComponents/salaryComponentDetails.component';
import SalaryComponents from 'salary/salaryComponents/salaryComponents.container';
import { SalaryGroupDetailsComponent as SalaryGroupDetails } from 'salary/salaryGroups/components/salaryGroups/salaryGroupDetails.component.js';

import TileDirectory from 'smart/tileDirectory/tileDirectory';

const DocuSignTemplates = React.lazy(() =>
	import(
		/* webpackChunkName: "DocuSignTemplates" */ 'salary/docuSignTemplates/docuSignTemplates.container.jsx'
	)
);

// const SalaryComponents = React.lazy(() =>
// 	import(/* webpackChunkName: "SalaryComponents" */ 'salary/salaryComponents/salaryComponents.container')
// );

const SalaryExports = React.lazy(() =>
	import(
		/* webpackChunkName: "SalaryExports" */ 'salary/salaryExports/salaryExports.component.tsx'
	)
);

const SalaryGroups = React.lazy(() =>
	import(
		/* webpackChunkName: "SalaryGroups" */ 'salary/salaryGroups/components/salaryGroups/salaryGroups.container.jsx'
	)
);

export const salaryRoutes = [
	{
		element: <Outlet />,
		path: 'salary',
		title: 'salary',
		allowedAccessControlLevels: ['Sales Audit Admin'],
		icon: require('@mui/icons-material/LocalAtmOutlined').default,
		children: [
			{
				index: true,
				element: <TileDirectory pathName="salary" />,
			},
			{
				element: (
					<Suspense fallback="Loading">
						<DocuSignTemplates />
					</Suspense>
				),
				path: 'docu-sign',
				title: 'Docu sign',
				description: 'Manage Docusign',
				allowedAccessControlLevels: ['Salary Admin'],
			},
			{
				element: <Outlet />,
				path: 'salary-components',
				title: 'Salary components',
				description: 'Manage salary components',
				allowedAccessControlLevels: ['Salary Admin'],
				children: [
					{
						index: true,
						element: <SalaryComponents />,
					},
					{
						element: <SalaryComponentDetails />,
						path: ':salaryComponentId',
						title: 'Salary components details',
						allowedAccessControlLevels: ['Salary Admin'],
					},
				],
			},
			{
				element: (
					<Suspense fallback="Loading">
						<SalaryExports />
					</Suspense>
				),
				path: 'salary-exports',
				title: 'Salary exports',

				description: 'Manage salary exports',
				allowedAccessControlLevels: ['Salary Admin'],
			},
			{
				element: <Outlet />,
				path: 'salary-groups',
				title: 'Salary groups',
				description: 'Manage salary groups',
				allowedAccessControlLevels: ['Salary Admin'],
				children: [
					{
						index: true,
						element: (
							<Suspense fallback="Loading">
								<SalaryGroups />
							</Suspense>
						),
					},
					{
						element: <Outlet />,
						path: ':salaryGroupId',
						title: 'Salary Group details',
						allowedAccessControlLevels: ['Salary Admin'],
						children: [
							{
								index: true,
								element: <SalaryGroupDetails />,
							},
							{
								element: <SalaryComponentDetails />,
								path: 'component/:salaryComponentId',
								title: 'Salary components details',
								allowedAccessControlLevels: ['Salary Admin'],
							},
						],
					},
				],
			},
		],
	},
];
