import React, { useCallback, useState } from 'react';
import { ReactDataWrapper } from 'reactDataWrapper';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { Input } from 'dumb';
import {
	createMarketPartnership,
	fetchMarketPartnerships,
	deleteMarketPartnership,
} from './marketPartnerships.service';
import { IColumnHeader } from 'types/reactDataWrapper';
import { z, ZodError } from 'zod';
import { FormErrors } from 'types/utils';

type FormState = Partial<IPartnership>;

const MarketPartnershipSchema = z.object({
	market: z.object({ id: z.number(), name: z.string() }),
	partnership_id: z.string().uuid(),
});

const reduxKey = 'market-partnerships';

type MarketPartnershipsProps = {
	marketId: number;
};

export default function MarketPartnerships({
	marketId,
}: MarketPartnershipsProps) {
	const [formState, setFormState] = useState<FormState>();
	const [formErrors, setFormErrors] = useState<FormErrors<FormState>>({});

	const columns: IColumnHeader<IPartnership>[] = [
		{
			Header: 'Partnership ID',
			id: 'partnershipId',
			accessor: (d) => d.partnership_id,
			filterPath: ':partnership_id',
		},
	];

	const editableCells = [
		{
			header: 'Partnership ID',
			value: (
				<Input
					id="partnershipId"
					errors={formErrors.partnership_id}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setFormState({ ...formState, partnership_id: e.target.value })
					}
					value={formState?.partnership_id}
				/>
			),
		},
	];

	const handleCreateEntry = async () => {
		const validatedForm = MarketPartnershipSchema.parse({
			...formState,
			market: { id: marketId, name: '' },
		});

		const result = await createMarketPartnership(validatedForm);
		setFeedback('Successfully created partnership', 1);
		return result;
	};

	const handleDeleteEntry = async (partnershipId: number) => {
		await deleteMarketPartnership(partnershipId);
		setFeedback('Successfully deleted partnership', 1);
	};

	const handleModalClose = useCallback(() => {
		setFormState(undefined);
		setFormErrors({});
	}, [setFormState, setFormErrors]);

	return (
		<ReactDataWrapper
			accessAreasAllowedToEdit={['Organization Admin']}
			title="Partnerships"
			columns={columns}
			fetchData={fetchMarketPartnerships}
			extraFilters={`:market.id=='${marketId}'`}
			filterable
			reduxKey={reduxKey}
			onError={(e: ZodError) => {
				setFormErrors(e.flatten().fieldErrors);
			}}
			manual
			createEntry={handleCreateEntry}
			editableCells={editableCells}
			onModalClose={handleModalClose}
			deleteEntry={handleDeleteEntry}
			defaultPageSize={5}
			deleteConfirm
		/>
	);
}
